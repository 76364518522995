import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Alert from "shared/components/Alert";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import Empty from "../Empty";

const processData = ({ data, type, meta }) => {
  let result;
  let count = 1;
  if (meta === "object") {
    result = data[type];
  } else {
    result = data[type].data;
    count = data[type].count;
  }
  return { count, result };
};

const HandleQuery = (WrappedComponent) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  const Process = ({
    error,
    loading,
    data,
    type,
    meta,
    mapper,
    NoDataStyle,
    NoData,
    ...rest
  }) => {
    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <Alert showIcon type="error" message={API_ERRORS.GENERIC} />;
    }
    const { count, result } = processData({ data, type, meta });
    if (isEmpty(result)) {
      return <NoData {...rest} NoDataStyle={NoDataStyle} />;
    }
    return <WrappedComponent data={result} count={count} type={type} {...rest} />;
  };

  Process.propTypes = {
    Loading: PropTypes.func,
    Error: PropTypes.func,
    NoData: PropTypes.func,
    error: PropTypes.object,
    loading: PropTypes.bool,
    data: PropTypes.object,
    type: PropTypes.string,
    meta: PropTypes.string,
    mapper: PropTypes.func,
  };

  Process.defaultProps = {
    Loading: () => <Loading />,
    mapper: (data) => data,
    meta: "array",
    NoData: Empty,
    error: undefined,
    loading: false,
    query: PropTypes.shape({
      data: [],
      count: 0,
    }),
  };

  return Process;
};

HandleQuery.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
};

HandleQuery.defaultProps = {};

export default HandleQuery;
