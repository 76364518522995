import cuid from "cuid";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import qs from "qs";
import { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { BOOLEAN, INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const { user } = useContext(StoreContext);
  const process = qs.parse(search, {
    ignoreQueryPrefix: true,
    depth: 10,
    integer: true,
  });
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path);
  const [addProject] = useMutation(graphql.mutations.CREATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: { where: { visible: true }, ...INITIAL_VALUES },
      },
    ],
    onCompleted: ({ addProject }) => {
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${addProject.id}`
      );
    },
    onError: () => {},
  });

  const onSubmit = (_) => {
    const { name } = _;
    const status = omitDeep(process.processStatuses, "__typename").reduce(
      (previous, current) =>
        Number(previous?.order) < Number(current.order) ? previous : current
    );
    return addProject({
      variables: {
        data: {
          id,
          name,
          status: { ...status, moveToNext: BOOLEAN[status.moveToNext] },
          contact: get(state, "project.contact"),
          fields: get(state, "project.fields", {}),
          author: { id: user.id },
          generalCosts: get(state, "project.fields.offer", []).map(
            (insurancePlan) => ({
              id: cuid(),
              insurancePlan: { id: insurancePlan.id },
              project: { id },
              cost: "0",
            })
          ),
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} process={process} />;
};

export default Create;
