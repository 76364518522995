import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import {
  CONTACT_POINT_SYSTEM,
  PROJECT_SOURCE,
  PROJECT_TYPES,
  SALE_MODES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { getMaxDeltaEnd, getMinDeltaStart } from "utils/helpers/array";
import View from "./View";

const AdherentInfo = ({ project, onNext, onBack }) => {
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [deltaEndRia, setDeltaEndRia] = useState();
  const [deltaEnd, setDeltaEnd] = useState();
  const [franceAddressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useQuery(INSURANCE_PLANS, {
    variables: { where: {} },
    onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
      const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
      const deltaStart = getMinDeltaStart({
        insurancePlans,
        minValue: 1,
        key: "DELTA_START",
      });
      const deltaEndRia = getMaxDeltaEnd({ insurancePlans }) || 0;
      const deltaEnd =
        getMaxDeltaEnd({
          insurancePlans,
          key: "DELTA_END",
        }) || 0;
      setDeltaRia(deltaRia);
      setDeltaStart(deltaStart);
      setDeltaEndRia(deltaEndRia);
      setDeltaEnd(deltaEnd);
    },
  });
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });
  const next = ({ contact: { telecoms, user, ...rest }, fields }) => {
    const mappedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const deltaS = rest.ria ? deltaRia : deltaStart;
    const deltaE = rest.ria ? deltaEndRia : deltaEnd;

    const issuanceDate = moment
      .max(
        moment().add(deltaS, "days"),
        moment().add(1, "year").startOf("y").add(5, "h")
      )
      .toDate();

    onNext({
      payload: {
        contact: {
          id: cuid(),
          user: { id: cuid(), ...user },
          ...rest,
          telecoms: mappedTelecoms,
          socioProfessionalCategory: SOCIO_PROFESSIONAL_CATEGORIES.OTHER,
        },
        contract: {
          id: get(project, "contract.id", cuid()),
          issuanceDate,
          saleMode: SALE_MODES.DISTANT,
        },
        projectType: PROJECT_TYPES.HOT_LEAD,
        fields: {
          ...fields,
          budget: { max: 1000, min: 0 },
          source: PROJECT_SOURCE.CLIENT,
          deltaStart: deltaS,
          deltaEnd: deltaE,
          info: { fonctionnaryOrAgent: true },
        },
      },
    });
  };
  return (
    <View
      onNext={next}
      contact={{ ...get(project, "contact", {}), telecoms }}
      fields={get(project, "fields", {})}
      onBack={onBack}
      franceAddressZipCodeCity={franceAddressZipCodeCity}
      locked={project.locked}
    />
  );
};

export default AdherentInfo;
