import { useMutation } from "@apollo/client";
import { notification } from "antd";
import cuid from "cuid";
import { filter, get, map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { CREATE_ATTACHMENT } from "utils/api/graphql/mutations/attachment";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import { ATTACHMENT_TYPES } from "utils/constants";
import Loading from "../Components/Spin";
import View from "./View";
export const DOCUMENTS_TYPE = {
  payerCin: "PAYER_CIN",
  cin: "CIN",
  paymentAccount: "PAYMENT_ACCOUNT",
  refundAccount: "REFUND_ACCOUNT",
};

const Justification = ({ project, onNext, onBack, updateUrl }) => {
  const [initialValues, setInitialValues] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const subscriptionId = get(project, "fields.currentSubscription");
  const [signDocuments] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });
  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const { loading: attachmentLoading } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: {
      where: {
        OR: [
          {
            subscription: {
              id: subscriptionId,
            },
          },
        ],
      },
      isIn: {
        type: Object.values(DOCUMENTS_TYPE),
      },
    },
    onCompleted: ({ attachments: { data: attachmentsList } }) => {
      setInitialValues({
        paymentAccount: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.PAYMENT_ACCOUNT
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        cin: map(
          filter(attachmentsList, ({ type }) => type === ATTACHMENT_TYPES.CIN),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
      });
      setLoading(false);
    },
  });
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);
  const onSubmit = async (documents) => {
    setLoading(true);
    const documentsToUpload = [];
    Object.entries(documents)?.map(([key, attachments]) =>
      attachments
        ?.filter((e) => !e.status)
        .forEach(({ name, contentType, base64 }) => {
          documentsToUpload.push({
            id: cuid(),
            subscription: { id: subscriptionId },
            name,
            content: base64,
            contentType,
            type: DOCUMENTS_TYPE[key],
          });
        })
    );
    await Promise.all(
      documentsToUpload.map((document, index) =>
        addAttachment({
          variables: {
            data: document,
          },
        })
      )
    );
    signDocuments({
      variables: {
        data: { id: subscriptionId },
      },
      onCompleted: () => {
        const source = localStorage.getItem("source");
        notification.open({
          message: TRANSLATION.E_SIGNATURE_SENT,
          duration: 5,
          icon: <IoCheckmarkCircleOutline color={COLORS.C_SUCCESS} />,
        });
        updateUrl({
          subscriptionId,
          url: get(
            data,
            "subscription.insurancePlan.config.generatedUrl",
            `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
          ),
        });
        onNext({
          payload: {},
        });
      },
    });
  };

  if (loading || attachmentLoading || !initialValues) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      translate={TRANSLATION}
      initialValues={initialValues}
      onBack={onBack}
    />
  );
};

export default Justification;
