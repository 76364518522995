const Stepper = ({ translate, currentStep }) => {
  return (
    <ul>
      <li className="active">
        <span>1</span>
        <span>{translate.STTEPER.SITUATION}</span>
      </li>
      <li className="active">
        <span>2</span>
        <span>{translate.STTEPER.QUOTE}</span>
      </li>
      <li className="active">
        <span>3</span>
        <span>{translate.STTEPER.SUBSCRIPTION}</span>
      </li>
      <li className={currentStep === "VALIDATION" ? "active" : ""}>
        <span>4</span>
        <span>{translate.STTEPER.VALIDATION}</span>
      </li>
    </ul>
  );
};

export default Stepper;
