import { Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import moment from "moment";
import { useState } from "react";
import {
  MdOutlineCake,
  MdOutlineChangeCircle,
  MdSocialDistance,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Input from "shared/components/Input";
import format from "string-template";
import { DATE_FORMAT } from "utils/constants";
import { bindDateInputProps } from "utils/helpers/input";

export const step = { CONTRACT: "CONTRACT" };

const Contract = ({
  index,
  translation,
  deltaRia,
  deltaStart,
  deltaEnd,
  deltaEndRia,
  values,
  ...formProps
}) => {
  const [pickerValue, setPickerValue] = useState(
    !get(values, "contract.issuanceDate") &&
      dayjs(moment.max(moment(), moment().add(1, "year").startOf("y")).toDate())
  );
  const [showInfo, setShowInfo] = useState(false);
  const checkExpired = ({ issuanceDate, isRia }) => {
    const minIssuanceDate = dayjs("31/12/2024")
      .startOf("day")
      .add(isRia ? deltaRia : deltaStart, "days");
    const maxIssuanceDate = isRia
      ? deltaEndRia === 0
        ? dayjs("31/12/2024").endOf("year")
        : dayjs("31/12/2024").add(deltaEndRia, "days")
      : deltaEnd === 0
      ? dayjs("31/12/2024").endOf("year")
      : dayjs("31/12/2024").add(deltaEnd, "days");
    const expired =
      values.expired ||
      dayjs(issuanceDate).isBefore(minIssuanceDate) ||
      dayjs(issuanceDate).isAfter(maxIssuanceDate);
    return expired;
  };

  return (
    <div className="contact-stepper" key={step.CONTRACT}>
      <div className="contact-stepper__header">
        <div className="contact-stepper__header--counter">
          <span>{index}</span>
        </div>
        <div className="contact-stepper__header--content">
          <h1 className="contact-stepper__header--content--title">
            <span>
              {translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].TITLE}
            </span>
          </h1>
          <p className="contact-stepper__header--content--description">
            {translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].DESCRIPTION}
          </p>
        </div>
      </div>
      <div className="contact-stepper__content">
        {checkExpired({
          issuanceDate: values?.contract?.issuanceDate,
          isRia: values.ria,
        }) && (
          <Alert
            showIcon
            type="error"
            message={
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].INVALID_DATE
            }
            description={format(
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT]
                .INVALID_ISSUANCE,
              {
                date: dayjs()
                  .add(values.ria ? deltaRia : deltaStart, "days")
                  .format(DATE_FORMAT),
              }
            )}
          />
        )}
        <div className="contact-stepper__content--radio">
          <MdOutlineChangeCircle size={22} />
          <span className="contact-stepper__content--radio--title">
            {
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM.CONTRACT
                .LABEL
            }
          </span>
          <Switch
            className="contact-stepper__content--radio--switch button__switch"
            checkedChildren={
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM.CONTRACT
                .OPTIONS.YES
            }
            unCheckedChildren={
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM.CONTRACT
                .OPTIONS.NO
            }
            defaultChecked={values.ria}
            onChange={(value, _) => {
              formProps.setFieldValue("ria", value);
              if (
                value &&
                checkExpired({
                  issuanceDate: values?.contract?.issuanceDate,
                  isRia: value,
                })
              ) {
                setShowInfo(true);
                formProps.setFieldValue(
                  "contract.issuanceDate",
                  dayjs().add(deltaRia, "days").toDate()
                );
              }
              setPickerValue(
                value
                  ? dayjs().add(deltaRia, "days")
                  : dayjs().add(1, "y").startOf("y").add(5, "h")
              );
            }}
          />
        </div>
        <Input
          icon={<MdOutlineCake size={22} />}
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM.START_DATE
              .LABEL
          }
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM.START_DATE
              .PLACEHOLDER
          }
          type="date"
          variant="simple"
          format={DATE_FORMAT}
          disabledDate={(current) => {
            const validEndDate = values.ria
              ? deltaEndRia === 0
                ? dayjs("31/12/2024").endOf("year")
                : dayjs("31/12/2024").add(deltaEndRia, "days")
              : deltaEnd === 0
              ? dayjs("31/12/2024").endOf("year")
              : dayjs("31/12/2024").add(deltaEnd, "days");
            const validStartDate = values.ria
              ? dayjs(
                  moment
                    .max(
                      moment().add(deltaRia, "days"),
                      moment().add(1, "year").startOf("y")
                    )
                    .toDate()
                )
              : dayjs(
                  moment
                    .max(
                      moment().add(deltaStart, "days"),
                      moment().add(1, "year").startOf("y")
                    )
                    .toDate()
                );
            return (
              current && (current < validStartDate || current > validEndDate)
            );
          }}
          defaultPickerValue={pickerValue}
          {...bindDateInputProps({
            name: "contract.issuanceDate",
            values,
            ...formProps,
          })}
        />
        <>
          {showInfo && (
            <Alert
              showIcon
              type="info"
              message={format(
                translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT]
                  .INVALID_ISSUANCE,
                {
                  date: dayjs()
                    .add(values.ria ? deltaRia : 0, "days")
                    .format(DATE_FORMAT),
                }
              )}
            />
          )}
        </>
        <div className="contact-stepper__content--radio">
          <MdSocialDistance size={22} />
          <span className="contact-stepper__content--radio--title">
            {
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM
                .CONTRACT_TYPE.LABEL
            }
          </span>
          <Switch
            className="contact-stepper__content--radio--switch button__switch"
            checkedChildren={
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM
                .CONTRACT_TYPE.OPTIONS.YES
            }
            unCheckedChildren={
              translation.TEMPLATES.CONTACT.STEPS[step.CONTRACT].FORM
                .CONTRACT_TYPE.OPTIONS.NO
            }
            onChange={(value, _) => {
              formProps.setFieldValue("fields.twoTimesSale", value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Contract;
