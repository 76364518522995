import { get } from "lodash";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { PROJECT } from "utils/api/graphql/queries/projects";
import { TRACER } from "utils/api/graphql/queries/tracers";
import { TRACER_TYPES } from "utils/constants";

const Default = ({ project }) => {
  return <div>No tracer found</div>;
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: PROJECT,
        awaitRefetchQueries: true,
        variables: { where: { id: project.id } },
      },
    ],
  });

  useQuery(TRACER, {
    variables: {
      where: { type: TRACER_TYPES.COMPARISON },
    },
    onCompleted: ({ tracer }) => {
      const status = get(tracer, "flow.process.processStatuses",[]).reduce(
        (previous, current) =>
          Number(previous?.order) < Number(current.order) ? previous : current
      );

      const currentStatus = get(
        project,
        `fields.syncActions.${get(tracer, "flow.process.id")}`
      ) || { id: get(status, "id") };

      updateProject({
        variables: {
          where: { id: project.id },
          data: {
            statuses: [
              ...get(project, "statuses", []).map(({ id }) => ({ id })),
              currentStatus,
            ],
          },
        },
      });
    },
  });
};

export default Default;
