import cuid from "cuid";
import View from "./View";

const EffectiveDate = ({ project, onNext, onBack }) => {
  const contract = project.contract || { id: cuid() };
  const next = (contract) =>
    onNext({
      payload: {
        contract,
      },
    });

  return <View onNext={next} contract={contract} onBack={onBack} />;
};

export default EffectiveDate;
