import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Default from "./Default";
import EligibilityCheck from "./EligibilityCheck";
import Quote from "./Quote";
import TerminalInfo from "./TerminalInfo";

const Templates = {
  Loading: Loading,
  [TEMPLATES.B2B.THEMES.B2B_SMART_PHONE.ELIGIBILITY_CHECK]: EligibilityCheck,
  [TEMPLATES.B2B.THEMES.B2B_SMART_PHONE.TERMINAL_INFO]: TerminalInfo,
  [TEMPLATES.B2B.THEMES.B2B_SMART_PHONE.CONTACT]: Contact,
  [TEMPLATES.B2B.THEMES.B2B_SMART_PHONE.QUOTE]: Quote,
  [TEMPLATES.B2B.THEMES.B2B_SMART_PHONE.COMPARISON]: Comparison,
  default: Default,
};
export default Templates;
