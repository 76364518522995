import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineAlternateEmail,
  MdOutlinePinDrop,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM, SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Contact = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const [cities, setCities] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });
  const [addressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useEffect(() => {
    addressZipCodeCity({
      variables: {
        where: {
          zipCode: get(
            initialValues,
            `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
          ),
        },
      },
      onCompleted: ({ franceAddressZipCodeCity: { response } }) => {
        setCities(response);
      },
    });
  }, []);

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
              icon={<MdOutlinePinDrop size={16} />}
              placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
              )}
              errors={
                errors[
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                ]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  ]
                )
              }
              disabled
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.ADDRESS.LABEL}
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={SUBSCRIPTION.FORM.ADDRESS.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                  ]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.EMAIL.LABEL}
              icon={<MdOutlineAlternateEmail size={16} />}
              placeholder={SUBSCRIPTION.FORM.EMAIL.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
              }
              touched={
                !isEmpty(
                  errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
