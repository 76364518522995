import classNames from "classnames";

const Stepper = ({ items = [] }) => {
  return (
    <div className="stepper">
      {items.map(({ label, completed }, i) => (
        <div key={i} className={classNames("step", { completed })}>
          <div className="step-number">{i + 1}</div>
          <div className="step-title">{label}</div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
