import classNames from "classnames";
import { CURRENCY_SYMBOL } from "utils/constants";

const Card = ({ cost, currency = "DEFAULT", insurancePlan, period }) => {
  const [integer, decimal = ""] = cost?.toString()?.split(".") || ["-"];
  const symbol = CURRENCY_SYMBOL[currency];
  return (
    <div className="insurance-plan">
      <img
        className="insurance-plan__icon"
        src={insurancePlan.icon}
        alt={insurancePlan.name}
      />
      <div className="insurance-plan__title">
        {insurancePlan.name}
        <div
          className={classNames("insurance-plan__title--light", {
            hidden: !insurancePlan.description,
          })}
        >
          {insurancePlan.description || "-"}
        </div>
      </div>
      <div className="insurance-plan__bottom">
        <div className="insurance-plan__cost">
          <span className="insurance-plan__cost--integer">
            {`${integer} ${symbol} `}
            <span className="insurance-plan__cost--decimal">{`${decimal}`}</span>
          </span>
          <span className="insurance-plan__cost--period">{` /${period}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
