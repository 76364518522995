import PropTypes from "prop-types";

import { Switch as SwitchComponent } from "antd";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Label from "shared/components/Label";
import { NAME_SPACES } from "shared/locales/constants";

const Switch = ({
  label,
  placeholder,
  icon,
  innerRef,
  required,
  optional,
  checkedChildren,
  unCheckedChildren,
  ...rest
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const SWITCH = t("SWITCH", { returnObjects: true });
  return (
    <div className={classnames("switch__wrapper")}>
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={""}>
        <SwitchComponent
          className={classnames("button__switch", {
            "disable--input": rest.disabled,
          })}
          ref={innerRef}
          checkedChildren={checkedChildren || SWITCH.YES}
          unCheckedChildren={unCheckedChildren || SWITCH.NO}
          {...rest}
        />
      </div>
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
};

Switch.defaultProps = {
  label: "",
};

export default Switch;
