import { TEMPLATES } from "utils/constants";
import AdherentInfo from "./AdherentInfo";
import AdherentTelecoms from "./AdherentTelecoms";
import Loading from "./Components/Spin";
import ContactUs from "./ContactUs";
import SubscriptionContract from "./Contract";
import Default from "./Default";
import ElectronicSignature from "./ElectronicSignature";
import Justification from "./Justification";
import NoSubscription from "./NoSubscription";
import Payment from "./Payment";
import Products from "./Products";
import Subscription from "./Subscription";
import SubscriptionDone from "./SubscriptionDone";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES.RADIANCE.B2C_SUBSCRIPTION]: Subscription,
  [TEMPLATES.B2C.THEMES.RADIANCE.PAYMENT]: Payment,
  [TEMPLATES.B2C.THEMES.RADIANCE.B2C_SUBSCRIPTION_CONTRACT]:
    SubscriptionContract,
  [TEMPLATES.B2C.THEMES.RADIANCE.B2C_SUBSCRIPTION_DONE]: SubscriptionDone,
  [TEMPLATES.B2C.THEMES.RADIANCE.JUSTIFICATIONS]: Justification,
  [TEMPLATES.B2C.THEMES.RADIANCE.ADHERENT_INFO]: AdherentInfo,
  [TEMPLATES.B2C.THEMES.RADIANCE.ADHERENT_TELECOMS]: AdherentTelecoms,
  [TEMPLATES.B2C.THEMES.RADIANCE.PRODUCTS]: Products,
  [TEMPLATES.B2C.THEMES.RADIANCE.CONTACT_US]: ContactUs,
  [TEMPLATES.B2C.THEMES.RADIANCE.NO_SUBSCRIPTION]: NoSubscription,
  [TEMPLATES.B2C.THEMES.RADIANCE.E_SIGN]: ElectronicSignature,
  default: Default,
};
export default Templates;
