import { useQuery } from "@apollo/client";
import cuid from "cuid";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { TRACER } from "utils/api/graphql/queries/tracers";
import { PROJECT_TYPES, SALE_MODES, SCREENS } from "utils/constants";

const Create = () => {
  const navigate = useNavigate();
  const [addProject] = useMutation(graphql.mutations.CREATE_PROJECT);
  const { src } = useParams();
  useQuery(TRACER, {
    fetchPolicy: "no-cache",
    variables: {
      where: { source: { id: src } },
    },
    onCompleted: ({ tracer }) => {
      const status = omitDeep(
        get(tracer, "flow.process.processStatuses",[]),
        "__typename"
      ).reduce((previous, current) =>
        Number(previous?.order) < Number(current.order) ? previous : current
      );
      addProject({
        variables: {
          data: {
            id: cuid(),
            statuses: [{ id: cuid(), status: { id: get(status, "id") } }],
            projectType: PROJECT_TYPES.HOT_LEAD,
            contract: {
              id: cuid(),
              saleMode: SALE_MODES.DISTANT,
            },
            fields: { processTemplate: get(tracer, "flow.process.theme") },
            visible: false,
          },
        },
        onCompleted: ({ addProject }) => {
          localStorage.setItem("projectId", addProject?.id);
          navigate(
            `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${addProject?.id}`,
            {
              state: {
                process: get(omitDeep(tracer, "__typename"), "flow.process"),
                tracer: omitDeep(tracer, "__typename"),
              },
            }
          );
        },
      });
    },
  });
};

export default Create;
