import { notification } from "antd";
import { get } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import format from "string-template";
import graphql from "utils/api/graphql";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_PARAMS_RULES,
} from "utils/constants";
import View from "./View";

const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const ChangeProduct = ({ subscription, setInsurancePlanId, translate }) => {
  const [loading, setLoading] = useState(true);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS);
  const [generalCosts, { data }] = useLazyQuery(GENERAL_COSTS);
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: get(subscription, "id") } },
        },
      ],
    }
  );
  const [selectedInsurancePlan, setSelectedInsurancePlan] = useState(
    get(subscription, "insurancePlan.id")
  );

  const changeInsurancePlan = () => {
    updateSubscription({
      variables: {
        where: {
          id: get(subscription, "id"),
        },
        data: { insurancePlan: { id: selectedInsurancePlan } },
      },
      onCompleted: () => {
        notification.open({
          message: translate.PRODUCT_CHANGED_SUCCESSFULY,
          duration: 5,
          type: "success",
        });
        setInsurancePlanId(false);
      },
    });
  };
  useEffect(() => {
    const issuanceDate = moment(
      get(subscription, "project.contract.issuanceDate")
    ).startOf("day");
    const issuanceDateDifference = issuanceDate.diff(
      moment().startOf("day"),
      "days"
    );

    const adherentAge = moment(
      get(subscription, "project.contact.user.birthDate")
    ).startOf("year");

    const age = moment().diff(adherentAge, "years");
    const [startKey, endKey] = subscription.project.ria
      ? [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
        ]
      : [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
        ];

    const socialRegime = get(subscription, "project.contact.socialRegime");

    const exerciseFrame = get(subscription, "project.contact.exerciseFrame");

    const department = get(
      get(subscription, "project.contact.telecoms").find(
        ({ system }) => system === CONTACT_POINT_SYSTEM.ZIP_CODE
      ),
      "value",
      ""
    )
      .toString()
      .substring(0, 2);

    const processId = get(subscription, "project.status.process.id");

    const sqlConditions = [
      generateSQLCondition(
        startKey,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        issuanceDateDifference
      ),
      generateSQLCondition(
        endKey,
        `r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value ${
          moment(get(subscription, "project.contract.issuanceDate")).year() ===
          moment().year()
            ? "OR CAST(r.value AS NUMERIC) = 0"
            : ""
        }`,
        issuanceDateDifference
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        age
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
        age
      ),
      // generateSQLCondition(
      //   INSURANCE_PLAN_PARAMS_RULES.EXERCISE_FRAME,
      //   "r.value IS NULL OR CAST(r.value AS TEXT) = '' OR CAST(r.value AS TEXT) = ':value' ",
      //   exerciseFrame
      // ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${socialRegime}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${department}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.PROCESSES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${processId}'`
      ),
    ];

    const insurancePlanFilter = {
      raw: sqlConditions,
      custom: {
        AND: [
          {
            OR: [
              { startDate: null },
              {
                startDate: issuanceDate,
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { endDate: null },
              {
                endDate: issuanceDate,
                operation: ">=",
              },
            ],
          },
        ],
      },
    };
    buildGeneralCosts({
      variables: {
        data: {
          project: { id: get(subscription, "project.id") },
          insurancePlanFilter,
        },
      },
      onCompleted: ({ buildGeneralCosts }) => {
        const ids = buildGeneralCosts
          .filter(({ cost }) => cost)
          .map(({ id }) => id);
        generalCosts({
          variables: {
            isIn: { id: ids },
          },
          onCompleted: () => setLoading(false),
        });
      },
    });
  }, []);

  if (loading) return <Loading />;
  return (
    <View
      selectedInsurancePlan={selectedInsurancePlan}
      setSelectedInsurancePlan={setSelectedInsurancePlan}
      changeInsurancePlan={changeInsurancePlan}
      translate={translate}
      onCloseProduct={() => setInsurancePlanId(false)}
      project={get(subscription, "project")}
      products={omitDeep(get(data, "generalCosts.data"), "__typename")}
    />
  );
};

export default ChangeProduct;
