import PropTypes from "prop-types";
import Button from "shared/components/Button";

const ClientInformation = ({
  title,
  info,
  variant = "default",
  onModify,
  disabled,
}) => {
  return (
    <div className={`client__information client__information--${variant}`}>
      <h3 className="client__information--title">{title}</h3>
      <div className="client__information--content">
        {info.map(({ label, value }, index) => (
          <div key={index} className="client__information--item">
            <span className="client__information--label">{label}</span>
            <span className="client__information--value">{value}</span>
          </div>
        ))}
      </div>
      {onModify && variant === "client" && (
        <div className="action">
          <Button
            type="primary--underline"
            onClick={onModify}
            className="btn--modify"
            disabled={disabled}
          >
            Modifier
          </Button>
        </div>
      )}
    </div>
  );
};

ClientInformation.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  variant: PropTypes.oneOf(["default", "client"]),
  onModify: PropTypes.func,
};

export default ClientInformation;
