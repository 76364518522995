import { useFormik } from "formik";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsFileText,
  BsUpload,
} from "react-icons/bs";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import TruncateName from "shared/components/TruncateName";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import { bindInputProps } from "utils/helpers/input";
import Stepper from "../Components/Stepper";
import SubscriptionRecap from "../Components/SubscriptionRecap";

const View = ({
  onBack,
  onSubmit,
  download,
  subscription,
  attachments,
  initialValues,
  generalCost,
}) => {
  const pageSize = 3;
  const pageLength = Math.ceil(attachments.length / pageSize);
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
    });

  const validForm = () =>
    get(values, "fields.acceptTeletransmission") &&
    get(values, "fields.acceptTeletransmission");

  return (
    <Container size="medium">
      <div className="b2c__area contract_wrapper">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: true },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />
        <div className="b2c__area--header">
          <h2 className="b2c__area--header__title">
            {TRANSLATION.CONTRACT.TITLE}
          </h2>
          <p className="b2c__area--header__subtitle">
            {TRANSLATION.CONTRACT.SUBTITLE}
          </p>
        </div>
        <div className="subscription__section">
          <SubscriptionRecap
            translate={TRANSLATION}
            generalCost={generalCost}
            subscription={subscription}
          />
        </div>
        <div className="documents__section">
          <span className="documents__section--title">
            {TRANSLATION.DOCUMENTS.LABEL}
          </span>
          <div className="documents__section--content">
            {attachments
              .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
              .map(({ id, name }, index) => (
                <div
                  className="documents__section--content__card"
                  key={`document-${index}`}
                >
                  <span className="documents__section--content__card_title">
                    <BsFileText size={20} />
                    <span title={name}>{TruncateName(name)}</span>
                  </span>
                  <Button type="danger" onClick={() => download(id)}>
                    <BsUpload />
                    {TRANSLATION.DOWNLOAD}
                  </Button>
                </div>
              ))}
          </div>
          <div className="documents__section--pagination">
            <Button
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={pageIndex < 1}
            >
              <BsArrowLeftCircle size={32} />
            </Button>
            <span>
              {pageIndex + 1} sur {pageLength} pages
            </span>
            <Button
              onClick={() => setPageIndex(pageIndex + 1)}
              disabled={pageIndex >= pageLength - 1}
            >
              <BsArrowRightCircle size={32} />
            </Button>
          </div>
        </div>
        <Input
          type="checkbox"
          label={TRANSLATION.TELETRANSMISSION.LABEL}
          checked={get(values, "fields.acceptTeletransmission", false)}
          {...bindInputProps({
            name: "fields.acceptTeletransmission",
            values,
            ...formProps,
          })}
        />
        <Input
          type="checkbox"
          label={format(TRANSLATION.AGREEMENT.LABEL, {
            product: get(generalCost, "insurancePlan.name"),
          })}
          checked={get(values, "fields.acceptAgreement", false)}
          {...bindInputProps({
            name: "fields.acceptAgreement",
            values,
            ...formProps,
          })}
        />

        <div className="b2c__area--actions">
          <Button onClick={onBack} className="prev">
            <BsArrowLeftCircle size={32} />
            <span>{TRANSLATION.PREVIOUS}</span>
          </Button>

          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            disabled={!validForm()}
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
