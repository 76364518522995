import { MdOpenInNew } from "react-icons/md";
import { CURRENCY_SYMBOL } from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const Card = ({
  icon,
  name,
  cost,
  currency,
  period,
  perYear,
  allIncluded,
  link,
}) => {
  return (
    <div className="quote-template__product-card">
      <div className="quote-template__product-card--body">
        <img src={icon} alt={name} />

        <div className="quote-template__product-card--infos">
          <h4 className="quote-template__product-card--name">{name}</h4>
          <h4>
            <span className="quote-template__product-card--price">
              {costToFrench(cost)} {CURRENCY_SYMBOL[currency]}
            </span>
            <small> {period}</small>
          </h4>
          <span>
            {`${(+cost * 12).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${CURRENCY_SYMBOL[currency]} ${perYear} `}
            <small>{allIncluded}</small>
          </span>
        </div>
        <div className="quote-template__product-card--link">
          <MdOpenInNew size={14} />
          {link}
        </div>
      </div>
    </div>
  );
};

export default Card;
