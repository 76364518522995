import { gql } from "@apollo/client";

const COVERAGE_BENEFIT_ADDED = gql`
  subscription CoverageBenefitAdded($where: CoverageBenefitWhereInput) {
    coverageBenefitAdded(where: $where) {
      id
      order
      type
    }
  }
`;

const COVERAGE_BENEFIT_UPDATED = gql`
  subscription CoverageBenefitUpdated($where: CoverageBenefitWhereInput) {
    coverageBenefitUpdated(where: $where) {
      id
      order
      type
    }
  }
`;

const COVERAGE_BENEFIT_DELETED = gql`
  subscription CoverageBenefitDeleted($where: CoverageBenefitWhereInput) {
    coverageBenefitDeleted(where: $where) {
      id
      order
      type
    }
  }
`;

const coverageBenefits = {
  COVERAGE_BENEFIT_ADDED,
  COVERAGE_BENEFIT_UPDATED,
  COVERAGE_BENEFIT_DELETED,
};

export default coverageBenefits;
