import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import PricingItem from 'shared/components/SmartphoneWidgets/PricingItem';
import OfferDetails from 'shared/components/SmartphoneWidgets/OfferDetails';
import Icon from 'shared/components/Icon';

const PricingCard = ({
  title,
  description,
  price,
  monthlyPrice,
  includedItems,
  excludedItems,
  onLearnMore,
}) => {
  return (
    <div className="pricingVertical__card">
      <div className="pricingVertical__card--header">
        <PricingItem
          title={title}
          description={description}
          price={price}
          monthlyPrice={monthlyPrice}
          variant="compact"
          onLearnMore={onLearnMore}
        />
      </div>

      <div className='content'>
        <div className="pricingVertical__card--summary">
          <div className="pricingVertical__card--summary-item">
            <Icon name="info" size={20} />
            <span><strong>Un seul sinistre</strong> pris en charge par année.</span>
          </div>
          <div className="pricingVertical__card--summary-item">
            <Icon name="handshake" size={20} />
            <span>Plafond annuel d’indemnisation <strong>238 660 XPF</strong></span>
          </div>
        </div>

        <div className="pricingVertical__card--details">
          <div className='box'>
            <OfferDetails
              title="Inclut dans cette offre :"
              icon={<Icon name="check_small" size={20} color="green" />}
              items={includedItems}
            />
          </div>
          <div className='box'>
            <OfferDetails
              title="Exclu de cette offre :"
              icon={<Icon name="close" size={20} color="red" />}
              items={excludedItems}
            />
          </div>
        </div>

        <div className="pricingVertical__card--action">
          <Button type="primary--underline" onClick={onLearnMore} className="btn--modify">
            En savoir plus
          </Button>
        </div>
      </div>


    </div>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  includedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  excludedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onLearnMore: PropTypes.func,
};

export default PricingCard;
