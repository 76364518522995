import classNames from "classnames";
import { useFormik } from "formik";
import { get } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { CONTACT_POINT_SYSTEM, USER_TYPES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Advisor from "./UserTypes/Advisor";
import Employee from "./UserTypes/Employee";
import Representative from "./UserTypes/Representative";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const formProps = useFormik({
    initialValues: {
      meta: {
        type:
          user?.meta?.type === USER_TYPES.INTERNAL_SUPERVISOR.type
            ? USER_TYPES.INTERNAL_ADVISOR.type
            : null,
        agency:
          user?.meta?.type === USER_TYPES.INTERNAL_SUPERVISOR.type
            ? null
            : undefined,
        network:
          user?.meta?.type === USER_TYPES.INTERNAL_SUPERVISOR.type
            ? null
            : undefined,
      },
      gender: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      orias: null,
      socialReason: null,
      rcsNumber: null,
      code: null,
      telecoms: {
        [CONTACT_POINT_SYSTEM.ADDRESS]: { value: null },
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
        [CONTACT_POINT_SYSTEM.CITY]: { value: null },
        [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
        [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
      },
    },
    onSubmit: onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
        returnObjects: true,
      })
    ),
  });

  const generateUserForm = (formProps) => ({
    [USER_TYPES.INSURANCE_REPRESENTATIVE.type]: (
      <Representative formProps={formProps} />
    ),
    [USER_TYPES.BROKER_REPRESENTATIVE.type]: (
      <Representative formProps={formProps} />
    ),
    [USER_TYPES.INTERNAL_ADVISOR.type]: <Advisor formProps={formProps} />,
    [USER_TYPES.BROKER_EMPLOYEE.type]: <Employee formProps={formProps} />,
  });

  return (
    <>
      <Header title={CREATE.TITLE} />
      <div className="fast-container">
        <Card head title={FORM.CREATE.TITLE} subtitle={FORM.CREATE.SUBTITLE}>
          <div className="wrapper--forms">
            <div
              className={classNames({
                hidden: user.meta.type === USER_TYPES.INTERNAL_SUPERVISOR.type,
              })}
            >
              <Radio
                isHorizontal={false}
                label={FORM.STATUS}
                options={[
                  {
                    label: FORM.SALARY,
                    value: USER_TYPES.BROKER_EMPLOYEE.type,
                  },
                  {
                    label: FORM.AGENT,
                    value: get(user, "organization.broker.isInternal")
                      ? USER_TYPES.INSURANCE_REPRESENTATIVE.type
                      : USER_TYPES.BROKER_REPRESENTATIVE.type,
                  },
                ]}
                icon={<BiUserCircle size={14} />}
                {...bindInputProps({ name: "meta.type", ...formProps })}
                onClick={(value) => {
                  if (value === USER_TYPES.BROKER_EMPLOYEE.type) {
                    formProps.setFieldValue("orias", null);
                  }
                  formProps.setFieldValue("meta.type", value);
                }}
              />
            </div>
            {generateUserForm(formProps)[get(formProps, "values.meta.type")]}
          </div>
        </Card>
        <div className="d-flex justify--between">
          <Button onClick={cancel} type="secondary--link">
            <MdArrowBackIos size={16} />
            {FORM.PREVIOUS}
          </Button>

          <Button onClick={formProps.handleSubmit} type="primary">
            {FORM.SAVE}
            <MdArrowForwardIos size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Create;
