import gql from "graphql-tag";
import {
  AUTH_ANNOTATION,
  PRIVATE_AUTH_ANNOTATION,
} from "shared/graphql/links/authBff.link";

export const CREATE_USER = gql`
  mutation AddUser($data: UserDataInput) {
    addUser(data: $data) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserDataInput, $where: UserWhereInput) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereInput) {
    deleteUser(where: $where) {
      id
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($data: UserDataInput) {
    signUp(data: $data) {
      id
    }
  }
`;

export const SIGN_UP_AUTH = gql`
  mutation signUp($data: UserDataInput) {
    signUp(data: $data) @${AUTH_ANNOTATION} {
      id
      username
      email
    }
  }
`;

export const UPDATE_USER_AUTH = gql`
  mutation updateUser($data: UserDataInput, $where: UserWhereInput) {
    updateUser(data: $data, where: $where) @${PRIVATE_AUTH_ANNOTATION} {
      id
      username
      email
    }
  }
`;

export const SIGN_IN_WITH_LINK = gql`
  mutation signInWithLink($data: LinkWhereInput) {
    signInWithLink(data: $data) @${AUTH_ANNOTATION} {
      accessToken
    }
  }
`;

export const SIGN_IN = gql`
  mutation signIn($data: InputUserAuth) {
    signIn(data: $data) @${AUTH_ANNOTATION} {
      accessToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordDataInput) {
    resetPassword(data: $data) @${AUTH_ANNOTATION}
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($data: ForgotPasswordDataInput) {
    forgotPassword(data: $data)
  }
`;

export const REQUEST_SIGN_UP = gql`
  mutation requestSignUp($data: UserDataInput) {
    requestSignUp(data: $data) {
      id
    }
  }
`;

export const ACCEPT_SIGN_UP = gql`
  mutation acceptSignUp($where: UserWhereInput) {
    acceptSignUp(where: $where) {
      id
    }
  }
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation SendTwoFactorAuthCode($data: TwoFactorAuthDataInput) {
    sendTwoFactorAuthCode(data: $data) {
      id
      email
    }
  }
`;

export const SIGN_IN_WITH_TWO_FACTOR_AUTH = gql`
  mutation signInWithTwoFactorAuth($data: InputUserTwoFactorAuth) {
    signInWithTwoFactorAuth(data: $data) @${AUTH_ANNOTATION} {
      accessToken 
    }
  }
`;

export const DOMAIN_SIGN_IN = gql`
  mutation domainSignIn($data: DomainSingInInput) {
    domainSignIn(data: $data) @${AUTH_ANNOTATION} {
      accessToken
    }
  }
`;

export const DOMAIN_SIGN_UP = gql`
  mutation addDomain($data: DomainDataInput) {
    addDomain(data: $data) @${AUTH_ANNOTATION} {
      id
    }
  }
`;

const users = {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SIGN_UP,
  SIGN_UP_AUTH,
  UPDATE_USER_AUTH,
  REQUEST_SIGN_UP,
  ACCEPT_SIGN_UP,
  SEND_VERIFICATION_CODE,
  SIGN_IN_WITH_TWO_FACTOR_AUTH,
  DOMAIN_SIGN_IN,
  DOMAIN_SIGN_UP,
};

export default users;
