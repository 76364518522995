import { SCREENS } from "utils/constants";
import ForgotPassword from "./ForgotPassword";
import Logout from "./Logout";
import NotActive from "./NotActive";
import Redirect from "./Redirect";
import ResetPassword from "./ResetPassword";
import SignIn from "./Signin";
import SignInTwoFactorAuth from "./SingInTwoFactorAuth";
import Verification from "./Verification";

const routes = [
  {
    path: SCREENS.AUTH.REDIRECT.path,
    exact: true,
    element: <Redirect />,
  },
  {
    path: SCREENS.AUTH.LOGOUT.path,
    exact: true,
    element: <Logout />,
  },
  {
    path: SCREENS.AUTH.SIGN_IN.path,
    exact: true,
    element: JSON.parse(process.env.REACT_APP_TWO_FACTOR_AUTH || false) ? (
      <SignInTwoFactorAuth />
    ) : (
      <SignIn />
    ),
  },
  {
    path: SCREENS.AUTH.VERIFICATION.path,
    exact: true,
    element: <Verification />,
  },
  {
    path: SCREENS.AUTH.RESET_PASSWORD.path,
    exact: true,
    element: <ResetPassword />,
  },
  {
    path: SCREENS.AUTH.FORGOT_PASSWORD.path,
    exact: true,
    element: <ForgotPassword />,
  },
  {
    path: SCREENS.AUTH.NOT_ACTIVE.path,
    exact: true,
    element: <NotActive />,
  },
  {
    path: "*",
    exact: false,
    element: <Redirect />,
  },
];

export default routes;
