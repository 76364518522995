import { NAME_SPACES } from "shared/locales/constants";
import contact from "./contact";
import processes from "./processes";

const translations = {
  [NAME_SPACES.PUBLIC.CONTACT]: contact,
  [NAME_SPACES.PUBLIC.PROCESSES]: processes,
};

export default translations;
