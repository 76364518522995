import dayjs from "dayjs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "utils/constants";

export const isMajor = dayjs().subtract(18, "years");

export const hundredYearsAgoStart = dayjs()
  .subtract(100, "year")
  .startOf("year");

export const twentySevenYearsAgoStart = dayjs()
  .subtract(27, "year")
  .startOf("year");

export const displayDate = ({
  date,
  format = DATE_TIME_FORMAT,
  defaultValue = "-",
}) =>
  date && moment(date).isValid() ? moment(date).format(format) : defaultValue;



export const getInitials = (firstname, lastname) => {
  if (!firstname || !lastname) return '';
  return `${firstname[0].toUpperCase()}${lastname[0].toUpperCase()}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};


export const relativeFormatDate = (dateStr) => {
  if (!dateStr) return "Date invalide";

  const date = new Date(dateStr);
  if (isNaN(date.getTime())) return "Date invalide";

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Aujourd'hui";
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return "Hier";
  }
  return date.toLocaleDateString("fr-FR");
};

export const formatTime = (timeStr) => {
  if (!timeStr) return "--:--";

  const date = new Date(timeStr);
  if (isNaN(date.getTime())) return "--:--";

  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
};

export const groupLogsByDate = (logs) => {
  return logs.reduce((groups, log) => {
    const dateGroup = relativeFormatDate(log.createdDate || log.date || null);
    if (!groups[dateGroup]) {
      groups[dateGroup] = [];
    }
    groups[dateGroup].push(log);
    return groups;
  }, {});
};
