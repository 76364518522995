import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import CollectionNeeds from "./CollectionNeeds";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Contract from "./Contract";
import Default from "./Default";
import Fast from "./Fast";
import Product from "./Product";
import Quote from "./Quote";
import SelectProducts from "./SelectProducts";

const Templates = {
  Loading: Loading,
  [TEMPLATES.B2B.THEMES.DEFAULT.CONTACT]: Contact,
  [TEMPLATES.B2B.THEMES.DEFAULT.CONTRACT]: Contract,
  [TEMPLATES.B2B.THEMES.DEFAULT.QUOTE]: Quote,
  [TEMPLATES.B2B.THEMES.DEFAULT.FAST]: Fast,
  [TEMPLATES.B2B.THEMES.DEFAULT.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2B.THEMES.DEFAULT.SELECT_PRODUCTS]: SelectProducts,
  [TEMPLATES.B2B.THEMES.DEFAULT.COMPARISON]: Comparison,
  ...Product,
  default: Default,
};
export default Templates;
