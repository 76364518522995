import classNames from "classnames";
import cuid from "cuid";
import dayjs from "dayjs";
import { FieldArray } from "formik";
import { get } from "lodash";
import {
  MdAdd,
  MdOutlineCake,
  MdOutlineDelete,
  MdReportGmailerrorred,
} from "react-icons/md";
import Input from "shared/components/Input";
import { DATE_FORMAT, RELATIONSHIP } from "utils/constants";
import { bindDateInputProps } from "utils/helpers/input";

export const step = { CHILDREN: "CHILDREN" };
const Children = ({ index, translation, values, ...formProps }) => (
  <div className="contact-stepper" key={step.CHILDREN}>
    <div className="contact-stepper__header">
      <div className="contact-stepper__header--counter">
        <span>{index}</span>
      </div>
      <div className="contact-stepper__header--content">
        <h1 className="contact-stepper__header--content--title">
          <span>
            {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].TITLE}
          </span>
        </h1>
        <p className="contact-stepper__header--content--description">
          {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].DESCRIPTION}
        </p>
      </div>
    </div>
    <div
      className={classNames("quote quote--warning", {
        hidden: values?.locked,
      })}
    >
      <MdReportGmailerrorred className="quote--icon" size={36} />
      <p className="quote--description">
        {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].QUOTE}
      </p>
    </div>
    <div className="contact-stepper__content">
      {!values.locked || values.children.length ? (
        <FieldArray
          name={"children"}
          render={(arrayHelpers) => [
            values.children.map((_, i) => [
              <div
                className="contact-stepper__content--box"
                key={`child-${_.id}`}
              >
                <div className="contact-stepper__content--box--header">
                  <h1 className="contact-stepper__content--box--header--title">
                    {`${
                      translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                        .CHILD
                    } ${i + 1}`}
                  </h1>
                  <div
                    className="contact-stepper__content--box--header--action"
                    onClick={() => {
                      arrayHelpers.remove(i);
                    }}
                  >
                    <MdOutlineDelete size={14} />
                    <span>
                      {
                        translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                          .DELETE_CHILD
                      }
                    </span>
                  </div>
                </div>
                <Input
                  icon={<MdOutlineCake size={22} />}
                  label={
                    translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                      .BIRTHDAY.LABEL
                  }
                  placeholder={
                    translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                      .BIRTHDAY.PLACEHOLDER
                  }
                  type="date"
                  variant="simple"
                  format={DATE_FORMAT}
                  disabledDate={(current) => current > dayjs()}
                  defaultPickerValue={
                    !get(values, `children.${i}.identity.user.birthDate`) &&
                    dayjs().subtract(1, "years")
                  }
                  {...bindDateInputProps({
                    name: `children.${i}.identity.user.birthDate`,
                    values,
                    ...formProps,
                  })}
                />
                {/* <Select
                icon={<MdOutlineHealthAndSafety size={22} />}
                options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                  value,
                  label: translation.SOCIAL_REGIMES[key],
                }))}
                label={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .SOCIAL_REGIME.LABEL
                }
                placeholder={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .SOCIAL_REGIME.PLACEHOLDER
                }
                {...bindInputProps({
                  name: `children.${i}.identity.socialRegime`,
                  values,
                  ...formProps,
                })}
              /> */}
              </div>,
            ]),
            <button
              key="add-child"
              className="button__action"
              onClick={() => {
                arrayHelpers.push({
                  id: cuid(),
                  relationship: RELATIONSHIP.CHILD,
                  identity: {
                    id: cuid(),
                    socialRegime: null,
                    user: {
                      id: cuid(),
                      birthDate: null,
                    },
                  },
                });
              }}
            >
              <MdAdd size={22} />
              {
                translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                  .ADD_NEW_CHILD
              }
            </button>,
          ]}
        />
      ) : (
        <p>
          {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM.NO_CHILDREN}
        </p>
      )}
    </div>
  </div>
);
export default Children;
