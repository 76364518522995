import { Switch } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineInsertComment } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Information = ({
  values,
  setFieldValue,
  errors,
  initialValues,
  formProps,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const INFORMATION = t(
    "SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.INFORMATION",
    {
      returnObjects: true,
    }
  );
  const DEFAULT_QUESTIONS = [
    "project.fields.info.cssBeneficiary",
    "project.fields.info.ccChiBeneficiary",
    "project.fields.info.protectionRegime",
    "project.contact.politicallyExposedPerson",
  ];
  const QUESTIONS = {
    "project.fields.info.cssBeneficiary": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.CSH_BENEFICIARY}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.cssBeneficiary"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.info.cssBeneficiary", e);
          }}
        />
      </div>
    ),
    "project.fields.info.participationCollectiveBeneficiary": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.PARTICIPATION_COLLECTIVE_BENEFICIARY}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.participationCollectiveBeneficiary"
          )}
          onChange={(e) => {
            set(
              initialValues,
              "project.fields.info.participationCollectiveBeneficiary",
              e
            );
          }}
        />
      </div>
    ),
    "project.fields.info.ccChiBeneficiary": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.CC_CHI_BENEFICIARY}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.ccChiBeneficiary"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.info.ccChiBeneficiary", e);
          }}
        />
      </div>
    ),
    "project.fields.info.protectionRegime": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.PROTECTION_REGIME}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.protectionRegime"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.info.protectionRegime", e);
          }}
        />
      </div>
    ),
    "project.contact.politicallyExposedPerson": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.POLITICALLY_EXPOSED_PERSON.LABEL}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.contact.politicallyExposedPerson"
          )}
          onChange={(e) => {
            set(initialValues, "project.contact.politicallyExposedPerson", e);
          }}
        />
      </div>
    ),
    "project.fields.info.fonctionnaryOrAgent": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.OFFICIAL_AGENT}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.fonctionnaryOrAgent"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.info.fonctionnaryOrAgent", e);
          }}
        />
      </div>
    ),
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION}-section`}
    >
      <Form type="vertical" title={INFORMATION.TITLE}>
        <div className="grid--1">
          {get(
            initialValues,
            "insurancePlan.config.information",
            DEFAULT_QUESTIONS
          ).map((info) => (
            <div key={info}>{QUESTIONS[info]}</div>
          ))}
          <Input
            type="textarea"
            label={INFORMATION.COMMENT}
            placeholder={INFORMATION.COMMENT}
            icon={<MdOutlineInsertComment size={16} />}
            defaultValue={get(initialValues, "project.fields.info.comment")}
            errors={errors["project.fields.info.comment"]}
            touched={!isEmpty(errors["project.fields.info.comment"])}
            onChange={({ target: { value } }) => {
              set(initialValues, "project.fields.info.comment", value);
            }}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Information;
