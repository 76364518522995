export const setPaginationHistory = ({ footprint, value, take, count }) => {
  const skip = (value - 1) * take;
  localStorage.setItem(
    "pagination",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("pagination") || "{}"),
      [footprint]: { value, skip: skip < count ? skip : 0, take, count },
    })
  );
};

export const getPaginationHistory = (footprint) => {
  const paginationHistory = JSON.parse(
    localStorage.getItem("pagination") || "{}"
  );
  return paginationHistory[footprint] || {};
};

export const clearPaginationHistory = (footprint) => {
  localStorage.setItem(
    "pagination",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("pagination") || "{}"),
      [footprint]: undefined,
    })
  );
};

export const decreasePagination = (footprint) => {
  const pagination = getPaginationHistory(footprint);
  setPaginationHistory({
    footprint,
    ...pagination,
    count: (pagination.count -= 1),
  });
};
