import { notification } from "antd";
import GeneralCosts from "entities/GeneralCosts";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { PROJECT } from "utils/api/graphql/queries/projects";
import { CONTACT_POINT_SYSTEM, NAVIGATION_DIRECTIONS } from "utils/constants";
import View from "./View";

const SendLink = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.SUBSCRIPTION);
  const SEND_LINK = t("SEND_LINK", { returnObjects: true });
  const { state } = useLocation();

  const { data, loading } = useQuery(PROJECT, {
    variables: { where: { id: state?.projectId } },
  });

  const [sendSubscriptionLink] = useMutation(
    graphql.mutations.SEND_SUBSCRIPTION_LINK,
    {
      variables: {
        data: {
          project: { id: state?.projectId },
          insurancePlan: { id: get(state, "insurancePlanIds.0", null) },
          link: state?.link,
        },
      },
      onCompleted: (_) => {
        navigate(NAVIGATION_DIRECTIONS.BACK);
        notification.open({
          message: SEND_LINK.SUCCESS,
          duration: 5,
          type: "success",
        });
      },
    }
  );
  const [updateContact] = useMutation(graphql.mutations.UPDATE_CONTACT, {
    onCompleted: () => {
      return sendSubscriptionLink();
    },
  });
  const sendLink = (telecoms) => {
    updateContact({
      variables: {
        where: {
          id: get(data, "project.contact.id", []),
        },
        data: {
          telecoms: [
            ...omitDeep(
              get(data, "project.contact.telecoms", []),
              "__typename"
            ).filter(
              (telecom) => telecom.system !== CONTACT_POINT_SYSTEM.EMAIL
            ),
            ...telecoms.map(({ label, value }) => ({
              id: value,
              system: CONTACT_POINT_SYSTEM.EMAIL,
              value: label,
            })),
          ],
        },
      },
    });
  };

  const generalCostsFilter = {
    where: {
      project: {
        id: state?.projectId,
      },
    },
    isIn: {
      insurancePlan: {
        id: state?.insurancePlanIds || [],
      },
    },
  };

  if (loading) return <Loading />;

  return (
    <GeneralCosts
      filter={generalCostsFilter}
      updateContact={updateContact}
      onSubmit={sendLink}
      View={View}
      NoData={View}
      link={state?.link}
      project={omitDeep(get(data, "project", {}), "__typename")}
    />
  );
};

export default SendLink;
