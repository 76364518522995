import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { API_DATE_FORMAT, DATE_FORMAT } from "utils/constants";

export const noSpace = (e) => {
  if (e.key === " ") {
    e.preventDefault();
  }
};

export const bindInputProps = ({
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  return {
    value: get(values, name, undefined),
    onChange: handleChange(name),
    onBlur: (e) => {
      const value = get(e, "target.value");
      setFieldTouched(name);
      onBlur(value);
    },
    touched: get(touched, name, false),
    errors: get(errors, name, ""),
    name,
  };
};

export const bindDateInputProps = ({
  name,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  const value = get(values, name, undefined);
  return {
    value: value && dayjs(value),
    defaultValue: value && dayjs(value),
    onChange: (date, _) => {
      setFieldValue(name, date ? date.add(2, "h") : date);
    },
    onBlur: (e) => {
      setFieldTouched(name);
      const value = get(e, "target.value");
      const date = dayjs(value, DATE_FORMAT);
      if (date.isValid()) setFieldValue(name, date ? date.add(2, "h") : date);
      onBlur(value);
    },
    touched: get(touched, name, false),
    errors: get(errors, name, ""),
    name,
  };
};

export const bindDateRangeInputProps = ({
  name,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  const [startValue, endValue] = get(values, name, undefined);
  const value = [startValue && dayjs(startValue), endValue && dayjs(endValue)];
  return {
    value,
    defaultValue: value,
    onChange: ([startDate, endDate]) => {
      setFieldValue(name, [
        startDate.format(API_DATE_FORMAT),
        endDate.format(API_DATE_FORMAT),
      ]);
    },
    onBlur: () => {
      setFieldTouched(name);
      onBlur();
    },
    touched: get(touched, name, false),
    errors: get(errors, name, ""),
    name,
  };
};

export const bindArrayInputProps = ({
  parent,
  index,
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  const childName = `${parent}.${index}.${name}`;
  return {
    value: get(values, childName, ""),
    onChange: handleChange(childName),
    onBlur: () => {
      setFieldTouched(childName);
      onBlur();
    },
    touched: get(touched, childName, false),
    errors: get(errors, childName, ""),
    name: childName,
  };
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const setDefaultIfEmpty = (value, defaultValue) =>
  isEmpty(value) ? defaultValue : value;

export const convertToTouched = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertToTouched(item));
  } else if (obj !== null && typeof obj === "object") {
    let newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = convertToTouched(obj[key]);
      }
    }
    return newObj;
  } else {
    return true;
  }
};
