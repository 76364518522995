import { Input } from "antd";
import classnames from "classnames";
import PropTypes from "prop-types";

import Label from "shared/components/Label";

const Number = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  required,
  maxLength,
  defaultBackground,
  ...rest
}) => {
  const checkMaxLength = ({ target }, maxLength) => {
    if (!maxLength) return;
    let value = target.value.replace(/\D/g, "");
    if (value.length > maxLength - 1) value = value.slice(0, maxLength - 1);
    target.value = value;
  };
  return (
    <div
      className={classnames("input__wrapper number--input", {
        __error: touched && errors,
        "default--background": defaultBackground,
      })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`input__wrapper--field`}>
        <Input
          type="number"
          inputMode="numeric"
          className={classnames({
            "input--borderd--red": touched && errors,
            "disable--input": rest.disabled,
          })}
          placeholder={placeholder}
          {...rest}
          onKeyDown={(_) => checkMaxLength(_, maxLength)}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Number.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Number.defaultProps = {
  label: "",
  placeholder: "",
};

export default Number;
