import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PiUserList } from "react-icons/pi";
import Button from "shared/components/Button";
import ScrollToTop from "shared/hooks/scrollToTop";
import { NAME_SPACES } from "shared/locales/constants";
import ContactCard from "../../Clients/Card";

const WithContactCard = (WrappedComponent) => {
  const WithContactCardComponent = (props) => {
    const { contactId, visible, ...otherProps } = props;
    const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
    const TRANSLATION = t("DETAIL", { returnObjects: true });
    const [open, setOpen] = useState(false);
    return (
      <>
        <ScrollToTop />
        <WrappedComponent {...otherProps} />
        {visible && (
          <Button
            className="contact_button"
            type="secondary"
            onClick={() => setOpen(true)}
          >
            <PiUserList size={20} />
            {TRANSLATION.CONTACT_CARD}
          </Button>
        )}
        {open && (
          <ContactCard
            contactId={contactId}
            open={open}
            project={props.project}
            setOpen={setOpen}
          />
        )}
      </>
    );
  };

  return WithContactCardComponent;
};

export default WithContactCard;
