import cuid from "cuid";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "utils/constants";
import View from "./View";

const CreateOffer = ({ translate, onClose }) => {

  const [addOffer] = useMutation(graphql.mutations.CREATE_OFFER, {
    refetchQueries: [
      {
        query: graphql.queries.OFFERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => onClose(),
    onError: () => {},
  });

  const initialValues = {
    insurancePlans: [],
  };

  const onSubmit = (values) => {
    const { insurancePlans } = values;
    addOffer({
      variables: {
        data: {
          id: cuid(),
          offerInsurancePlans: insurancePlans,
        },
      },
    });
  };
  return (
    <View
      translate={translate}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default CreateOffer;
