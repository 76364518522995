import { Row } from "antd";
import { get } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LuFileSignature } from "react-icons/lu";
import { MdFileOpen } from "react-icons/md";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_STATUSES } from "utils/constants";

import { useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import generateDocumentForm from "./Widgets";

const ICONS = {
  DOCUMENTS: <MdFileOpen size={16} />,
  E_SIGNATURE: <LuFileSignature size={16} />,
};

const Document = ({
  subscription,
  additionalInfoPercentage,
  proofPercentage,
  setActive,
}) => {
  const { id } = useParams();
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
    }
  );
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });
  const formList = useMemo(
    () =>
      Object.entries(
        generateDocumentForm(
          get(subscription, "status") === SUBSCRIPTION_STATUSES.SIGNED
        )
      ).filter(([_, Form]) => Form),
    []
  );

  return (
    <Row gutter={[16, 16]}>
      Signature
      {/* <Col lg={24} xs={24}>
        <Row align="middle" gutter={[16, 16]}>
          <Col lg={18} xs={24} />
          <Col align="end" lg={6} xs={24}>
            <span className="indicator-step">
              {format(SUBSCRIPTION.INDICATOR, { count: "3", total: "3" })}
            </span>
          </Col>
        </Row>
      </Col>
      <Col lg={24} xs={24}>
        <Row gutter={[16, 16]}>
          <Col lg={9} xs={24}>
            <div className="card-skeleton--container highlighted">
              <div className="card-skeleton--wrapper">
                <h3 className="title--md">{SUBSCRIPTION.DOCUMENT.TITLE} </h3>
                <div className="card-skeleton--wrapper__progress-list">
                  {formList.map(([form], index) => (
                    <Progress
                      key={"progress-" + index}
                      percent={
                        form === SUBSCRIPTION_FORM_KEYS.SIGNATURE.E_SIGNATURE
                          ? additionalInfoPercentage
                          : proofPercentage
                      }
                      translation={SUBSCRIPTION}
                      icon={ICONS[form]}
                      title={SUBSCRIPTION.DOCUMENT.STEPS[form]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={15} xs={24}>
            <Card
              title={SUBSCRIPTION.DOCUMENT.TITLE}
              subtitle={SUBSCRIPTION.DOCUMENT.SUBTITLE}
            >
              {formList.map(([_, Form], index) => (
                <Form
                  key={"Widget-" + index}
                  additionalInfoPercentage={additionalInfoPercentage}
                  proofPercentage={proofPercentage}
                  setActive={setActive}
                  subscription={subscription}
                  updateSubscription={updateSubscription}
                />
              ))}
            </Card>
            <div className="m-top-16">
              <Button
                type="secondary--link"
                onClick={() => setActive(STEPPER_KEYS.PROOF)}
              >
                <MdArrowBackIos size={16} />
                {SUBSCRIPTION.PROOF.ACTION.PREVIOUS}
              </Button>
            </div>
          </Col>
        </Row>
      </Col> */}
    </Row>
  );
};

export default Document;
