import { gql } from "@apollo/client";

export const LINKS = gql`
  query Links(
    $where: LinkWhereInput
    $like: LinkWhereInput
    $take: Int
    $skip: Int
  ) {
    links(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        url
        fields
        project {
          id
        }
        subscription {
          id
        }
      }
      count
    }
  }
`;

export const LINK = gql`
  query Link($where: LinkWhereInput) {
    link(where: $where) {
      id
      url
      fields
      project {
        id
      }
      subscription {
        id
      }
    }
  }
`;

const links = { LINKS, LINK };

export default links;
