import { useTranslation } from "react-i18next";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Default = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const FORM = t("FORM", { returnObjects: true });

  return [
    <Card key="user" type={"simple"} head title={FORM.LEGAL_INFO}>
      <div className="grid--2">
        <Radio
          isHorizontal={false}
          label={FORM.CIVILITY.LABEL}
          options={[
            {
              label: FORM.CIVILITY.MALE,
              value: GENDER.MALE,
            },
            {
              label: FORM.CIVILITY.FEMALE,
              value: GENDER.FEMALE,
            },
          ]}
          {...bindInputProps({ name: "gender", ...formProps })}
          required
        />
        <div />
        <Input
          placeholder={FORM.FIRST_NAME}
          label={FORM.FIRST_NAME}
          {...bindInputProps({ name: "firstname", ...formProps })}
        />
        <Input
          placeholder={FORM.LAST_NAME}
          label={FORM.LAST_NAME}
          {...bindInputProps({ name: "lastname", ...formProps })}
        />
        <Input
          placeholder={FORM.PHONE}
          label={FORM.PHONE}
          {...bindInputProps({ name: "phone", ...formProps })}
        />
        <Input
          placeholder={FORM.EMAIL}
          label={FORM.EMAIL}
          {...bindInputProps({ name: "email", ...formProps })}
        />
      </div>
    </Card>,
  ];
};

export default Default;
