import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useMemo } from "react";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { downloadDocument } from "utils/helpers/files";
import View from "./View";

const ThankYou = ({ onNext, onBack, project }) => {
  const insurancePlans = useMemo(
    () =>
      get(project, "fields.insurancePlans", [])?.map((id) => ({
        insurancePlans: { id },
      })),
    []
  );
  const { data, loading } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: get(project, "fields.currentSubscription") } },
  });
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const filter = {
    where: {
      OR: [
        {
          insurancePlans: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
        {
          subscription: {
            id: get(project, "fields.currentSubscription"),
          },
        },
        {
          project: {
            id: project.id,
          },
        },
        ...insurancePlans,
      ],
    },
  };
  const { data: attachmentsList } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: filter,
  });

  if (loading) return <Loading />;
  const download = async (file) => {
    const { id, generate } = file;
    if (generate) return file.onClick();

    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };

  return (
    <View
      subscription={get(data, "subscription")}
      attachments={get(attachmentsList, "attachments.data", [])}
      download={download}
      onBack={onBack}
    />
  );
};

export default ThankYou;
