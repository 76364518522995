import cuid from "cuid";
import { get } from "lodash";
import { useMemo } from "react";
import { RELATIONSHIP } from "utils/constants";
import View from "./View";

const SpouseBirthDate = ({ project, onNext, onBack }) => {
  const spouse = useMemo(
    () =>
      get(project, "contact.relatedPersons", []).find(
        ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
      ) || {
        id: cuid(),
        relationship: RELATIONSHIP.SPOUSE,
        identity: {
          id: cuid(),
          user: {
            id: cuid(),
          },
        },
      },
    []
  );
  const next = (spouse) =>
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          relatedPersons: [
            ...get(project, "contact.relatedPersons", []).filter(
              ({ id }) => id !== spouse.id
            ),
            spouse,
          ],
        },
      },
    });

  return <View onNext={next} spouse={spouse} onBack={onBack} />;
};

export default SpouseBirthDate;
