import attachment from "./attachment";
import brokers from "./brokers";
import categoryWeight from "./category-weight";
import commissionStatements from "./commission-statements";
import contacts from "./contacts";
import contracts from "./contracts";
import costs from "./costs";
import coverageCategories from "./coverage-categories";
import coverages from "./coverages";
import coverageBenefits from "./coverages-benefits";
import distributions from "./distributions";
import flows from "./flows";
import insurancePlans from "./insurance-plans";
import links from "./link";
import notes from "./notes";
import offers from "./offers";
import organizations from "./organizations";
import pricers from "./pricers";
import processes from "./processes";
import projects from "./projects";
import qualifications from "./qualifications";
import qualifiers from "./qualifiers";
import quotes from "./quotes";
import reminders from "./reminders";
import sources from "./sources";
import subscriptions from "./subscription";
import tracers from "./tracers";
import users from "./users";
import workflows from "./workflows";

const mutations = {
  ...contracts,
  ...organizations,
  ...users,
  ...projects,
  ...sources,
  ...flows,
  ...processes,
  ...tracers,
  ...contacts,
  ...notes,
  ...insurancePlans,
  ...coverages,
  ...coverageBenefits,
  ...costs,
  ...qualifiers,
  ...qualifications,
  ...reminders,
  ...quotes,
  ...subscriptions,
  ...attachment,
  ...brokers,
  ...workflows,
  ...pricers,
  ...commissionStatements,
  ...categoryWeight,
  ...coverageCategories,
  ...distributions,
  ...offers,
  ...links,
};

export default mutations;
