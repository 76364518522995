import PropTypes from "prop-types";
import { useMemo } from "react";
import { SUBSCRIPTION_STATUSES } from "utils/constants";
import Draft from "./draft";
import Pending from "./pending";
import Signed from "./signed";

const STATUSES = {
  [SUBSCRIPTION_STATUSES.DRAFT]: Draft,
  [SUBSCRIPTION_STATUSES.IN_PROGRESS]: Draft,
  [SUBSCRIPTION_STATUSES.PENDING]: Pending,
  [SUBSCRIPTION_STATUSES.SIGNED]: Signed,
};

const SubscriptionStatus = ({ type, ...props }) => {
  const View = useMemo(() => STATUSES[type], [type]);
  return <View {...props} />;
};

SubscriptionStatus.propTypes = {
  type: PropTypes.oneOf(Object.keys(STATUSES)),
};

SubscriptionStatus.defaultProps = {
  type: SUBSCRIPTION_STATUSES.DRAFT,
};

export default SubscriptionStatus;
