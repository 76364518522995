const Levels = ({ size, filled, icon }) => {
  const filledBarsArray = Array.from({ length: filled }, (_, index) => (
    <div
      key={`filled-${index}`}
      className="levels__bar levels__bar--filled"
    ></div>
  ));
  const emptyBarsArray = Array.from({ length: size - filled }, (_, index) => (
    <div key={`empty-${index}`} className="levels__bar"></div>
  ));
  return (
    <div className="levels-container">
      <div className="levels">
        {emptyBarsArray}
        {filledBarsArray}
      </div>
      <div className="levels__icon">
        <img loading="lazy" alt="" src={icon} />
      </div>
    </div>
  );
};

Levels.propTypes = {};

export default Levels;
