import classNames from "classnames";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "shared/components/Icon";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_STATUSES, TYPES } from "utils/constants";
import { formatDate } from "utils/helpers/date";

const mapStatus = (status, TRANSLATION) => {
  const values = {
    [SUBSCRIPTION_STATUSES.DRAFT]: {
      label: TRANSLATION.STATUS.DRAFT,
      icon: <Icon name="flash_on" size={12} fill variant="rounded" />,
    },
    [SUBSCRIPTION_STATUSES.PENDING]: {
      label: TRANSLATION.STATUS.SEND,
      icon: <Icon name="flash_on" size={12} fill variant="rounded" />,
    },
    [SUBSCRIPTION_STATUSES.SIGNED]: {
      label: TRANSLATION.STATUS.SIGNED,
      icon: <Icon name="flash_on" size={12} fill variant="rounded" />,
    },
    DEFAULT: {
      label: status,
      icon: <Icon name="flag" size={12} fill variant="rounded" />,
    },
  };
  return values[status] || values.DEFAULT;
};

const ActivityItem = ({ activity }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const { status, date, author, type = TYPES.DEFAULT } = activity;
  const TRANSLATION = t("ACTIVITIES", { returnObjects: true });
  const { label, icon } = mapStatus(status, TRANSLATION);

  return (
    <div className="activities__log--item">
      <div
        className={classNames("icon", {
          error: type === TYPES.DANGER,
          primary: type === TYPES.DEFAULT,
        })}
      >
        {icon}
      </div>
      <div className="content">
        <div className="info">
          <span className="status">{label}</span>
          <span className="name">
            {!isEmpty(author) ? `${author?.firstname} ${author?.lastname}` : ""}
          </span>
        </div>
        <span className="date">{date ? formatDate(date) : "-"}</span>
      </div>
    </div>
  );
};

export default ActivityItem;
