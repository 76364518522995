import cuid from "cuid";
import {
  defaults,
  defaultsDeep,
  filter,
  get,
  isEmpty,
  keyBy,
  map,
  set,
} from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { STEPPER_KEYS } from "screens/Private/Broker/Subscription/Detail";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { CONTACT_POINT_SYSTEM, RELATIONSHIP } from "utils/constants";
import View from "./View";

const EditSubscription = ({ project, onNext, onBack }) => {
  const { data, loading } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: get(project, "fields.currentSubscription") } },
  });
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: {
            where: { id: get(project, "fields.currentSubscription") },
          },
        },
      ],
    }
  );

  const next = (values = {}) => {
    const {
      id,
      hasSpouse,
      spouse,
      children,
      childrenCount,
      telecoms: { contact },
      ...data
    } = values;
    const contactTelecoms = map(contact, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const relatedPersons = [
      ...(hasSpouse
        ? [
            defaultsDeep(spouse, {
              id: cuid(),
              relationship: RELATIONSHIP.SPOUSE,
              identity: { id: cuid(), user: { id: cuid() } },
            }),
          ]
        : []),
      ...children.map((child) =>
        defaultsDeep(child, {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: { id: cuid(), user: { id: cuid() } },
        })
      ),
    ];
    set(data, "project.contact.telecoms", contactTelecoms);
    set(data, "project.contact.relatedPersons", relatedPersons);
    updateSubscription({
      variables: {
        where: {
          id,
        },
        data,
      },
      onCompleted: (_) => {
        onNext({
          payload: {
            fields: {
              ...project.fields,
              quickEdit: false,
              currentSubscription: id,
            },
          },
        });
      },
    });
  };
  const [disabled, setDisabled] = useState({});

  if (loading) return <Loading />;

  const subscription = omitDeep(get(data, "subscription"), "__typename");
  const spouse = get(subscription, "project.contact.relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );
  const children = filter(
    get(subscription, "project.contact.relatedPersons", []),
    {
      relationship: RELATIONSHIP.CHILD,
    }
  );
  const shouldBeAdherent =
    get(subscription, "payment.payer.owner.isAdherent", true) ||
    get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);

  if (isEmpty(subscription)) return;
  const contactTelecoms = get(subscription, "project.contact.telecoms", []);
  const payerTelecoms = get(subscription, "payment.payer.owner.telecoms", []);

  const telecoms = defaultsDeep(
    {
      contact: keyBy(contactTelecoms, "system"),
      payer: keyBy(
        shouldBeAdherent ? contactTelecoms : payerTelecoms,
        "system"
      ),
    },
    {
      contact: {
        [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
        [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
        [CONTACT_POINT_SYSTEM.STREET]: { value: null },
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
        [CONTACT_POINT_SYSTEM.CITY]: { value: null },
        [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
        [CONTACT_POINT_SYSTEM.LANDLINE]: { value: null },
        [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
      },
      payer: {
        [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
        [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
        [CONTACT_POINT_SYSTEM.STREET]: { value: null },
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
        [CONTACT_POINT_SYSTEM.CITY]: { value: null },
      },
    }
  );

  const initialValues = {
    ...subscription,
    spouse,
    childrenCount: children.length,
    children,
    telecoms,
    hasSpouse: !isEmpty(spouse),
  };
  const isFieldRequired = (block, value) =>
    get(
      initialValues.fields,
      `${STEPPER_KEYS.ADDITIONAL_INFORMATION}.${block}`,
      []
    ).includes(value) && !get(disabled, value);

  return (
    <View
      onBack={onBack}
      isFieldRequired={isFieldRequired}
      onNext={next}
      disabled={false}
      initialValues={initialValues}
    />
  );
};

export default EditSubscription;
