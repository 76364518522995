import {
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
} from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";

const useMutation = (mutation, options = {}) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  return useApolloMutation(mutation, {
    onError: (e) => {
      notification.error({
        message: API_ERRORS[e?.message] || API_ERRORS.GENERIC,
      });
    },
    ...options,
  });
};

const useQuery = (query, options = {}) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  return useApolloQuery(query, {
    onError: () => notification.error({ message: API_ERRORS.GENERIC }),
    ...options,
  });
};

const useLazyQuery = (query, options = {}) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  return useApolloLazyQuery(query, {
    onError: () => notification.error({ message: API_ERRORS.GENERIC }),
    ...options,
  });
};

export { useLazyQuery, useMutation, useQuery };
