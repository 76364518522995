import { Table } from "antd";
import {
  chain,
  find,
  flatten,
  flattenDeep,
  get,
  isEmpty,
  sortBy,
  uniqBy,
} from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import Alert from "shared/components/Alert";
import Card from "shared/components/Card";
import Empty from "shared/components/Empty";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import CoverageBenefitCard from "./Widgets/Coverages/Card";
import CoverageCategoryHeader from "./Widgets/Coverages/Header";
import CoverageLabel from "./Widgets/Coverages/Label";
import InsurancePlanCard from "./Widgets/InsurancePlans";

const getCoverageBenefitById = (insurancePlanCoverages, coverageBenefitId) => {
  const coverageBenefits = flattenDeep(
    insurancePlanCoverages.map(({ coverageMappings, coverageCategory }) => {
      return [
        coverageCategory,
        ...coverageMappings.map(
          ({ coverage, limits, supportingInformation }) => ({
            ...coverage,
            limits,
            supportingInformation,
          })
        ),
      ];
    })
  );
  const coverageBenefit = find(coverageBenefits, { id: coverageBenefitId });
  return coverageBenefit;
};

const View = ({ project }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [hideDetail, setHideDetail] = useState({});
  const toggleCoverage = (name, hide) => {
    setHideDetail((prev) => ({
      ...prev,
      [name]: hide,
    }));
  };

  const HIDE_DETAIL = {
    [false]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_MORE}</span>
        <MdOutlineExpandMore />
      </>
    ),
    [true]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_LESS}</span>
        <MdOutlineExpandLess />
      </>
    ),
  };

  const [generalCosts, dataSource] = useMemo(() => {
    const costs = get(project, "generalCosts", []).filter(({ insurancePlan }) =>
      get(project, "fields.insurancePlans", []).includes(insurancePlan.id)
    );

    const groupedCoverageBenefits = chain(costs)
      .flatMap((cost) => get(cost, "insurancePlan.insurancePlanCoverages"))
      .value();

    const mergedCoverages = uniqBy(
      groupedCoverageBenefits,
      (coverage) => coverage.coverageCategory.value
    );

    const coveragesByCategory = mergedCoverages.map(
      ({ coverageMappings, ...coverage }) => {
        const matchingCoverages = groupedCoverageBenefits.filter(
          (item) =>
            item.coverageCategory.value === coverage.coverageCategory.value
        );

        return {
          ...coverage,
          coverages: uniqBy(
            flatten(matchingCoverages.map((c) => c.coverageMappings)),
            "coverage.id"
          ),
        };
      }
    );
    const res = sortBy(coveragesByCategory, "coverageCategory.order").flatMap(
      ({ coverageCategory, coverages }) => {
        const categoryBenefits = [
          coverageCategory,
          ...uniqBy(
            sortBy(
              coverages.flatMap(({ coverage }) => ({
                ...coverage,
                rowId: coverageCategory.value + coverage.id,
              })),
              "order"
            ),
            "id"
          ),
        ];
        const uniqueBenefits = categoryBenefits.reduce((acc, benefit) => {
          if (!benefit.type) acc.push(benefit);
          const existing = acc.find((item) => item.type === benefit.type);
          if (!existing) {
            acc.push(benefit);
          }
          return acc;
        }, []);
        return uniqueBenefits;
      }
    );

    return [costs, res];
  }, []);

  const columns = [
    {
      width: 300,
      dataIndex: "__typename",
      render: (record, row) => {
        return record === "CoverageCategory" ? (
          <CoverageCategoryHeader
            className="coverage-header"
            src={get(row, "icon")}
            type={row.label}
            label={row.label}
          />
        ) : (
          <CoverageLabel
            row={row}
            label={row.type}
            subLabel={get(row, "subtype", "")}
            info={get(row, "additionalInfo", "")}
            expanded={hideDetail[row.id]}
            link={HIDE_DETAIL[get(hideDetail, row.id, false)]}
            onToggle={() => toggleCoverage(row.id, !hideDetail[row.id])}
          />
        );
      },
      // fixed: true,
    },
    ...(generalCosts?.map(({ insurancePlan, cost, currency }) => ({
      dataIndex: "type",
      title: () => (
        <InsurancePlanCard
          insurancePlan={insurancePlan}
          cost={cost}
          currency={currency}
          translation={DETAIL.TEMPLATES.COMPARISON}
          expired={project.expired}
        />
      ),
      render: (benefits, row) => {
        return benefits ? (
          <CoverageBenefitCard
            translation={DETAIL.TEMPLATES.COMPARISON}
            expanded={hideDetail[row.label]}
            coverageBenefit={getCoverageBenefitById(
              get(insurancePlan, "insurancePlanCoverages", []),
              row.id
            )}
          />
        ) : null;
      },
    })) || []),
  ];

  return (
    <div>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />
      <div className="comparison-container">
        <Card type="simple">
          {project.expired ? (
            <Alert
              message={DETAIL.TEMPLATES.COMPARISON.QUOTE_EXPIRED}
              showIcon
              type="error"
            />
          ) : isEmpty(generalCosts) ? (
            <Empty type="normal" />
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              className="comparison-table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true, y: 520 }}
              rowClassName={(record) =>
                record.__typename === "CoverageCategory"
                  ? "coverage-header-row"
                  : ""
              }
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default View;
