import moment from "moment";
import { CONTACT_POINT_SYSTEM, REGEX_EXPS } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (errors, { franceAddressZipCodeCity: validateZipCode }) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  return yup.object().shape({
    contact: yup.object().shape({
      user: yup.object().shape({
        birthDate: yup
          .date()
          .typeError(errors.REQUIRED)
          .max(isMajor, errors.RADIANCE.ADHERENT_AGE)
          .min(
            moment().subtract(100, "year").startOf("year"),
            errors.RADIANCE.ADHERENT_AGE
          )
          .required(errors.REQUIRED),
      }),
      socialRegime: requiredString,
      telecoms: yup.object().shape({
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: yup
            .string()
            .typeError(errors.REQUIRED)
            .test("isValid", errors.INVALID_VALUE, async function (value) {
              const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
              if (!isValidRegex) return false;
              const {
                data: {
                  franceAddressZipCodeCity: { response },
                },
              } = await validateZipCode({
                variables: {
                  where: { zipCode: value },
                },
              });
              return response.length;
            }),
        }),
      }),
    }),
  });
};

export default validation;
