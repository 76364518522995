import { gql } from "@apollo/client";

export const ADDRESS_PATH_TYPES = gql`
  query AddressPathTypes($where: ReferentialWhereInput) {
    addressPathTypes(where: $where) {
      response
    }
  }
`;

export const ADDRESS_ZIP_CODE_CITY = gql`
  query addressZipCodeCity($where: ZipCodeCityWhereInput) {
    addressZipCodeCity(where: $where) {
      id
      city
      zipCode
    }
  }
`;

export const FRANCE_ADDRESS_ZIP_CODE_CITY = gql`
  query franceAddressZipCodeCity($where: ZipCodeCityWhereInput) {
    franceAddressZipCodeCity(where: $where) {
      response
    }
  }
`;

const referential = {
  ADDRESS_PATH_TYPES,
  ADDRESS_ZIP_CODE_CITY,
  FRANCE_ADDRESS_ZIP_CODE_CITY,
};

export default referential;
