import { Button as AntButton } from "antd";
import PropTypes from "prop-types";

const ALIGNMENTS = ["left", "center", "right"];

const TYPES = [
  "default",
  "primary",
  "secondary",
  "primary--light",
  "secondary--light",
  "primary--link",
  "secondary--link",
  "primary--outlined",
  "primary--underline",
  "success",
  "success--link",
  "success--light",
  "black",
  "gray",
  "gray--outlined",
  "gray--underline",
  "gray--link",
  "secondary--link",
  "warning",
  "warning--link",
  "warning--light",
  "danger",
  "danger--link",
  "danger--outlined",
  "danger--light",
];

const Button = ({
  type,
  alignment,
  children,
  size,
  shape = false,
  className,
  ...rest
}) => (
  <div className={`button__wrapper ${alignment} ${className}`}>
    {shape && (
      <svg
        width="21"
        height="33"
        viewBox="0 0 21 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.68311 28C9.68311 28 0.462129 25.6954 0.687158 15.9438C0.888499 7.21569 8.46988 5.28878 9.68311 4.99997C9.68311 4.99997 5.30024 8.75604 5.35797 16.5C5.35797 16.5 5.18328 23.3982 9.68311 28Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8418 32.029C20.8418 32.029 8.3848 28.917 8.6888 15.749C8.9608 3.96296 19.2028 1.36096 20.8418 0.970961C20.8418 0.970961 14.9208 6.04296 14.9988 16.5C14.9988 16.5 14.7628 25.815 20.8418 32.029Z"
          fill="currentColor"
        />
      </svg>
    )}
    <AntButton className={[type, size]} size={size} {...rest}>
      {children}
    </AntButton>
    {shape && (
      <svg
        width="21"
        height="32"
        viewBox="0 0 21 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1587 4.02908C11.1587 4.02908 20.3797 6.33368 20.1546 16.0852C19.9533 24.8134 12.3719 26.7403 11.1587 27.0291C11.1587 27.0291 15.5416 23.273 15.4838 15.5291C15.4838 15.5291 15.6585 8.63086 11.1587 4.02908Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.15527e-05C0 9.15527e-05 12.457 3.11209 12.153 16.2801C11.881 28.0661 1.639 30.6681 -1.35759e-06 31.0581C-1.35759e-06 31.0581 5.921 25.9861 5.843 15.5291C5.843 15.5291 6.079 6.21409 0 9.15527e-05Z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>
);

Button.propTypes = {
  alignment: PropTypes.oneOf(ALIGNMENTS),
  type: PropTypes.oneOf(TYPES),
};

Button.defaultProps = {
  alignment: "left",
  type: "default",
};

export default Button;
