import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    firstname: yup
      .string()
      .typeError(errors.REQUIRED)
      .required(errors.REQUIRED),
    lastname: yup.string().typeError(errors.REQUIRED).required(errors.REQUIRED),
    birthDate: yup
      .date()
      .typeError(errors.REQUIRED)
      .max(isMajor, errors.AGE_18)
      .required(errors.REQUIRED),
  });
};

export default validation;
