import { Drawer } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const View = ({ open, onClose, createProject, tracers }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TRACER);
  const DRAWER = t("DRAWER", { returnObjects: true });

  return (
    <Drawer
      title={DRAWER.TITLE}
      placement="right"
      onClose={onClose}
      open={open}
      className="offer__drawer"
    >
      <div className="offer__drawer--container">
        {tracers.map((tracer, index) => (
          <div className="offer__card" key={`tracer-${index}`}>
            <div className="offer__card--header">
              <h1>{tracer.name}</h1>
              <p>{DRAWER.TRACER_DESCRIPTION[get(tracer, "flow.process.theme", "")]}</p>
            </div>
            <Button type="primary" onClick={() => createProject(tracer)}>
              {DRAWER.CREATE_PROJECT}
              <MdArrowForwardIos />
            </Button>
          </div>
        ))}
      </div>
    </Drawer>
  );
};
export default View;
