import { gql } from "@apollo/client";

const PROJECT_ADDED = gql`
  subscription projectAdded($where: ProjectWhereInput) {
    projectAdded(where: $where) {
      id
      name
      createdDate
      locked
      ria
      contract {
        issuanceDate
      }
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          createdDate
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
            theme
            processStatuses {
              id
              name
              tag
              template
              progress
              order
              color
              background
              moveToNext
              possibleStatuses {
                id
                nextStatus
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        acceptContact
        acceptDigital
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
        address
        zipCode
        city
      }
    }
  }
`;

const PROJECT_UPDATED = gql`
  subscription projectUpdated($where: ProjectWhereInput) {
    projectUpdated(where: $where) {
      id
      name
      createdDate
      locked
      ria
      contract {
        issuanceDate
      }
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          createdDate
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
    }
  }
`;

const PROJECT_DELETED = gql`
  subscription projectDeleted($where: ProjectWhereInput) {
    projectDeleted(where: $where) {
      id
      name
      createdDate
      locked
      ria
      contract {
        issuanceDate
      }
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          createdDate
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
            theme
            processStatuses {
              id
              name
              tag
              template
              progress
              order
              color
              background
              moveToNext
              possibleStatuses {
                id
                nextStatus
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        acceptContact
        acceptDigital
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
        address
        zipCode
        city
      }
    }
  }
`;

const PROJECT_DUPLICATED = gql`
  subscription projectDuplicated($where: ProjectWhereInput) {
    projectDuplicated(where: $where) {
      id
      name
      createdDate
      locked
      ria
      contract {
        issuanceDate
      }
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          createdDate
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
            theme
            processStatuses {
              id
              name
              tag
              template
              progress
              order
              color
              background
              moveToNext
              possibleStatuses {
                id
                nextStatus
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        acceptContact
        acceptDigital
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
        address
        zipCode
        city
      }
    }
  }
`;

const projects = {
  PROJECT_ADDED,
  PROJECT_UPDATED,
  PROJECT_DELETED,
  PROJECT_DUPLICATED,
};

export default projects;
