import { notification } from "antd";
import cuid from "cuid";
import {
  defaultTo,
  defaults,
  defaultsDeep,
  filter,
  get,
  isEmpty,
  keyBy,
  map,
  set,
} from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { STEPPER_KEYS } from "screens/Private/Broker/Subscription/Detail";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  CONTACT_POINT_SYSTEM,
  PAYMENT_RECEIVER,
  PROJECT_TYPES,
  RELATIONSHIP,
  SALE_MODES,
  SUBSCRIPTION_FORM_KEYS,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import View from "./View";
const AdherentSocialRegime = ({ project, onNext, onBack }) => {
  const subscriptionId = get(project, "fields.currentSubscription");
  const [paymentBlock, setPaymentBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insurancePlanId, setInsurancePlanId] = useState(false);

  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const editSubscription = () =>
    onNext({
      payload: {
        fields: {
          ...project.fields,
          quickEdit: true,
        },
      },
    });

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    if (isEmpty(subscription)) return;
    const contactTelecoms = get(subscription, "project.contact.telecoms", []);
    const payerTelecoms = get(subscription, "payment.payer.owner.telecoms", []);
    const initialDisabled = {
      project: {
        ria: true,
        contract: {
          caseType: get(subscription, "project.ria", false),
          saleMode: get(subscription, "project.fields.twoTimesSale", false),
        },
        contact: {
          siretNumber: true,
          optMadelin: !get(subscription, "insurancePlan.fields.madelin"),
          fields: {
            affiliateOrganizationCode: true,
          },
        },
        projectType:
          get(subscription, "project.fields.twoTimesSale") ||
          [SALE_MODES.FACE_TO_FACE].includes(
            get(subscription, "project.contract.saleMode")
          ),
      },
      createdDate: true,
      telecoms: {
        payer: {
          firstName: shouldBeAdherent,
          lastName: shouldBeAdherent,
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET_TYPE]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.CITY]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
        },
        contact: {
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET_TYPE]: {
            value: true,
          },
        },
      },
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST]: !get(
        subscription,
        "project.ria",
        false
      ),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE]: !get(
        subscription,
        "project.ria",
        false
      ),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER]: !get(
        subscription,
        "project.contact.relatedPersons",
        []
      ).find((person) => person.relationship === RELATIONSHIP.SPOUSE),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN]: !get(
        subscription,
        "project.contact.relatedPersons",
        []
      ).find((person) => person.relationship === RELATIONSHIP.CHILD),
      [SUBSCRIPTION_FORM_KEYS.PROOF.KBIS]: !get(
        subscription,
        "insurancePlan.fields.tns"
      ),
      [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]: !get(
        subscription,
        "project.contact.fields.teletransmission"
      ),
    };
    const telecoms = defaultsDeep(
      {
        contact: keyBy(contactTelecoms, "system"),
        payer: keyBy(
          shouldBeAdherent ? contactTelecoms : payerTelecoms,
          "system"
        ),
      },
      {
        contact: {
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
          [CONTACT_POINT_SYSTEM.STREET]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
          [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
          [CONTACT_POINT_SYSTEM.LANDLINE]: { value: null },
          [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
        },
        payer: {
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
          [CONTACT_POINT_SYSTEM.STREET]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
        },
      }
    );
    const children = filter(
      get(subscription, "project.contact.relatedPersons", []),
      {
        relationship: RELATIONSHIP.CHILD,
      }
    );

    const fields = {
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: {
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT]: [
          "project.contact.user.gender",
          "project.contact.user.lastname",
          "project.contact.user.firstname",
          "project.contact.user.birthDate",
          "project.contact.socialRegime",
          "project.contact.socialSecurityNumber",
          "project.contact.fields.affiliateOrganizationCode",
          "project.contact.fields.familySituation",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ACTIVITY]: [
          "project.contact.socioProfessionalCategory",
          "project.contact.exerciseFrame",
          "project.contact.siretNumber",
          "project.contact.profession",
          "project.contact.optMadelin",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT]: [
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
          "project.contact.acceptContact",
          "project.contact.acceptDigital",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER]: [
          "spouse.identity.fields.teletransmission",
          "spouse.identity.user.firstname",
          "spouse.identity.user.lastname",
          "spouse.identity.user.birthDate",
          "spouse.identity.socioProfessionalCategory",
          "spouse.identity.socialRegime",
          "spouse.identity.socialSecurityNumber",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN]:
          children.flatMap((_, i) => [
            `children.${i}.identity.user.firstname`,
            `children.${i}.identity.user.lastname`,
            `children.${i}.identity.user.birthDate`,
            `children.${i}.identity.socialRegime`,
            `children.${i}.identity.socialSecurityNumber`,
          ]),
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT]: [
          "payment.type",
          "payment.frequency",
          "payment.debitDay",
          "payment.payer.IBAN",
          "payment.payer.BIC",
          ...(get(subscription, "insurancePlan.fields.tns")
            ? ["payment.payer.owner.socialReason"]
            : [
                "payment.payer.owner.firstName",
                "payment.payer.owner.lastName",
              ]),
          `telecoms.payer.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`,
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.REFUND]: [
          "isPayerReceiver",
          "payment.receiver.owner.firstName",
          "payment.receiver.owner.lastName",
          "payment.receiver.IBAN",
          "payment.receiver.BIC",
        ],
      },
    };

    const spouse = get(subscription, "project.contact.relatedPersons", []).find(
      ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
    );
    const additionalInfoInitialValues = {
      ...subscription,
      telecoms,
      project: {
        ...subscription.project,
        ...(subscription.project.fields.twoTimesSale && {
          projectType: PROJECT_TYPES.COLD_LEAD,
        }),
        contact: defaultsDeep(subscription.project.contact, {
          fields: { teletransmission: true, familySituation: null },
          politicallyExposedPerson: false,
        }),
        contract: {
          ...subscription.project.contract,
          caseType: "NEW_CONTRACT",
          saleMode: "DISTANT",
        },
      },
      isPayerReceiver:
        get(subscription, "payment.payer.id", "") ===
        get(subscription, "payment.receiver.id")
          ? PAYMENT_RECEIVER.SAME_ACCOUNT
          : PAYMENT_RECEIVER.ANOTHER_ACCOUNT,
      payment: {
        id: cuid(),
        type: null,
        frequency: null,
        debitDay: null,
        ...subscription.payment,
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
        receiver: defaultsDeep(subscription.payment?.receiver, {
          id: cuid(),
          IBAN: null,
          BIC: null,
          owner: { id: cuid() },
        }),
      },
      children,
      spouse: isEmpty(spouse)
        ? undefined
        : defaultsDeep(spouse, {
            identity: { fields: { teletransmission: true } },
          }),
    };

    setDisabled(initialDisabled);
    setInitialValues({
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: additionalInfoInitialValues,
      fields,
    });
  }, []);

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: initSubscription,
  });

  const [signDocuments] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });

  const [disabled, setDisabled] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const isFieldRequired = (block, value) =>
    get(
      initialValues.fields,
      `${STEPPER_KEYS.ADDITIONAL_INFORMATION}.${block}`,
      []
    ).includes(value) && !get(disabled, value);

  const onFormSubmit = (values = {}) => {
    const { payment } = values;
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: { payment },
      },
      onCompleted: () => {
        onNext({
          payload: {},
        });
      },
    });
  };

  const onAddressSave = ({
    telecoms: { contact },
    project: {
      contact: { socialSecurityNumber },
    },
  }) => {
    const subscription = omitDeep(get(data, "subscription"), "__typename");
    const contactTelecoms = map(contact, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    set(subscription, "project.contact.telecoms", contactTelecoms);
    set(
      subscription,
      "project.contact.socialSecurityNumber",
      socialSecurityNumber
    );
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: subscription,
      },
      onCompleted: (_) => {
        initSubscription(_);
        setPaymentBlock(true);
      },
    });
  };

  const signDocument = async () => {
    if (
      [SUBSCRIPTION_STATUSES.DRAFT, SUBSCRIPTION_STATUSES.IN_PROGRESS].includes(
        get(data, "subscription.status")
      )
    )
      return;

    // setLoading(true);

    await signDocuments({
      variables: {
        data: {
          id: get(data, "subscription.id"),
        },
      },
      onCompleted: () => {
        setLoading(false);
        notification.open({
          message: TRANSLATION.E_SIGNATURE_SENT,
          duration: 5,
          type: "success",
        });
      },
    });
  };

  if (!initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION] || loading)
    return <Loading />;

  return (
    <View
      subscription={get(data, "subscription")}
      onBack={onBack}
      paymentBlock={paymentBlock}
      setPaymentBlock={setPaymentBlock}
      initialValues={initialValues}
      isFieldRequired={isFieldRequired}
      signDocument={signDocument}
      setLoading={setLoading}
      editSubscription={editSubscription}
      onAddressSave={onAddressSave}
      onPaymentSave={onFormSubmit}
      insurancePlanId={insurancePlanId}
      setInsurancePlanId={setInsurancePlanId}
      translate={TRANSLATION}
    />
  );
};

export default AdherentSocialRegime;
