import * as yup from "yup";

const validator = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);

  const requiredNumber = yup
    .number()
    .typeError(messages.REQUIRED)
    .min(0, `${messages.MIN} 0`)
    .required(messages.REQUIRED);

  return yup.object().shape({
    brand: requiredString,
    model: requiredString,
    purchasePrice: requiredNumber,
  });
};

export default validator;
