import classNames from "classnames";
import { MdCheck } from "react-icons/md";
import ProgressCircle from "shared/components/Progress";
import { COLORS } from "shared/style/colors";

const Progress = ({
  icon,
  slideTo = () => {},
  form,
  percent,
  title,
  translation,
}) => {
  return (
    <div
      className={classNames("subscription-progress", {
        complete: percent === 100,
      })}
      onClick={() => slideTo(form)}
    >
      <div className="subscription-progress--header">
        <ProgressCircle
          type="circle"
          percent={percent}
          size="small"
          strokeWidth={2}
          format={() => icon}
          strokeColor={COLORS.C_PRIMARY}
        />
        {percent === 100 ? (
          <MdCheck size={8} className="subscription-progress--header__status" />
        ) : (
          <div className="subscription-progress--header__status" />
        )}
      </div>
      <div className="subscription-progress--content">
        <h5 className="subscription-progress--content__title">{title}</h5>
        <p className="subscription-progress--content__description">
          {percent || 0}% {translation.PROGRESS}
        </p>
      </div>
    </div>
  );
};
export default Progress;
