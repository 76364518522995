import { useMutation } from "@apollo/client";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft, BiTrash } from "react-icons/bi";
import { useParams } from "react-router-dom";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Select from "shared/components/Select";
import Loading from "shared/components/Spin";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import { UPDATE_USER } from "utils/api/graphql/mutations/users";
import { USER, USERS } from "utils/api/graphql/queries/users";
import { USER_TYPES } from "utils/constants";

const Supervisor = ({ poleId, userName, onCancel, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.USER);
  const DEACTIVATE = t("DEACTIVATE", { returnObjects: true });
  const { id } = useParams();
  const [isAffected, setIsAffected] = useState({});
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: USER,
        awaitRefetchQueries: true,
        variables: {
          where: { id },
        },
      },
    ],
  });
  const [getSupervisors, { data: supervisors, loading: supervisorsLoading }] =
    useLazyQuery(USERS, {
      fetchPolicy: "no-cache",
      variables: {
        custom: {
          AND: [
            { meta: { type: USER_TYPES.INTERNAL_SUPERVISOR.type } },
            { id, operation: "!=" },
          ],
        },
      },
    });
  const { data, loading, error } = useQuery(USERS, {
    variables: {
      where: {
        pole: { id: poleId },
        meta: { type: USER_TYPES.INTERNAL_ADVISOR.type },
      },
    },
    onCompleted: ({ users }) => get(users, "count") && getSupervisors(),
  });
  const handleAffect = (advisorId, value) => {
    updateUser({
      variables: { where: { id: advisorId }, data: { pole: { id: value } } },
    });
    setIsAffected((prev) => ({ ...prev, [advisorId]: true }));
  };

  if (loading || supervisorsLoading || error) return <Loading />;

  return [
    <Header
      key={"header"}
      title={DEACTIVATE.SUPERVISOR.TITLE}
      subtitle={DEACTIVATE.SUPERVISOR.ACTION}
      actions={[
        {
          text: DEACTIVATE.SUPERVISOR.DISCARD,
          onClick: onCancel,
          type: "primary--outlined",
          icon: <BiChevronLeft size={20} />,
        },
        {
          text: DEACTIVATE.SUPERVISOR.DEACTIVATE,
          onClick: onSubmit,
          type: "danger",
          icon: <BiTrash size={20} />,
          disabled:
            Object.keys(isAffected)?.length < get(data, "users.count", 0),
        },
      ]}
    />,
    <Card key="user" type={"simple"}>
      <div className="user-delete">
        <h3 className="user-delete__title">
          {format(DEACTIVATE.SUPERVISOR.INFO, { name: userName })}
        </h3>
        {!!get(data, "users.count", 0) && (
          <h4 className="user-delete__subtitle">
            {format(DEACTIVATE.SUPERVISOR.COUNT, {
              count: get(data, "users.count", 0),
            })}
          </h4>
        )}
        <div>
          {get(data, "users.data", []).map(
            ({ id, firstname, lastname }, index) => (
              <div key={id} className="user-delete__row grid--2">
                <div>
                  {`${DEACTIVATE.SUPERVISOR.ADVISOR} ${index + 1} : `}
                  <strong>{`${firstname} ${lastname}`}</strong>
                </div>
                <Select
                  loading={supervisorsLoading}
                  options={get(supervisors, "users.data", [])?.map(
                    (supervisor) => ({
                      label: `${get(supervisor, "firstname")} ${get(
                        supervisor,
                        "lastname"
                      )}`,
                      value: get(supervisor, "pole.id"),
                    })
                  )}
                  placeholder={DEACTIVATE.SUPERVISOR.SELECT_SUPERVISOR}
                  onChange={(value) => handleAffect(id, value)}
                />
              </div>
            )
          )}
        </div>
      </div>
    </Card>,
  ];
};

export default Supervisor;
