import React from "react";
import { Spin } from "antd";
import { useWindowSize } from "shared/hooks/resize";

const Loading = () => {
  const [height] = useWindowSize();
  const adjustedHeight = height * 0.4;
  return (
    <div className="loader__wrapper" style={{ height: `${adjustedHeight}px` }}>
      <div className="loading">
        <Spin />
      </div>
    </div>
  );
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
