import { useSubscription } from "@apollo/client";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { KEYS, SCREENS } from "utils/constants";

export const Context = React.createContext("store");
export const { Consumer } = Context;

export const Provider = ({ children }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation(NAME_SPACES.HOME);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(KEYS.ACCESS_TOKEN)
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem(KEYS.USER)));
  const [language, setLanguage] = useState(localStorage.getItem(KEYS.LANGUAGE));

  const clearStore = () => {
    localStorage.clear();
    setAccessToken("");
    setUser(undefined);
    setLanguage("");
  };
  useSubscription(graphql.subscriptions.USER_UPDATED, {
    onData: ({ data: { data } }) => {
      if (get(data, "userUpdated.isActive")) return;
      const updatedUser = get(data, "userUpdated.id");
      if (updatedUser && updatedUser === user?.id) {
        clearStore();
        navigate(SCREENS.AUTH.SIGN_IN.path, { replace: true });
      }
    },
  });
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleSetAccessToken = (data) => {
    setAccessToken(data.accessToken);
    return localStorage.setItem(KEYS.ACCESS_TOKEN, data.accessToken);
  };

  const handleSetUser = (data) => {
    setUser(data.user);
    return localStorage.setItem(KEYS.USER, JSON.stringify(data.user));
  };

  const handleSetLanguage = (data) => {
    setLanguage(data.language);
    return localStorage.setItem(KEYS.LANGUAGE, data.language);
  };

  return (
    <Context.Provider
      value={{
        clearStore,
        setAccessToken: handleSetAccessToken,
        accessToken,
        setUser: handleSetUser,
        user,
        setLanguage: handleSetLanguage,
        language,
      }}
    >
      {children}
    </Context.Provider>
  );
};
