import gql from "graphql-tag";

export const CREATE_QUOTE = gql`
  mutation AddQuote($data: QuoteDataInput) {
    addQuote(data: $data) {
      id
    }
  }
`;

export const SEND_QUOTE = gql`
  mutation sendQuote($data: SendQuoteDataInput) {
    sendQuote(data: $data) {
      id
    }
  }
`;

export const SEND_B2C_QUOTE = gql`
  mutation sendB2CQuote($data: SendB2CQuoteDataInput) {
    sendB2CQuote(data: $data) {
      id
    }
  }
`;

const quotes = { SEND_QUOTE };

export default quotes;
