import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import { NAME_SPACES } from "shared/locales/constants";
import Stepper from "../Components/Stepper";

const View = ({ onNext, onBack, signatureLink }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js";
    script.integrity =
      "sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr";
    script.crossOrigin = "anonymous";
    script.onload = () => {
      const yousign = new window.Yousign({
        signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: process.env.REACT_APP_YOUSIGN_SANDBOX === 'true',
      });
      yousign.onSuccess((_) => onNext(_));
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [signatureLink]);

  return (
    <Container size={"medium"}>
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: true },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: true },
          ]}
        />
        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.ELECTRONIC_SIGNATURE.TITLE}
          </div>
          <div className="b2c__area--header__subtitle">
            {TRANSLATION.ELECTRONIC_SIGNATURE.SUBTITLE}
          </div>
        </div>
        <div id="iframe-container"></div>
      </div>
    </Container>
  );
};

export default View;
