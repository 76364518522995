import cuid from "cuid";
import { constant, get, isEmpty, times } from "lodash";
import { useMemo } from "react";
import { INCLUDED_BENEFICIARIES, RELATIONSHIP } from "utils/constants";
import View from "./View";

const ChildrenBirthDates = ({ project, onNext, onBack }) => {
  const children = useMemo(() => {
    const projectChildren = get(project, "contact.relatedPersons", []).filter(
      ({ relationship }) => relationship === RELATIONSHIP.CHILD
    );
    if (!isEmpty(projectChildren)) return projectChildren;
    return [
      INCLUDED_BENEFICIARIES.ALL,
      INCLUDED_BENEFICIARIES.WITH_CHILDREN,
    ].includes(get(project, "fields.includedBeneficiaries"))
      ? times(get(project, "fields.childrenNumber", 0), constant(null)).map(
          () => ({
            id: cuid(),
            relationship: RELATIONSHIP.CHILD,
            identity: {
              id: cuid(),
              user: {
                id: cuid(),
              },
            },
          })
        )
      : [];
  }, []);

  const next = (children) =>
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          relatedPersons: [
            get(project, "contact.relatedPersons", []).find(
              ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
            ),
            ...children,
          ],
        },
      },
    });

  return <View onNext={next} children={children} onBack={onBack} />;
};

export default ChildrenBirthDates;
