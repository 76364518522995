import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";

import { ConfigProvider } from "antd";
import locale from "antd/locale/fr_FR";
import "dayjs/locale/fr";
import client from "shared/graphql";
import { GlobalSubscriptionContext } from "shared/services/subscriptions";
import { StoreProvider } from "shared/store";
import { PreviousDisplayProvider } from "shared/store/previousDisplay";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./shared/locales/i18n";
import "./shared/style/style.scss";
// @ts-ignore

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <HashRouter>
      <GlobalSubscriptionContext client={client}>
        <StoreProvider>
          <PreviousDisplayProvider>
            <ConfigProvider locale={locale}>
              <App />
            </ConfigProvider>
          </PreviousDisplayProvider>
        </StoreProvider>
      </GlobalSubscriptionContext>
    </HashRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
