import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import { FaNetworkWired } from "react-icons/fa";
import {
  MdAlternateEmail,
  MdLocationCity,
  MdLocationPin,
  MdNumbers,
  MdOutlineBadge,
  MdOutlineDomain,
  MdPhone,
} from "react-icons/md";
import Radio from "shared/components/Form/Radio";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Advisor = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const FORM = t("FORM", { returnObjects: true });

  return (
    <div className="grid--1">
      <Radio
        isHorizontal={false}
        label={FORM.CIVILITY.LABEL}
        options={[
          {
            label: FORM.CIVILITY.MALE,
            value: GENDER.MALE,
          },
          {
            label: FORM.CIVILITY.FEMALE,
            value: GENDER.FEMALE,
          },
        ]}
        icon={<BiUserCircle size={14} />}
        {...bindInputProps({ name: "gender", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.NETWORK}
        label={FORM.NETWORK}
        icon={<FaNetworkWired size={14} />}
        {...bindInputProps({ name: "meta.network", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.AGENCY}
        label={FORM.AGENCY}
        icon={<MdOutlineDomain size={14} />}
        {...bindInputProps({ name: "meta.agency", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.FIRST_NAME}
        label={FORM.FIRST_NAME}
        icon={<MdOutlineBadge size={14} />}
        {...bindInputProps({ name: "firstname", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.LAST_NAME}
        label={FORM.LAST_NAME}
        icon={<MdOutlineBadge size={14} />}
        {...bindInputProps({ name: "lastname", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.EMAIL}
        label={FORM.EMAIL}
        icon={<MdAlternateEmail size={14} />}
        {...bindInputProps({ name: "email", ...formProps })}
        required
      />
      <Input
        placeholder={FORM.PHONE_AGENCY}
        label={FORM.PHONE_AGENCY}
        icon={<MdPhone size={14} />}
        {...bindInputProps({ name: "phone", ...formProps })}
      />
      <Input
        placeholder={FORM.ADDRESS}
        label={FORM.ADDRESS}
        icon={<MdLocationPin size={14} />}
        {...bindInputProps({ name: "address", ...formProps })}
      />
      <Input
        placeholder={FORM.ZIP_CODE}
        label={FORM.ZIP_CODE}
        icon={<MdNumbers size={14} />}
        {...bindInputProps({ name: "zipCode", ...formProps })}
      />
      <Input
        placeholder={FORM.CITY}
        label={FORM.CITY}
        icon={<MdLocationCity size={14} />}
        {...bindInputProps({ name: "city", ...formProps })}
      />
    </div>
  );
};

export default Advisor;
