import { Pagination } from "antd";
import { useState } from "react";
import { setPaginationHistory } from "utils/helpers/pagination";
import { INITIAL_VALUES } from "../../../utils/constants";

const TOP = { X: 0, Y: 0 };
const Component = ({
  count,
  setValues,
  skip = INITIAL_VALUES.skip,
  take = INITIAL_VALUES.take,
  scrollToTop = true,
  footprint = "",
}) => {
  const [page, setPage] = useState(Math.floor(skip / take) + 1);
  const onChange = (value) => {
    scrollToTop && window.scrollTo(TOP.X, TOP.Y);
    setPage(value);
    footprint && setPaginationHistory({ footprint, value, take, count });
    setValues((prev) => ({
      ...prev,
      skip: (value - 1) * take,
      take: take,
    }));
  };
  return (
    <Pagination
      onChange={onChange}
      defaultCurrent={page}
      total={count}
      showSizeChanger={false}
      pageSize={take}
    />
  );
};

export default Component;
