import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import View from "./View";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.DASHBOARD.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.DASHBOARD]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={View}
      />
    ),
  },
];

export default routes;
