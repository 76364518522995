import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { COLORS } from "shared/style/colors";
import View from "./View";

const ContactsStats = ({ data, title }) => {
  const [departmentsData, setDepartmentsData] = useState();

  useEffect(() => {
    constructChart(data);
  }, [data.map((item) => item.count).join(",")]);

  const constructChart = (chartData) => {
    const categories = chartData.map(({ name }) => name);
    const dataPoints = chartData.map(({ count }) => count);

    setDepartmentsData({
      options: {
        title: {
          text: title,
          align: "left",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: COLORS.C_TEXT,
          },
        },
        chart: {
          id: `basic-bar`,
          background: COLORS.C_WHITE,
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [COLORS.C_WHITE],
          },
          offsetY: -20,
          dropShadow: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: categories[0],
          data: dataPoints,
        },
      ],
    });
  };

  if (isUndefined(departmentsData)) return <Loading />;

  return <View data={departmentsData} />;
};

export default ContactsStats;
