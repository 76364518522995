import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
  const [addCoverageBenefits] = useMutation(
    graphql.mutations.CREATE_COVERAGE_BENEFITS,
    {
      refetchQueries: [
        {
          query: graphql.queries.COVERAGE_BENEFITS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
      },
      onError: () => {},
    }
  );
  const onSubmit = ({ coverageBenefits }) => {
    return addCoverageBenefits({
      variables: {
        data: coverageBenefits,
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
