import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import screens from "screens";
import Layout from "shared/components/Layout";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import Siders from "./Siders";

const App = () => {
  const { user, language, setLanguage } = useContext(StoreContext);
  const { t, i18n } = useTranslation(NAME_SPACES.HOME);
  const navigate = useNavigate();
  const drawers = [];
  const header = {
    menu: [],
    actions: [],
  };
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/admin")) {
      document.title = "Espace Admin";
    } else if (location.pathname.startsWith("/broker")) {
      document.title = "Espace conseiller";
    } else if (
      location.pathname.startsWith("/guest") ||
      location.pathname.startsWith("/quotes") ||
      location.pathname.startsWith("/public/process")
    ) {
      document.title = "Devis";
    } else {
      document.title = "Kayros";
    }
  }, [location]);

  const sider = Siders({ t, i18n, navigate, user, setLanguage, language });
  const layouts = {
    [true]: {
      element: Layout,
      header,
      sider,
      drawers,
    },
    [false]: { element: ({ children }) => <>{children}</> },
  };

  return useRoutes(screens({ user, layouts }));
};

export default App;
