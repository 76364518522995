import { useMutation } from "@apollo/client";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import {
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import Loading from "../Components/Spin";
import View from "./View";
const DOCUMENTS_TYPE = [
  DOCUMENTS_TYPES.CIN,
  DOCUMENTS_TYPES.PAYMENT_ACCOUNT,
  DOCUMENTS_TYPES.QUOTE,
  DOCUMENTS_TYPES.DUTY_OF_ADVICE,
  DOCUMENTS_TYPES.MEMBERSHIP_FORM,
  DOCUMENTS_TYPES.SEPA_DIRECT_DEBIT_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_LETTER,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.IPID,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.INFO_NOTICE,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.BROCHURE,
];

const SubscriptionDone = ({ project, onNext, onBack }) => {
  const [loading, setLoading] = useState(true);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const subscriptionId = get(project, "fields.currentSubscription");
  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: () => {
      getGeneralCost();
      getAttachments();
      setLoading(false);
    },
  });
  const subscription = get(omitDeep(data, "__typename"), "subscription", {});
  const filter = {
    where: {
      OR: [
        {
          AND: [
            {
              project: null,
            },
            {
              insurancePlans: {
                id: get(subscription, "insurancePlan.id", ""),
              },
            },
          ],
        },
        {
          subscription: {
            id: get(subscription, "id", ""),
          },
        },
        {
          AND: [
            {
              project: {
                id: get(subscription, "project.id"),
              },
            },
            {
              insurancePlans: {
                id: get(subscription, "insurancePlan.id", ""),
              },
            },
          ],
        },
      ],
    },
    isIn: {
      type: DOCUMENTS_TYPE,
    },
  };
  const [getAttachments, { data: attachmentsList }] = useLazyQuery(
    graphql.queries.ATTACHMENTS,
    {
      variables: filter,
      onCompleted: () => setLoading(false),
    }
  );
  const download = async (id) => {
    setLoading(true);
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
        setLoading(false);
      },
    });
  };

  const [getGeneralCost, { data: generalCost }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(project, "id") },
          insurancePlan: {
            id: get(subscription, "insurancePlan.id"),
          },
        },
      },
      onCompleted: () => setLoading(false),
    }
  );

  if (loading) return <Loading />;

  return (
    <View
      onBack={onBack}
      onNext={onNext}
      generalCost={get(generalCost, "generalCost")}
      download={download}
      attachments={get(attachmentsList, "attachments.data", [])}
      subscription={subscription}
    />
  );
};

export default SubscriptionDone;
