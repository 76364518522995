import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Create from "./Create";
import Deactivate from "./Deactivate";
import Delete from "./Delete";
import Detail from "./Detail";
import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.USERS.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.USER]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={List}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.ADMIN.USERS.CREATE.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.USER]: SUBMODULES.ADMIN_CREATE }}
        WrappedComponent={Create}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.USERS.DETAIL.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.USER]: SUBMODULES.ADMIN_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.USERS.DEACTIVATE.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.USER]: SUBMODULES.ADMIN_DELETE }}
        WrappedComponent={Deactivate}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.USERS.DELETE.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.USER]: SUBMODULES.ADMIN_DELETE }}
        WrappedComponent={Delete}
      />
    ),
  },
];

export default routes;
