import { Col, Row } from "antd";
import { get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import processDone from "shared/assets/icons/process_complete.svg";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT } from "utils/constants";
import { getFullAddress } from "utils/helpers/array";
import ProductCard from "../../widgets/ProductCard";
import Stepper from "../../widgets/Stepper";
import Container from "shared/components/Container";

const View = ({ subscription, attachments, download, onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const telecoms = get(subscription, "project.contact.telecoms", {});

  return (
    <Container size="medium">
      <div className="b2c__area bg-transparent">
        <div className="b2c-subscription_stepper">
          <Stepper translate={TRANSLATION} currentStep={"VALIDATION"} />
        </div>
        <div className="edit-subscription_wrapper">
          <div className="edit-subscription_wrapper--circle">
            <div className="edit-subscription_wrapper--circle__icon">
              <img alt="done" src={processDone} />
            </div>
            <span className="edit-subscription_wrapper--circle__title">
              {TRANSLATION.THANK_YOU.TITLE}
            </span>
            <span className="edit-subscription_wrapper--circle__substitle">
              {TRANSLATION.THANK_YOU.SUBTITLE}
            </span>
          </div>
          <div className="edit-subscription_wrapper--child">
            <Row gutter={[16, 16]}>
              <Col lg={10} xs={24}>
                <div className="resume_container--child resume_container__product">
                  <span className="resume_container__product--subtitle">
                    {TRANSLATION.OFFER}
                  </span>

                  <ProductCard
                    insurancePlan={get(subscription, "insurancePlan")}
                    project={get(subscription, "project")}
                  />
                </div>
              </Col>
              <Col lg={14} xs={24}>
                <div className="resume_container--card">
                  <div className="resume_container__informations--spouse">
                    <span className="resume_container__informations--spouse_title">
                      {TRANSLATION.SUBSCRIBER}
                    </span>
                    <div className="resume_container__informations--recap__node">
                      <span>{TRANSLATION.IDENTITY}</span>
                      <span>{`${get(
                        subscription,
                        "project.contact.user.firstname"
                      )} ${get(
                        subscription,
                        "project.contact.user.lastname"
                      )}`}</span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>{TRANSLATION.ADHERENT_INFO.BIRTH_DATE}</span>
                      <span>
                        {moment(
                          get(subscription, "project.contact.user.birthDate")
                        ).format(DATE_FORMAT)}
                      </span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>{TRANSLATION.ADHERENT_INFO.ADDRESS}</span>
                      <span>{getFullAddress({ telecoms })}</span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>
                        {TRANSLATION.ADHERENT_INFO.SOCIAL_SECURITY_NUMBER}
                      </span>
                      <span>
                        {get(
                          subscription,
                          "project.contact.socialSecurityNumber"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="resume_container__informations--spouse">
                    <span className="resume_container__informations--spouse_title">
                      {TRANSLATION.DOCUMENTS}
                    </span>
                    {attachments.map((attachment, index) => (
                      <div
                        key={`attachment-${index}`}
                        className="resume_container__informations--recap__node"
                      >
                        <span>{attachment.name}</span>
                        <Button
                          className="danger--underline"
                          onClick={() => download(attachment)}
                        >
                          {TRANSLATION.DOWNLOAD}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default View;
