import cuid from "cuid";
import { FieldArray } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiDeleteBinFill } from "react-icons/ri";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { bindArrayInputProps } from "utils/helpers/input";

const CoverageBenefitsList = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT);
  const FORM = t("LIST", { returnObjects: true });

  return (
    <>
      <Card type="simple">
        <FieldArray
          name={"coverageBenefits"}
          render={(arrayHelpers) => (
            <div className="coverage-benefits-wrapper">
              {get(formProps, "values.coverageBenefits")?.map((_, i) => (
                <div key={_.id} className="coverage__input">
                  <div className="d-flex justify--end">
                    <div onClick={() => arrayHelpers.remove(i)}>
                      <RiDeleteBinFill size={24} color={COLORS.C_DANGER} />
                    </div>
                  </div>
                  <div className="grid--2">
                    <Input
                      type="number"
                      label={FORM.ORDER}
                      placeholder={FORM.ORDER}
                      min={1}
                      {...bindArrayInputProps({
                        parent: "coverageBenefits",
                        index: i,
                        name: "order",
                        ...formProps,
                      })}
                    />
                    <Input
                      label={FORM.CATEGORY}
                      placeholder={FORM.CATEGORY}
                      {...bindArrayInputProps({
                        parent: "coverageBenefits",
                        index: i,
                        name: "type",
                        ...formProps,
                      })}
                    />
                    <Input
                      label={FORM.SUBTYPE}
                      placeholder={FORM.SUBTYPE}
                      {...bindArrayInputProps({
                        parent: "coverageBenefits",
                        index: i,
                        name: "subtype",
                        ...formProps,
                      })}
                    />
                    <Input
                      type="textarea"
                      label={FORM.INFO}
                      placeholder={FORM.INFO}
                      rows={2}
                      {...bindArrayInputProps({
                        parent: "coverageBenefits",
                        index: i,
                        name: "additionalInfo",
                        ...formProps,
                      })}
                    />
                  </div>
                </div>
              ))}
              <Button
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({ id: cuid() });
                }}
                type={"primary"}
              >
                {FORM.ADD_BENEFIT}
                <RiAddFill />
              </Button>
            </div>
          )}
        />
      </Card>
    </>
  );
};

export default CoverageBenefitsList;
