import { REGEX_EXPS, USER_TYPES } from "utils/constants";
import * as yup from "yup";

const validator = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);

  const advisorRequiredZipCodeString = yup.mixed().when("meta.type", {
    is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
    then: yup
      .string()
      .typeError(errors.REQUIRED)
      .test("isValid", errors.INVALID_VALUE, async function (value) {
        return new RegExp(REGEX_EXPS.zipCode).test(value);
      }),
  });
  return yup.object().shape({
    meta: yup.object().shape({
      type: requiredString,
      network: yup.mixed().when("type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
      agency: yup.mixed().when("type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
    }),
    gender: requiredString,
    firstname: requiredString,
    lastname: requiredString,
    phone: yup
      .string()
      .typeError(errors.REQUIRED)
      .nullable()
      .test("isValid", errors.INVALID_VALUE, function (value) {
        if (!value) return true;
        return new RegExp(REGEX_EXPS.phone).test(value);
      }),
    address: yup.mixed().when("meta.type", {
      is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
      then: requiredString,
    }),
    city: yup.mixed().when("meta.type", {
      is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
      then: requiredString,
    }),
    zipCode: advisorRequiredZipCodeString,
  });
};

export default validator;
