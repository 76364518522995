import { Col, Row, Switch } from "antd";
import { useFormik } from "formik";
import { get } from "lodash";
import moment from "moment";
import { MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { DATE_FORMAT } from "utils/constants";
import { getFullAddress } from "utils/helpers/array";
import ProductCard from "../../widgets/ProductCard";
import Stepper from "../../widgets/Stepper";
import Container from "shared/components/Container";

const View = ({
  subscription,
  signDocuments,
  onBack,
  attachments,
  download,
  translate,
}) => {
  const telecoms = get(subscription, "project.contact.telecoms", {});

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {},
    onSubmit: signDocuments,
  });

  const validForm = () =>
    get(values, "condition") && get(values, "teletransmision");

  return (
    <Container size="medium">
      <div className="b2c__area bg-transparent">
        <div className="b2c-subscription_stepper">
          <Stepper translate={translate} currentStep="VALIDATION" />
        </div>
        <div className="edit-subscription_wrapper">
          <div className="edit-subscription_wrapper--header">
            <span className="edit-subscription_wrapper--header__title">
              {translate.THANK_YOU.TITLE}
            </span>
            <span className="edit-subscription_wrapper--header__substitle">
              {translate.THANK_YOU.SUBTITLE}
            </span>
          </div>
          <div className="edit-subscription_wrapper--child">
            <Row gutter={[16, 16]}>
              <Col lg={10} xs={24}>
                <div className="resume_container--child resume_container__product">
                  <span className="resume_container__product--subtitle">
                    {translate.OFFER}
                  </span>
                  <ProductCard
                    insurancePlan={get(subscription, "insurancePlan")}
                    project={get(subscription, "project")}
                  />
                </div>
              </Col>
              <Col lg={14} xs={24}>
                <div className="resume_container--card">
                  <div className="resume_container__informations--spouse">
                    <span className="resume_container__informations--spouse_title">
                      {translate.SUBSCRIBER}
                    </span>
                    <div className="resume_container__informations--recap__node">
                      <span>{translate.IDENTITY}</span>
                      <span>{`${get(
                        subscription,
                        "project.contact.user.firstname"
                      )} ${get(
                        subscription,
                        "project.contact.user.lastname"
                      )}`}</span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>{translate.ADHERENT_INFO.BIRTH_DATE}</span>
                      <span>
                        {moment(
                          get(subscription, "project.contact.user.birthDate")
                        ).format(DATE_FORMAT)}
                      </span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>{translate.ADHERENT_INFO.ADDRESS}</span>
                      <span>{getFullAddress({ telecoms })}</span>
                    </div>
                    <div className="resume_container__informations--recap__node">
                      <span>
                        {translate.ADHERENT_INFO.SOCIAL_SECURITY_NUMBER}
                      </span>
                      <span>
                        {get(
                          subscription,
                          "project.contact.socialSecurityNumber"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="resume_container__informations--spouse">
                    <span className="resume_container__informations--spouse_title">
                      <span>{translate.DOCUMENTS.LABEL}</span>
                    </span>
                    {attachments.map((attachment, index) => (
                      <div
                        key={`attachment-${index}`}
                        className="resume_container__informations--recap__node"
                      >
                        <span>{attachment.name}</span>
                        <Button
                          className="danger--underline"
                          onClick={() => download(attachment)}
                        >
                          {translate.DOWNLOAD}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="edit-subscription_wrapper--child conditions-wrapper">
            <div className="edit-subscription_wrapper--child__condition">
              <Switch
                className="contact-stepper__content--radio--switch button__switch"
                checkedChildren={translate.TELETRANSMISSION.OPTIONS.YES}
                unCheckedChildren={translate.TELETRANSMISSION.OPTIONS.NO}
                onChange={(e) => setFieldValue("teletransmision", e)}
              />
              <span>{translate.TELETRANSMISSION.LABEL}</span>
            </div>
            <div className="edit-subscription_wrapper--child__condition">
              <Switch
                className="contact-stepper__content--radio--switch button__switch"
                checkedChildren={translate.AGREEMENT.OPTIONS.YES}
                unCheckedChildren={translate.AGREEMENT.OPTIONS.NO}
                onChange={(e) => setFieldValue("condition", e)}
              />
              <span>{translate.AGREEMENT.LABEL}</span>
            </div>
          </div>
          <div className="edit-subscription_wrapper--actions">
            <Row gutter={[16, 16]}>
              <Col lg={24} xs={24}>
                <Button
                  className="edit-subscription_wrapper--actions__next"
                  onClick={handleSubmit}
                  disabled={!validForm()}
                >
                  {translate.SIGN}
                  <MdArrowForwardIos />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="edit-subscription__previous">
          <Button className="secondary--link" onClick={onBack}>
            {translate.BACK_TO_PAYMENT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
