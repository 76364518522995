import PropTypes from "prop-types";
import { useMemo } from "react";

import Default from "./Default";
import Vertical from "./Vertical";

const MODES = {
  default: Default,
  vertical: Vertical,
};
const Card = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Card.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Card.defaultProps = {
  type: "default",
};

export default Card;
