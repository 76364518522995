import { Col, Row } from "antd";
import { filter, get, isEmpty } from "lodash";
import moment from "moment";
import Button from "shared/components/Button";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  GENDER,
  RELATIONSHIP,
} from "utils/constants";
import ChangeProduct from "./screens/ChangeProduct";
import Contact from "./widgets/Contact";
import Payment from "./widgets/Payment";
import ProductCard from "./widgets/ProductCard";
import Stepper from "./widgets/Stepper";

const GENDER_TEXT = {
  [GENDER.MALE]: "Monsieur",
  [GENDER.FEMALE]: "Madame",
};

const View = ({
  editSubscription,
  onAddressSave,
  paymentBlock,
  setPaymentBlock,
  subscription,
  onPaymentSave,
  initialValues,
  isFieldRequired,
  setInsurancePlanId,
  translate,
  insurancePlanId,
}) => {
  const children = filter(
    get(subscription, "project.contact.relatedPersons", []),
    {
      relationship: RELATIONSHIP.CHILD,
    }
  );
  const spouse = get(subscription, "project.contact.relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );

  return (
    <div className="b2c__area bg-transparent">
      <div className="b2c-subscription_stepper">
        <Stepper translate={translate} />
      </div>
      <Row gutter={[24, 24]}>
        <Col lg={16} xs={24}>
          <div className="collaped_container">
            <div
              className={`collaped_container--child ${
                !paymentBlock && "active"
              }`}
            >
              <Contact
                initialValues={initialValues}
                paymentBlock={paymentBlock}
                setPaymentBlock={setPaymentBlock}
                translate={translate}
                isFieldRequired={isFieldRequired}
                onAddressSave={onAddressSave}
              />
            </div>
            <div
              className={`collaped_container--child ${
                paymentBlock && "active"
              }`}
            >
              <Payment
                initialValues={initialValues}
                translate={translate}
                isFieldRequired={isFieldRequired}
                onPaymentSave={onPaymentSave}
              />
            </div>
          </div>
        </Col>
        <Col lg={8} xs={24}>
          <div className="recap_container">
            <div className="recap_container--child recap_container__product">
              <span className="recap_container__product--subtitle">
                {translate.SELECTED_OFFER}
              </span>
              <ProductCard
                insurancePlan={get(subscription, "insurancePlan")}
                project={get(subscription, "project")}
              />
              <div className="recap_container__product--actions">
                <Button
                  className="recap_container__product--actions__edit"
                  onClick={() => setInsurancePlanId(true)}
                >
                  {translate.EDIT_PRODUCT}
                </Button>
              </div>
            </div>
            <div className="recap_container--child recap_container__informations">
              <div className="recap_container__informations--title">
                <span>{translate.ADHERENT_INFO.BLOCK_TITLE}</span>
                <Button
                  className="danger--underline"
                  onClick={() => editSubscription()}
                >
                  <span>{translate.EDIT}</span>
                </Button>
              </div>
              <div className="recap_container__informations--container">
                <div className="recap_container__informations--recap">
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.CIVILITY}</span>
                    <span>
                      {
                        GENDER_TEXT[
                          get(
                            initialValues,
                            "ADDITIONAL_INFORMATION.project.contact.user.gender"
                          )
                        ]
                      }
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.LAST_NAME}</span>
                    <span>
                      {get(
                        initialValues,
                        "ADDITIONAL_INFORMATION.project.contact.user.lastname"
                      )}
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.FIRST_NAME}</span>
                    <span>
                      {get(
                        initialValues,
                        "ADDITIONAL_INFORMATION.project.contact.user.firstname"
                      )}
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.MANDATORY_SCHEME.LABEL}</span>
                    <span>
                      {
                        translate.MANDATORY_SCHEME.OPTIONS[
                          get(
                            initialValues,
                            "ADDITIONAL_INFORMATION.project.contact.socialRegime"
                          )
                        ]
                      }
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.BIRTH_DATE}</span>
                    <span>
                      {moment(
                        get(
                          initialValues,
                          "ADDITIONAL_INFORMATION.project.contact.user.birthDate"
                        )
                      ).format(DATE_FORMAT)}
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.PHONE_NUMBER}</span>
                    <span>
                      {get(
                        initialValues,
                        `ADDITIONAL_INFORMATION.telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                      )}
                    </span>
                  </div>
                  <div className="recap_container__informations--recap__node">
                    <span>{translate.ADHERENT_INFO.EMAIL}</span>
                    <span>
                      {get(
                        initialValues,
                        `ADDITIONAL_INFORMATION.telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                      )}
                    </span>
                  </div>
                </div>
                {!isEmpty(spouse) && (
                  <div className="recap_container__informations--spouse">
                    <span className="recap_container__informations--spouse_title">
                      {translate.PARTNER.TITLE}
                    </span>
                    <div className="recap_container__informations--recap__node">
                      <span>{translate.ADHERENT_INFO.BIRTH_DATE}</span>
                      <span>
                        {moment(get(spouse, "identity.user.birthDate")).format(
                          DATE_FORMAT
                        )}
                      </span>
                    </div>
                    <div className="recap_container__informations--recap__node">
                      <span>{translate.MANDATORY_SCHEME.LABEL}</span>
                      <span>
                        {
                          translate.MANDATORY_SCHEME.OPTIONS[
                            get(spouse, "identity.socialRegime")
                          ]
                        }
                      </span>
                    </div>
                  </div>
                )}
                {children.length > 0 && (
                  <div className="recap_container__informations--children">
                    <span className="recap_container__informations--children_title">
                      {translate.CHILDREN.TITLE}
                    </span>
                    <div className="recap_container__informations--recap__node">
                      <span>{translate.CHILDREN.DESCRIPTION}</span>
                      <span>{children.length}</span>
                    </div>
                    {children.map((child, index) => (
                      <div className="children-block" key={`child-${index}`}>
                        <span className="children-count">
                          {translate.CHILDREN_COUNT[index]}
                        </span>
                        <div className="recap_container__informations--recap__node">
                          <span>{translate.ADHERENT_INFO.BIRTH_DATE}</span>
                          <span>
                            {moment(
                              get(child, "identity.user.birthDate")
                            ).format(DATE_FORMAT)}
                          </span>
                        </div>
                        <div className="recap_container__informations--recap__node">
                          <span>{translate.MANDATORY_SCHEME.LABEL}</span>
                          <span>
                            {
                              translate.MANDATORY_SCHEME.OPTIONS[
                                get(child, "identity.socialRegime")
                              ]
                            }
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {insurancePlanId && (
        <ChangeProduct
          translate={translate}
          insurancePlan={get(subscription, "insurancePlan")}
          setInsurancePlanId={setInsurancePlanId}
          subscription={subscription}
        />
      )}
    </div>
  );
};

export default View;
