import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { CREATE_ATTACHMENT } from "utils/api/graphql/mutations/attachment";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
  const [updateInsurancePlan] = useMutation(
    graphql.mutations.UPDATE_INSURANCE_PLAN
  );

  const [addCoverage] = useMutation(graphql.mutations.CREATE_COVERAGE, {
    refetchQueries: [
      {
        query: graphql.queries.COVERAGES,
        awaitRefetchQueries: true,
        variables: {
          ...INITIAL_VALUES,
          where: { insurancePlan: { id } },
        },
      },
      {
        query: graphql.queries.COVERAGES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
  });
  const { data, loading, error } = useQuery(graphql.queries.INSURANCE_PLAN, {
    variables: { where: { id } },
  });

  if (loading || error) return "Loading";

  return (
    <View
      addCoverage={addCoverage}
      data={data.insurancePlan}
      cancel={cancel}
      addAttachment={addAttachment}
      updateInsurancePlan={updateInsurancePlan}
    />
  );
};

export default Detail;
