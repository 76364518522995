import cuid from "cuid";
import { get } from "lodash";
import View from "./View";

const AdherentInfo = ({ project, onNext, onBack }) => {
  const next = (user) => {
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          user: {
            id: get(project, "contact.user.id") || cuid(),
            ...user,
          },
        },
      },
    });
  };
  return (
    <View onNext={next} user={get(project, "contact.user")} onBack={onBack} />
  );
};

export default AdherentInfo;
