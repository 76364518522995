import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import NoDate from "shared/assets/icons/no_date.png";
import Button from "shared/components/Button";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";

const UpdateCostModal = ({
  showChangeCostModal,
  insurancePlanName,
  onChangeCost,
  onCancelChangeCost,
  setShowChangeCostModal,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const CHANGE_COST = t("DETAIL.CHANGE_COST", { returnObjects: true });
  const isCostExist = {
    [false]: (
      <>
        <img src={NoDate} alt="noDAte" />
        <h4 className="cost-updated__title">{CHANGE_COST.NO_COST}</h4>
        <p className="cost-updated__text">{CHANGE_COST.NO_COST_TEXT}</p>
        <div className="cost-updated__actions">
          <Button
            type="gray--outlined"
            onClick={() => setShowChangeCostModal({})}
          >
            {CHANGE_COST.CLOSE}
          </Button>
        </div>
      </>
    ),
    [true]: (
      <>
        <div>
          <h4 className="cost-updated__title">{CHANGE_COST.TITLE}</h4>
          <p className="cost-updated__subtitle">{CHANGE_COST.SUBTITLE}</p>
        </div>
        <div className="cost-updated__frame-container">
          <div className="cost-updated__cost">
            <h4>{CHANGE_COST.OLD_COST}</h4>
            <div className="cost-updated__frame">
              <p className="cost-updated__frame--effectiveDate">
                {`${CHANGE_COST.EFFECTIVE_DATE} : ${get(
                  showChangeCostModal,
                  "issuanceDate.old"
                )}`}
              </p>
              <p className="cost-updated__frame--insurance">
                {insurancePlanName}
              </p>
              <p className="cost-updated__frame--cost">
                <strong>{get(showChangeCostModal, "cost.old")}</strong>{" "}
                {CHANGE_COST.PER_MONTH}
              </p>
            </div>
          </div>
          <div className="cost-updated__cost cost-updated__cost--active">
            <h4>{CHANGE_COST.NEW_COST}</h4>
            <div className="cost-updated__frame cost-updated__frame--active">
              <p className="cost-updated__frame--effectiveDate cost-updated__frame--effectiveDate--active">
                {`${CHANGE_COST.EFFECTIVE_DATE} : ${get(
                  showChangeCostModal,
                  "issuanceDate.new"
                )}`}{" "}
              </p>
              <p className="cost-updated__frame--insurance cost-updated__frame--insurance--active">
                {insurancePlanName}
              </p>
              <p className="cost-updated__frame--cost cost-updated__frame--cost--active">
                <strong>{get(showChangeCostModal, "cost.new")}</strong>{" "}
                {CHANGE_COST.PER_MONTH}
              </p>
            </div>
          </div>
        </div>
        <div className="cost-updated__actions">
          <Button type="gray--outlined" onClick={onCancelChangeCost}>
            {CHANGE_COST.CANCEL}
          </Button>
          <Button type="primary" onClick={onChangeCost}>
            {CHANGE_COST.CONTINUE}
          </Button>
        </div>
      </>
    ),
  };
  return (
    <Modal
      mode="simple"
      width="80%"
      open={!isEmpty(showChangeCostModal)}
      footer={null}
      className="cost-updated"
      onCancel={() =>
        showChangeCostModal.noCost
          ? setShowChangeCostModal({})
          : onCancelChangeCost()
      }
      child={isCostExist[!showChangeCostModal.noCost]}
    />
  );
};

export default UpdateCostModal;
