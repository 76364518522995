import { isEmpty } from "lodash";
import { useContext } from "react";
import Redirect from "screens/Auth/Redirect";
import { StoreContext } from "shared/store";

function WithRoutePermission({ submodules, WrappedComponent, ...props }) {
  const {
    user: { permissions },
  } = useContext(StoreContext);

  const hasPermission =
    !isEmpty(submodules) &&
    Object.entries(submodules).every(([module, submodule]) =>
      permissions.find(
        ({ module: permissionModule, submodules, granted }) =>
          granted &&
          permissionModule === module &&
          submodules?.find(({ granted, name }) => granted && name === submodule)
      )
    );

  if (hasPermission) {
    return <WrappedComponent {...props} />;
  } else {
    return <Redirect />;
  }
}

export default WithRoutePermission;
