import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineQrCode,
  MdOutlineSouthAmerica,
  MdOutlineWorkHistory,
  MdWorkOutline,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_EXERCISE_FRAME,
  JOB_LIST,
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

export const showExerciseFrame = (value) =>
  [
    SOCIO_PROFESSIONAL_CATEGORIES.FARMERS,
    SOCIO_PROFESSIONAL_CATEGORIES.ARTISANS,
    SOCIO_PROFESSIONAL_CATEGORIES.LIBERAL_PROFESSIONS_AND_SIMILAR,
    SOCIO_PROFESSIONAL_CATEGORIES.TRADERS_AND_SIMILAR,
    SOCIO_PROFESSIONAL_CATEGORIES.ENTREPRENEURS,
  ].includes(value);

const Activity = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  disabled,
  setDisabled,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ACTIVITY}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ACTIVITY.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Select
              options={Object.entries(SOCIO_PROFESSIONAL_CATEGORIES).map(
                ([key, value]) => ({
                  value,
                  label:
                    SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.OPTIONS[key],
                })
              )}
              label={SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.LABEL}
              icon={<MdWorkOutline size={16} />}
              placeholder={
                SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.PLACEHOLDER
              }
              defaultValue={get(
                initialValues,
                "project.contact.socioProfessionalCategory"
              )}
              errors={errors["project.contact.socioProfessionalCategory"]}
              touched={
                !isEmpty(errors["project.contact.socioProfessionalCategory"])
              }
              onChange={(value) => {
                set(
                  initialValues,
                  "project.contact.socioProfessionalCategory",
                  value
                );
                if (
                  (
                    get(
                      initialValues,
                      "insurancePlan.config.additionalDocuments",
                      []
                    ) || []
                  ).includes("proof.pensionFund")
                ) {
                  setDisabled((prev) => ({
                    ...prev,
                    [SUBSCRIPTION_FORM_KEYS.PROOF.PENSION_FUND]:
                      value === SOCIO_PROFESSIONAL_CATEGORIES.RETIREES,
                  }));
                }

                if (!showExerciseFrame(value))
                  set(
                    initialValues,
                    "project.contact.exerciseFrame",
                    CONTACT_EXERCISE_FRAME.EMPLOYEE
                  );
                setDisabled((prev) => ({
                  ...prev,
                  project: {
                    ...prev.project,
                    contact: {
                      ...prev.project.contact,
                      exerciseFrame: !showExerciseFrame(value),
                    },
                  },
                }));
              }}
              onBlur={() => setPercent(initialValues)}
              optional={
                !isFieldRequired("project.contact.socioProfessionalCategory") &&
                SUBSCRIPTION.FORM.OPTIONAL
              }
              required={isFieldRequired(
                "project.contact.socioProfessionalCategory"
              )}
            />
          </Col>
          {showExerciseFrame(
            get(initialValues, "project.contact.socioProfessionalCategory")
          ) && (
            <Col lg={24} xs={24}>
              <Input
                type="radio"
                variant="group"
                optional={SUBSCRIPTION.FORM.OPTIONAL}
                icon={<MdOutlineWorkHistory size={16} />}
                label={SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.LABEL}
                values={[
                  {
                    value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                    label:
                      SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.OPTIONS.EMPLOYEE,
                  },
                  {
                    value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                    label:
                      SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.OPTIONS.INDEPENDENT,
                  },
                ]}
                defaultValue={get(
                  initialValues,
                  "project.contact.exerciseFrame"
                )}
                errors={errors["project.contact.exerciseFrame"]}
                touched={!isEmpty(errors["project.contact.exerciseFrame"])}
                onBlur={() => setPercent(initialValues)}
                onChange={(value) => {
                  set(initialValues, "project.contact.exerciseFrame", value);
                  if (value === CONTACT_EXERCISE_FRAME.EMPLOYEE)
                    set(initialValues, "project.contact.siretNumber", null);
                }}
                required={isFieldRequired("project.contact.exerciseFrame")}
              />
            </Col>
          )}
          {get(initialValues, "project.contact.exerciseFrame") ===
            CONTACT_EXERCISE_FRAME.INDEPENDENT && (
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.SIRET.LABEL}
                optional={SUBSCRIPTION.FORM.OPTIONAL}
                icon={<MdOutlineQrCode size={16} />}
                placeholder={SUBSCRIPTION.FORM.SIRET.PLACEHOLDER}
                defaultValue={get(initialValues, "project.contact.siretNumber")}
                errors={errors["project.contact.siretNumber"]}
                touched={!isEmpty(errors["project.contact.siretNumber"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "project.contact.siretNumber", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("project.contact.siretNumber")}
              />
            </Col>
          )}
          <Col lg={24} xs={24}>
            <Select
              options={Object.entries(JOB_LIST).map(([key, value]) => ({
                label: SUBSCRIPTION.FORM.PROFESSION.LIST[key],
                value,
              }))}
              icon={<MdWorkOutline size={16} />}
              placeholder={SUBSCRIPTION.FORM.PROFESSION.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.PROFESSION.LABEL}
              defaultValue={get(initialValues, "project.contact.profession")}
              errors={errors["project.contact.profession"]}
              touched={!isEmpty(errors["project.contact.profession"])}
              onChange={(value) => {
                set(initialValues, "project.contact.profession", value);
              }}
              onBlur={() => setPercent(initialValues)}
              optional={
                !get(disabled, "project.contact.profession") &&
                !isFieldRequired("project.contact.profession") &&
                SUBSCRIPTION.FORM.OPTIONAL
              }
              required={isFieldRequired("project.contact.profession")}
              disabled={get(disabled, "project.contact.profession")}
            />
          </Col>
          {!get(disabled, "project.contact.optMadelin") && (
            <>
              <Col lg={24} xs={24}>
                <Input
                  type="radio"
                  variant="group"
                  optional={SUBSCRIPTION.FORM.OPTIONAL}
                  icon={<MdOutlineSouthAmerica size={16} />}
                  label={SUBSCRIPTION.FORM.MADELIN_LAW.LABEL}
                  values={[
                    {
                      value: true,
                      label: SUBSCRIPTION.FORM.MADELIN_LAW.OPTIONS.YES,
                    },
                    {
                      value: false,
                      label: SUBSCRIPTION.FORM.MADELIN_LAW.OPTIONS.NO,
                    },
                  ]}
                  defaultValue={get(
                    initialValues,
                    "project.contact.optMadelin"
                  )}
                  errors={errors["project.contact.optMadelin"]}
                  touched={!isEmpty(errors["project.contact.optMadelin"])}
                  onChange={(value) =>
                    set(initialValues, "project.contact.optMadelin", value)
                  }
                  required={isFieldRequired("project.contact.optMadelin")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Alert
                  showIcon
                  type="info"
                  message={SUBSCRIPTION.FORM.MADELIN_LAW.INFO}
                />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default Activity;
