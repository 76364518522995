import { get } from "lodash";
import View from "./View";

const IncludedBeneficiaries = ({ project, onNext, onBack }) => {
  const next = (includedBeneficiaries) =>
    onNext({
      payload: {
        fields: {
          ...project.fields,
          includedBeneficiaries,
        },
      },
    });

  return (
    <View
      onNext={next}
      onBack={onBack}
      includedBeneficiaries={get(project, "fields.includedBeneficiaries")}
    />
  );
};

export default IncludedBeneficiaries;
