import PropTypes from "prop-types";
import Button from "shared/components/Button";

const ProductCard = ({
  image,
  name,
  price,
  onModify,
  variant = "horizontal",
  disabled = false,
}) => {
  const cardClass = `product__card product__card--${variant}`;

  return (
    <div className={cardClass}>
      <img src={image} alt={name} className="product__card--image" />
      <div className="product__card--content">
        <h3 className="product__card--name">{name}</h3>
        <p className="product__card--price">
          <span className="label">Prix d’achat :</span>
          <span className="value">{price}</span>
        </p>
        <Button
          type="primary--underline"
          onClick={onModify}
          className="btn--modify"
          disabled={disabled}
        >
          Modifier
        </Button>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onModify: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["horizontal", "vertical"]),
};

export default ProductCard;
