import { get } from "lodash";
import moment from "moment";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { COVERAGE_NEEDS, DATE_FORMAT } from "utils/constants";
import Level from "./Level";

const ProductCard = ({ insurancePlan, onClick, project }) => {
  const { data, loading } = useQuery(graphql.queries.GENERAL_COST, {
    variables: {
      where: {
        project: { id: get(project, "id") },
        insurancePlan: { id: get(insurancePlan, "id") },
      },
    },
  });
  if (loading) return <Loading />;
  const convertNeed = (need) => {
    const needsMapping = {
      5: 4,
      4: 3,
      3: 2,
      default: 1,
    };
    return (
      needsMapping[get(get(data, "generalCost.insurancePlan.needs"), need)] ||
      needsMapping.default
    );
  };

  return (
    <div onClick={onClick}>
      <div className="recap_container__product--title">
        <span className="product-title">{get(insurancePlan, "name")}</span>
        <span className="product-formula">
          {get(insurancePlan, "description")}
        </span>
      </div>
      <div className="recap_container__product--needs">
        <div className="recap_container__product--needs__node">
          <Level
            size={4}
            filled={convertNeed(COVERAGE_NEEDS.MEDICAL_CARE)}
            label="Soins courants"
          />
        </div>
        <div className="recap_container__product--needs__node">
          <Level
            size={4}
            filled={convertNeed(COVERAGE_NEEDS.HOSPITALIZATION)}
            label="Hospitalisation"
          />
        </div>
        <div className="recap_container__product--needs__node">
          <Level
            size={4}
            filled={convertNeed(COVERAGE_NEEDS.OPTICAL)}
            label="Optique"
          />
        </div>
        <div className="recap_container__product--needs__node">
          <Level
            size={4}
            filled={convertNeed(COVERAGE_NEEDS.DENTAL)}
            label="Dentaire"
          />
        </div>
        <div className="recap_container__product--needs__node">
          <Level
            size={4}
            filled={convertNeed(COVERAGE_NEEDS.EQUIPMENT)}
            label="Aides auditives"
          />
        </div>
      </div>
      <div className="recap_container__product--recap">
        <div className="recap_container__product--recap__node">
          <span>Cotisation mensuelle</span>
          <span className="price-tag">€{get(data, "generalCost.cost")}</span>
        </div>
        <div className="recap_container__product--recap__node">
          <span>Date d'effet</span>
          <span>
            {moment(get(project, "contract.issuanceDate")).format(DATE_FORMAT)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
