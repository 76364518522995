import { CopyOutlined } from "@ant-design/icons";
import { message } from "antd";
import cuid from "cuid";
import { useState } from "react";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useMutation } from "shared/hooks/useApi";
import { DOMAIN_SIGN_UP } from "utils/api/graphql/mutations/users";
import { SOURCES } from "utils/api/graphql/queries/sources";
import { USER_TYPES } from "utils/constants";

function IframeGenerator({ organizationId, translation }) {
  const [source, setSource] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [url, setUrl] = useState("");
  const [addDomain] = useMutation(DOMAIN_SIGN_UP);

  const handleGenerateIframeCode = async () => {
    addDomain({
      variables: {
        data: {
          id: cuid(),
          name: `${url}/${source}`,
          meta: { type: USER_TYPES.INSURANCE_GUEST.type },
          organization: {
            id: organizationId,
          },
        },
      },
    });
    const iframeCode = `<iframe
    id="b2c_flow"
    frameborder="0"
    width="100%"
    height="1000"
    src="${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}"
  ></iframe>
  <script>
    const iframe = document.getElementById("b2c_flow");
    iframe.addEventListener("load", function () {
      iframe.contentWindow.postMessage(
        { url: window.location.href },
        "${process.env.REACT_APP_BASE_URL}"
      );
    });
  </script>`;
    setIframeSrc(iframeCode);
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        setUrl("");
        message.success(translation.SUCCESS);
      })
      .catch(() => {
        message.error(translation.ERROR);
      });
  };

  return (
    <div className="grid--1">
      <Input
        value={url}
        placeholder={translation.SET_URL}
        label={translation.URL}
        onChange={({ target: { value } }) => setUrl(value)}
      />
      <Select
        type="async"
        label={translation.SOURCE}
        node={{
          query: SOURCES,
          variables: {
            where: {},
          },
          accessors: {
            root: "sources.data",
            label: "name",
          },
        }}
        value={source}
        onChange={(value) => setSource(value)}
      />
      <Button type="primary" onClick={handleGenerateIframeCode} disabled={!url}>
        {translation.GENERATE}
      </Button>
      <Input
        type="textarea"
        readOnly
        autoSize={{ minRows: 4, maxRows: 6 }}
        value={iframeSrc}
      />
      <Button
        type="default"
        icon={<CopyOutlined />}
        onClick={() => {
          navigator.clipboard.writeText(iframeSrc);
          message.success(translation.SUCCESS);
        }}
        disabled={!iframeSrc}
      >
        {translation.COPY}
      </Button>
    </div>
  );
}

export default IframeGenerator;
