import classNames from "classnames";
import Checkbox from "./Item";

const SelectPlan = ({ items, checked, onCheck }) => {
  console.log(checked);
  return (
    <div className="select__plan">
      {items.map((item) => (
        <div
          key={item.id}
          className={classNames(`select__plan--item`, {
            "select__plan--selected": checked.includes(item.id),
          })}
          onClick={() => onCheck(item.id)}
        >
          <Checkbox isChecked={checked.includes(item.id)} />
          <div className="content">
            <h3 className="title">{item.label}</h3>
            <p className="description">{item.description}</p>
            <div className="price">
              <h4>{item.price}</h4>
              <span>Par mois, soit {item.monthlyPrice} par an</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectPlan;
