import cuid from "cuid";
import View from "./View";

const Create = ({ addQualifier, setOpenCreate, openCreate }) => {
  const onClose = () => setOpenCreate(false);

  const onSubmit = (data) => {
    const { name, description } = data;
    return addQualifier({
      variables: {
        data: {
          id: cuid(),
          name,
          description,
        },
      },
    });
  };

  return <View onSubmit={onSubmit} onClose={onClose} open={openCreate} />;
};

export default Create;
