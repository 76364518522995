import { Col, Row, Table } from "antd";
import { filter, find, get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineDeleteOutline,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

function List({ data, count, onEdit, setValues, skip }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.OFFER);
  const LIST = t("LIST", { returnObjects: true });

  const columns = [
    {
      title: LIST.INSURANCE_PLAN_NAME,
      render: ({ offerInsurancePlans }) =>
        get(
          find(offerInsurancePlans, { main: true }),
          "insurancePlan.name",
          "---"
        ),
    },
    {
      title: LIST.UPSELLS,
      render: ({ id: offerID, offerInsurancePlans }) => {
        const upsells = filter(offerInsurancePlans, { main: false });
        return (
          <div className="d-flex">
            {upsells.map(({ id, insurancePlan: { name } }) => (
              <div key={`insurancePlan-${offerID}_${id}`}>{name}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <Row gutter={8} justify="end" align="center" wrap={false}>
          <Col onClick={() => onEdit(id)}>
            <MdOutlineModeEditOutline size={24} color={COLORS.C_GRAY} />
          </Col>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.OFFERS.DELETE.path}/${id}`)
            }
          >
            <MdOutlineDeleteOutline size={24} color={COLORS.C_DANGER} />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
