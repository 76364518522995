import cuid from "cuid";
import { get } from "lodash";
import View from "./View";

const Gender = ({ project, onNext, onBack }) => {
  const next = (gender) => {
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          user: {
            id: get(project, "contact.user.id") || cuid(),
            gender,
          },
        },
      },
    });
  };
  return (
    <View
      onNext={next}
      gender={get(project, "contact.user.gender")}
      onBack={onBack}
    />
  );
};

export default Gender;
