import { Drawer, Switch } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck } from "react-icons/bi";
import { MdAdd, MdDelete } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { bindArrayInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onClose, initialValues, onSubmit, translate }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.OFFER);
  const isChecked = (values, index) =>
    get(values, `insurancePlans.${index}.main`, false);

  return (
    <Drawer
      title={translate.HEADER.CLOSE}
      placement="right"
      onClose={onClose}
      open={true}
      contentWrapperStyle={{
        width: "600px",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, values, handleSubmit, setFieldValue, ...formProps }) => [
          <Header
            key={"header"}
            title={translate.HEADER.TITLE}
            actions={[
              {
                text: translate.HEADER.SAVE,
                onClick: handleSubmit,
                disabled: !get(values, "insurancePlans", []).length || !isValid,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />,
          <Card key="insurancePlans">
            <FieldArray
              name={"insurancePlans"}
              render={(arrayHelpers) => [
                get(values, "insurancePlans", []).map((_, index) => (
                  <div className="d-flex flex--wrap" key={_.id}>
                    <div className="d-flex flex--grow-1 m-bottom-16">
                      <div className="flex--grow-1">
                        <Select
                          type="async"
                          node={{
                            query: INSURANCE_PLANS,
                            accessors: {
                              root: "insurancePlans.data",
                              label: "name",
                            },
                          }}
                          placeholder={"FORM.INSURANCE_PLAN"}
                          {...bindArrayInputProps({
                            parent: "insurancePlans",
                            index: index,
                            name: "insurancePlan.id",
                            values,
                            ...formProps,
                          })}
                          filter={({ value }) => {
                            return !get(values, `insurancePlans`, []).some(
                              ({ insurancePlan }, i) => {
                                return (
                                  i !== index &&
                                  insurancePlan?.id &&
                                  insurancePlan.id === value
                                );
                              }
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex">
                        <Switch
                          className="button__switch"
                          checkedChildren={"Main"}
                          checked={isChecked(values, index)}
                          onChange={(e, _) => {
                            get(values, "insurancePlans", []).map((_, i) =>
                              setFieldValue(`insurancePlans.${i}.main`, false)
                            );
                            setFieldValue(`insurancePlans.${index}.main`, e);
                          }}
                        />
                      </div>
                      <div className="d-flex">
                        <MdDelete
                          size={24}
                          color={COLORS.C_DANGER}
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                    </div>
                  </div>
                )),
                <Button
                  type="primary"
                  key="add-row"
                  onClick={() => {
                    arrayHelpers.push({
                      id: cuid(),
                      main: get(values, "insurancePlans", []).length
                        ? false
                        : true,
                    });
                  }}
                >
                  <MdAdd />
                </Button>,
              ]}
            />
          </Card>,
        ]}
      </Formik>
    </Drawer>
  );
};

export default View;
