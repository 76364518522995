import { find, get, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { COLORS } from "shared/style/colors";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const DepartmentsStats = ({ data, title }) => {
  const [departmentsData, setDepartmentsData] = useState();

  useEffect(() => {
    constructChart(data);
  }, [data.length]);

  const constructChart = (chartData) => {
    const departments = new Set();
    chartData.map((contract) => {
      return departments.add(
        get(
          find(get(contract, "project.contact.telecoms", []), {
            system: CONTACT_POINT_SYSTEM.ZIP_CODE,
          }),
          "value",
          ""
        )
          .toString()
          .substring(0, 2)
      );
    });

    const stats = Array.from(departments).map((name) => ({
      name,
      count: chartData.filter(
        ({ project }) =>
          get(
            find(get(project, "contact.telecoms", []), {
              system: CONTACT_POINT_SYSTEM.ZIP_CODE,
            }),
            "value",
            ""
          )
            .toString()
            .substring(0, 2) === name
      ).length,
    }));

    const categories = stats.map(({ name }) => name);
    const dataPoints = stats.map(({ count }) => count);

    setDepartmentsData({
      options: {
        title: {
          text: title,
          align: "left",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: COLORS.C_TEXT,
          },
        },
        chart: {
          id: `basic-bar`,
          background: COLORS.C_WHITE,
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [COLORS.C_WHITE],
          },
          offsetY: -20,
          dropShadow: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: title,
          data: dataPoints,
        },
      ],
    });
  };
  if (isUndefined(departmentsData)) return <Loading />;

  return <View data={departmentsData} />;
};

export default DepartmentsStats;
