import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        birthDate: yup
          .date()
          .typeError(errors.REQUIRED)
          .max(isMajor, errors.AGE_18)
          .required(errors.REQUIRED),
      }),
    }),
  });
};

export default validation;
