import { find, get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  MdCheckCircleOutline,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { CURRENCY_SYMBOL, DATE_FORMAT } from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const Done = ({ subscription }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const SIGN_PROJECT = t("LIST.SIGN_PROJECT", { returnObjects: true });

  const LOCK_ICON = {
    [true]: <MdOutlineLock size={16} color={COLORS.C_GRAY} />,
    [false]: <MdOutlineLockOpen size={16} color={COLORS.C_GRAY} />,
  };
  const { cost, currency } = find(
    get(subscription, "project.generalCosts"),
    (cost) =>
      get(cost, "insurancePlan.id") === get(subscription, "insurancePlan.id")
  );
  return (
    <div className="sign-project-recap__container">
      <div className="sign-project-recap__success">
        <MdCheckCircleOutline size={24} />
        <p>{SIGN_PROJECT.SUCCESS}</p>
      </div>
      <div className="sign-project-recap__card">
        <div className="sign-project-recap__top">
          <div className="sign-project-recap__logo">
            <img
              src={get(subscription, "insurancePlan.icon")}
              alt={get(subscription, "insurancePlan.name", "")}
            />
          </div>
          <div className="sign-project-recap__right">
            <div className="sign-project-recap__right--title">
              {get(subscription, "insurancePlan.name")}
              {LOCK_ICON[get(subscription, "project.locked", true)]}
            </div>
            <span className="sign-project-recap__right--status">
              {SIGN_PROJECT.STATUSES[get(subscription, "status")] ||
                get(subscription, "status")}
            </span>
            <div className="sign-project-recap__right--creation">
              <span>{`${SIGN_PROJECT.CREATED_AT} ${moment(
                get(subscription, "createdDate")
              ).format(DATE_FORMAT)}`}</span>
            </div>
          </div>
        </div>
        <div className="sign-project-recap__price">
          <h4 className="sign-project-recap__price--monthly">
            {costToFrench(cost)} {CURRENCY_SYMBOL[currency]}
            <span>{SIGN_PROJECT.PER_MONTH}</span>
          </h4>
          <span className="sign-project-recap__price--yearly">
            {`${(+cost * 12).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${CURRENCY_SYMBOL[currency]} ${SIGN_PROJECT.PER_YEAR} `}
            <span>{SIGN_PROJECT.ALL_INCLUDED}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Done;
