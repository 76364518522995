import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowForwardIos } from "react-icons/md";
import { images } from "screens/Public/Processes/utils/contants";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import ImageWithLabel from "../components/ImageWithLabel";
import Radio from "../components/Radio";

const View = ({ onNext, gender }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const [selectedGender, setSelectedGender] = useState(gender);

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.GENDER.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.GENDER.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Radio
          name={"gender"}
          value={selectedGender}
          values={[
            {
              label: (
                <ImageWithLabel
                  src={images.male}
                  label={TRANSLATION.GENDER.GENDERS.MALE}
                />
              ),
              value: GENDER.MALE,
            },
            {
              label: (
                <ImageWithLabel
                  src={images.female}
                  label={TRANSLATION.GENDER.GENDERS.FEMALE}
                />
              ),
              value: GENDER.FEMALE,
            },
          ]}
          onChange={setSelectedGender}
        />
      </div>
      <div className="b2c__area--actions justify--end">
        <Button
          className="b2c__area--actions__next"
          onClick={() => onNext(selectedGender)}
          disabled={!selectedGender}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
