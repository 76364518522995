import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { images } from "screens/Public/Processes/utils/contants";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, needs }) => {
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: needs,
    onSubmit: onNext,
  });

  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const needsValues = [
    {
      label: TRANSLATION.NEEDS.LEVELS.MIN,
      value: 2,
    },
    {
      label: TRANSLATION.NEEDS.LEVELS.MEDIUM,
      value: 3,
    },
    {
      label: TRANSLATION.NEEDS.LEVELS.STRONG,
      value: 4,
    },
    {
      label: TRANSLATION.NEEDS.LEVELS.MAX,
      value: 5,
    },
  ];

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.NEEDS.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.NEEDS.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Radio
          label={TRANSLATION.NEEDS.OPTIONS.MEDICAL_EXPENSES.LABEL}
          subLabel={TRANSLATION.NEEDS.OPTIONS.MEDICAL_EXPENSES.SUB_LABEL}
          icon={
            <img
              src={images[COVERAGE_CATEGORIES.MEDICAL_CARE]}
              alt="icon"
              width={24}
              height={16}
            />
          }
          values={needsValues}
          {...bindInputProps({
            name: "medicalExpenses",
            values,
            ...formProps,
          })}
          onChange={(value) => setFieldValue("medicalExpenses", value)}
        />
        <Radio
          label={TRANSLATION.NEEDS.OPTIONS.HOSPITALIZATION.LABEL}
          subLabel={TRANSLATION.NEEDS.OPTIONS.HOSPITALIZATION.SUB_LABEL}
          icon={
            <img
              src={images[COVERAGE_CATEGORIES.HOSPITALIZATION]}
              alt="icon"
              width={24}
              height={16}
            />
          }
          values={needsValues}
          {...bindInputProps({
            name: "hospitalization",
            values,
            ...formProps,
          })}
          onChange={(value) => setFieldValue("hospitalization", value)}
        />
        <Radio
          label={TRANSLATION.NEEDS.OPTIONS.OPTICAL.LABEL}
          subLabel={TRANSLATION.NEEDS.OPTIONS.OPTICAL.SUB_LABEL}
          icon={
            <img
              src={images[COVERAGE_CATEGORIES.OPTICAL]}
              alt="icon"
              width={24}
              height={16}
            />
          }
          values={needsValues}
          {...bindInputProps({
            name: "optical",
            values,
            ...formProps,
          })}
          onChange={(value) => setFieldValue("optical", value)}
        />
        <Radio
          label={TRANSLATION.NEEDS.OPTIONS.DENTAL.LABEL}
          subLabel={TRANSLATION.NEEDS.OPTIONS.DENTAL.SUB_LABEL}
          icon={
            <img
              src={images[COVERAGE_CATEGORIES.DENTAL]}
              alt="icon"
              width={24}
              height={16}
            />
          }
          values={needsValues}
          {...bindInputProps({
            name: "dental",
            values,
            ...formProps,
          })}
          onChange={(value) => setFieldValue("dental", value)}
        />
        <Radio
          label={TRANSLATION.NEEDS.OPTIONS.HEARING_AID.LABEL}
          subLabel={TRANSLATION.NEEDS.OPTIONS.HEARING_AID.SUB_LABEL}
          icon={
            <img
              src={images[COVERAGE_CATEGORIES.EQUIPMENT]}
              alt="icon"
              width={24}
              height={16}
            />
          }
          values={needsValues}
          {...bindInputProps({
            name: "hearingAid",
            values,
            ...formProps,
          })}
          onChange={(value) => setFieldValue("hearingAid", value)}
        />
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button className="b2c__area--actions__next" onClick={handleSubmit}>
          {TRANSLATION.NEXT} <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
