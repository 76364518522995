import { Radio, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { PROCESS_TYPES, TEMPLATE_TYPES, TEMPLATES } from "utils/constants";

const { Text } = Typography;

const GenerateLink = ({
  onGenerateLink,
  selectedOption,
  setSelectedOption,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const GENERATE_LINK = t("LIST.SUBSCRIPTION.GENERATE_SUBSCRIPTION_LINK", {
    returnObjects: true,
  });

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <Text className="modal-text">{GENERATE_LINK.CHOOSE_STEP}</Text>
      <Radio.Group
        className="radio-group"
        onChange={handleOptionChange}
        value={selectedOption}
      >
        <div className="radio-item">
          <Radio
            value={
              TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
                .B2C_SUBSCRIPTION
            }
          >
            <span className="radio-title">
              {GENERATE_LINK.B2C_SUBSCRIPTION.TITLE}
            </span>
          </Radio>
          <Text className="radio-description">
            {GENERATE_LINK.B2C_SUBSCRIPTION.TEXT}
          </Text>
        </div>
        <div className="radio-item">
          <Radio
            value={
              TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
                .PAYMENT
            }
          >
            <span className="radio-title">{GENERATE_LINK.PAYMENT.TITLE}</span>
          </Radio>
          <Text className="radio-description">
            {GENERATE_LINK.PAYMENT.TEXT}
          </Text>
        </div>
        <div className="radio-item">
          <Radio
            value={
              TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
                .JUSTIFICATIONS
            }
          >
            {GENERATE_LINK.JUSTIFICATIONS.TITLE}
          </Radio>
          <Text className="radio-description">
            {GENERATE_LINK.JUSTIFICATIONS.TEXT}
          </Text>
        </div>
      </Radio.Group>
      <Button type="primary" size="full--width" onClick={onGenerateLink}>
        {GENERATE_LINK.SUBMIT}
      </Button>
    </>
  );
};

export default GenerateLink;
