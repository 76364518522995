import { useMutation } from "@apollo/client";
import cuid from "cuid";
import { defaultsDeep, defaultTo, find, get, head, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  ACTION_TYPES,
  BANK_ACCOUNT_OWNER_TYPE,
  PAYMENT_TYPES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATE_TYPES,
  TEMPLATES,
  TRACER_TYPES,
} from "utils/constants";
import { filterByConditions } from "../../../..";
import Loading from "../Components/Spin";
import View from "./View";

const findStatus = (data, template) => {
  return find(data, ({ template: _ }) => _ === template);
};

const Payment = ({ project, onNext, onBack, updateUrl }) => {
  const [initialValues, setInitialValues] = useState({});
  const subscriptionId = get(project, "fields.currentSubscription");
  const navigate = useNavigate();
  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT);
  const [getTracer] = useLazyQuery(TRACER);
  const duplicateProjectStatus = ({ Template, prevTemplate, fields }) => {
    getTracer({
      fetchPolicy: "no-cache",
      variables: {
        where: { type: TRACER_TYPES.COMPARISON },
      },
      onCompleted: ({ tracer }) => {
        const status = findStatus(
          get(tracer, "flow.process.processStatuses", []),
          Template
        );
        const prevStatus = findStatus(
          get(tracer, "flow.process.processStatuses", []),
          prevTemplate
        );
        const actionsToExecute = filterByConditions(
          get(prevStatus || status, "actions", []),
          project
        );
        const syncActions = actionsToExecute
          .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
          .map(({ args: { status } }) => ({
            id: cuid(),
            status: { id: status },
          }));

        duplicateProject({
          variables: {
            where: { id: project.id },
            data: {
              statuses: [
                { id: cuid(), status: { id: status.id } },
                ...syncActions,
              ],
              fields,
            },
          },
          onCompleted: ({ duplicateProject }) => {
            localStorage.setItem("projectId", duplicateProject?.id);
            navigate(
              `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
              {
                state: {
                  process: get(omitDeep(tracer, "__typename"), "flow.process"),
                  tracer: omitDeep(tracer, "__typename"),
                },
              }
            );
          },
        });
      },
    });
  };
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    const initialValues = {
      isPayerReceiver: isEmpty(get(subscription, "payment.receiver.id"))
        ? true
        : get(subscription, "payment.receiver.id") ===
          get(subscription, "payment.payer.id"),
      contact: get(subscription, "project.contact"),
      payment: {
        id: cuid(),
        type:
          head(get(subscription, "insurancePlan.fields.payment.type")) ||
          PAYMENT_TYPES.DEBIT,
        frequency: null,
        debitDay: null,
        ...subscription.payment,
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
        receiver: defaultsDeep(subscription.payment?.receiver, {
          id: cuid(),
          IBAN: null,
          BIC: null,
          owner: { id: cuid() },
        }),
      },
    };
    setInitialValues(initialValues);
  }, []);
  const { data: subscription } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: initSubscription,
  });
  const data = omitDeep(subscription, "__typename");

  const onPersonalInformationEdit = () => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      ...fields
    } = project?.fields;
    duplicateProjectStatus({
      Template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_TELECOMS,
      prevTemplate:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      fields,
    });
  };

  const onSocialRegimeEdit = () => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      ...fields
    } = project?.fields;

    duplicateProjectStatus({
      Template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      prevTemplate:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
          .ADHERENT_INFO,
      fields,
    });
  };

  const onSubmit = ({ payment }) => {
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: {
          payment,
        },
      },
      onCompleted: (_) => {
        const source = localStorage.getItem("source");
        updateUrl({
          subscriptionId,
          url: get(
            data,
            "subscription.insurancePlan.config.generatedUrl",
            `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
          ),
        });
        onNext({
          payload: {},
        });
      },
    });
  };

  const { data: generalCost, loading } = useQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(project, "id") },
          insurancePlan: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
      },
    }
  );

  if (loading || isEmpty(initialValues)) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      subscription={get(data, "subscription")}
      onBack={onBack}
      onPersonalInformationEdit={onPersonalInformationEdit}
      onSocialRegimeEdit={onSocialRegimeEdit}
      generalCost={get(generalCost, "generalCost")}
      initialValues={initialValues}
    />
  );
};

export default Payment;
