import { useTranslation } from "react-i18next";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";

const CoverageBenefitsList = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT);
  const FORM = t("LIST", { returnObjects: true });

  return (
    <Card type="simple">
      <div className="grid--2 ">
        <Input
          type="number"
          label={FORM.ORDER}
          placeholder={FORM.ORDER}
          min={1}
          {...bindInputProps({
            name: "order",
            ...formProps,
          })}
        />
        <Input
          label={FORM.CATEGORY}
          placeholder={FORM.CATEGORY}
          {...bindInputProps({
            name: "type",
            ...formProps,
          })}
        />
        <Input
          label={FORM.SUBTYPE}
          placeholder={FORM.SUBTYPE}
          {...bindInputProps({
            name: "subtype",
            ...formProps,
          })}
        />
        <Input
          type="textarea"
          label={FORM.INFO}
          placeholder={FORM.INFO}
          rows={2}
          {...bindInputProps({
            name: "additionalInfo",
            ...formProps,
          })}
        />
      </div>
    </Card>
  );
};

export default CoverageBenefitsList;
