import { useSubscription as useApolloSubscription } from "@apollo/client";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useContext } from "react";
import Loader from "shared/components/Loader";
import { useQuery } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "../utils/constants";

const Projects = ({ View, filter, fetchPolicy, ...props }) => {
  const { user } = useContext(StoreContext);
  const variables = {
    ...filter,
    skip: parseInt(filter.skip) || INITIAL_VALUES.skip,
    take: parseInt(filter.take) || INITIAL_VALUES.take,
  };
  const query = useQuery(graphql.queries.PROJECTS, {
    fetchPolicy,
    variables,
  });
  const WrappedView = Loader(View);
  useApolloSubscription(graphql.subscriptions.USER_UPDATED, {
    onData: ({ data: { data } }) => {
      const updatedUser = get(data, "userUpdated.id");
      if (updatedUser && updatedUser === user?.id) {
        query.refetch();
      }
    },
  });
  // useSubscription({ query: names.PROJECTS, variables });
  return (
    <WrappedView
      {...{
        ...props,
        ...query,
        type: "projects",
      }}
    />
  );
};

Projects.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Projects.defaultProps = {
  filter: {},
};

export default Projects;
