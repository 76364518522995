import { gql } from "@apollo/client";

export const QUALIFIERS = gql`
  query Qualifiers(
    $where: QualifierWhereInput
    $take: Int
    $skip: Int
    $withDeleted: Boolean
  ) {
    qualifiers(
      where: $where
      take: $take
      skip: $skip
      withDeleted: $withDeleted
    ) {
      data {
        id
        name
        description
        priority
        isDefault
        isBlocking
        hasReminder
        fields
        qualifications {
          id
          createdDate
        }
      }
      count
    }
  }
`;

export const QUALIFIER = gql`
  query Qualifier($where: QualifierWhereInput, $withDeleted: Boolean) {
    qualifier(where: $where, withDeleted: $withDeleted) {
      id
      name
      description
      priority
      isDefault
      isBlocking
      hasReminder
      fields
      qualifications {
        id
        createdDate
      }
    }
  }
`;

const qualifiers = { QUALIFIERS, QUALIFIER };

export default qualifiers;
