import { get } from "lodash";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  CREATE_ATTACHMENT,
  GENERATE_DUTY_OF_ADVICE_DOCUMENT,
  GENERATE_ESIGN_DOCUMENTS,
  GENERATE_MEMBERSHIP_FORM_DOCUMENT,
  GENERATE_SEPA_DIRECT_DEBIT_MANDATE_DOCUMENT,
  GENERATE_TERMINATION_LETTER_DOCUMENT,
  GENERATE_TERMINATION_MANDATE_DOCUMENT,
} from "utils/api/graphql/mutations/attachment";
import {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_SINGLE_DOCUMENT,
} from "utils/api/graphql/mutations/download-document";
import { SEND_SUBSCRIPTION_ATTACHMENTS } from "utils/api/graphql/mutations/subscription";
import { ATTACHMENTS } from "utils/api/graphql/queries/attachments";
import {
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
} from "utils/constants";
import View from "./View";

const Documents = ({
  subscription,
  additionalInfoPercentage,
  setActive,
  updateSubscription,
}) => {
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [generateMembershipFormDocument] = useMutation(
    GENERATE_MEMBERSHIP_FORM_DOCUMENT
  );
  const [generateSepaDirectDebitMandateDocument] = useMutation(
    GENERATE_SEPA_DIRECT_DEBIT_MANDATE_DOCUMENT
  );
  const [generateTerminationMandateDocument] = useMutation(
    GENERATE_TERMINATION_MANDATE_DOCUMENT
  );
  const [generateTerminationLetterDocument] = useMutation(
    GENERATE_TERMINATION_LETTER_DOCUMENT
  );
  const [generateDutyOfAdviceDocument] = useMutation(
    GENERATE_DUTY_OF_ADVICE_DOCUMENT
  );
  const [downloadDocuments] = useMutation(DOWNLOAD_DOCUMENT);
  const [generateEsingDocuments] = useMutation(GENERATE_ESIGN_DOCUMENTS);
  const [sendSubscriptionAttachments] = useMutation(
    SEND_SUBSCRIPTION_ATTACHMENTS,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: get(subscription, "id") } },
        },
      ],
    }
  );
  const filter = {
    where: {
      OR: [
        {
          AND: [
            {
              project: null,
            },
            {
              insurancePlans: {
                id: get(subscription, "insurancePlan.id", ""),
              },
            },
          ],
        },
        {
          subscription: {
            id: get(subscription, "id"),
          },
        },
        {
          AND: [
            {
              project: {
                id: get(subscription, "project.id"),
              },
            },
            {
              insurancePlans: {
                id: get(subscription, "insurancePlan.id", ""),
              },
            },
          ],
        },
      ],
    },
    isIn: {
      type: [
        ...Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES),
        DOCUMENTS_TYPES.QUOTE,
      ],
    },
  };
  const { data, loading } = useQuery(ATTACHMENTS, { variables: filter });

  const [addAttachment] = useMutation(CREATE_ATTACHMENT);

  if (loading) return <Loading />;

  return (
    <View
      data={get(data, "attachments.data")}
      additionalInfoPercentage={additionalInfoPercentage}
      setActive={setActive}
      sendAttachments={sendSubscriptionAttachments}
      addAttachment={addAttachment}
      subscription={subscription}
      generateduty={generateMembershipFormDocument}
      generateDutyOfAdviceDocument={generateDutyOfAdviceDocument}
      generateMembershipFormDocument={generateMembershipFormDocument}
      generateTerminationMandateDocument={generateTerminationMandateDocument}
      generateTerminationLetterDocument={generateTerminationLetterDocument}
      generateSepaDirectDebitMandateDocument={
        generateSepaDirectDebitMandateDocument
      }
      generateEsingDocuments={generateEsingDocuments}
      downloadSingleDocument={downloadSingleDocument}
      updateSubscription={updateSubscription}
      downloadDocuments={downloadDocuments}
    />
  );
};

export default Documents;
