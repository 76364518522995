import cuid from "cuid";
import { get } from "lodash";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import View from "./View";

const ElectronicSignature = ({ project, onNext, onBack }) => {
  const subscriptionId = get(project, "fields.currentSubscription");
  const { data, loading } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });

  if (loading) return <Loading />;
  const signatureLink = get(data, "subscription.signature.signatureLink");

  const next = (user) => {
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          user: {
            id: get(project, "contact.user.id") || cuid(),
            ...user,
          },
        },
      },
    });
  };
  return (
    <View
      onNext={next}
      user={get(project, "contact.user")}
      onBack={onBack}
      signatureLink={signatureLink}
    />
  );
};

export default ElectronicSignature;
