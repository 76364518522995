import format from "string-template";
import {
  CONTACT_POINT_SYSTEM,
  PAYMENT_TYPES,
  REGEX_EXPS,
} from "utils/constants";
import * as yup from "yup";

const validation = (messages, { paymentType }) => {
  const string = yup.string().typeError(messages.NOT_STRING).nullable();
  const requiredString = yup
    .string()
    .typeError(messages.NOT_STRING)
    .required(messages.REQUIRED);
  return yup.object().shape({
    project: yup.object().shape({
      contact: yup.object().shape({
        user: yup.object().shape({
          gender: string,
          lastname: string,
          firstname: string,
        }),
      }),
      fields: yup.object().shape({
        phoneViniNumber: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED)
          .test("isValid", messages.INVALID_VINI_VALUE, function (value) {
            return value
              ? new RegExp(REGEX_EXPS.vini).test(value.replace(/\s+/g, ""))
              : true;
          }),
        imei: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED)
          .nullable()
          .test("isValid", messages.INVALID_IMEI_VALUE, function (value) {
            return value
              ? new RegExp(REGEX_EXPS.imei).test(value.replace(/\s+/g, ""))
              : true;
          }),
        purchaseDate: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),
    }),
    telecoms: yup.object().shape({
      contact: yup.object().shape({
        [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup.string().nullable().email(messages.EMAIL),
        }),
      }),
    }),
    payment: yup.object().shape({
      type: string,
      ...(paymentType === PAYMENT_TYPES.DEBIT && {
        frequency: string,
        debitDay: requiredString,
        payer: yup.object().shape({
          BIC: requiredString,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .required(messages.REQUIRED)
            .max(34, format(messages.MAX_LENGTH, { length: 34 }))
            .matches(
              /^(fr|cz|nl)/i,
              format(messages.INVALID_PREFIX, {
                prefixes: '"FR", "CZ" or "NL"',
              })
            )
            .nullable(),
          owner: yup.object().shape({
            isAdherent: yup.boolean().typeError(messages.NOT_BOOLEAN),
            firstName: string,
            lastName: string,
          }),
        }),
        receiver: yup.object().shape({
          BIC: string,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .max(34, format(messages.MAX_LENGTH, { length: 34 }))
            .matches(
              /^(fr|cz|nl)/i,
              format(messages.INVALID_PREFIX, {
                prefixes: '"FR", "CZ" or "NL"',
              })
            )
            .nullable(),
          owner: yup.object().shape({
            firstName: string,
            lastName: string,
          }),
        }),
      }),
    }),
  });
};

export default validation;
