import { Modal } from "antd";
import PropTypes from "prop-types";

const Component = ({ message, child, ...rest }) => (
  <Modal className="modal__container" {...rest}>
    <div className="modal__container--body">
      {message}
      {child}
    </div>
  </Modal>
);

Component.propTypes = {
  child: PropTypes.node,
  message: PropTypes.string,
};
Component.defaultProps = {};

export default Component;
