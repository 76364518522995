import React from "react";

const Checkbox = ({ isChecked, onChange }) => {
  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={onChange}
      className="custom-checkbox"
    />
  );
};

export default Checkbox;
