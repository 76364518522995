import { Col, Row, Table } from "antd";
import Users from "entities/Users";
import { get } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCircleFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { MdCheck, MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Pagination from "shared/components/Pagination";
import Switch from "shared/components/Switch";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { USERS } from "utils/api/graphql/queries/users";
import { SCREENS, USER_TYPES } from "utils/constants";

const UsersTable = ({ data, acceptSignUp, updateParams }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const [pagination, setPagination] = useState({});

  const DETAIL = t("DETAIL", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });
  const userFilter = {
    where: {
      AND: [
        {
          OR: [
            { organization: { id: get(data, "organization.id") } },
            ...get(data, "organization.contracts", [])?.map(({ subjects }) => ({
              organization: { id: get(subjects, "signedWith", "") },
            })),
          ],
        },
        {
          OR: [
            { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
            { meta: { type: USER_TYPES.BROKER_REPRESENTATIVE.type } },
            { meta: { type: USER_TYPES.INSURANCE_REPRESENTATIVE.type } },
            { meta: { type: USER_TYPES.INTERNAL_ADVISOR.type } },
            { meta: { type: USER_TYPES.INTERNAL_SUPERVISOR.type } },
          ],
        },
      ],
    },
    ...pagination,
  };
  const STATUS = {
    [USER_TYPES.BROKER_REPRESENTATIVE.type]: (
      <div className="d-flex align--center">
        <BsCircleFill color={COLORS.C_FEMALE} />
        <span>{DETAIL.REPRESENTATIVE}</span>
      </div>
    ),
    [USER_TYPES.INSURANCE_REPRESENTATIVE.type]: (
      <div className="d-flex align--center">
        <BsCircleFill color={COLORS.C_FEMALE} />
        <span>{DETAIL.REPRESENTATIVE}</span>
      </div>
    ),
    [USER_TYPES.BROKER_EMPLOYEE.type]: (
      <div className="d-flex align--center">
        <BsCircleFill color={COLORS.C_MALE} />
        <span>{DETAIL.EMPLOYEE}</span>
      </div>
    ),
    [USER_TYPES.INTERNAL_ADVISOR.type]: (
      <div className="d-flex align--center">
        <BsCircleFill color={COLORS.C_FEMALE} />
        <span>{DETAIL.ADVISOR}</span>
      </div>
    ),
    [USER_TYPES.INTERNAL_SUPERVISOR.type]: (
      <div className="d-flex align--center">
        <BsCircleFill color={COLORS.C_MALE} />
        <span>{DETAIL.SUPERVISOR}</span>
      </div>
    ),
  };
  const columns = [
    {
      title: LIST.STATUS,
      dataIndex: "meta",
      render: (record) => STATUS[record?.type] || "-",
    },
    {
      title: LIST.CIVILITY,
      dataIndex: "gender",
      render: (gender) => <span className="c-text">{LIST.GENDER[gender]}</span>,
    },
    {
      title: LIST.FIRST_NAME,
      dataIndex: "firstname",
    },
    {
      title: LIST.LAST_NAME,
      dataIndex: "lastname",
    },
    {
      title: LIST.EMAIL,
      dataIndex: "email",
      render: (email) => <span className="c-text">{email}</span>,
    },
    {
      title: LIST.SEE_NEW_PROJECTS,
      dataIndex: "params",
      render: (params, row) => (
        <Switch
          className="button__switch"
          checkedChildren={LIST.YES}
          unCheckedChildren={LIST.NO}
          defaultChecked={get(params, "seeNewProjects", false)}
          onChange={(e, _) =>
            updateParams({
              userId: row?.id,
              params: { ...row?.params, seeNewProjects: e },
            })
          }
        />
      ),
    },
    {
      title: LIST.ACCEPT,
      dataIndex: "isActive",
      align: "center",
      render: (isActive, row) => (
        <div className="d-flex justify--center">
          {isActive ? (
            <MdCheck color={COLORS.C_GRAY} />
          ) : (
            <Button
              type="primary"
              onClick={() =>
                acceptSignUp({
                  variables: {
                    where: { id: row.id },
                  },
                  refetchQueries: [
                    {
                      query: USERS,
                      awaitRefetchQueries: true,
                      variables: userFilter,
                    },
                  ],
                })
              }
            >
              <MdCheck />
            </Button>
          )}
        </div>
      ),
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <Row gutter={8} justify="end" align="center" wrap={false}>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.USERS.DETAIL.path}/${id}`)
            }
          >
            <FaEdit size={24} color={COLORS.C_PRIMARY} />
          </Col>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.USERS.DEACTIVATE.path}/${id}`)
            }
          >
            <MdOutlineDeleteOutline size={24} color={COLORS.C_DANGER} />
          </Col>
        </Row>
      ),
    },
  ];

  const UsersTable = useCallback(
    ({ data, count }) => {
      return (
        <>
          <Table
            dataSource={data}
            className="table__wrapper"
            columns={columns}
            rowKey={"id"}
            pagination={false}
            scroll={{ x: true }}
          />
          <Pagination
            scrollToTop={false}
            count={count}
            setValues={setPagination}
            skip={pagination.skip || 0}
          />
        </>
      );
    },
    [pagination.skip]
  );

  return (
    <Users
      fetchPolicy="network-only"
      filter={userFilter}
      View={UsersTable}
      NoData={UsersTable}
    />
  );
};

export default UsersTable;
