import gql from "graphql-tag";

export const CREATE_QUALIFICATION = gql`
  mutation AddQualification($data: QualificationDataInput) {
    addQualification(data: $data) {
      id
    }
  }
`;

const qualifications = { CREATE_QUALIFICATION };

export default qualifications;
