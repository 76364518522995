import edit from "shared/assets/icons/generate.svg";

const Pending = ({ translation }) => {
  return (
    <div className="modal-info">
      <img src={edit} alt={"en cours"} />
      <div className="modal-info__text">
        <h4>{translation.IN_PROGRESS}</h4>
        <p>{translation.FEW_SECONDS}</p>
      </div>
    </div>
  );
};

export default Pending;
