import { gql } from "@apollo/client";

export const COVERAGE_CATEGORIES = gql`
  query CoverageCategories(
    $where: CoverageCategoryWhereInput
    $take: Int
    $skip: Int
    $like: CoverageCategoryWhereInput
    $orderBy: [CoverageCategoryWhereInput]
  ) {
    coverageCategories(
      where: $where
      take: $take
      skip: $skip
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        value
        label
        icon
        order
        weight
      }
      count
    }
  }
`;

export const COVERAGE_CATEGORY = gql`
  query CoverageCategory($where: CoverageCategoryWhereInput) {
    coverageCategory(where: $where) {
      id
      value
      label
      icon
      order
      weight
    }
  }
`;

const coverageCategories = { COVERAGE_CATEGORIES, COVERAGE_CATEGORY };

export default coverageCategories;
