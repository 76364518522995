export const includedItems = [
  { value: "Garantie pour les dommages accidentels, l'oxydation ou le vol si les dommages sont visibles extérieurement" },
  { value: "Un seul sinistre pris en charge par année d’assurance" },
  { value: "Le plafond annuel d’indemnisation est fixé à 238 660 XPF" },
];

export const excludedItems = [
  { value: "Faute intentionnelle de l’adhérent" },
  { value: "Défaillance de l’écran tactile, rayures, tâches, écaillures, égratignures, bosses" },
  { value: "Appareils totalement détruits et/ou irréparables" },
];

export const reimbursementItems = [
  {
    subtitle: "En cas de vol :",
    value:
      "Si la garantie est acquise, un appareil de remplacement sera envoyé au domicile de l’adhérent en Polynésie Française sous 24 heures ouvrées, sous réserve du stock disponible. Les frais d’envoi sont à la charge de l’assureur.",
  },
  {
    subtitle: "En cas de bris et d’oxydation :",
    value:
      "L’appareil endommagé doit être envoyé dans un centre de réparation agréé. Si réparable, l’appareil est renvoyé après réparation sous 8 jours ouvrés. Si irréparable, un appareil de remplacement est envoyé sous 3 jours ouvrés. Les frais d’envoi et de remise en état sont à la charge de l’assureur.",
  },
  {
    subtitle:
      "En cas de coût de réparation ou de remplacement supérieur au montant maximum d’indemnisation :",
    value:
      "L’indemnité versée sera égale au montant maximum d’indemnisation, déduction faite de la vétusté (2% par mois à partir du 6ème mois d’assurance).",
  },
];

export const descriptionItems = [
  {
    value: "Le produit assure les smartphones équipés d’au moins une Carte SIM ou e-SIM, achetés neufs ou reconditionnés de grade A."
  },
  {
    value: "Les smartphones doivent avoir été achetés en boutique Vini ou sur le site internet Vini, être âgés de moins de 3 ans au moment de l’adhésion, et être utilisés uniquement à des fins privées."
  },
  {
    value: "Le numéro IMEI de l’appareil doit être fourni dans un délai d’un mois à compter de la date de souscription."
  }
];

export const plans = [
  {
    id: "premium",
    label: "Premium",
    description:
      "Couverture complète incluant réparation ou remplacement en cas de bris, oxydation, ou vol",
    price: "2 510 XPF",
    monthlyPrice: "30 120 XPF",
  },
  {
    id: "essential",
    label: "Essentiel",
    description:
      "Assurance couvrant la réparation ou le remplacement de l'écran en cas de dommages affectant le fonctionnement",
    price: "1 672 XPF",
    monthlyPrice: "20 064 XPF",
  },
];

export const documents = [
  {
    name: 'Devoir de conseil',
    size: '1.4 mb',
    onDownload: () => alert('Downloading Devoir de conseil'),
  },
  {
    name: 'Proposition',
    size: '1.4 mb',
    onDownload: () => alert('Downloading Proposition'),
  },
  {
    name: 'CP',
    size: '1.4 mb',
    onDownload: () => alert('Downloading CP'),
  },
];
