import { Col, Row } from "antd";
import Card from "shared/components/Card";
import BasicBar from "../../Components/BasicBar";

const View = ({ data }) => {
  return (
    <Card type="simple">
      <div className="process_chart">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BasicBar data={data} />
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default View;
