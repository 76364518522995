import Lottie from "lottie-react";
import { useWindowSize } from "shared/hooks/resize";
import animationData from "../../Assets/loader.json";

const Loading = () => {
  const [height] = useWindowSize();
  return (
    <div className="loader__radiance" style={{ height: "100vh" }}>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
