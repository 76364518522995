import { findKey, isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { SIGN_IN, UPDATE_USER } from "utils/api/graphql/mutations/users";
import { SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser, setAccessToken, accessToken, clearStore, user } =
    useContext(StoreContext);

  const [checkUser, { loading }] = useLazyQuery(graphql.queries.CHECK_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ profile }) => onSignIn(profile),
    onError: () => navigate(SCREENS.AUTH.LOGOUT.path, { replace: true }),
  });

  const [signIn] = useMutation(SIGN_IN);
  const [updateUser] = useMutation(UPDATE_USER);

  const onSubmit = ({ username, password }) => {
    signIn({
      variables: {
        data: {
          username,
          password,
        },
      },
      onCompleted: ({ signIn }) =>
        setAccessToken({ accessToken: signIn?.accessToken }),
    });
  };

  useEffect(() => {
    if (accessToken) {
      checkUser();
    }
  }, [accessToken]);

  const onSignIn = (user) => {
    if (isEmpty(user)) return;
    if (!user.isActive) {
      clearStore();
      return navigate(SCREENS.AUTH.NOT_ACTIVE.path);
    }
    if (!user.hasSignedIn) {
      return updateUser({
        variables: { where: { id: user.id }, data: { hasSignedIn: true } },
        onCompleted: () => {
          setUser({ user: { ...user, hasSignedIn: true } });
          navigate(
            USER_TYPES[
              findKey(
                USER_TYPES,
                (element) => element.type === user?.meta?.type
              )
            ]?.index
          );
        },
      });
    }
    setUser({ user });

    navigate(
      USER_TYPES[
        findKey(USER_TYPES, (element) => element.type === user?.meta?.type)
      ]?.index
    );
  };

  const onLinkClick = () => navigate(SCREENS.AUTH.FORGOT_PASSWORD.path);

  if (loading) return <Loading />;
  return <View onSubmit={onSubmit} onLinkClick={onLinkClick} />;
};

export default SignIn;
