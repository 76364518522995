import { get } from "lodash";
import moment from "moment";
import format from "string-template";
import { CONTACT_POINT_SYSTEM, GENDER } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const validateSocialSecurityNumber = function (value) {
    if (!value) return true;
    const prefix = `${
      (get(this.parent, "user.gender") === GENDER.MALE && "[17]") ||
      (get(this.parent, "user.gender") === GENDER.MALE && "[28]") ||
      "[1278]"
    }${moment(get(this.parent, "user.birthDate")).format("YY")}${moment(
      moment(get(this.parent, "user.birthDate"))
    ).format("MM")}`;
    const regexPattern = new RegExp(`^${prefix}(?:[0-9]{8}|[0-9]{10})$`);
    return new RegExp(regexPattern).test(value);
  };
  const string = yup.string().typeError(messages.NOT_STRING).nullable();
  return yup.object().shape({
    project: yup.object().shape({
      contact: yup.object().shape({
        socialSecurityNumber: yup
          .string()
          .typeError(messages.REQUIRED)
          .nullable()
          .optional()
          .test(
            "securityNumberFormat",
            messages.INVALID_VALUE,
            validateSocialSecurityNumber
          ),
      }),
    }),
    telecoms: yup.object().shape({
      contact: yup.object().shape({
        [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: yup.object().shape({
          value: yup
            .number()
            .integer()
            .typeError(messages.NUMBER)
            .max(9999, format(messages.MAX_LENGTH, { length: 4 }))
            .nullable(),
        }),
        [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.STREET]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.REQUIRED)
            .required(messages.REQUIRED),
        }),
        [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
          value: string,
        }),
      }),
    }),
  });
};

export default validation;
