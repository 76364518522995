import Icon from "shared/components/Icon";
import { COLORS } from "shared/style/colors";

const Count = ({ icon, label, value, onClick }) => {
  return (
    <div className="card__count">
      {icon && <span className="card__count--icon">{icon}</span>}
      <div className="row">
        <div className="card__count--content">
          <span className="card__count--label">{label}</span>
          <span className="card__count--value">{value}</span>
        </div>

        <div className="card__count--action" onClick={onClick}>
          <span className="label">Voir tous</span>
          <Icon name="open_in_new" size={20} color={COLORS.C_PRIMARY} />
        </div>
      </div>
    </div>
  );
};

export default Count;
