import subscriptions from "utils/api/graphql/subscriptions";
import { names, SUBSCRIPTION_EVENT_TYPES } from "./constants";

const config = {
  [names.PROFILE]: [
    {
      subscriptionQuery: subscriptions.USER_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.USER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.CONTACTS]: [
    {
      subscriptionQuery: subscriptions.CONTACT_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.CONTACT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.CONTACT_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.SOURCES]: [
    {
      subscriptionQuery: subscriptions.SOURCE_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.SOURCE_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.SOURCE_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.TRACERS]: [
    {
      subscriptionQuery: subscriptions.TRACER_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.TRACER_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.TRACER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.FLOWS]: [
    {
      subscriptionQuery: subscriptions.FLOW_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.FLOW_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.FLOW_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.PROCESSES]: [
    {
      subscriptionQuery: subscriptions.PROCESS_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.PROCESS_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.PROCESS_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.USERS]: [
    {
      subscriptionQuery: subscriptions.USER_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.USER_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.USER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.CONTRACTS]: [
    {
      subscriptionQuery: subscriptions.CONTRACT_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.CONTRACT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.CONTRACT_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.ORGANIZATIONS]: [
    {
      subscriptionQuery: subscriptions.ORGANIZATION_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.ORGANIZATION_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.ORGANIZATION_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.INSURANCE_PLANS]: [
    {
      subscriptionQuery: subscriptions.INSURANCE_PLAN_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.INSURANCE_PLAN_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.INSURANCE_PLAN_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.PROJECTS]: [
    {
      subscriptionQuery: subscriptions.PROJECT_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.PROJECT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.PROJECT_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
    {
      subscriptionQuery: subscriptions.PROJECT_DUPLICATED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
  ],
  [names.PROJECT]: [
    {
      subscriptionQuery: subscriptions.PROJECT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
  ],
  [names.COVERAGES]: [
    {
      subscriptionQuery: subscriptions.COVERAGE_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.COVERAGE_DUPLICATED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.COVERAGE_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.COVERAGE_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.GENERAL_COSTS]: [
    {
      subscriptionQuery: subscriptions.GENERAL_COST_BUILT,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.GENERAL_COST_BUILT,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.GENERAL_COST_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.GENERAL_COST_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
  ],
  [names.QUALIFICATIONS]: [
    {
      subscriptionQuery: subscriptions.QUALIFICATION_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.QUALIFICATION_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.QUALIFICATION_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.NOTES]: [
    {
      subscriptionQuery: subscriptions.NOTE_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.NOTE_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.QUALIFIERS]: [
    {
      subscriptionQuery: subscriptions.QUALIFIER_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.QUALIFIER_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.QUALIFIER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.REMINDERS]: [
    {
      subscriptionQuery: subscriptions.REMINDER_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.REMINDER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.COVERAGE_BENEFITS]: [
    {
      subscriptionQuery: subscriptions.COVERAGE_BENEFIT_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.COVERAGE_BENEFIT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.COVERAGE_BENEFIT_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.ATTACHMENTS]: [
    {
      subscriptionQuery: subscriptions.ATTACHMENT_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.ATTACHMENT_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.ATTACHMENT_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
  [names.SUBSCRIPTION]: [
    {
      subscriptionQuery: subscriptions.SUBSCRIPTION_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
  ],
  [names.BROKER]: [
    {
      subscriptionQuery: subscriptions.BROKER_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.BROKER_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
    {
      subscriptionQuery: subscriptions.BROKER_DELETED,
      type: SUBSCRIPTION_EVENT_TYPES.DELETE,
    },
  ],
};

export default config;
