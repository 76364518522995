import cuid from "cuid";
import { get } from "lodash";
import View from "./View";

const AdherentSocialRegime = ({ project, onNext, onBack }) => {
  const next = (socialRegime) => {
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          socialRegime,
        },
      },
    });
  };

  return (
    <View
      onNext={next}
      socialRegime={get(project, "contact.socialRegime")}
      onBack={onBack}
    />
  );
};

export default AdherentSocialRegime;
