import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import deactivate from "shared/assets/images/deactivate.png";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { SCREENS } from "utils/constants";

const NotActive = () => {
  const navigate = useNavigate();
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const NOT_ACTIVE = t("NOT_ACTIVE", {
    returnObjects: true,
  });
  useEffect(() => {
    if (user && user.isActive) return navigate(SCREENS.AUTH.REDIRECT.path);
  }, []);

  return (
    <div className="auth">
      <img src={deactivate} alt="deactivate" />
      <h3 className="auth__deactivate--title">{NOT_ACTIVE.TITLE}</h3>
      <p className="auth__deactivate--text">{NOT_ACTIVE.TEXT}</p>
      <Link className="auth__deactivate--link" to={SCREENS.AUTH.SIGN_IN.path}>
        {NOT_ACTIVE.LINK}
      </Link>
    </div>
  );
};

export default NotActive;
