import ActivityItem from "./item";

const Activities = ({ activities }) => {
  return (
    <div className="activities__log">
      {activities.map((activity) => (
        <ActivityItem key={activity.date} activity={activity} />
      ))}
    </div>
  );
};

export default Activities;
