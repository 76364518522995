import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle, BsUpload } from "react-icons/bs";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import Stepper from "../Components/Stepper";
import validation from "./validation";

const View = ({
  initialValues,
  loadingButton,
  onSubmit,
  onBack,
  translate,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        }),
        {
          isRetired: get(initialValues, "isRetired"),
        }
      ),
    });

  const onFileRemove = async ({ uid: id }) => {
    if (id.indexOf("AUTO") !== -1) return;
  };

  return (
    <Container size="medium">
      <div className="b2c__area">
        <Stepper
          items={[
            { label: translate.STTEPER.SITUATION, completed: true },
            { label: translate.STTEPER.QUOTE, completed: true },
            { label: translate.STTEPER.SUBSCRIPTION, completed: true },
            { label: translate.STTEPER.VALIDATION, completed: false },
          ]}
        />
        <div className="b2c__area--header">
          <h2 className="b2c__area--header__title">
            {translate.ONE_MORE_STEP}
          </h2>
        </div>

        <div className="justification__section">
          <div className="justification__section--child">
            <span className="justification__section--child__title">
              {translate.DOCUMENTS.TYPES.RIB}
            </span>
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              onUpload={(fileList) => {
                setFieldValue("paymentAccount", fileList);
              }}
              validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
              onFileRemove={onFileRemove}
              fileList={get(values, "paymentAccount", [])}
            >
              {isEmpty(get(values, "paymentAccount")) && (
                <div className="upload">
                  <BsUpload size={20} />
                  <span className="label">{translate.DOCUMENTS.DRAG}</span>
                  <Button type="primary--link">
                    {translate.DOCUMENTS.CHOOSE}
                  </Button>
                </div>
              )}
            </Input>
            <span className="justification__section--child__caption">
              <span>{translate.DOCUMENTS.ACCEPT_FILES}</span>
              <span>{translate.DOCUMENTS.MAX_SIZE}</span>
            </span>
            <span className="error-message">
              {get(formProps.errors, "paymentAccount")}
            </span>
          </div>
          <div className="justification__section--child">
            <span className="justification__section--child__title">
              {translate.DOCUMENTS.TYPES.CIN}
            </span>
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
              onUpload={(fileList) => {
                setFieldValue("cin", fileList);
              }}
              onFileRemove={onFileRemove}
              fileList={get(values, "cin", [])}
            >
              {get(values, "cin", []).length < 2 && (
                <div className="upload">
                  <BsUpload size={20} />
                  <span className="label">{translate.DOCUMENTS.DRAG_MANY}</span>
                  <Button type="primary--link">
                    {translate.DOCUMENTS.CHOOSE}
                  </Button>
                </div>
              )}
            </Input>
            <span className="justification__section--child__caption">
              <span>{translate.DOCUMENTS.ALLOWED_FILES}</span>
              <span>{translate.DOCUMENTS.MAX_SIZE}</span>
            </span>
            <span className="error-message">
              {get(formProps.errors, "cin")}
            </span>
          </div>
          {get(values, "isRetired") && (
            <div className="justification__section--child">
              <span className="justification__section--child__title">
                {translate.DOCUMENTS.TYPES.TERRITORIAL_DOCUMENT}
              </span>

              <Input
                type="file"
                variant="uploader"
                listType="picture"
                onUpload={(fileList) => {
                  setFieldValue("pensionFund", fileList);
                }}
                validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
                onFileRemove={onFileRemove}
                fileList={get(values, "pensionFund", [])}
              >
                {isEmpty(get(values, "pensionFund")) && (
                  <div className="upload">
                    <BsUpload size={20} />
                    <span className="label">{translate.DOCUMENTS.DRAG}</span>
                    <Button type="primary--link">
                      {translate.DOCUMENTS.CHOOSE}
                    </Button>
                  </div>
                )}
              </Input>
              <span className="justification__section--child__caption">
                <span>{translate.DOCUMENTS.ACCEPT_FILES}</span>
                <span>{translate.DOCUMENTS.MAX_SIZE}</span>
              </span>
              <span className="error-message">
                {get(formProps.errors, "paymentAccount")}
              </span>
            </div>
          )}
        </div>

        <div className="b2c__area--actions">
          <Button onClick={onBack} className="prev">
            <BsArrowLeftCircle size={32} />
            <span>{translate.PREVIOUS}</span>
          </Button>
          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            loading={loadingButton}
            disabled={!isEmpty(formProps.errors) || loadingButton}
          >
            {translate.SIGN_CONTRACT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
