import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdLogin } from "react-icons/md";
import logo from "shared/assets/images/logo.svg";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onSubmit, onLinkClick, resendCode }) => {
  const [timer, setTimer] = useState(90);
  const { t: auth } = useTranslation(NAME_SPACES.AUTH);
  const { t: errors } = useTranslation(NAME_SPACES.ERRORS);

  useEffect(() => {
    if (timer > 0) {
      const timer = setTimeout(() => {
        setTimer((prev) => (prev -= 1));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timer]);

  const SIGN_IN = auth("SIGN_IN", {
    returnObjects: true,
  });
  const VALIDATIONS = errors("VALIDATIONS", {
    returnObjects: true,
  });
  return (
    <div className="auth">
      <img alt="logo" src={logo} />
      <Formik
        initialValues={{ code: "" }}
        onSubmit={onSubmit}
        validationSchema={validation(VALIDATIONS)}
        validateOnBlur
        validateOnChange={false}
      >
        {({ isValid, handleSubmit, ...formProps }) => (
          <div className="auth__body">
            <Input
              type="number"
              label={SIGN_IN.TWO_FACTOR_CODE}
              placeholder={SIGN_IN.TWO_FACTOR_CODE}
              {...bindInputProps({
                name: "code",
                ...formProps,
              })}
            />
            <Button type="primary" onClick={handleSubmit}>
              {SIGN_IN.SUBMIT}
              <MdLogin size={16} />
            </Button>
          </div>
        )}
      </Formik>
      {timer > 0 ? (
        <Button
          type="secondary--link"
          className="auth__link"
          disabled={timer > 0}
        >
          {timer}s {SIGN_IN.TIME_LEFT}
        </Button>
      ) : (
        <Button
          type="primary--link"
          className="auth__link"
          onClick={() => {
            setTimer(300);
            resendCode();
          }}
        >
          {SIGN_IN.RESEND_CODE}
        </Button>
      )}
      <span className="auth__link" onClick={onLinkClick}>
        {SIGN_IN.SUBMIT}
      </span>
    </div>
  );
};

export default View;
