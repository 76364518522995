import { Popover, Select } from "antd";
import classNames from "classnames";
import Label from "shared/components/Label";

const { Option } = Select;

const Tag = ({
  label,
  errors,
  icon,
  touched,
  required,
  direction,
  optional,
  optionFilterProp = "label",
  options,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        "select__wrapper select__tag",
        { __error: touched && errors },
        direction
      )}
    >
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`select__wrapper select__tag--wrapper`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          optionFilterProp={optionFilterProp}
          {...rest}
        >
          {options.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              <div
                className="item"
                onClick={() => {
                  if (!option.disabled && option.onClick) {
                    option.onClick();
                  }
                }}
              >
                <Popover
                  overlayInnerStyle={{
                    padding: "4px 12px",
                  }}
                  autoAdjustOverflow
                  placement="right"
                  content={option.popover}
                  arrow
                >
                  <div className="tag">{option.label}</div>
                </Popover>
              </div>
            </Option>
          ))}
        </Select>
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Tag;
