import gql from "graphql-tag";

export const CREATE_OFFER = gql`
  mutation AddOffer($data: OfferDataInput) {
    addOffer(data: $data) {
      id
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($data: OfferDataInput, $where: OfferWhereInput) {
    updateOffer(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($where: OfferWhereInput) {
    deleteOffer(where: $where) {
      id
    }
  }
`;

const offers = {
  CREATE_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
};

export default offers;
