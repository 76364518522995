import cuid from "cuid";
import { defaults, get, map } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const Create = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateUserAuth] = useMutation(graphql.mutations.UPDATE_USER_AUTH);
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER, {
    refetchQueries: [
      {
        query: graphql.queries.USERS,
        awaitRefetchQueries: true,
        variables: {
          where: {
            OR: [
              { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
              { meta: { type: USER_TYPES.BROKER_REPRESENTATIVE.type } },
              { meta: { type: USER_TYPES.INSURANCE_REPRESENTATIVE.type } },
              { meta: { type: USER_TYPES.INTERNAL_ADVISOR.type } },
            ],
          },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: () => navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.LIST.path),
  });
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.LIST.path);

  const { data, loading, error } = useQuery(graphql.queries.USER, {
    variables: { where: { id } },
  });

  const onSubmit = (submittedData) => {
    const {
      gender,
      email,
      orias,
      socialReason,
      phone,
      code,
      rcsNumber,
      telecoms,
      meta,
      ...values
    } = submittedData;

    const organizationTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );

    updateUserAuth({
      variables: {
        where: {
          id,
        },
        data: {
          phone,
        },
      },
      onCompleted: ({ updateUser: { id } }) => {
        updateUser({
          variables: {
            where: { id },
            data: {
              phone,
              meta,
              ...values,
              organization: {
                id: get(data, "user.organization.id"),
                broker: {
                  id: get(data, "user.organization.broker.id"),
                  socialReason,
                  code,
                  rcsNumber,
                },
                telecoms:
                  meta.type === USER_TYPES.INSURANCE_REPRESENTATIVE.type
                    ? organizationTelecoms
                    : undefined,
              },
            },
          },
        });
      },
    });
  };

  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      cancel={cancel}
      data={omitDeep(data.user, "__typename")}
    />
  );
};

export default Create;
