import { gql } from "@apollo/client";

export const NOTES_ = gql`
  query Notes(
    $where: NoteWhereInput
    $take: Int
    $skip: Int
    $gt: NoteWhereInput
    $lt: NoteWhereInput
  ) {
    notes(where: $where, take: $take, skip: $skip, gt: $gt, lt: $lt) {
      data {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
      }
      count
    }
  }
`;

export const NOTES = gql`
  query Notes($where: NoteWhereInput, $take: Int, $skip: Int) {
    notes(where: $where, take: $take, skip: $skip) {
      data {
        id
        content
        createdDate
        updatedDate
        author {
          id
          firstname
          lastname
        }
      }
      count
    }
  }
`;

export const NOTE = gql`
  query Note($where: NoteWhereInput) {
    note(where: $where) {
      id
      content
      createdDate
      author {
        id
        firstname
        lastname
      }
      qualification {
        id
        qualifier {
          id
          name
          fields
        }
      }
    }
  }
`;

const notes = { NOTES, NOTE };

export default notes;
