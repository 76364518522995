import { Col, Row } from "antd";
import { useFormik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
    MdOutlineBadge,
    MdOutlineEditCalendar,
    MdOutlineQrCode,
    MdOutlineReplay,
} from "react-icons/md";
import { STEPPER_KEYS } from "screens/Private/Broker/Subscription/Detail";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { DEBIT_POSSIBLE_DATES, PAYMENT_FREQUENCIES } from "utils/constants";
import { bindInputProps, setDefaultIfEmpty } from "utils/helpers/input";
import validation from "./validation";

const Payment = ({
  initialValues,
  translate,
  isFieldRequired,
  onPaymentSave,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues: initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION],
      onSubmit: onPaymentSave,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });
  return (
    <>
      <span className="collaped_container--child_title">
        {translate.PAYMENT_INFORMATIONS}
      </span>
      <div className="collaped_container--child_content">
        <div className="collaped_block">
          <span className="collaped_block--title">
            {translate.PAYER.TITLE}
          </span>
          <div className="collaped_block--content">
            <Row gutter={[16, 16]}>
              <Col lg={24} xs={24}>
                <Input
                  label={translate.PAYER.FIRST_NAME.LABEL}
                  direction={"row"}
                  icon={<MdOutlineBadge size={16} />}
                  placeholder={
                    translate.PAYER.FIRST_NAME.PLACEHOLDER
                  }
                  {...bindInputProps({
                    name: "payment.payer.owner.lastName",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.payer.owner.lastName")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  label={translate.PAYER.LAST_NAME.LABEL}
                  direction={"row"}
                  icon={<MdOutlineBadge size={16} />}
                  placeholder={
                    translate.PAYER.LAST_NAME.PLACEHOLDER
                  }
                  {...bindInputProps({
                    name: "payment.payer.owner.firstName",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.payer.owner.firstName")}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="collaped_block">
          <span className="collaped_block--title">
            {translate.BANK_ACCOUNT}
          </span>
          <div className="collaped_block--content">
            <Row gutter={[16, 16]}>
              <Col lg={24} xs={24}>
                <Input
                  label={translate.IBAN.LABEL}
                  icon={<MdOutlineQrCode size={16} />}
                  direction={"row"}
                  placeholder={translate.IBAN.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.payer.IBAN",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.payer.IBAN")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  label={translate.BIC.LABEL}
                  icon={<MdOutlineQrCode size={16} />}
                  direction={"row"}
                  placeholder={translate.BIC.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.payer.BIC",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.payer.BIC")}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="collaped_block">
          <span className="collaped_block--title">{translate.PERIODICITY}</span>
          <div className="collaped_block--content">
            <Row gutter={[16, 16]}>
              <Col lg={24} xs={24}>
                <Select
                  direction="row"
                  options={setDefaultIfEmpty(
                    get(values, "insurancePlan.fields.payment.debitDay"),
                    DEBIT_POSSIBLE_DATES
                  ).map((value) => ({
                    value,
                    label: value,
                  }))}
                  placeholder={translate.DEBIT_DATE}
                  label={translate.DEBIT_DATE}
                  icon={<MdOutlineEditCalendar size={16} />}
                  {...bindInputProps({
                    name: "payment.debitDay",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.debitDay")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  type="radio"
                  variant="group"
                  icon={<MdOutlineReplay size={16} />}
                  label={translate.PAYMENT_FREQUENCY.LABEL}
                  values={setDefaultIfEmpty(
                    get(values, "insurancePlan.fields.payment.frequency"),
                    Object.values(PAYMENT_FREQUENCIES)
                  ).map((value) => ({
                    value,
                    label: translate.PAYMENT_FREQUENCY.OPTIONS[value],
                  }))}
                  {...bindInputProps({
                    name: "payment.frequency",
                    values,
                    ...formProps,
                  })}
                  required={isFieldRequired("payment.frequency")}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="collaped_container--actions">
        <Button
          className="collaped_container--actions__next"
          onClick={handleSubmit}
        >
          {translate.NEXT}
        </Button>
      </div>
    </>
  );
};

export default Payment;
