import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdReportGmailerrorred } from "react-icons/md";
import Alert from "shared/components/Alert";
import { NAME_SPACES } from "shared/locales/constants";
import { CURRENCY_SYMBOL } from "utils/constants";
import ProductCard from "../ProductCard";

const Selected = ({
  data,
  actions: { onRemove = () => {} },
  showCommission,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });

  return (
    <div className="card-skeleton--container">
      <div className="card-skeleton--wrapper">
        <h3 className="title--md m-bottom-24">
          {DETAIL.TEMPLATES.PRODUCTS.COMPARE.TITLE}
        </h3>
        <Alert
          type="warning"
          showIcon
          message={DETAIL.TEMPLATES.PRODUCTS.WARNING}
          icon={<MdReportGmailerrorred size={16} />}
        />
        {!data?.length && (
          <div className="notice m-top-8">
            {DETAIL.TEMPLATES.PRODUCTS.NOTICE}
          </div>
        )}
        {data.map((cost, index) => {
          const [integer, decimal] = get(cost, "cost", "-")
            ?.toString()
            ?.split(".") || ["-"];
          const currency = CURRENCY_SYMBOL[get(cost, "currency") || "DEFAULT"];
          return (
            <div key={"product-" + index} className="product--selected">
              <ProductCard
                image={cost.insurancePlan.icon}
                name={cost.insurancePlan.name}
                description={cost.insurancePlan.description}
                costPerMonth={
                  <>
                    {`${integer} ${currency} `}
                    <sup>{decimal?.slice(0, 2)}</sup>
                  </>
                }
                costAll={(+get(cost, "cost") * 12).toFixed(2)}
                state="added"
                onClick={() => onRemove(cost)}
                translation={DETAIL.TEMPLATES.PRODUCTS.CARD}
                showCommission={showCommission}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Selected;
