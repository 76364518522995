import { gql } from "@apollo/client";

const PROCESS_ADDED = gql`
  subscription processAdded($where: ProcessWhereInput) {
    processAdded(where: $where) {
      id
      name
      processStatuses {
        id
        name
        tag
        template
        progress
        order
        moveToNext
        possibleStatuses {
          id
          nextStatus
          order
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
      }
    }
  }
`;

const PROCESS_UPDATED = gql`
  subscription processUpdated($where: ProcessWhereInput) {
    processUpdated(where: $where) {
      id
      name
      processStatuses {
        id
        name
        tag
        template
        progress
        order
        moveToNext
        possibleStatuses {
          id
          nextStatus
          order
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
      }
    }
  }
`;

const PROCESS_DELETED = gql`
  subscription processDeleted($where: ProcessWhereInput) {
    processDeleted(where: $where) {
      id
      name
      processStatuses {
        id
        name
        tag
        template
        progress
        order
        moveToNext
        possibleStatuses {
          id
          nextStatus
          order
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
      }
    }
  }
`;

const processes = { PROCESS_ADDED, PROCESS_UPDATED, PROCESS_DELETED };

export default processes;
