import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  COVERAGE_CATEGORIES,
  COVERAGE_CATEGORY,
} from "utils/api/graphql/queries/coverage-categories";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path);
  const { data, loading, error } = useQuery(COVERAGE_CATEGORY, {
    variables: { where: { id } },
  });
  const [updateCoverageCategory] = useMutation(
    graphql.mutations.UPDATE_COVERAGE_CATEGORY,
    {
      refetchQueries: [
        {
          query: COVERAGE_CATEGORIES,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path);
      },
    }
  );
  const onSubmit = ({ iconAttachment, ...values }) => {
    return updateCoverageCategory({
      variables: {
        where: { id },
        data: {
          ...values,
          icon: iconAttachment,
        },
      },
    });
  };

  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      cancel={cancel}
      data={omitDeep(data.coverageCategory, "__typename")}
    />
  );
};

export default Create;
