import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const View = ({ onNext, onBack, user, signatureLink }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: user,
    onSubmit: onNext,
  });
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js";
    script.integrity =
      "sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr";
    script.crossOrigin = "anonymous";
    script.onload = () => {
      const yousign = new window.Yousign({
        signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: true,
      });
      yousign.onSuccess((data) => {
        console.log(data);
        console.log("Signer has successfully signed");
      });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.ELECTRONIC_SIGNATURE.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.ELECTRONIC_SIGNATURE.SUBTITLE}
        </div>
      </div>
      <div id="iframe-container"></div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!isEmpty(formProps.errors)}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
