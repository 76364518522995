import { gql } from "@apollo/client";

export const INSURANCE_PLAN_COVERAGES = gql`
  query InsurancePlanCoverages(
    $where: InsurancePlanCoverageWhereInput
    $take: Int
    $skip: Int
    $like: InsurancePlanCoverageWhereInput
    $orderBy: [InsurancePlanCoverageWhereInput]
  ) {
    insurancePlanCoverages(
      where: $where
      take: $take
      skip: $skip
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        coverageCategory {
          id
        }
        needs {
          id
          level
        }
        coverageMappings {
          id
          waitingDays
          coverage {
            id
          }
          limits {
            id
            value
          }
        }
      }
      count
    }
  }
`;

export const INSURANCE_PLAN_COVERAGE = gql`
  query InsurancePlanCoverage($where: InsurancePlanCoverageWhereInput) {
    insurancePlanCoverage(where: $where) {
      id
    }
  }
`;

const insurancePlanCoverages = {
  INSURANCE_PLAN_COVERAGES,
  INSURANCE_PLAN_COVERAGE,
};

export default insurancePlanCoverages;
