import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineCake, MdOutlineFreeCancellation } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { ANSET_SUBSCRIPTION_FORM_KEYS, DATE_FORMAT } from "utils/constants";

const Terminal = ({
  formProps,
  values,
  setPercent,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINAL}-section`}
    >
      <Form
        type="vertical"
        title={"Informations sur le terminal"}
        subtitle={"Les informations concernant le terminal"}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={"0612345678"}
              label={"Numéro de téléphone VINI"}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(
                initialValues,
                "project.fields.phoneViniNumber"
              )}
              errors={errors["project.fields.phoneViniNumber"]}
              touched={!isEmpty(errors["project.fields.phoneViniNumber"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.phoneViniNumber", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.phoneViniNumber")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={"IMEI"}
              label={"IMEI"}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "project.fields.imei")}
              errors={errors["project.fields.imei"]}
              touched={!isEmpty(errors["project.fields.imei"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.imei", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.imei")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={"Date d’achat"}
              icon={<MdOutlineCake size={16} />}
              placeholder={"Date d’achat"}
              type="date"
              variant="simple"
              errors={errors["project.fields.purchaseDate"]}
              defaultValue={dayjs()}
              onChange={(_, date) => {
                set(initialValues, "project.fields.purchaseDate", date);
              }}
              touched={!isEmpty(errors["project.fields.purchaseDate"])}
              onBlur={() => setPercent(initialValues)}
              format={DATE_FORMAT}
              required={isFieldRequired("project.fields.purchaseDate")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Terminal;
