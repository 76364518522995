import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import { bindDateInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onNext, onBack, spouse }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: spouse,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.SPOUSE_BIRTH_DATE.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.SPOUSE_BIRTH_DATE.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Input
          label={"Date de naissance"}
          placeholder={"Date de naissance"}
          type="date"
          variant="simple"
          format={DATE_FORMAT}
          disabledDate={(current) => current > isMajor}
          defaultPickerValue={
            !get(values, "identity.user.birthDate") &&
            dayjs().subtract(30, "years")
          }
          {...bindDateInputProps({
            name: "identity.user.birthDate",
            values,
            ...formProps,
          })}
        />
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!isEmpty(formProps.errors)}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
