import { USER_TYPES } from "utils/constants";
import Default from "./UserTypes/Default";
import Supervisor from "./UserTypes/Supervisor";

const View = ({ onSubmit, cancel, userType, visible, poleId, userName }) => {
  const generateUserForm = () => ({
    [true]: (
      <Supervisor
        poleId={poleId}
        onCancel={cancel}
        onSubmit={onSubmit}
        userName={userName}
      />
    ),
    [false]: <Default onCancel={cancel} onOk={onSubmit} visible={visible} />,
  });

  return generateUserForm()[userType === USER_TYPES.INTERNAL_SUPERVISOR.type];
};

export default View;
