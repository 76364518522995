import { notification } from "antd";
import { findKey, isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import {
  SEND_VERIFICATION_CODE,
  SIGN_IN_WITH_TWO_FACTOR_AUTH,
  UPDATE_USER,
} from "utils/api/graphql/mutations/users";
import { SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const Verification = () => {
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const ERRORS = t("ERRORS", {
    returnObjects: true,
  });
  const MESSAGES = t("MESSAGES", {
    returnObjects: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { id, email, password } = location.state;
  const { setUser, setAccessToken, accessToken, clearStore } =
    useContext(StoreContext);
  const [sendVerificationCode] = useMutation(SEND_VERIFICATION_CODE);
  const [checkUser, { loading }] = useLazyQuery(graphql.queries.CHECK_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ profile }) => onSignIn(profile),
  });

  const [updateUser] = useMutation(UPDATE_USER);
  const [signIn] = useMutation(SIGN_IN_WITH_TWO_FACTOR_AUTH);

  const onSubmit = ({ code }) => {
    signIn({
      variables: {
        data: {
          id,
          email,
          code,
        },
      },
      onCompleted: ({ signInWithTwoFactorAuth }) =>
        setAccessToken({ accessToken: signInWithTwoFactorAuth?.accessToken }),
      onError: () => notification.error({ message: ERRORS.VERIFICATION_CODE }),
    });
  };

  useEffect(() => {
    if (accessToken) checkUser();
  }, [checkUser, accessToken]);

  const onSignIn = (user) => {
    if (isEmpty(user)) return;
    if (!user.isActive) {
      clearStore();
      return navigate(SCREENS.AUTH.NOT_ACTIVE.path);
    }
    if (!user.hasSignedIn) {
      return updateUser({
        variables: { where: { id: user.id }, data: { hasSignedIn: true } },
        onCompleted: () => {
          setUser({ user: { ...user, hasSignedIn: true } });
          navigate(
            USER_TYPES[
              findKey(
                USER_TYPES,
                (element) => element.type === user?.meta?.type
              )
            ]?.index
          );
        },
      });
    }
    setUser({ user });
    navigate(
      USER_TYPES[
        findKey(USER_TYPES, (element) => element.type === user?.meta?.type)
      ].index
    );
  };

  const onLinkClick = () => navigate(SCREENS.AUTH.SIGN_IN.path);

  const resendCode = () => {
    sendVerificationCode({
      variables: {
        data: { email, password },
      },
      onCompleted: (_) => {
        notification.open({
          message: MESSAGES.RESEND_CODE_SUCCESS,
          type: "success",
          duration: 5,
        });
      },
      onError: () => notification.error({ message: ERRORS.EMAIL_PASSWORD }),
    });
  };

  if (loading) return <Loading />;
  return (
    <View
      onSubmit={onSubmit}
      onLinkClick={onLinkClick}
      resendCode={resendCode}
    />
  );
};

export default Verification;
