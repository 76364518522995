import gql from "graphql-tag";

export const CREATE_DISTRIBUTION = gql`
  mutation AddDistribution($data: DistributionDataInput) {
    addDistribution(data: $data) {
      id
    }
  }
`;

export const UPDATE_DISTRIBUTION = gql`
  mutation UpdateDistribution(
    $data: DistributionDataInput
    $where: DistributionWhereInput
  ) {
    updateDistribution(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_DISTRIBUTION = gql`
  mutation DeleteDistribution($where: DistributionWhereInput) {
    deleteDistribution(where: $where) {
      id
    }
  }
`;

const distributions = {
  CREATE_DISTRIBUTION,
  UPDATE_DISTRIBUTION,
  DELETE_DISTRIBUTION,
};

export default distributions;
