import { useState } from "react";

import { Radio } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import Label from "shared/components/Label";
import Popover from "shared/components/Popover";

const Component = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  size,
  subLabel,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = (e) => {
    setCheckedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="b2c-radio">
      <Label
        label={label}
        subLabel={subLabel}
        icon={icon}
        required={required}
      />
      <Radio.Group
        onChange={onSelect}
        value={checkedValue}
        defaultValue={value}
        optionType="button"
        className={classNames({ [`grid--${size}`]: !!size, "d-flex": !size })}
        {...rest}
      >
        {values.map(({ label, value: _value, popover }) => (
          <Radio
            value={_value}
            className={`custom--item  ${_value === checkedValue && "checked"}`}
            key={`radio-${_value}`}
          >
            {popover && <Popover text={popover} />}
            <div className={"custom--label"}>
              <div>{label}</div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

Component.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
  size: PropTypes.number,
};

Component.defaultProps = {
  onChange: () => {},
  values: [],
};
export default Component;
