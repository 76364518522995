import { get } from "lodash";
import { images } from "screens/Public/Processes/utils/contants";
import Button from "shared/components/Button";
import {
  COVERAGE_CATEGORIES,
  COVERAGE_NEEDS,
  CURRENCY_SYMBOL,
} from "utils/constants";
import Levels from "./Levels";

const ProductCard = ({
  icon,
  name,
  description,
  cost,
  currency,
  needs,
  onClick,
  onSubscribe,
  translate,
}) => {
  const [integer, decimal = "00"] = cost.toString().split(".");
  const levelsSize = 4;
  const convertNeed = (need) => {
    const needsMapping = {
      5: 4,
      4: 3,
      3: 2,
      default: 1,
    };
    return needsMapping[get(needs, need)] || needsMapping.default;
  };
  return (
    <div className="product-card">
      <div className="product-card__title">
        <img
          className="product-card--image"
          alt="logo"
          src={icon}
          loading="lazy"
        />
        <div className="product-card--detail">
          <div className="product-card--title">
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>

      <div className="product-card__needs">
        <Levels
          size={levelsSize}
          filled={convertNeed(COVERAGE_NEEDS.MEDICAL_CARE)}
          icon={images[COVERAGE_CATEGORIES.MEDICAL_CARE]}
        />
        <Levels
          size={levelsSize}
          filled={convertNeed(COVERAGE_NEEDS.HOSPITALIZATION)}
          icon={images[COVERAGE_CATEGORIES.HOSPITALIZATION]}
        />
        <Levels
          size={levelsSize}
          filled={convertNeed(COVERAGE_NEEDS.OPTICAL)}
          icon={images[COVERAGE_CATEGORIES.OPTICAL]}
        />
        <Levels
          size={levelsSize}
          filled={convertNeed(COVERAGE_NEEDS.DENTAL)}
          icon={images[COVERAGE_CATEGORIES.DENTAL]}
        />
        <Levels
          size={levelsSize}
          filled={convertNeed(COVERAGE_NEEDS.EQUIPMENT)}
          icon={images[COVERAGE_CATEGORIES.EQUIPMENT]}
        />
      </div>

      <div className="product-card--price">
        <div className="product-card--price_container">
          <h3>
            <span>
              {`${integer} ${CURRENCY_SYMBOL[currency]} `}
              <sup>{decimal}</sup>
            </span>
            /mois
          </h3>
          <p>
            <span>
              {`${(cost * 12)?.toFixed(2)} ${
                CURRENCY_SYMBOL[currency]
              } / an tous frais inclus`}
            </span>
          </p>
        </div>
      </div>
      <div className="detailcontainer">
        <div className="actions">
          <Button className="b2c__area--actions__next" onClick={onClick}>
            {translate.REQUEST_QUOTE}
          </Button>
          <Button
            className="b2c__area--actions__subscribe"
            onClick={onSubscribe}
          >
            {translate.SUBSCRIBE_TO_PRODUCT}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
