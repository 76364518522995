import { get, intersection, isEmpty } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { useScreenActionPermissions } from "shared/hooks/usePermissions";
import format from "string-template";
import {
  BUILD_GENERAL_COST,
  BUILD_GENERAL_COSTS,
} from "utils/api/graphql/mutations/costs";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import {
  CONTACT_POINT_SYSTEM,
  INITIAL_VALUES,
  INSURANCE_PLAN_PARAMS_RULES,
  MODULES,
  SUBMODULES,
} from "utils/constants";
import View from "./View";

const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const List = ({ project, onNext, onBack, processId }) => {
  const actions = useScreenActionPermissions({
    module: MODULES.PROJECT,
    submodule: SUBMODULES.BROKER_DETAIL,
  });
  const { id } = useParams();
  const take = 5;
  const [skip, setSkip] = useState(INITIAL_VALUES.skip);
  const insurancePlanFilter = useMemo(() => {
    const issuanceDate = moment(get(project, "contract.issuanceDate")).startOf(
      "day"
    );
    const issuanceDateDifference = issuanceDate.diff(
      moment().startOf("day"),
      "days"
    );

    const adherentAge = moment(get(project, "contact.user.birthDate")).startOf(
      "year"
    );

    const age = moment().diff(adherentAge, "years");

    const [startKey, endKey] = project.ria
      ? [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
        ]
      : [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
        ];
    const socialRegime = get(project, "contact.socialRegime");

    const exerciseFrame = get(project, "contact.exerciseFrame");

    const profession = get(project, "contact.profession");

    const department = get(
      get(project, "contact.telecoms", []).find(
        ({ system }) => system === CONTACT_POINT_SYSTEM.ZIP_CODE
      ),
      "value",
      ""
    )
      .toString()
      .substring(0, 2);

    const sqlConditions = [
      generateSQLCondition(
        startKey,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        issuanceDateDifference
      ),
      generateSQLCondition(
        endKey,
        `r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value ${
          moment(get(project, "contract.issuanceDate")).year() ===
          moment().year()
            ? "OR CAST(r.value AS NUMERIC) = 0"
            : ""
        }`,
        issuanceDateDifference
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        age
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
        age
      ),
      // generateSQLCondition(
      //   INSURANCE_PLAN_PARAMS_RULES.EXERCISE_FRAME,
      //   "r.value IS NULL OR CAST(r.value AS TEXT) = '' OR CAST(r.value AS TEXT) = ':value' ",
      //   exerciseFrame
      // ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${socialRegime}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.PROFESSION,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${profession}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${department}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.PROCESSES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${processId}'`
      ),
    ];

    return {
      raw: sqlConditions,
      custom: {
        AND: [
          {
            OR: [
              { startDate: null },
              {
                startDate: issuanceDate,
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { endDate: null },
              {
                endDate: issuanceDate,
                operation: ">=",
              },
            ],
          },
        ],
      },
    };
  }, []);
  const [filter, setFilter] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedGeneralCosts, { loading }] = useLazyQuery(GENERAL_COSTS, {
    onCompleted: ({ generalCosts: { data } }) => {
      setSelectedProducts(omitDeep(data, "__typename"));
    },
  });
  const [buildGeneralCost] = useMutation(BUILD_GENERAL_COST);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS, {
    variables: {
      data: {
        project: { id },
        insurancePlanFilter,
      },
    },
    onCompleted: ({ buildGeneralCosts }) => {
      const selectedInsurancePlans =
        intersection(
          buildGeneralCosts?.map(({ insurancePlan }) => insurancePlan?.id),
          get(project, "fields.selectedToCompare", [])
        ) || [];

      selectedGeneralCosts({
        variables: {
          where: { project: { id } },
          isIn: {
            insurancePlan: {
              id: selectedInsurancePlans,
            },
          },
        },
      });
      setFilter({
        skip: 0,
        take,
        where: {
          project: { id },
        },
        gt: { cost: +get(project, "fields.budget.min") - 1 },
        lt: { cost: +get(project, "fields.budget.max") + 1 },
        isIn: { id: buildGeneralCosts?.map(({ id }) => id) || [] },
      });
    },
  });

  const onAdd = (product) => setSelectedProducts((prev) => [...prev, product]);

  const onRemove = (product) =>
    setSelectedProducts((prev) => prev.filter(({ id }) => id !== product.id));

  const onChangeCommission = ({ commission, insurancePlan }) => {
    buildGeneralCost({
      variables: {
        data: {
          project: { id },
          insurancePlan,
          commission,
        },
      },
    });
  };
  const onFilter = ({ min, max, needs }) => {
    updateProject({
      variables: {
        where: { id },
        data: { fields: { ...project.fields, budget: { min, max }, needs } },
      },
      onCompleted: () => {
        setFilter((prev) => ({
          ...prev,
          where: {
            ...prev.where,
            project: {
              ...prev.where.project,
              fields: {
                ...prev.where.project.fields,
                needs,
              },
            },
          },
          gt: { cost: min - 1 },
          lt: { cost: max + 1 },
        }));
      },
    });
  };

  useEffect(() => {
    if (project.locked) {
      selectedGeneralCosts({
        variables: {
          where: { project: { id } },
          isIn: {
            insurancePlan: { id: get(project, "fields.selectedToCompare", []) },
          },
        },
      });
      return setFilter({
        skip: INITIAL_VALUES.skip,
        take,
        where: { project: { id } },
      });
    }

    buildGeneralCosts();
  }, []);

  return (
    <View
      initialFields={project.fields}
      initialNeeds={project.fields.needs}
      expired={project?.expired}
      onNext={onNext}
      filter={filter}
      take={take}
      onBack={onBack}
      onAdd={onAdd}
      onRemove={onRemove}
      selectedProducts={selectedProducts}
      onChangeCommission={onChangeCommission}
      onFilter={onFilter}
      onLoadMore={setSkip}
      loading={isEmpty(filter)}
      actions={actions}
    />
  );
};

export default List;
