import { useNavigate } from "react-router";
import { useMutation } from "shared/hooks/useApi";
import {
  RESET_PASSWORD,
  SIGN_IN_WITH_LINK,
} from "utils/api/graphql/mutations/users";
import { SCREENS } from "utils/constants";
import View from "./View";

const Redirect = () => {
  const navigate = useNavigate();
  const [signInWithLink] = useMutation(SIGN_IN_WITH_LINK);
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleResetPassword = async ({ password, passcode, url }) => {
    signInWithLink({
      variables: {
        data: {
          passcode: passcode?.trim(),
          url,
        },
      },
      onCompleted: ({ signInWithLink = {} }) => {
        resetPassword({
          variables: {
            data: {
              password,
            },
          },
          context: {
            headers: {
              authorization: "Bearer " + signInWithLink.accessToken,
            },
          },
          onCompleted: () => navigate(SCREENS.AUTH.LOGOUT.path),
        });
      },
    });
  };

  const onSubmit = (values) => {
    const { passcode, password } = values;
    handleResetPassword({ password, passcode, url: window.location.href });
  };

  return <View onSubmit={onSubmit} />;
};

export default Redirect;
