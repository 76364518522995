import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { TRACER_TYPES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, id, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TRACER);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{ name: "", source: "" }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", ...formProps })}
            />
            <Input
              placeholder={FORM.DESTINATION}
              label={FORM.DESTINATION}
              {...bindInputProps({ name: "destination", ...formProps })}
            />

            <Select
              type="async"
              node={{
                query: graphql.queries.SOURCES,
                variables: { where: {} },
                accessors: { root: "sources.data" },
              }}
              placeholder={FORM.SOURCE}
              label={FORM.SOURCE}
              {...bindInputProps({ name: "source", ...formProps })}
            />
            <Select
              options={Object.entries(TRACER_TYPES).map(([label, value]) => ({
                label: FORM.TRACER_TYPES[label],
                value,
              }))}
              placeholder={FORM.TYPE}
              label={FORM.TYPE}
              {...bindInputProps({ name: "type", ...formProps })}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
