import Loading from "shared/components/Spin";
import { TEMPLATES, TEMPLATE_TYPES } from "utils/constants";
import CollectionNeeds from "./CollectionNeeds";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Contract from "./Contract";
import Default from "./Default";
import Fast from "./Fast";
import Product from "./Product";
import Quote from "./Quote";
import SelectProducts from "./SelectProducts";

const Templates = {
  Loading: Loading,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].CONTACT]: Contact,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].CONTRACT]: Contract,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].QUOTE]: Quote,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].FAST]: Fast,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].SELECT_PRODUCTS]: SelectProducts,
  [TEMPLATES.B2B.THEMES[TEMPLATE_TYPES.B2B_RADIANCE_COSA].COMPARISON]: Comparison,
  ...Product,
  default: Default,
};
export default Templates;
