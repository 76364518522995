import { Divider, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import Icon from 'shared/components/Icon';
import { OfferDetails, PricingItem } from 'shared/components/SmartphoneWidgets';
import { COLORS } from 'shared/style/colors';
import { descriptionItems, excludedItems, includedItems, reimbursementItems } from '../../../insuranceData';

const Details = ({ onClose, open, ...rest }) => {
  const [drawerWidth, setDrawerWidth] = useState("520px");

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setDrawerWidth("95%");
      } else if (windowWidth <= 1024) {
        setDrawerWidth("70%");
      } else {
        setDrawerWidth("520px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Drawer
      title={<></>}
      placement="right"
      className="contact__drawer"
      contentWrapperStyle={{
        width: drawerWidth,
      }}
      onClose={onClose}
      open={open}
      {...rest}>




      <PricingItem
        title="Premium"
        description="Couverture complète incluant réparation ou remplacement en cas de bris, oxydation, ou vol"
        price="2 510 XPF"
        monthlyPrice="30 120 XPF"
        variant="highlighted"
      />
      <Divider />

      <OfferDetails
        title="Description"
        background="dark"
        icon={<Icon name="arrow_right" fill size={20} color={COLORS.C_GRAY} />}
        items={descriptionItems} />

      <Divider />


      <div className="infos--summary">
        <div className="infos--summary-item">
          <Icon name="info" size={20} />
          <span><strong>Un seul sinistre</strong> pris en charge par année.</span>
        </div>
        <div className="infos--summary-item">
          <Icon name="handshake" size={20} />
          <span>Plafond annuel d’indemnisation <strong>238 660 XPF</strong></span>
        </div>
      </div>
      <Divider />


      <OfferDetails
        title="Inclut dans cette offre :"
        icon={<Icon name="check_small" size={20} color={COLORS.C_GREEN} />}
        items={includedItems} />
      <Divider />
      <OfferDetails
        title="Exclu de cette offre :"
        icon={<Icon name="close" size={20} color={COLORS.C_RED} />}
        items={excludedItems} />

      <Divider />

      <OfferDetails
        title="Modalités de remboursement"
        icon={<Icon name="arrow_right" fill size={20} color={COLORS.C_GRAY} />}
        items={reimbursementItems} />

    </Drawer>
  );
};
export default Details;