import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineFreeCancellation,
  MdOutlineShareLocation,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const BrokerageMandate = ({
  formProps,
  values,
  setPercent,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(initialValues, "project.ria", false) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE}-section`}
      >
        <Form
          type="vertical"
          title={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE.TITLE
          }
          subtitle={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE.SUBTITLE
          }
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                icon={<MdOutlineFreeCancellation size={16} />}
                defaultValue={get(
                  initialValues,
                  "project.terminationRequest.brokerageMandate.lastName"
                )}
                errors={
                  errors["project.terminationRequest.brokerageMandate.lastName"]
                }
                touched={
                  !isEmpty(
                    errors[
                      "project.terminationRequest.brokerageMandate.lastName"
                    ]
                  )
                }
                onChange={(value) => {
                  set(
                    initialValues,
                    "project.terminationRequest.brokerageMandate.lastName",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                disabled
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.lastName"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.FIRST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                icon={<MdOutlineFreeCancellation size={16} />}
                defaultValue={get(
                  initialValues,
                  "project.terminationRequest.brokerageMandate.firstName"
                )}
                errors={
                  errors[
                    "project.terminationRequest.brokerageMandate.firstName"
                  ]
                }
                touched={
                  !isEmpty(
                    errors[
                      "project.terminationRequest.brokerageMandate.firstName"
                    ]
                  )
                }
                onBlur={() => setPercent(initialValues)}
                disabled
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.firstName"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.CURRENT_COMPANY.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.CURRENT_COMPANY.LABEL}
                icon={<MdOutlineShareLocation size={16} />}
                defaultValue={get(
                  initialValues,
                  "project.terminationRequest.brokerageMandate.currentCompany"
                )}
                errors={
                  errors[
                    "project.terminationRequest.brokerageMandate.currentCompany"
                  ]
                }
                touched={
                  !isEmpty(
                    errors[
                      "project.terminationRequest.brokerageMandate.currentCompany"
                    ]
                  )
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    "project.terminationRequest.brokerageMandate.currentCompany",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.currentCompany"
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default BrokerageMandate;
