import classNames from "classnames";
import { get } from "lodash";
import Button from "shared/components/Button";
import { CURRENCY_SYMBOL } from "utils/constants";

const ProductCard = ({
  name,
  description,
  price,
  action,
  info,
  link,
  recommendation,
}) => {
  return (
    <div className="product__item">
      <div
        className={classNames({
          "product__item--recommended": recommendation?.value,
        })}
      >
        {recommendation?.value && (
          <h3 className="product__item--recommended--header">
            {recommendation?.label}
          </h3>
        )}
        <div className="product__item--wrapper">
          <div className="product__item--header">
            <h3 className="product__item--title">{name}</h3>
            <div className="product__item--price">
              <h1>
                {price?.cost} {CURRENCY_SYMBOL[price?.currency]}
              </h1>
              <span>{price?.period}</span>
            </div>
            <p className="product__item--description">{description}</p>
          </div>
          <div className="product__item--action">
            <Button
              type="primary"
              onClick={action?.onClick}
              loading={action?.loading}
              disabled={action?.disabled}
            >
              {action?.label}
            </Button>
          </div>
          <div className="product__item--info">
            <span className="product__item--info--title">{info?.title}</span>

            <ul className="--list">
              {get(info, "items", []).map(
                ({ coverage, limit, complement }, i) => (
                  <li key={i} className="item">
                    {coverage} <span>{limit}</span> <sup>{complement}</sup>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="product__item--link" onClick={link?.onClick}>
            {link?.label}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
