import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ActivitiesLog from "shared/components/ActivitiesLog";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import { QUALIFIERS } from "utils/api/graphql/queries/qualifiers";
import { QUOTES } from "utils/api/graphql/queries/quotes";
import { SUBSCRIPTIONS } from "utils/api/graphql/queries/subscription";
import { SUBSCRIPTION_STATUSES, TYPES } from "utils/constants";

const SubscriptionActivities = ({ project, contactId }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);

  const variables = {
    where: {
      project: {
        id: project?.id,
      },
    },
  };

  const { data: qualifiersData, loading: qualifiersLoading } = useQuery(
    QUALIFIERS,
    {
      variables: {
        withDeleted: true,
        where: {
          qualifications: {
            contact: { id: contactId },
          },
        },
      },
    }
  );

  const { loading, data } = useQuery(SUBSCRIPTIONS, {
    variables: {
      where: {
        AND: [
          {
            project: {
              id: project?.id,
            },
          },
          {
            OR: [
              { status: SUBSCRIPTION_STATUSES.PENDING },
              { status: SUBSCRIPTION_STATUSES.SIGNED },
            ],
          },
        ],
      },
    },
  });
  const {
    loading: { quotesLoading },
    data: quotesData,
  } = useQuery(QUOTES, {
    variables,
  });

  if (loading || quotesLoading || qualifiersLoading) return <Loading />;

  const subscriptions = data?.subscriptions?.data || [];
  const quotes = quotesData?.quotes?.data || [];
  const qualifiers = qualifiersData?.qualifiers?.data || [];

  const activities = sortBy(
    [
      ...qualifiers.flatMap(({ qualifications, name }) =>
        qualifications.map(({ createdDate }) => ({
          status: name,
          date: createdDate,
          author: project?.author,
        }))
      ),
      ...quotes.flatMap(({ createdDate }) => ({
        status: SUBSCRIPTION_STATUSES.DRAFT,
        date: createdDate,
        type: TYPES.DANGER,
        author: project?.author,
      })),
      ...subscriptions.flatMap(({ sendSignatureDate, signedDate, status }) =>
        status === SUBSCRIPTION_STATUSES.PENDING
          ? {
              date: sendSignatureDate,
              status: SUBSCRIPTION_STATUSES.PENDING,
              type: TYPES.DANGER,
              author: project?.author,
            }
          : [
              {
                date: sendSignatureDate,
                status: SUBSCRIPTION_STATUSES.PENDING,
                type: TYPES.DANGER,
                author: project?.author,
              },
              {
                date: signedDate,
                status: SUBSCRIPTION_STATUSES.SIGNED,
                type: TYPES.DANGER,
                author: project?.author,
              },
            ]
      ),
    ],
    (activity) => moment(activity.date).toDate()
  ).reverse();

  if (!activities.length) {
    return <div className="no-activity">{t("ACTIVITIES.NO_ACTIVITY")}</div>;
  }

  return <ActivitiesLog activities={activities} />;
};

export default SubscriptionActivities;
