import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Stepper from "../Components/Stepper";
import validation from "./validation";

const View = ({ onNext, onBack, contact, locked }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: {
      ...contact,
      acceptContact: get(contact, "acceptContact") || false,
      acceptDigital: get(contact, "acceptDigital") || false,
      fields: {
        ...get(contact, "fields"),
        isClient: get(contact, "fields.isClient", false),
      },
    },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  return (
    <Container size="medium">
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: false },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: false },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />
        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.ADHERENT_TELECOMS.TITLE_R}
          </div>
        </div>
        <div className="b2c__area--content adherent-telecoms">
          <div className="grid--2">
            <Input
              label={TRANSLATION.ADHERENT_INFO.FIRST_NAME}
              placeholder={TRANSLATION.ADHERENT_INFO.FIRST_NAME}
              {...bindInputProps({
                name: "user.firstname",
                values,
                ...formProps,
              })}
              disabled={locked}
            />
            <Input
              label={TRANSLATION.ADHERENT_INFO.LAST_NAME}
              placeholder={TRANSLATION.ADHERENT_INFO.LAST_NAME}
              {...bindInputProps({
                name: "user.lastname",
                values,
                ...formProps,
              })}
              disabled={locked}
            />
            <Input
              type="phone"
              label={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.PHONE}
              placeholder={
                TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.PHONE
              }
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                values,
                ...formProps,
              })}
              disabled={locked}
            />
            <Input
              label={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.EMAIL}
              placeholder={
                TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.EMAIL
              }
              type="email"
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                values,
                ...formProps,
              })}
              disabled={locked}
            />
          </div>
          <div className="already-adherent">
            <div>
              <span>{TRANSLATION.ADHERENT_TELECOMS.ALREADY_ADHERENT}</span>
              <div className="d-flex">
                <Button
                  type={classNames({
                    primary: get(values, "fields.isClient"),
                    "primary--outlined": !get(values, "fields.isClient"),
                  })}
                  onClick={() => setFieldValue("fields.isClient", true)}
                  disabled={locked}
                >
                  {TRANSLATION.YES}
                </Button>
                <Button
                  type={classNames({
                    primary: !get(values, "fields.isClient"),
                    "primary--outlined": get(values, "fields.isClient"),
                  })}
                  onClick={() => setFieldValue("fields.isClient", false)}
                  disabled={locked}
                >
                  {TRANSLATION.NO}
                </Button>
              </div>
            </div>
          </div>
          <Input
            type="checkbox"
            label={TRANSLATION.ADHERENT_TELECOMS.CALL_PERMISSION}
            checked={get(values, "acceptContact") || false}
            {...bindInputProps({
              name: "acceptContact",
              values,
              ...formProps,
            })}
            disabled={locked}
          />
          <Input
            type="checkbox"
            label={TRANSLATION.ADHERENT_TELECOMS.MAIL_PERMISSION}
            checked={get(values, "acceptDigital") || false}
            {...bindInputProps({
              name: "acceptDigital",
              values,
              ...formProps,
            })}
            disabled={locked}
          />
        </div>
        <div className="b2c__area--actions">
          <Button onClick={onBack} className="prev">
            <BsArrowLeftCircle size={32} />
            <span>{TRANSLATION.PREVIOUS}</span>
          </Button>
          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            disabled={
              !isEmpty(formProps.errors) ||
              !get(values, "acceptDigital") ||
              !get(values, "acceptContact") ||
              formProps.isSubmitting
            }
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
