import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Detail from "./Detail";
import SendLink from "./SendLink";

const routes = [
  {
    path: `${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.DETAIL.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.SUBSCRIPTION]: SUBMODULES.BROKER_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.SEND_LINK.path}`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.SUBSCRIPTION]: SUBMODULES.BROKER_DETAIL }}
        WrappedComponent={SendLink}
      />
    ),
  },
];

export default routes;
