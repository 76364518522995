import { gql } from "@apollo/client";

export const PROCESS_STATICS = gql`
  query ProjectProcessStatusesStats(
    $where: ProcessWhereInput
    $gt: ProjectProcessStatusWhereInput
    $lt: ProjectProcessStatusWhereInput
  ) {
    projectProcessStatusesStats(where: $where, gt: $gt, lt: $lt) {
      process {
        id
        name
      }
      data
    }
  }
`;

const dashboard = { PROCESS_STATICS };

export default dashboard;
