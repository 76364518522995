import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineFrontHand, MdOutlinePinDrop } from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const TerminationRequest = ({
  formProps,
  values,
  initialValues,
  setPercent,
  errors,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(initialValues, "project.ria", false) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST}-section`}
      >
        <Form
          type="vertical"
          title={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.TERMINATION_REQUEST.TITLE
          }
          subtitle={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.TERMINATION_REQUEST
              .SUBTITLE
          }
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Alert
                showIcon
                type="info"
                message={SUBSCRIPTION.FORM.TERMINATION_ALERT}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.CONTRACT_NUMBER.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.CONTRACT_NUMBER.LABEL}
                icon={<MdOutlineFrontHand size={16} />}
                defaultValue={get(
                  initialValues,
                  "project.terminationRequest.contractNumber"
                )}
                errors={errors["project.terminationRequest.contractNumber"]}
                touched={
                  !isEmpty(errors["project.terminationRequest.contractNumber"])
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    "project.terminationRequest.contractNumber",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  "project.terminationRequest.contractNumber"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                optional={SUBSCRIPTION.FORM.OPTIONAL}
                placeholder={
                  SUBSCRIPTION.FORM.CURRENT_COMPANY_ADDRESS.PLACEHOLDER
                }
                label={SUBSCRIPTION.FORM.CURRENT_COMPANY_ADDRESS.LABEL}
                icon={<MdOutlinePinDrop size={16} />}
                defaultValue={get(
                  initialValues,
                  "project.terminationRequest.address"
                )}
                errors={errors["project.terminationRequest.address"]}
                touched={!isEmpty(errors["project.terminationRequest.address"])}
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    "project.terminationRequest.address",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("project.terminationRequest.address")}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default TerminationRequest;
