import gql from "graphql-tag";

export const CREATE_LINK = gql`
  mutation AddLink($data: LinkDataInput) {
    addLink(data: $data) {
      id
    }
  }
`;

export const DELETE_LINK = gql`
  mutation DeleteLink($where: LinkWhereInput) {
    deleteLink(where: $where) {
      id
    }
  }
`;

const links = { CREATE_LINK, DELETE_LINK };

export default links;
