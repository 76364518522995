import PropTypes from 'prop-types';
import {MaterialSymbol} from 'react-material-symbols';
import 'react-material-symbols/outlined';
import 'react-material-symbols/rounded';
import 'react-material-symbols/sharp';

const Icon = ({
  name,
  grade,
  size = 24,
  weight = 400,
  fill = false,
  color = 'currentColor',
  variant = 'outlined',
  className = '',
}) => {
  return (
    <MaterialSymbol
      icon={name}
      size={size}
      weight={weight}
      fill={fill ? 1 : 0} 
      color={color}
      grade={grade}
      variant={variant}
      className={className}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  grade: PropTypes.number,
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700]),
  fill: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'rounded', 'sharp']),
  className: PropTypes.string,
};

export default Icon;


// import PropTypes from 'prop-types';
// import * as MaterialSymbol from 'react-material-symbols';
// import 'react-material-symbols/outlined';
// import 'react-material-symbols/rounded';
// import 'react-material-symbols/sharp';

// const Icon = ({
//   name,
//   grade,
//   size = 24,
//   weight = 400,
//   fill = false,
//   color = 'currentColor',
//   variant = 'outlined',
//   className = ''
// }) => {
//   return (
//     <MaterialSymbol
//       icon={name}
//       size={size}
//       weight={weight}
//       fill={fill}
//       color={color}
//       grade={grade}
//       variant={variant}
//       className={className}
//     />
//   );
// };

// Icon.propTypes = {
//   name: PropTypes.string.isRequired,
//   size: PropTypes.number,
//   grade: PropTypes.number,
//   weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700]),
//   fill: PropTypes.bool,
//   color: PropTypes.string,
//   variant: PropTypes.oneOf(['outlined', 'rounded', 'sharp']),
//   className: PropTypes.string
// };

// export default Icon;
