import moment from "moment";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    spouse: yup
      .object()
      .shape({
        identity: yup.object().shape({
          user: yup.object().shape({
            birthDate: yup
              .date()
              .typeError(errors.REQUIRED)
              .max(isMajor, errors.RADIANCE.SPOUSE_AGE)
              .min(
                moment().subtract(100, "year").startOf("year"),
                errors.RADIANCE.SPOUSE_AGE
              )
              .required(errors.REQUIRED),
          }),
        }),
      })
      .nullable()
      .default(undefined),
    children: yup.array().of(
      yup.object().shape({
        identity: yup.object().shape({
          user: yup.object().shape({
            birthDate: yup
              .date()
              .typeError(errors.REQUIRED)
              .min(
                moment().subtract(27, "year").startOf("year"),
                errors.RADIANCE.CHILD_AGE
              )
              .max(moment(), errors.RADIANCE.CHILD_AGE)
              .required(errors.REQUIRED),
          }),
        }),
      })
    ),
  });
};

export default validation;
