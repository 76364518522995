import { COVERAGE_CATEGORIES } from "utils/constants";

export const images = {
  male: "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516dcc44cf3b8b0e3e09927_bald-man.svg",
  insured:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516dcc44cf3b8b0e3e09927_bald-man.svg",
  insured_and_spouse:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516e8e2fa815abfc1e0c483_Group%20323.svg",
  insured_and_children:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516e9435538fe0ee76360a4_Group%20320.svg",
  all_family:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516e96887cbb6c97ec654ad_Group%20319.svg",
  female:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516dcc3e4ed247ed53d7c0e_woman.svg",

  [COVERAGE_CATEGORIES.MEDICAL_CARE]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f6164b1113c1478f04f52_cdfvdfVector.svg",

  [COVERAGE_CATEGORIES.HOSPITALIZATION]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f62cbf10812a7d158139a_dfcvVector.svg",

  [COVERAGE_CATEGORIES.OPTICAL]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f62efca0db6375cf2d7ca_Vcdcvector.svg",

  [COVERAGE_CATEGORIES.DENTAL]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f63060f5fb9b01aea9caa_Vectocdcdcr.svg",

  [COVERAGE_CATEGORIES.ALTERNATIVE_MEDICINE]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f633843cb308828f1354a_Vecdcdctor.svg",

  [COVERAGE_CATEGORIES.EQUIPMENT]:
    "https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/646f635294281d943488cd0f_cdcdVector.svg",
};
