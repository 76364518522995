import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path);
  const [addCoverageCategory] = useMutation(
    graphql.mutations.CREATE_COVERAGE_CATEGORY,
    {
      refetchQueries: [
        {
          query: COVERAGE_CATEGORIES,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path);
      },
    }
  );
  const onSubmit = ({ iconAttachment, ...values }) => {
    return addCoverageCategory({
      variables: { data: { id, ...values, icon: iconAttachment } },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
