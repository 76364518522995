import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onNext, onBack, user }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: user,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.ADHERENT_INFO.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.ADHERENT_INFO.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <div className="grid--2">
          <Input
            label={TRANSLATION.ADHERENT_INFO.LAST_NAME}
            placeholder={TRANSLATION.ADHERENT_INFO.LAST_NAME}
            {...bindInputProps({
              name: "lastname",
              values,
              ...formProps,
            })}
          />
          <Input
            label={TRANSLATION.ADHERENT_INFO.FIRST_NAME}
            placeholder={TRANSLATION.ADHERENT_INFO.FIRST_NAME}
            {...bindInputProps({
              name: "firstname",
              values,
              ...formProps,
            })}
          />
          <Input
            label={TRANSLATION.ADHERENT_INFO.BIRTH_DATE}
            type="date"
            variant="simple"
            format={DATE_FORMAT}
            disabledDate={(current) => current > isMajor}
            defaultPickerValue={
              !get(values, "birthDate") && dayjs().subtract(30, "years")
            }
            {...bindDateInputProps({
              name: "birthDate",
              values,
              ...formProps,
            })}
          />
        </div>
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!isEmpty(formProps.errors)}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
