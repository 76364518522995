import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, childrenNumber }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const [selectedOption, setSelectedOption] = useState(childrenNumber);
  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.CHILDREN_INSURANCE_COUNT.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.CHILDREN_INSURANCE_COUNT.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <div className="b2c__area--content__children-count">
          <div className="image_container">
            <img
              width="80"
              alt=""
              src="https://assets-global.website-files.com/63778e89ddc4bfbb14af9d63/6516e8e16c4e76b37580546b_Group%20322.svg"
            />
          </div>
          <div className="flex--grow-1">
            <Radio
              name={"gender"}
              value={selectedOption}
              values={[
                {
                  label: TRANSLATION.CHILDREN_INSURANCE_COUNT.OPTIONS.ONE,
                  value: "1",
                },
                {
                  label: TRANSLATION.CHILDREN_INSURANCE_COUNT.OPTIONS.TWO,
                  value: "2",
                },
                {
                  label: TRANSLATION.CHILDREN_INSURANCE_COUNT.OPTIONS.THREE,
                  value: "3",
                },
                {
                  label:
                    TRANSLATION.CHILDREN_INSURANCE_COUNT.OPTIONS.MORE_THAN_FOUR,
                  value: "4",
                },
              ]}
              onChange={setSelectedOption}
            />
          </div>
        </div>
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={() => onNext(selectedOption)}
          disabled={!selectedOption}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
      <div className="b2c__area--infos">
        <div>{TRANSLATION.CHILDREN_INSURANCE_COUNT.INFO}</div>
      </div>
    </div>
  );
};

export default View;
