import { Col, Row } from "antd";
import cuid from "cuid";
import { useFormik } from "formik";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdFileUpload,
  MdOutlineBadge,
  MdOutlineDescription,
  MdPayment,
} from "react-icons/md";
import GenerateLinkModal from "screens/Private/Broker/Projects/List/Subscriptions/GenerateLinkModal";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "utils/api/graphql/mutations/attachment";
import { SEND_SUBSCRIPTION_ADDITIONAL_DOCUMENTS } from "utils/api/graphql/mutations/subscription";
import { SUBSCRIPTION as SUBSCRIPTION_QUERY } from "utils/api/graphql/queries/subscription";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_STATUSES,
  THEMES_PALETTE,
} from "utils/constants";
import { STEPPER_KEYS } from "../..";
import Progress from "../Progress";
import generateProofForm from "./Forms";
import validation from "./validation";

export const DOCUMENTS_TYPE = {
  kbis: "KBIS",
  payerCin: "PAYER_CIN",
  cin: "CIN",
  paymentAccount: "PAYMENT_ACCOUNT",
  refundAccount: "REFUND_ACCOUNT",
  socialSecurityCertificate: "SOCIAL_SECURITY_CERTIFICATE",
  pensionFund: "PENSION_FUND",
};

const ICONS = {
  SOCIAL_SECURITY_CERTIFICATE: <MdOutlineDescription size={16} />,
  ID: <MdOutlineBadge size={16} />,
  BANK_ACCOUNT: <MdPayment size={16} />,
  KBIS: <MdFileUpload size={16} />,
  PENSION_FUND: <MdFileUpload size={16} />,
};

const Proof = ({
  subscription,
  progress,
  setPercent,
  disabled,
  initialValues,
  setActive,
  calculateProgressMean,
  isSubmitting,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const [addAttachment, { loading }] = useMutation(CREATE_ATTACHMENT);
  const [getTracer] = useLazyQuery(TRACER);

  const [sendAdditionalDocument] = useMutation(
    SEND_SUBSCRIPTION_ADDITIONAL_DOCUMENTS
  );
  const refetchQueries = useMemo(
    () => [
      {
        query: SUBSCRIPTION_QUERY,
        awaitRefetchQueries: true,
        variables: {
          where: { id: subscription.id },
        },
      },
    ],
    [subscription.id]
  );

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    refetchQueries,
  });

  const [open, setOpen] = useState({});

  const onOpenGenerateLink = (data) => setOpen(data);

  useEffect(() => {
    calculateProgressMean(STEPPER_KEYS.PROOF, values);
  }, [
    get(subscription, "project.contact.fields.teletransmission"),
    get(subscription, "project.contact.socioProfessionalCategory"),
  ]);

  const onSubmit = async (data) => {
    const documents = get(data, "proof", {});
    const documentsToUpload = [];
    Object.entries(documents)?.map(([key, attachments]) =>
      attachments
        ?.filter((e) => !e.status)
        .forEach((document) => {
          document.status = true;
          documentsToUpload.push({
            id: cuid(),
            subscription: { id: get(subscription, "id") },
            name: document.name,
            content: document.base64,
            contentType: document.contentType,
            type: DOCUMENTS_TYPE[key],
          });
        })
    );
    const uploadedDocuments = await Promise.all(
      documentsToUpload.map((document, index) =>
        addAttachment({
          variables: {
            data: document,
          },
          refetchQueries:
            index === documentsToUpload?.length - 1 && refetchQueries,
        })
      )
    );

    if (subscription.status === SUBSCRIPTION_STATUSES.SIGNED) {
      sendAdditionalDocument({
        variables: {
          where: { id: get(subscription, "id") },
          data: uploadedDocuments.map(({ data: { addAttachment } }) =>
            omitDeep(addAttachment, "__typename")
          ),
        },
      });
    }

    calculateProgressMean(STEPPER_KEYS.PROOF, data);
    setActive(STEPPER_KEYS.DOCUMENT);
  };

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  const formList = useMemo(
    () =>
      Object.entries(
        generateProofForm({
          isRetired:
            (
              subscription?.insurancePlan?.config?.additionalDocuments || []
            ).includes("proof.pensionFund") &&
            subscription?.project?.contact?.socioProfessionalCategory ===
              SOCIO_PROFESSIONAL_CATEGORIES.RETIREES,
          isTNS: subscription?.insurancePlan?.fields?.tns,
          acceptTeletransmission:
            subscription?.project?.contact?.fields?.teletransmission ?? true,
        })
      ).filter(([_, Form]) => Form),
    [
      subscription?.project?.contact?.socioProfessionalCategory,
      subscription?.insurancePlan?.fields?.tns,
      subscription?.project?.contact?.fields?.teletransmission,
      subscription?.insurancePlan?.config?.additionalDocuments,
    ]
  );
  const onFileRemove = async ({ uid: id }) => {
    if (id.indexOf("AUTO") !== -1) return;
    return deleteAttachment({
      variables: {
        where: {
          id,
        },
      },
    });
  };

  return (
    <div key="proof" className="subscription-proof--wrapper">
      <GenerateLinkModal
        open={open}
        setOpen={setOpen}
        template={get(subscription, "project.fields.processTemplate")}
      />

      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <Row align="middle" gutter={[16, 16]}>
            <Col lg={18} xs={24}>
              <Alert
                type="warning"
                showIcon
                message={SUBSCRIPTION.PROOF.WARNING}
              />
            </Col>
            <Col align="end" lg={6} xs={24}>
              <span className="indicator-step">
                {format(SUBSCRIPTION.INDICATOR, { count: "2", total: "3" })}
              </span>
            </Col>
          </Row>
        </Col>
        <Col lg={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Col lg={9} xs={24}>
              <div className="card-skeleton--container highlighted">
                <div className="card-skeleton--wrapper">
                  <h3 className="title--md">{SUBSCRIPTION.PROOF.TITLE}</h3>
                  <div className="card-skeleton--wrapper__progress-list">
                    {formList.map(([form], index) => (
                      <Progress
                        key={"progress-" + index}
                        percent={progress["PROOF"][form]}
                        status={progress["PROOF"][form]}
                        translation={SUBSCRIPTION}
                        icon={ICONS[form]}
                        title={SUBSCRIPTION.PROOF.STEPS[form]}
                      />
                    ))}
                  </div>
                </div>
                <Button
                  type={"primary"}
                  size="full--width"
                  onClick={async () => {
                    const {
                      data: {
                        tracer: { source },
                      },
                    } = await getTracer({
                      variables: {
                        where: {
                          flow: {
                            process: {
                              theme:
                                THEMES_PALETTE[
                                  get(
                                    subscription,
                                    "project.fields.processTemplate"
                                  )
                                ].PROSPECT_PROCESS,
                            },
                          },
                        },
                      },
                    });
                    onOpenGenerateLink({
                      projectId: get(subscription, "project.id"),
                      subscriptionId: subscription.id,
                      insurancePlanIds: [get(subscription, "insurancePlan.id")],
                      isInfoCompleted: get(
                        subscription,
                        "additionalInfo.isInfoCompleted"
                      ),
                      baseUrl: get(
                        subscription,
                        "insurancePlan.config.generatedUrl",
                        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source.id}`
                      ),
                    });
                  }}
                  disabled={isSubmitting}
                >
                  {SUBSCRIPTION.GENERATE_LINK}
                  <AiOutlineLink size={24} />
                </Button>
              </div>
            </Col>
            {loading ? (
              <Loading />
            ) : (
              <Col lg={15} xs={24} gutter={16}>
                <Card
                  title={SUBSCRIPTION.PROOF.TITLE}
                  subtitle={SUBSCRIPTION.PROOF.SUBTITLE}
                >
                  {formList.map(([key, Form], index) => {
                    return (
                      <Form
                        key={"form-" + index}
                        disabled={disabled}
                        subscription={subscription}
                        values={values}
                        formProps={{ ...formProps }}
                        setFieldValue={setFieldValue}
                        onFileRemove={onFileRemove}
                        setPercent={(data) => {
                          setPercent(STEPPER_KEYS.PROOF, key, data);
                        }}
                      />
                    );
                  })}
                </Card>
                <div className="d-flex justify--between m-top-16">
                  <Button
                    type="secondary--link"
                    onClick={() =>
                      setActive(STEPPER_KEYS.ADDITIONAL_INFORMATION)
                    }
                  >
                    <MdArrowBackIos size={16} />
                    {SUBSCRIPTION.PROOF.ACTION.PREVIOUS}
                  </Button>

                  <Button type="primary" onClick={handleSubmit}>
                    {
                      SUBSCRIPTION.PROOF.ACTION[
                        subscription.status === SUBSCRIPTION_STATUSES.SIGNED
                          ? "SEND_DOCUMENTS"
                          : "SAVE"
                      ]
                    }
                    <MdArrowForwardIos size={16} />
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Proof;
