import { Drawer } from "antd";
import { get } from "lodash";
import Button from "shared/components/Button";
import ProductCard from "../../widgets/ProductCard";

const View = ({
  project,
  onCloseProduct,
  products,
  translate,
  changeInsurancePlan,
  selectedInsurancePlan,
  setSelectedInsurancePlan,
}) => {
  return (
    <Drawer
      title="Fermer"
      placement="right"
      onClose={onCloseProduct}
      open={true}
      className="product-drawer"
      contentWrapperStyle={{
        width: "400px",
      }}
    >
      {selectedInsurancePlan}
      <span className="product-drawer--header">
        {translate.PROPOSED_PRODUCT}
      </span>
      <div className="product-drawer--container">
        {products.map((product, index) => (
          <div
            key={`product-${index}`}
            className={`product-drawer--container__card ${
              product.insurancePlan.id === selectedInsurancePlan && "active"
            }`}
          >
            <ProductCard
              insurancePlan={get(product, "insurancePlan")}
              project={project}
              onClick={() => setSelectedInsurancePlan(product.insurancePlan.id)}
            />
          </div>
        ))}
      </div>
      <div className="product-drawer--actions">
        <Button
          className="product-drawer--actions__save"
          onClick={() => changeInsurancePlan()}
        >
          {translate.SAVE_CHANGES}
        </Button>
      </div>
    </Drawer>
  );
};

export default View;
