import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import AdherentInfo from "./AdherentInfo";
import AdherentSocialRegime from "./AdherentSocialRegime";
import AdherentTelecoms from "./AdherentTelecoms";
import Beneficiaries from "./Beneficiaries";
import ChildrenBirthDates from "./ChildrenBirthdates";
import ChildrenNumber from "./ChildrenNumber";
import Default from "./Default";
import EffectiveDate from "./EffectiveDate";
import ElectronicSignature from "./ElectronicSignature";
import Gender from "./Gender";
import Needs from "./Needs";
import Products from "./Products";
import SpouseBirthDate from "./SpouseBirthdate";
import SpouseSocialRegime from "./SpouseSocialRegime";
import Subscription from "./Subscription";
import SubscriptionContract from "./Subscription/screens/Contract";
import EditSubscription from "./Subscription/screens/EditSubscription";
import ThankYou from "./Subscription/screens/ThankYou";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES.DEFAULT.GENDER]: Gender,
  [TEMPLATES.B2C.THEMES.DEFAULT.CHILDREN_BIRTHDATES]: ChildrenBirthDates,
  [TEMPLATES.B2C.THEMES.DEFAULT.ADHERENT_SOCIAL_REGIME]: AdherentSocialRegime,
  [TEMPLATES.B2C.THEMES.DEFAULT.B2C_SUBSCRIPTION]: Subscription,
  [TEMPLATES.B2C.THEMES.DEFAULT.B2C_EDIT_SUBSCRIPTION]: EditSubscription,
  [TEMPLATES.B2C.THEMES.DEFAULT.B2C_SUBSCRIPTION_CONTRACT]:
    SubscriptionContract,
  [TEMPLATES.B2C.THEMES.DEFAULT.E_SIGN]: ElectronicSignature,
  [TEMPLATES.B2C.THEMES.DEFAULT.B2C_SUBSCRIPTION_DONE]: ThankYou,
  [TEMPLATES.B2C.THEMES.DEFAULT.ADHERENT_INFO]: AdherentInfo,
  [TEMPLATES.B2C.THEMES.DEFAULT.ADHERENT_TELECOMS]: AdherentTelecoms,
  [TEMPLATES.B2C.THEMES.DEFAULT.SPOUSE_BIRTHDATE]: SpouseBirthDate,
  [TEMPLATES.B2C.THEMES.DEFAULT.SPOUSE_SOCIAL_REGIME]: SpouseSocialRegime,
  [TEMPLATES.B2C.THEMES.DEFAULT.BENEFICIARIES]: Beneficiaries,
  [TEMPLATES.B2C.THEMES.DEFAULT.CHILDREN_NUMBER]: ChildrenNumber,
  [TEMPLATES.B2C.THEMES.DEFAULT.EFFECTIVE_DATE]: EffectiveDate,
  [TEMPLATES.B2C.THEMES.DEFAULT.NEEDS]: Needs,
  [TEMPLATES.B2C.THEMES.DEFAULT.PRODUCTS]: Products,
  default: Default,
};
export default Templates;
