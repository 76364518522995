import { Popover, Select } from "antd";
import classNames from "classnames";
import { useState } from "react";
import Label from "shared/components/Label";

const { Option } = Select;

const Subtext = ({
  label,
  errors,
  icon,
  touched,
  required,
  direction,
  optional,
  options,
  optionFilterProp = "label",
  value,
  onChange,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(
    options.find((opt) => opt.value === value)
  );

  const handleSelectChange = (option) => {
    const selected = options.find((opt) => opt.value === option);
    setSelectedOption({
      value: selected?.value,
      label: selected?.label,
      status: selected?.status,
    });
    onChange(option);
  };

  return (
    <div
      className={classNames(
        "select__wrapper",
        { __error: touched && errors },
        direction
      )}
    >
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`select__wrapper--subtext`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          optionFilterProp={optionFilterProp}
          value={selectedOption?.value || null}
          onChange={handleSelectChange}
          {...rest}
        >
          {options.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              <Popover
                overlayInnerStyle={{
                  padding: "4px 12px",
                }}
                autoAdjustOverflow
                placement="left"
                content={option.popover}
                arrow
              >
                <div className="option__label">{option.label}</div>
                <div className="option__status"> {option.status}</div>
              </Popover>
            </Option>
          ))}
        </Select>
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Subtext;
