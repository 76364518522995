import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, NAVIGATION_DIRECTIONS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
  const [deleteCoverageBenefit] = useMutation(
    graphql.mutations.DELETE_COVERAGE_BENEFIT,
    {
      refetchQueries: [
        {
          query: graphql.queries.COVERAGE_BENEFITS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        setVisible(false);
        navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
      },
      onError: () => {},
    }
  );
  const onSubmit = () => {
    return deleteCoverageBenefit({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
