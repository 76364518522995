import { SCREENS } from "utils/constants";
import Create from "./Create";
import Detail from "./Detail";
import IFrame from "./IFrame";
import Init from "./Init";

const routes = [
  {
    path: `${SCREENS.PUBLIC.PROCESSES.IFRAME.path}`,
    exact: true,
    element: <IFrame />,
  },
  {
    path: `${SCREENS.PUBLIC.PROCESSES.DETAIL.path}/:id`,
    exact: true,
    element: <Detail />,
  },
  {
    path: `${SCREENS.PUBLIC.PROCESSES.CREATE.path}/:src`,
    exact: true,
    element: <Create />,
  },
  {
    path: `${SCREENS.PUBLIC.PROCESSES.INIT.path}/:src`,
    exact: true,
    element: <Init />,
  },
];

export default routes;
