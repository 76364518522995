import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
  const variables = {
    where: { id },
    orderBy: [{ order: "asc" }],
  };
  const [updateCoverageBenefit] = useMutation(
    graphql.mutations.UPDATE_COVERAGE_BENEFIT,
    {
      refetchQueries: [
        {
          query: graphql.queries.COVERAGE_BENEFITS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
        {
          query: graphql.queries.COVERAGE_BENEFIT,
          awaitRefetchQueries: true,
          variables,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
      },
      onError: () => {},
    }
  );

  const { data, loading, error } = useQuery(graphql.queries.COVERAGE_BENEFIT, {
    variables,
  });

  const onSubmit = (values) => {
    return updateCoverageBenefit({
      variables: {
        where: {
          id,
        },
        data: {
          id,
          ...values,
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.coverageBenefit, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;
