import { Modal } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT } from "utils/constants";
import { bindDateInputProps } from "utils/helpers/input";

const ExpirationModal = ({
  open,
  values,
  formProps,
  setOpen,
  deltaStart,
  deltaRia,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={500}
      footer={null}
    >
      <div className="expiration_modal">
        <span className="expiration_modal--icon">
          <HiOutlineExclamationCircle size={32} />
        </span>
        <span className="expiration_modal--title">
          {DETAIL.EXPIRATION_MODAL.TITLE}
        </span>
        <span className="expiration_modal--description">
          {DETAIL.EXPIRATION_MODAL.DESCRIPTION}
        </span>
        <div className="expiration_modal--date">
          <Input
            type="date"
            variant="simple"
            format={DATE_FORMAT}
            disabledDate={(current) =>
              current && values.ria
                ? current < dayjs().startOf("day").add(deltaRia, "days")
                : current < dayjs().startOf("day").add(deltaStart, "days")
            }
            defaultPickerValue={
              !get(values, "contract.issuanceDate") && dayjs()
            }
            {...bindDateInputProps({
              name: "contract.issuanceDate",
              values,
              ...formProps,
            })}
          />
        </div>
        <div className="expiration_modal--actions">
          <Button type="gray--outlined" onClick={() => setOpen(false)}>
            {DETAIL.EXPIRATION_MODAL.EXIT}
          </Button>
          <Button type="primary" onClick={() => setOpen(false)}>
            {DETAIL.EXPIRATION_MODAL.VALIDATE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExpirationModal;
