import Admin from "./Admin";
import Broker from "./Broker";
import Guest from "./Guest";

const screens = ({ user }) => [
  ...Broker(user?.permissions),
  ...Admin(user?.permissions),
  ...Guest
];

export default screens;
