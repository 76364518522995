import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  INITIAL_VALUES,
  NAVIGATION_DIRECTIONS,
  USER_TYPES,
} from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(NAVIGATION_DIRECTIONS.BACK);
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER_AUTH);
  const [deleteUser] = useMutation(graphql.mutations.DELETE_USER, {
    refetchQueries: [
      {
        query: graphql.queries.USERS,
        awaitRefetchQueries: true,
        variables: {
          where: {
            OR: [
              { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
              { meta: { type: USER_TYPES.BROKER_REPRESENTATIVE.type } },
              { meta: { type: USER_TYPES.INSURANCE_REPRESENTATIVE.type } },
              { meta: { type: USER_TYPES.INTERNAL_ADVISOR.type } },
            ],
          },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: () => {
      setVisible(false);
      navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
    },
    onError: () => {},
  });
  const onSubmit = () => {
    updateUser({
      variables: {
        where: { id },
        data: {
          phone: null,
          username: null,
          email: null,
          password: null,
        },
      },
    });
    return deleteUser({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
