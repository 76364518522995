import { useTranslation } from "react-i18next";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";

const Default = ({ onOk, onCancel, visible }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.USER);
  const DELETE = t("DELETE", { returnObjects: true });

  return (
    <Modal
      type="info"
      okText={DELETE.OK}
      cancelText={DELETE.CANCEL}
      onCancel={onCancel}
      onOk={onOk}
      message={DELETE.MESSAGE}
      visible={visible}
    />
  );
};

export default Default;
