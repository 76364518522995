import InsurancePlanCoverage from "entities/InsurancePlanCoverage";
import { useState } from "react";
import Loading from "shared/components/Spin";
import { INSURANCE_PLAN_COVERAGES } from "utils/api/graphql/queries/insurance-plan-coverage";
import View from "./View";

const Detail = ({ insurancePlanId, updateInsurancePlan }) => {
  const [loading, setLoading] = useState(false);
  const filter = { where: { insurancePlan: { id: insurancePlanId } } };

  const onSubmit = ({ insurancePlanCoverages }) => {
    setLoading(true);
    updateInsurancePlan({
      refetchQueries: [
        {
          query: INSURANCE_PLAN_COVERAGES,
          awaitRefetchQueries: true,
          variables: { ...filter, skip: null, take: null },
        },
      ],
      variables: {
        where: { id: insurancePlanId },
        data: {
          insurancePlanCoverages,
        },
        onCompleted: setLoading(false),
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <InsurancePlanCoverage
      filter={filter}
      View={View}
      onSubmit={onSubmit}
      NoData={View}
    />
  );
};

export default Detail;
