import PropTypes from "prop-types";

function ImageWithLabel({ src, label }) {
  return (
    <div className="d-flex flex--column justify--center">
      <img src={src} alt="" className="label-with-image" />
      <div>{label}</div>
    </div>
  );
}

ImageWithLabel.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
};

export default ImageWithLabel;
