import { Form, Modal, Spin, Upload } from "antd";
import { Field, FieldArray, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeft, BsTrash, BsUpload } from "react-icons/bs";
import { FiSave } from "react-icons/fi";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { GENDER, ORGANIZATION_TYPES, USER_TYPES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import * as XLSX from "xlsx";
import { validationSchema } from "./validation";

const View = ({ open, setOpen, onCancel, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const { t: tt } = useTranslation(NAME_SPACES.HOME);
  const MODAL = t("MODAL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const [fields, setFields] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const Steps = {
    UPLOAD: 1,
    FORM: 2,
  };

  const initialValues = useMemo(
    () => ({
      code: null,
      socialReason: null,
      orias: null,
      users: [
        {
          gender: null,
          firstname: null,
          lastname: null,
          phone: null,
          email: null,
          type: null,
        },
      ],
      organization: "",
    }),
    []
  );

  const handleFileUpload = ({ file }) => {
    setLoading(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const binaryStr = String.fromCharCode.apply(
        null,
        new Uint8Array(arrayBuffer)
      );
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = parsedData[0];
      const rowsData = parsedData.slice(1);

      const filteredRowsData = rowsData.filter((row) =>
        row.some((cell) => cell !== "")
      );

      const newFields = filteredRowsData.map((row) => {
        const rowData = {};
        headers.forEach((header, colIndex) => {
          rowData[header.toLowerCase()] = row[colIndex] || "";
        });
        return rowData;
      });

      setFields(newFields);
      setCurrentStep(2);
      setLoading(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const isDuplicateRow = (rowIndex, rowData, values) => {
    return values.users.slice(0, rowIndex).some((user) => {
      return Object.keys(rowData).every((key) => user[key] === rowData[key]);
    });
  };

  const handlePrev = () => {
    setCurrentStep(Steps.UPLOAD);
    clearFields();
  };

  const clearFields = () => {
    setFields([]);
  };

  useEffect(() => {
    open &&
      (() => {
        clearFields();
        setCurrentStep(Steps.UPLOAD);
      })();
  }, [open, Steps.UPLOAD]);

  const genderOptions = Object.entries(GENDER).map(([key, value]) => ({
    key,
    value,
  }));

  const userTypeOptions = Object.keys(USER_TYPES).map((key) => ({
    label: tt(`USER_TYPES.${key}`) || key,
    value: key,
  }));

  const stepComponents = {
    [Steps.UPLOAD]: (
      <div className="upload__area">
        <Upload
          accept=".xlsx, .xls"
          showUploadList={false}
          beforeUpload={(file) => {
            handleFileUpload({ file });
            return false;
          }}
        >
          <BsUpload size={40} color={COLORS.C_PRIMARY} />
          <label>{MODAL.LABEL}</label>
        </Upload>
      </div>
    ),
    [Steps.FORM]: loading ? (
      <Spin size="large" />
    ) : (
      <Formik
        initialValues={{
          ...initialValues,
          users: fields,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const jsonData = values.users.map((user) => ({
            ...user,
            organization: { id: values.organization },
          }));
          onSubmit(jsonData);
          setFields([]);
        }}
      >
        {({
          isValid,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          ...formProps
        }) => (
          <>
            <Form.Item>
              <Field name="organization">
                {() => (
                  <Select
                    type={"async"}
                    {...bindInputProps({
                      name: "organization",
                      values,
                      handleChange: formProps.handleChange,
                      setFieldTouched: formProps.setFieldTouched,
                      touched,
                      errors,
                    })}
                    onChange={(value) => setFieldValue("organization", value)}
                    label={MODAL.ORGANIZATION_LABEL}
                    node={{
                      query: graphql.queries.ORGANIZATIONS,
                      variables: {
                        where: { type: ORGANIZATION_TYPES.BROKER },
                      },
                      accessors: {
                        root: "organizations.data",
                        label: "name",
                        value: "id",
                      },
                    }}
                    errors={errors.organization}
                    touched={touched.organization}
                    placeholder={MODAL.ORGANIZATION_SELECT}
                    allowClear
                  />
                )}
              </Field>
            </Form.Item>

            <FieldArray name="users">
              {({ remove }) => (
                <div className="wrapper__inputs">
                  {values.users.length > 0 &&
                    values.users.map((user, index) => (
                      <div
                        key={index}
                        className={`wrapper__inputs--row ${
                          isDuplicateRow(index, user, values) ? "duplicate" : ""
                        }`}
                      >
                        <div className="item gender">
                          <Field name={`users.${index}.gender`}>
                            {() => (
                              <Select
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "gender",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                onChange={(value) =>
                                  setFieldValue(`users.${index}.gender`, value)
                                }
                                label={FORM.CIVILITY.LABEL}
                                options={genderOptions}
                                allowClear
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item">
                          <Field name={`users.${index}.firstname`}>
                            {() => (
                              <Input
                                label={FORM.FIRST_NAME}
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "firstname",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                errors={errors.users?.[index]?.firstname}
                                touched={touched.users?.[index]?.firstname}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item">
                          <Field name={`users.${index}.lastname`}>
                            {() => (
                              <Input
                                label={FORM.LAST_NAME}
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "lastname",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                errors={errors.users?.[index]?.lastname}
                                touched={touched.users?.[index]?.lastname}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item">
                          <Field name={`users.${index}.phone`}>
                            {() => (
                              <Input
                                label={FORM.PHONE}
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "phone",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                errors={errors.users?.[index]?.phone}
                                touched={touched.users?.[index]?.phone}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item">
                          <Field name={`users.${index}.email`}>
                            {() => (
                              <Input
                                label={FORM.EMAIL}
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "email",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                errors={errors.users?.[index]?.email}
                                touched={touched.users?.[index]?.email}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item">
                          <Field name={`users.${index}.type`}>
                            {() => (
                              <Select
                                {...bindArrayInputProps({
                                  parent: "users",
                                  index,
                                  name: "type",
                                  values,
                                  handleChange: formProps.handleChange,
                                  setFieldTouched: formProps.setFieldTouched,
                                  touched,
                                  errors,
                                })}
                                onChange={(value) =>
                                  setFieldValue(`users.${index}.type`, value)
                                }
                                label={FORM.TYPE}
                                options={userTypeOptions}
                                allowClear
                              />
                            )}
                          </Field>
                        </div>
                        <div className="item actions">
                          <Button
                            type="danger--link"
                            onClick={() => remove(index)}
                          >
                            <BsTrash size={24} />
                          </Button>
                        </div>
                      </div>
                    ))}
                  <div className="actions">
                    {currentStep === 2 && (
                      <Button
                        key="prev"
                        icon={<BsArrowLeft size={20} />}
                        type="primary--outlined"
                        onClick={handlePrev}
                      >
                        {MODAL.CLEAR}
                      </Button>
                    )}
                    <Button
                      key="done"
                      icon={<FiSave size={20} />}
                      type="primary"
                      onClick={handleSubmit}
                      disabled={!isValid}
                    >
                      {MODAL.SAVE}
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </>
        )}
      </Formik>
    ),
  };

  return (
    <Modal
      title={MODAL.TITLE}
      className="modal__xlsx"
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={onCancel}
      footer={[]}
    >
      {stepComponents[currentStep]}
    </Modal>
  );
};

export default View;
