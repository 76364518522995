import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import cuid from 'cuid';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowReturnRight } from 'react-icons/bs';
import { MdOpenInNew, MdOutlineDeleteOutline } from 'react-icons/md';
import Button from 'shared/components/Button';
import Input from 'shared/components/Input';
import Loading from 'shared/components/Spin';
import { NAME_SPACES } from 'shared/locales/constants';
import { COLORS } from 'shared/style/colors';
import { CREATE_NOTE, DELETE_NOTE, UPDATE_NOTE } from 'utils/api/graphql/mutations/notes';
import { NOTES } from 'utils/api/graphql/queries/notes';
import { displayDate, getInitials } from 'utils/helpers/date';
import * as yup from 'yup';


const formatDateWithUpdate = (createdDate, updatedDate) => {
  const formattedCreatedDate = displayDate({ date: createdDate });
  if (updatedDate && new Date(updatedDate).getTime() !== new Date(createdDate).getTime()) {
    return `${formattedCreatedDate} (Modifiée)`;
  }
  return formattedCreatedDate;
};

const Notes = ({ projectId }) => {
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const [deleteNoteId, setDeleteNoteId] = useState(null);

  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const TRANSLATION = t("NOTES", { returnObjects: true });

  const validationSchema = yup.object().shape({
    content: yup.string().required(TRANSLATION.REQUIRED_CONTENT),
  });

  const variables = {
    where: {
      project: { id: projectId },
    },
    take: 10,
    skip: 0,
  };

  const { loading, error, data, refetch } = useQuery(NOTES, {
    variables,
  });

  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted: () => {
      notification.success({
        message: TRANSLATION.NOTE_CREATED,
        closeIcon: null
      });
      refetch();
    },
  });

  const [deleteNote] = useMutation(DELETE_NOTE, {
    onCompleted: () => {
      notification.error({
        message: TRANSLATION.NOTE_DELETED,
        closeIcon: null
      });
      refetch();
    },
  });

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted: () => {
      notification.info({
        message: TRANSLATION.NOTE_UPDATED,
        closeIcon: null
      });
      refetch();
      setEditingNoteId(null);
    },
  });

  if (loading) return <Loading />;
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const notes = data?.notes?.data || [];

  const handleCreateNote = (values) => {
    createNote({
      variables: {
        data: {
          id: cuid(),
          content: values.content,
          project: { id: projectId },
        },
      },
    });
  };

  const handleUpdateNote = (id, values) => {
    updateNote({
      variables: {
        where: { id },
        data: {
          content: values.content,
        },
      },
    });
  };

  const handleDeleteNote = (id) => {
    deleteNote({
      variables: { where: { id } },
    });
  };

  const confirmDeleteNote = () => {
    deleteNote({
      variables: { where: { id: deleteNoteId } },
    });
    setDeleteNoteId(null);
  };

  return (
    <div className="notes__area">
      <Formik
        initialValues={{ content: editingNoteId ? editingContent : '' }}
        onSubmit={(values, { resetForm }) => {
          if (editingNoteId) {
            handleUpdateNote(editingNoteId, values);
          } else {
            handleCreateNote(values);
          }
          resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, handleSubmit, handleChange, values, isSubmitting }) => (
          <Form className="notes__area--add">
            <Field
              name="content"
              as={Input}
              type="textarea"
              value={values.content}
              onChange={handleChange}
              placeholder={TRANSLATION.NOTE_PLACEHOLDER}
              errors={errors.content}
              touched={touched.content}
            />
            <Button type="primary" onClick={handleSubmit} disabled={isSubmitting}>
              <BsArrowReturnRight size={20} />
            </Button>
          </Form>
        )}
      </Formik>

      <div className="notes__area--list">
        {notes.length === 0 ? (
          <div>{TRANSLATION.NO_NOTE}</div>
        ) : (
          notes.map((note) => {
            return (
              <div key={note.id} className="item">
                {editingNoteId === note.id ? (
                  <Formik
                    initialValues={{ content: editingContent }}
                    onSubmit={(values, { resetForm }) => {
                      handleUpdateNote(note.id, values);
                      resetForm();
                    }}
                    validationSchema={validationSchema}
                  >
                    {({ handleSubmit, handleChange, values, isSubmitting }) => (
                      <Form className="notes__area--add">
                        <Field
                          name="content"
                          as={Input}
                          type="textarea"
                          value={values.content}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="content" component="div" className="error" />
                        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                          <BsArrowReturnRight size={20} color={COLORS.C_PRIMARY} />
                        </Button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className="content">
                    <div className="row">
                      <div className="icon">
                        {getInitials(note.author.firstname, note.author.lastname)}
                      </div>
                      <div className="note">
                        <p>{note.content}</p>
                        <small>
                          {TRANSLATION.WITH} <span>{note.author.firstname} {note.author.lastname}</span> {TRANSLATION.AT} {formatDateWithUpdate(note.createdDate, note.updatedDate)}
                        </small>
                      </div>
                    </div>
                    <div className="actions">
                      <div className="btn" onClick={() => {
                        setEditingNoteId(note.id);
                        setEditingContent(note.content);
                      }}>
                        <MdOpenInNew size={20} color={COLORS.C_PRIMARY} />
                      </div>
                      <div className="btn" onClick={() => handleDeleteNote(note.id)}>
                        <MdOutlineDeleteOutline size={20} color={COLORS.C_DANGER} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )

          })
        )}
      </div>
    </div >
  );
};

export default Notes;
