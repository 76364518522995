import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    label: yup.string().typeError(errors.REQUIRED).required(errors.REQUIRED),
    value: yup.string().typeError(errors.REQUIRED).required(errors.REQUIRED),
  });
};

export default validation;
