import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { images } from "screens/Public/Processes/utils/contants";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { INCLUDED_BENEFICIARIES } from "utils/constants";
import ImageWithLabel from "../components/ImageWithLabel";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, includedBeneficiaries }) => {
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState(
    includedBeneficiaries
  );

  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.BENEFICIARIES.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.BENEFICIARIES.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Radio
          value={selectedBeneficiaries}
          size={2}
          values={[
            {
              label: (
                <ImageWithLabel
                  src={images.insured}
                  label={TRANSLATION.BENEFICIARIES.BENEFICIARY_OPTIONS.YOU}
                />
              ),
              value: INCLUDED_BENEFICIARIES.ALONE,
            },
            {
              label: (
                <ImageWithLabel
                  src={images.insured_and_spouse}
                  label={
                    TRANSLATION.BENEFICIARIES.BENEFICIARY_OPTIONS.WITH_SPOUSE
                  }
                />
              ),
              value: INCLUDED_BENEFICIARIES.WITH_SPOUSE,
            },
            {
              label: (
                <ImageWithLabel
                  src={images.insured_and_children}
                  label={
                    TRANSLATION.BENEFICIARIES.BENEFICIARY_OPTIONS.WITH_CHILDREN
                  }
                />
              ),
              value: INCLUDED_BENEFICIARIES.WITH_CHILDREN,
            },
            {
              label: (
                <ImageWithLabel
                  src={images.all_family}
                  label={TRANSLATION.BENEFICIARIES.BENEFICIARY_OPTIONS.ALL}
                />
              ),
              value: INCLUDED_BENEFICIARIES.ALL,
            },
          ]}
          onChange={setSelectedBeneficiaries}
        />
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={() => onNext(selectedBeneficiaries)}
          disabled={!selectedBeneficiaries}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
