import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import { MdAlternateEmail, MdOutlineBadge, MdPhone } from "react-icons/md";
import Radio from "shared/components/Form/Radio";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Employee = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  return (
    <>
      <div className="wrapper--forms">
        <Radio
          isHorizontal={false}
          label={FORM.CIVILITY}
          options={[
            {
              label: FORM.MALE,
              value: GENDER.MALE,
            },
            {
              label: FORM.FEMALE,
              value: GENDER.FEMALE,
            },
          ]}
          icon={<BiUserCircle size={14} />}
          {...bindInputProps({ name: "gender", ...formProps })}
        />
        <Input
          placeholder={FORM.FIRSTNAME}
          label={FORM.FIRSTNAME}
          icon={<MdOutlineBadge size={14} />}
          {...bindInputProps({ name: "firstname", ...formProps })}
        />
        <Input
          placeholder={FORM.LASTNAME}
          label={FORM.LASTNAME}
          icon={<MdOutlineBadge size={14} />}
          {...bindInputProps({ name: "lastname", ...formProps })}
        />
        <Input
          placeholder={FORM.EMAIL}
          label={FORM.EMAIL}
          icon={<MdAlternateEmail size={14} />}
          {...bindInputProps({ name: "email", ...formProps })}
        />
        <Input
          placeholder={FORM.PHONE}
          label={FORM.PHONE}
          icon={<MdPhone size={14} />}
          {...bindInputProps({ name: "phone", ...formProps })}
        />
      </div>
    </>
  );
};

export default Employee;
