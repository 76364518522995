import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { useState } from "react";
import { useLazyQuery } from "shared/hooks/useApi";
import { ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const AdherentTelecoms = ({ project, onNext, onBack }) => {
  const [addressZipCodeCity] = useLazyQuery(ADDRESS_ZIP_CODE_CITY);
  const [city, setCity] = useState({
    id: cuid(),
    system: CONTACT_POINT_SYSTEM.CITY,
    value: null,
  });

  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
    [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
    [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
  });
  const next = (values) => {
    const telecoms = map(values, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          telecoms: [...telecoms, city],
        },
      },
    });
  };
  return (
    <View
      onNext={next}
      telecoms={telecoms}
      onBack={onBack}
      addressZipCodeCity={addressZipCodeCity}
      setCity={setCity}
    />
  );
};

export default AdherentTelecoms;
