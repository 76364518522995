import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, size }) => {
  const baseClass = "container";
  const className = size ? `${baseClass}--${size}` : baseClass;

  return <div className={className}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["medium"]),
};

Container.defaultProps = {};

export default Container;
