import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  SOCIAL_REGIMES,
} from "utils/constants";
import { hundredYearsAgoStart, isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Radio from "../Components/Radio";
import Stepper from "../Components/Stepper";
import validation from "./validation";

const View = ({
  onNext,
  franceAddressZipCodeCity,
  contact,
  fields,
  locked,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: { contact, fields },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { franceAddressZipCodeCity }
    ),
  });
  return (
    <Container size="medium">
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: false },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: false },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />
        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.ADHERENT_INFO.TITLE_R.MAIN}{" "}
            <span>{TRANSLATION.ADHERENT_INFO.TITLE_R.COMPLEMENT}</span>
          </div>
        </div>
        <div className="b2c__area--content adherent-info">
          <div className="grid--1">
            <Radio
              disabled={locked}
              label={TRANSLATION.ADHERENT_INFO.SELECT_REGIME}
              size={2}
              values={[
                {
                  label: TRANSLATION.SOCIAL_REGIMES.GENERAL_REGIME,
                  value: SOCIAL_REGIMES.SECURITE_SOCIALE,
                  subLabel: TRANSLATION.ADHERENT_INFO.MICRO,
                },
                {
                  label: TRANSLATION.SOCIAL_REGIMES.SELF_EMPLOYED_REGIME,
                  value: SOCIAL_REGIMES.SECURITE_SOCIALE_INDEPENDANTS,
                  subLabel: TRANSLATION.ADHERENT_INFO.HORS_MICRO,
                },
                {
                  label:
                    TRANSLATION.SOCIAL_REGIMES.AGRICULTURAL_EMPLOYEES_REGIME,
                  value: SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES,
                },
                {
                  label: TRANSLATION.SOCIAL_REGIMES.ALSACE_MOSELLE_REGIME,
                  value: SOCIAL_REGIMES.ALSACE_MOSELLE,
                },
              ]}
              {...bindInputProps({
                name: "contact.socialRegime",
                values,
                ...formProps,
              })}
              onChange={(value) => {
                formProps.setFieldValue("contact.socialRegime", value);
                formProps.setFieldValue("fields.info.cssBeneficiary", false);
              }}
            />
          </div>
          {get(values, "contact.socialRegime") ===
            SOCIAL_REGIMES.SECURITE_SOCIALE && (
            <div className="d-flex justify--center">
              <Input
                type="checkbox"
                label={TRANSLATION.ADHERENT_INFO.CSS}
                checked={get(values, "fields.info.cssBeneficiary", false)}
                {...bindInputProps({
                  name: "fields.info.cssBeneficiary",
                  values,
                  ...formProps,
                })}
                disabled={locked}
              />
            </div>
          )}
          <div className="divider" />
          <div className="grid--2">
            <Input
              label={TRANSLATION.ADHERENT_INFO.BIRTH_DATE}
              placeholder={TRANSLATION.ADHERENT_INFO.SELECT_DATE}
              type="date"
              variant="simple"
              format={DATE_FORMAT}
              disabledDate={(current) =>
                current > isMajor || current < hundredYearsAgoStart
              }
              defaultPickerValue={
                !get(values, "contact.user.birthDate") &&
                dayjs().subtract(30, "years")
              }
              {...bindDateInputProps({
                name: "contact.user.birthDate",
                values,
                ...formProps,
              })}
              disabled={locked}
            />
            <Input
              label={TRANSLATION.ADHERENT_INFO.ZIP_CODE}
              placeholder={TRANSLATION.ADHERENT_INFO.ZIP_CODE}
              inputMode="numeric"
              {...bindInputProps({
                name: `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                values,
                ...formProps,
              })}
              onChange={async ({ target: { value } }) => {
                formProps.setFieldValue(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                  value.toString()
                );
                if (value?.length >= 2)
                  formProps.setFieldValue(
                    "contact.fields.department",
                    value.toString().substring(0, 2)
                  );

                await formProps.validateForm();
                formProps.setFieldTouched(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                );
              }}
              disabled={locked}
            />
          </div>
        </div>
        <div className="b2c__area--actions justify--end">
          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            disabled={!isEmpty(formProps.errors) || formProps.isSubmitting}
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
