import * as Yup from "yup";

const phoneRegExp =
  /^(\+\d{1,3}[- ]?)?\d{10}$|^\(\d{3}\) \d{3}-\d{4}$|^\d{3}-\d{3}-\d{4}$|^(\d{2} ){4}\d{2}$/;

export const validationSchema = Yup.object().shape({
  code: Yup.string().nullable(),
  socialReason: Yup.string().nullable(),
  orias: Yup.string().nullable(),
  organization: Yup.string().required("Organization is required"),
  users: Yup.array()
    .of(
      Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        phone: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .required("Phone is required"),
      })
    )
    .required("Users are required"),
});
