import { useState } from "react";

import { Radio } from "antd";
import PropTypes from "prop-types";
import Label from "shared/components/Label";
import Popover from "shared/components/Popover";

const Component = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  optional,
  direction,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = (e) => {
    setCheckedValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div className={`radio-group__wrapper ${direction}`}>
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`radio-group__wrapper--field`}>
        <Radio.Group
          onChange={onSelect}
          value={checkedValue}
          defaultValue={value}
          optionType="button"
          className={"custom"}
          {...rest}
        >
          {values.map(({ label, value: _value, popover }) => (
            <Radio
              value={_value}
              className={`custom--item  ${
                _value === checkedValue && "checked"
              }`}
              key={`radio-${_value}`}
            >
              {popover && <Popover text={popover} />}
              <p className={"custom--label"}>{label}</p>
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

Component.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
};

Component.defaultProps = {
  onChange: () => {},
  values: [],
};
export default Component;
