import { Col, Row } from "antd";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdNumbers,
  MdOutlineAddRoad,
  MdOutlinePinDrop,
  MdOutlineRestartAlt,
  MdOutlineSouthAmerica,
  MdOutlineSubtitles,
} from "react-icons/md";
import { STEPPER_KEYS } from "screens/Private/Broker/Subscription/Detail";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { ADDRESS_PATH_TYPES } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM, REPETITION_INDEX } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Contact = ({
  initialValues,
  paymentBlock,
  setPaymentBlock,
  translate,
  isFieldRequired,
  onAddressSave,
}) => {
  const [options, setOptions] = useState([]);
  const { data: list } = useQuery(ADDRESS_PATH_TYPES, {
    variables: { where: {} },
    onCompleted: (data) => setOptions(get(data, "addressPathTypes.response")),
  });
  const handleSearch = (value) => {
    const filteredOptions = get(list, "addressPathTypes.response", []).filter(
      (option) => option.label.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues: initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION],
      onSubmit: onAddressSave,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  return (
    <>
      <span className="collaped_container--child_title">
        {translate.ADDITIONAL_INFORMATION}
        {paymentBlock && (
          <Button
            className="danger--underline"
            onClick={() => setPaymentBlock(false)}
          >
            {translate.EDIT}
          </Button>
        )}
      </span>
      <div className="collaped_container--child_content">
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              label={translate.NUMBER.LABEL}
              icon={<MdNumbers size={16} />}
              direction="row"
              placeholder={translate.NUMBER.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
                values,
                ...formProps,
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              options={REPETITION_INDEX.map((index) => ({
                value: index,
                label: index,
              }))}
              direction="row"
              icon={<MdOutlineRestartAlt size={16} />}
              label={translate.REPETITION_INDEX.LABEL}
              placeholder={translate.REPETITION_INDEX.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                values,
                ...formProps,
              })}
              onChange={(value) =>
                setFieldValue(
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                  value || null
                )
              }
              allowClear
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              label={translate.TYPE.LABEL}
              placeholder={translate.TYPE.PLACEHOLDER}
              onSearch={handleSearch}
              direction="row"
              showSearch
              icon={<MdOutlineAddRoad size={16} />}
              options={options}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
                values,
                ...formProps,
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label="Adresse"
              icon={<MdOutlineAddRoad size={16} />}
              direction="row"
              placeholder={translate.STREET.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`,
                values,
                ...formProps,
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={translate.COMPLEMENT.LABEL}
              direction="row"
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={translate.COMPLEMENT.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                values,
                ...formProps,
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={translate.POSTAL_CODE.LABEL}
              direction={"row"}
              icon={<MdOutlinePinDrop size={16} />}
              placeholder={translate.POSTAL_CODE.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                values,
                ...formProps,
              })}
              disabled
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              icon={<MdOutlineSouthAmerica size={16} />}
              label={translate.CITY.LABEL}
              direction={"row"}
              placeholder={translate.CITY.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                values,
                ...formProps,
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
              )}
              disabled
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              direction="row"
              label={translate.SOCIAL_SECURITY_NUMBER.LABEL}
              icon={<MdOutlineSubtitles size={16} />}
              placeholder={translate.SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
              {...bindInputProps({
                name: "project.contact.socialSecurityNumber",
                values,
                ...formProps,
              })}
              required={isFieldRequired("project.contact.socialSecurityNumber")}
            />
          </Col>
        </Row>
      </div>
      <div className="collaped_container--actions">
        <Button
          className="collaped_container--actions__next"
          disabled={!isEmpty(formProps.errors)}
          onClick={() => handleSubmit()}
        >
          {translate.SAVE}
        </Button>
      </div>
    </>
  );
};

export default Contact;
