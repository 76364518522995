import { gql } from "@apollo/client";

export const PROCESS_STATUSES = gql`
  query ProcessStatuses($where: ProcessStatusWhereInput, $isIn: JSONObject) {
    processStatuses(where: $where, isIn: $isIn) {
      data {
        id
        name
        tag
        process {
          id
        }
        actions {
          id
          type
          args
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
      }
      count
    }
  }
`;

export const PROCESS_STATUS = gql`
  query ProcessStatus($where: ProcessStatusWhereInput) {
    processStatus(where: $where) {
      id
      name
      tag
    }
  }
`;

const processStatuses = { PROCESS_STATUSES, PROCESS_STATUS };

export default processStatuses;
