import { TEMPLATE_TYPES } from "utils/constants";
import DEFAULT from "./Default";
import RADIANCE from "./Radiance";
import RADIANCE_COSA from "./RadianceCosa";

const Templates = {
  [TEMPLATE_TYPES.DEFAULT]: DEFAULT,
  [TEMPLATE_TYPES.RADIANCE]: RADIANCE,
  [TEMPLATE_TYPES.RADIANCE_COSA]: RADIANCE_COSA,
};
export default Templates;
