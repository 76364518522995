import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";

import cuid from "cuid";
import { defaultsDeep, find, get, isEmpty } from "lodash";
import Card from "shared/components/Card";
import Icon from "shared/components/Icon";
import Input from "shared/components/Input";
import {
  ActionStepper,
  ProductCard,
} from "shared/components/SmartphoneWidgets";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Contact = ({ onNext, onBack, project }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", {
    returnObjects: true,
  });
  const TERMINAL_CONTACT = t("DETAIL.TEMPLATES.TERMINAL_CONTACT", {
    returnObjects: true,
  });

  const onSubmit = ({ contact, phoneNumber }) => {
    onNext({
      payload: {
        contact: {
          ...contact,
          telecoms: [
            ...contact.telecoms.filter(
              ({ system }) => system !== CONTACT_POINT_SYSTEM.PHONE
            ),
            ...(phoneNumber.value ? [phoneNumber] : []),
          ],
        },
      },
    });
  };

  return (
    <>
      <Header key={"header"} title={DETAIL.TITLE} />
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          contact: defaultsDeep(get(project, "contact", {}), {
            id: cuid(),
            user: { id: cuid(), firstname: null, lastname: null },
            telecoms: [],
          }),
          phoneNumber: find(get(project, "contact.telecoms", []), {
            system: CONTACT_POINT_SYSTEM.PHONE,
          }) || { id: cuid(), value: null, system: CONTACT_POINT_SYSTEM.PHONE },
        }}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ handleSubmit, ...formProps }) => [
          <Card
            key="card"
            type={"custom"}
            styleType={"bordered"}
            size={"medium"}
            head
            title={TERMINAL_CONTACT.TITLE}
            subtitle={TERMINAL_CONTACT.SUBTITLE}
          >
            <div className="m-top-24" />
            <h1 className="title">
              <span>{TERMINAL_CONTACT.TERMINAL_INFO}</span>
            </h1>
            <div className="m-top-8" />
            <Card type={"custom"} styleType={"shadow"}>
              <div className="grid--1">
                <ProductCard
                  image="https://via.placeholder.com/150"
                  name={get(project, "fields.terminal.model", "")}
                  price={`${get(
                    project,
                    "fields.terminal.purchasePrice",
                    ""
                  )} XPF`}
                  onModify={onBack}
                />
              </div>
            </Card>
            <div className="m-top-24" />
            <h1 className="title">
              <span>{TERMINAL_CONTACT.ADHERENT}</span>
            </h1>
            <p className="subtitle">{TERMINAL_CONTACT.ADHERENT_COORDINATES}</p>
            <div className="m-top-8" />
            <Card type={"custom"} styleType={"shadow"}>
              <div className="grid--1">
                <Input
                  label={TERMINAL_CONTACT.LAST_NAME}
                  placeholder={TERMINAL_CONTACT.LAST_NAME}
                  icon={<Icon size={16} name="account_circle" />}
                  required
                  {...bindInputProps({
                    name: "contact.user.lastname",
                    ...formProps,
                  })}
                />
                <Input
                  label={TERMINAL_CONTACT.FIRST_NAME}
                  placeholder={TERMINAL_CONTACT.FIRST_NAME}
                  icon={<Icon size={16} name="phone_android" />}
                  required
                  {...bindInputProps({
                    name: "contact.user.firstname",
                    ...formProps,
                  })}
                />
                <Input
                  label={TERMINAL_CONTACT.VINI_NUMBER}
                  placeholder={TERMINAL_CONTACT.VINI_NUMBER}
                  icon={<Icon size={16} name="phone_android" />}
                  {...bindInputProps({
                    name: "phoneNumber.value",
                    ...formProps,
                  })}
                />
              </div>
            </Card>
          </Card>,
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              onPrevious={onBack}
              onNext={handleSubmit}
              isNextDisabled={!isEmpty(formProps.errors)}
            />
          </Card>,
        ]}
      </Formik>
    </>
  );
};

export default Contact;
