import { Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS, USER_TYPES } from "utils/constants";
import Advisor from "./UserTypes/Advisor";
import Default from "./UserTypes/Default";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.USER);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const generateUserForm = (formProps, setFieldValue) => ({
    [true]: (
      <Advisor
        key="advisor"
        formProps={formProps}
        setFieldValue={setFieldValue}
      />
    ),
    [false]: (
      <Default
        key="default"
        formProps={formProps}
        setFieldValue={setFieldValue}
      />
    ),
  });
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.USERS.DELETE.path}/${data.id}`,
                  { replace: true }
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
              disabled: !formProps.dirty,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          {
            generateUserForm(formProps)[
              get(formProps, "values.meta.type") ===
                USER_TYPES.INTERNAL_ADVISOR.type
            ]
          }
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
