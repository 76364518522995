import { useTranslation } from "react-i18next";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";

const Default = ({ onOk, onCancel, visible }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.USER);
  const DEACTIVATE = t("DEACTIVATE", { returnObjects: true });

  return (
    <Modal
      type="info"
      okText={DEACTIVATE.OK}
      cancelText={DEACTIVATE.CANCEL}
      onCancel={onCancel}
      onOk={onOk}
      message={DEACTIVATE.MESSAGE}
      visible={visible}
    />
  );
};

export default Default;
