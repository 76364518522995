import { notification } from "antd";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import {
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import View from "./View";

const SubscriptionContract = ({ onNext, onBack, project }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const [loading, setLoading] = useState(true);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: get(project, "fields.currentSubscription") } },
    onCompleted: () => setLoading(false),
  });
  const [signDocuments] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: {
          where: { id: get(data, "subscription.id") },
        },
      },
    ],
  });
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const filter = {
    where: {
      OR: [
        {
          AND: [
            {
              project: null,
            },
            {
              insurancePlans: {
                id: get(data, "insurancePlan.id", ""),
              },
            },
          ],
        },
        {
          subscription: {
            id: get(data, "id"),
          },
        },
        {
          AND: [
            {
              project: {
                id: get(data, "project.id"),
              },
            },
            {
              insurancePlans: {
                id: get(data, "insurancePlan.id", ""),
              },
            },
          ],
        },
      ],
    },
    isIn: {
      type: [
        ...Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES),
        DOCUMENTS_TYPES.QUOTE,
      ],
    },
  };
  const { data: attachmentsList } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: filter,
  });

  if (loading) return <Loading />;

  const signDocument = async () => {
    if (
      [SUBSCRIPTION_STATUSES.DRAFT, SUBSCRIPTION_STATUSES.IN_PROGRESS].includes(
        get(data, "subscription.status")
      )
    )
      return onNext({
        payload: {},
      });
    setLoading(true);
    await signDocuments({
      variables: {
        data: {
          id: get(data, "subscription.id"),
        },
      },
      onCompleted: () => {
        notification.open({
          message: TRANSLATION.E_SIGNATURE_SENT,
          duration: 5,
          type: "success",
        });
        setLoading(false);
        onNext({
          payload: {},
        });
      },
    });
  };

  const download = async (file) => {
    const { id, generate } = file;
    if (generate) return file.onClick();

    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };

  return (
    <View
      onBack={onBack}
      signDocuments={signDocument}
      subscription={get(data, "subscription")}
      attachments={get(attachmentsList, "attachments.data", [])}
      download={download}
      translate={TRANSLATION}
    />
  );
};

export default SubscriptionContract;
