import { Alert, Popover, notification } from "antd";
import cuid from "cuid";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdReportGmailerrorred, MdSend } from "react-icons/md";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, DATE_FORMAT, REGEX } from "utils/constants";
import Attachments from "./widgets/Attachments";
import EmailTemplate from "./widgets/EmailTemplate";

const DUMMY_ICON = "https://icon-library.com/images/icon-url/icon-url-15.jpg";

const View = ({
  project,
  onNext,
  loading,
  attachments,
  setAttachments,
  onBack,
  data: costs,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [telecoms, setTelecoms] = useState(
    get(project, "contact.telecoms", [])
      .filter((telecom) => telecom.system === CONTACT_POINT_SYSTEM.EMAIL)
      .map((telecom) => ({
        label: telecom.value,
        value: telecom.id,
      }))
  );

  const unlinkAttachment = (id) =>
    setAttachments((prev) => [...prev.filter((item) => item.id !== id)]);

  const onAddEmail = (value) => {
    if (REGEX.EMAIL.test(value)) {
      setTelecoms([...telecoms, { label: value, value: cuid() }]);
      value = "";
    } else {
      notification.error({
        message: DETAIL.TEMPLATES.QUOTE.MAIL.INVALID_EMAIL,
      });
    }
  };

  const onDeleteEmail = (email) => {
    setTelecoms(telecoms.filter(({ value }) => value !== email));
  };

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.QUOTE.TITLE}
        actions={[]}
      />
      <div className="quote-template">
        <Alert
          type="warning"
          showIcon
          icon={<MdReportGmailerrorred size={16} />}
          message={DETAIL.TEMPLATES.QUOTE.WARNING}
        />
        <div className="quote-template__mail">
          <div className="quote-template__mail-header">
            <Select
              label={DETAIL.TEMPLATES.QUOTE.MAIL.TO}
              mode="tags"
              maxTagCount="responsive"
              value={telecoms}
              options={telecoms}
              onSelect={onAddEmail}
              onDeselect={onDeleteEmail}
            />
            <Input
              label={DETAIL.TEMPLATES.QUOTE.MAIL.SUBJECT.label}
              value={DETAIL.TEMPLATES.QUOTE.MAIL.SUBJECT.text}
              disabled
            />
          </div>
          <div className="quote-template__mail-label">
            {DETAIL.TEMPLATES.QUOTE.MAIL.MESSAGE}
          </div>
          <EmailTemplate
            translation={DETAIL.TEMPLATES.QUOTE.MAIL.RADIANCE}
            civility={get(project, "contact.user.gender", "")}
            firstName={get(project, "contact.user.firstname", "")}
            lastName={get(project, "contact.user.lastname", "")}
            currentDate={moment().format(DATE_FORMAT)}
            endOfValidityDate={
              moment().format("MM") === "12"
                ? moment().endOf("M").format(DATE_FORMAT)
                : moment().add(30, "d").format(DATE_FORMAT)
            }
            adminPhoneNumber={get(project, "author.phone", "")}
            adminEmail={get(project, "author.email", "")}
            adminFirstName={get(project, "author.firstname", "")}
            adminLastName={get(project, "author.lastname", "")}
            adminAddress={[
              get(project, "author.address", ""),
              get(project, "author.zipCode", ""),
              get(project, "author.city", ""),
            ].join(" ")}
            costs={costs}
            addLinks={get(project, "fields.addLinks")}
          />
        </div>
        <Attachments
          unlinkAttachment={unlinkAttachment}
          loading={loading}
          list={attachments}
        />
        <div className="d-flex justify--between">
          <Button onClick={onBack} type="secondary--link">
            <MdArrowBackIos size={16} />
            {DETAIL.TEMPLATES.COMPARISON.PREVIOUS}
          </Button>
          <div className="popover-wrapper">
            {isEmpty(telecoms) ? (
              <Popover content={DETAIL.TEMPLATES.QUOTE.POPOVER}>
                <Button type="secondary" disabled={true}>
                  {DETAIL.TEMPLATES.QUOTE.SEND}
                  <MdSend size={16} />
                </Button>
              </Popover>
            ) : (
              <Button
                onClick={() => onNext(telecoms)}
                type="secondary"
                disabled={project.expired}
              >
                {DETAIL.TEMPLATES.QUOTE.SEND}
                <MdSend size={16} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
