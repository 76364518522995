import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import View from "./View";

const routes = [
  {
    path: SCREENS.PRIVATE.BROKER.DASHBOARD.path,
    exact: true,
    element: (
      <View />
    ),
  },
];

export default routes;
