import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';

const PricingItem = ({
  title,
  description,
  price,
  monthlyPrice,
  variant = 'compact',
  onLearnMore,
}) => {
  return (
    <div className={`pricing__item pricing__item--${variant}`}>
      <div className="--header">
        <div className='--top'>
          <h3 className="--title">{title}</h3>
          {variant === 'detailed' && (
            <Button type="primary--underline" onClick={onLearnMore} className="btn--modify">
              En savoir plus
            </Button>
          )}
        </div>
        {description && (
          <p className="--description">{description}</p>
        )}
      </div>

      <div className="--price">
        <span className="main">{price}</span>
        <span className="sub">
          Par mois, soit {monthlyPrice} par an
        </span>
      </div>
    </div>
  );
};

PricingItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['compact', 'detailed', 'highlighted']),
  onLearnMore: PropTypes.func,
};

export default PricingItem;
