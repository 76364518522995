import cuid from "cuid";
import { Formik } from "formik";
import { get, isEmpty, keyBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ATTACHMENT_TYPES,
  CONTACT_POINT_SYSTEM,
  ORGANIZATION_TYPES,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import IframeGenerator from "./iframe";
import validation from "./validation";
import UsersTable from "./Widgets/UserList";

const Detail = ({
  onSubmit,
  data,
  distributions,
  cancel,
  onDelete,
  acceptSignUp,
  updateParams,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  // const [insurancePlans, setInsurancePlans] = useState([]);
  // useQuery(INSURANCE_PLANS, {
  //   variables: { where: { internal: false } },
  //   onCompleted: ({ insurancePlans: { data } }) => {
  //     setInsurancePlans(
  //       data.map(({ id, name, commissions }) => ({
  //         value: id,
  //         label: name,
  //         commissions,
  //       }))
  //     );
  //   },
  // });

  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const COMPANY_FORM_OPTIONS = t("COMPANY_FORM_OPTIONS", {
    returnObjects: true,
  });
  const telecoms = keyBy(get(data, "organization.telecoms"), "system");

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("organization.icon", file.base64);
  };

  return (
    <Formik
      initialValues={{
        ...data,
        telecoms,
        // distributions: distributions?.map(({ commissions, ...rest }) => ({
        //   ...rest,
        //   commissions: commissions?.map(({ id }) => id),
        // })),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: onDelete,
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <div key="organization">
          <Card type={"simple"} head title={FORM.ADMIN_INFO}>
            <div className="grid--2">
              {/* <Input
                placeholder={FORM.BROKER_CODE}
                label={FORM.BROKER_CODE}
                {...bindInputProps({ name: "code", ...formProps })}
                required
              /> */}
              <Input
                placeholder={FORM.SOCIAL_REASON}
                label={FORM.SOCIAL_REASON}
                {...bindInputProps({ name: "socialReason", ...formProps })}
                required
              />
              <Input
                placeholder={FORM.BUSINESS_NAME}
                label={FORM.BUSINESS_NAME}
                {...bindInputProps({ name: "organization.name", ...formProps })}
                required
              />
              <Select
                label={FORM.CATEGORY.LABEL}
                options={Object.values(ORGANIZATION_TYPES).map((value) => ({
                  value,
                  label: FORM.CATEGORY.OPTIONS[value],
                }))}
                {...bindInputProps({ name: "organization.type", ...formProps })}
                disabled
              />
              {/* <Input
                placeholder={FORM.ORIAS_CODE}
                label={FORM.ORIAS_CODE}
                disabled={true}
                {...bindInputProps({ name: "orias", ...formProps })}
                required
              /> */}
              {/* <Select
                label={FORM.COMPANY_FORM}
                placeholder={FORM.COMPANY_FORM}
                options={Object.values(ORGANIZATION_FORMS).map((value) => ({
                  value,
                  label: COMPANY_FORM_OPTIONS[value],
                }))}
                {...bindInputProps({ name: "organization.form", ...formProps })}
                required
              /> */}
              <Input
                placeholder={FORM.ORGANIZATION_PHONE}
                label={FORM.ORGANIZATION_PHONE}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.ORGANIZATION_EMAIL}
                label={FORM.ORGANIZATION_EMAIL}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.POSTAL_ADDRESS}
                label={FORM.POSTAL_ADDRESS}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.ZIP_CODE}
                label={FORM.ZIP_CODE}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.CITY}
                label={FORM.CITY}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                  ...formProps,
                })}
              />
              {/* <Input
                placeholder={FORM.RCS_NUMBER}
                label={FORM.RCS_NUMBER}
                {...bindInputProps({ name: "rcsNumber", ...formProps })}
              />
              <Input
                type="textarea"
                placeholder={FORM.RCS_PLACE}
                label={FORM.RCS_PLACE}
                {...bindInputProps({ name: "rcsPlace", ...formProps })}
              /> */}
              <Input
                placeholder={FORM.SIREN_NUMBER}
                label={FORM.SIREN_NUMBER}
                {...bindInputProps({ name: "sirenNumber", ...formProps })}
              />
              <Input
                type="file"
                variant="uploader"
                listType="picture"
                showUploadList={false}
                maxCount={1}
                onUpload={([file]) => handleFileUpload(file, setFieldValue)}
                fileList={
                  isEmpty(get(formProps, "values.organization.icon", []))
                    ? []
                    : [get(formProps, "values.organization.icon")]
                }
              >
                <Button type="primary--link">{FORM.ICON}</Button>
                <div>
                  {get(formProps, "values.organization.icon") && (
                    <img
                      className="icon"
                      alt="icon"
                      src={get(formProps, "values.organization.icon")}
                    />
                  )}
                </div>
              </Input>
            </div>
          </Card>
        </div>,
        <div key="divider-div" className="m-bottom-24" />,
        // <div
        //   key="capital"
        //   className={classNames({
        //     hidden:
        //       get(formProps, "values.user.meta.type") ===
        //       USER_TYPES.INTERNAL_SUPERVISOR.type,
        //   })}
        // >
        //   <Card type={"simple"} head title={FORM.CAPITAL_DEPENDENCE}>
        //     <div className="grid--1">
        //       <div className="grid--1 m-bottom-24">
        //         <h4>{FORM.REMUNERATION}</h4>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.BASED_ON_FEES}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.basedOnFees"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.basedOnFees", e)
        //             }
        //           />
        //         </div>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.BASE_ON_COMMISSION}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.basedOnCommission"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.basedOnCommission", e)
        //             }
        //           />
        //         </div>
        //       </div>
        //       <div className="grid--1 m-bottom-24">
        //         <h4>{FORM.FINANCIAL_LINKS}</h4>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.CAPITAL_PLUS_TEN}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.capitalPlusTen"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.capitalPlusTen", e)
        //             }
        //           />
        //         </div>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.FIRM_PLUS_TEN}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.firmPlusTen"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.firmPlusTen", e)
        //             }
        //           />
        //         </div>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.LAST_EXERCISE}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.lastExercise"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.lastExercise", e)
        //             }
        //           />
        //         </div>
        //       </div>
        //       <div className="grid--1">
        //         <h4>{FORM.EXCLUSIVE_LINKS}</h4>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.CONTRACTUAL_OBLIGATION}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.contractualObligation"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.contractualObligation", e)
        //             }
        //           />
        //         </div>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.ANALYSIS_MARKET_CONTRACTS}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.analysisMarketContracts"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue("additionalInfo.analysisMarketContracts", e)
        //             }
        //           />
        //         </div>
        //         <div className="d-flex justify--between">
        //           <span>{FORM.RECOMMENDATION_HEALTH_INSURANCE}</span>
        //           <Switch
        //             className="button__switch"
        //             checkedChildren={FORM.OPTIONS.YES}
        //             unCheckedChildren={FORM.OPTIONS.NO}
        //             defaultChecked={get(
        //               formProps.values,
        //               "additionalInfo.recommendationHealthInsurance"
        //             )}
        //             onChange={(e, _) =>
        //               setFieldValue(
        //                 "additionalInfo.recommendationHealthInsurance",
        //                 e
        //               )
        //             }
        //           />
        //         </div>
        //       </div>
        //     </div>
        //   </Card>
        // </div>,
        <div key="org-div" className="m-bottom-24" />,
        // <div
        //   key="user"
        //   className={classNames({
        //     hidden:
        //       get(formProps, "values.user.meta.type") ===
        //       USER_TYPES.INTERNAL_SUPERVISOR.type,
        //   })}
        // >
        //   <Card type={"simple"} head title={FORM.LEGAL_INFO}>
        //     <div className="grid--2">
        //       <Radio
        //         isHorizontal={false}
        //         label={FORM.CIVILITY.LABEL}
        //         options={[
        //           {
        //             label: FORM.CIVILITY.MALE,
        //             value: GENDER.MALE,
        //           },
        //           {
        //             label: FORM.CIVILITY.FEMALE,
        //             value: GENDER.FEMALE,
        //           },
        //         ]}
        //         {...bindInputProps({ name: "user.gender", ...formProps })}
        //         required
        //       />
        //       <Input
        //         placeholder={FORM.FIRST_NAME}
        //         label={FORM.FIRST_NAME}
        //         {...bindInputProps({ name: "user.firstname", ...formProps })}
        //         required
        //       />
        //       <Input
        //         placeholder={FORM.LAST_NAME}
        //         label={FORM.LAST_NAME}
        //         {...bindInputProps({ name: "user.lastname", ...formProps })}
        //         required
        //       />
        //       <Input
        //         placeholder={FORM.PHONE}
        //         label={FORM.PHONE}
        //         {...bindInputProps({ name: "user.phone", ...formProps })}
        //         required
        //       />
        //       <Input
        //         placeholder={FORM.EMAIL}
        //         label={FORM.EMAIL}
        //         {...bindInputProps({ name: "user.email", ...formProps })}
        //         required
        //       />
        //     </div>
        //   </Card>
        //   <div key="user-div" className="m-bottom-24" />
        // </div>,
        // <div
        //   key="distribution"
        //   className={classNames({
        //     hidden:
        //       get(formProps, "values.user.meta.type") ===
        //       USER_TYPES.INTERNAL_SUPERVISOR.type,
        //   })}
        // >
        //   <Card title={FORM.DISTRIBUTION}>
        //     <FieldArray
        //       name={"distributions"}
        //       render={(arrayHelpers) => [
        //         get(formProps, "values.distributions", []).map((_, i) => (
        //           <div className="d-flex flex--wrap" key={_.id}>
        //             <div className="grid--3 flex--grow-1 m-bottom-16">
        //               <Select
        //                 options={insurancePlans.filter(
        //                   (plan) =>
        //                     !get(formProps, "values.distributions", []).some(
        //                       (distribution, index) =>
        //                         index !== i &&
        //                         distribution.insurancePlan &&
        //                         distribution.insurancePlan.id === plan.value
        //                     )
        //                 )}
        //                 placeholder={FORM.INSURANCE_PLAN}
        //                 label={FORM.INSURANCE_PLAN}
        //                 {...bindArrayInputProps({
        //                   parent: "distributions",
        //                   index: i,
        //                   name: "insurancePlan.id",
        //                   ...formProps,
        //                 })}
        //                 onChange={(value) => {
        //                   setFieldValue(`distributions.${i}.commissions`, []);
        //                   setFieldValue(
        //                     `distributions.${i}.insurancePlan.id`,
        //                     value
        //                   );
        //                 }}
        //                 required
        //               />
        //               <Select
        //                 mode="multiple"
        //                 options={get(
        //                   insurancePlans.find(
        //                     ({ value }) =>
        //                       value ===
        //                       get(
        //                         formProps,
        //                         `values.distributions.${i}.insurancePlan.id`
        //                       )
        //                   ),
        //                   "commissions",
        //                   []
        //                 ).map(({ id, value, withholding }) => ({
        //                   value: id,
        //                   label: `${value}/${withholding || value}`,
        //                 }))}
        //                 placeholder={FORM.COMMISSIONS}
        //                 label={FORM.COMMISSIONS}
        //                 {...bindArrayInputProps({
        //                   parent: "distributions",
        //                   index: i,
        //                   name: "commissions",
        //                   ...formProps,
        //                 })}
        //                 onChange={(values) =>
        //                   setFieldValue(
        //                     `distributions.${i}.commissions`,
        //                     values
        //                   )
        //                 }
        //                 required
        //               />
        //               <MdDelete
        //                 size={24}
        //                 color={COLORS.C_DANGER}
        //                 onClick={() => arrayHelpers.remove(i)}
        //               />
        //             </div>
        //           </div>
        //         )),
        //         <Button
        //           type="primary"
        //           key="add-row"
        //           onClick={() => {
        //             arrayHelpers.push({
        //               id: cuid(),
        //               insurancePlan: { id: null },
        //               commissions: [],
        //             });
        //           }}
        //         >
        //           <MdAdd />
        //         </Button>,
        //       ]}
        //     />
        //   </Card>
        //   <div key="distribution-div" className="m-bottom-24" />
        // </div>,
        <Card key="list" type={"simple"}>
          <UsersTable
            data={data}
            acceptSignUp={acceptSignUp}
            updateParams={updateParams}
          />
        </Card>,
        <div key="iframe-div" className="m-bottom-24" />,
        <Card title={FORM.IFRAME_GENERATOR.TITLE} key="iframe">
          <IframeGenerator
            organizationId={get(data, "organization.id")}
            translation={FORM.IFRAME_GENERATOR}
          />
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
