import { gql } from "@apollo/client";

export const SUBSCRIPTIONS = gql`
  query Subscriptions(
    $where: SubscriptionWhereInput
    $like: JSONObject
    $take: Int
    $skip: Int
    $gt: SubscriptionWhereInput
    $lt: SubscriptionWhereInput
  ) {
    subscriptions(
      where: $where
      like: $like
      take: $take
      skip: $skip
      gt: $gt
      lt: $lt
    ) {
      count
      data {
        id
        createdDate
        signedDate
        sendSignatureDate
        locked
        status
        additionalInfo
        insurancePlan {
          id
          name
          description
          icon
          config
          params {
            rules {
              id
              name
              value
            }
          }
        }
        project {
          id
          ria
          expired
          fields
          generalCosts {
            id
            cost
            currency
            commission {
              id
              type
              value
              withholding
            }
            insurancePlan {
              id
            }
          }
          contact {
            id
            user {
              id
              firstname
              lastname
              birthName
              birthDate
            }
            telecoms {
              id
              system
              value
            }
          }
          contract {
            id
            issuanceDate
          }
          author {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTIONS_LOG = gql`
  query Subscriptions($where: SubscriptionWhereInput, $take: Int, $skip: Int) {
    subscriptions(where: $where, take: $take, skip: $skip) {
      count
      data {
        id
        createdDate
        signedDate
        locked
        status
        additionalInfo
        project {
          id
          author {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION = gql`
  query subscription($where: SubscriptionWhereInput) {
    subscription(where: $where) {
      id
      createdDate
      locked
      envelopeId
      signature
      status
      externalManagement
      signedDate
      additionalInfo
      attachments {
        id
        name
        type
        fileUrl
      }
      insurancePlan {
        id
        name
        description
        fields
        config
        params {
          id
          rules {
            name
            value
          }
        }
      }
      project {
        id
        ria
        projectType
        fields
        terminationRequest {
          id
          contractNumber
          reason
          address
          brokerageMandate {
            id
            firstName
            lastName
            currentCompany
          }
        }
        contact {
          id
          socialRegime
          socialSecurityNumber
          nationality
          familySituation
          socioProfessionalCategory
          politicallyExposedPerson
          siretNumber
          profession
          exerciseFrame
          optMadelin
          acceptContact
          acceptDigital
          telecoms {
            id
            system
            value
          }
          user {
            id
            firstname
            lastname
            gender
            phone
            birthDate
            birthName
          }
          relatedPersons {
            id
            relationship
            identity {
              id
              socialRegime
              socioProfessionalCategory
              socialSecurityNumber
              fields
              user {
                id
                firstname
                lastname
                birthDate
                birthName
              }
            }
          }
          telecoms {
            id
            system
            value
          }
          fields
        }
        contract {
          id
          issuanceDate
          caseType
          saleMode
        }
      }
      payment {
        id
        type
        frequency
        debitDay
        payer {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
            isAdherent
            type
            socialReason
            telecoms {
              id
              system
              value
            }
          }
        }
        receiver {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const COUNT_SUBSCRIPTIONS = gql`
  query CountSubscriptions(
    $where: SubscriptionWhereInput
    $gt: SubscriptionWhereInput
    $lt: SubscriptionWhereInput
  ) {
    countSubscriptions(where: $where, gt: $gt, lt: $lt)
  }
`;

const subscriptions = {
  SUBSCRIPTIONS,
  COUNT_SUBSCRIPTIONS,
  SUBSCRIPTIONS_LOG,
  SUBSCRIPTION,
};

export default subscriptions;
