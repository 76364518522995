import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onNext, onBack, telecoms, setCity, addressZipCodeCity }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: telecoms,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { addressZipCodeCity, setCity }
    ),
  });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.ADHERENT_TELECOMS.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.ADHERENT_TELECOMS.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Input
          label={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.ZIP_CODE}
          placeholder={
            TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.ZIP_CODE
          }
          {...bindInputProps({
            name: `${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
            values,
            ...formProps,
          })}
        />
        <Input
          label={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.PHONE}
          placeholder={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.PHONE}
          {...bindInputProps({
            name: `${CONTACT_POINT_SYSTEM.PHONE}.value`,
            values,
            ...formProps,
          })}
        />
        <Input
          label={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.EMAIL}
          placeholder={TRANSLATION.ADHERENT_TELECOMS.CONTACT_INFORMATION.EMAIL}
          {...bindInputProps({
            name: `${CONTACT_POINT_SYSTEM.EMAIL}.value`,
            values,
            ...formProps,
          })}
        />
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!isEmpty(formProps.errors)}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
