import { Col, Row } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineHealthAndSafety,
  MdOutlineSubtitles,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  RELATIONSHIP,
  SOCIAL_REGIMES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

const Children = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  errors,
  initialValues,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(initialValues, "project.contact.relatedPersons", []).find(
      (person) => person.relationship === RELATIONSHIP.CHILD
    ) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN}-section`}
      >
        <Form
          type="vertical"
          title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.TITLE}
          subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.SUBTITLE}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Alert
                showIcon
                type="info"
                message={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.INFO}
              />
            </Col>
            {get(initialValues, "children", []).map((_, index) => [
              <Col lg={24} xs={24} key={"child-form-" + index}>
                <Form
                  type="highlight"
                  title={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.CHILD
                      .TITLE +
                    " " +
                    (index + 1)
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col lg={24} xs={24}>
                      <Input
                        placeholder={SUBSCRIPTION.FORM.FIRST_NAME.PLACEHOLDER}
                        label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                        icon={<MdOutlineBadge size={16} />}
                        defaultValue={get(
                          initialValues,
                          `children.${index}.identity.user.firstname`
                        )}
                        errors={
                          errors[`children.${index}.identity.user.firstname`]
                        }
                        touched={
                          !isEmpty(
                            errors[`children.${index}.identity.user.firstname`]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `children.${index}.identity.user.firstname`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `children.${index}.identity.user.firstname`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                        icon={<MdOutlineBadge size={16} />}
                        placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                        defaultValue={get(
                          initialValues,
                          `children.${index}.identity.user.lastname`
                        )}
                        errors={
                          errors[`children.${index}.identity.user.lastname`]
                        }
                        touched={
                          !isEmpty(
                            errors[`children.${index}.identity.user.lastname`]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `children.${index}.identity.user.lastname`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `children.${index}.identity.user.lastname`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
                        icon={<MdOutlineCake size={16} />}
                        placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
                        type="date"
                        variant="simple"
                        defaultValue={dayjs(
                          get(
                            initialValues,
                            `children.${index}.identity.user.birthDate`
                          )
                        )}
                        format={DATE_FORMAT}
                        disabled
                        required={isFieldRequired(
                          `children.${index}.identity.user.birthDate`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Select
                        label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
                        icon={<MdOutlineHealthAndSafety size={16} />}
                        optional={SUBSCRIPTION.FORM.OPTIONAL}
                        placeholder={
                          SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER
                        }
                        type="simple"
                        options={Object.entries(SOCIAL_REGIMES).map(
                          ([key, value]) => ({
                            value,
                            label:
                              SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
                          })
                        )}
                        defaultValue={get(
                          initialValues,
                          `children.${index}.identity.socialRegime`
                        )}
                        errors={
                          errors[`children.${index}.identity.socialRegime`]
                        }
                        touched={
                          !isEmpty(
                            errors[`children.${index}.identity.socialRegime`]
                          )
                        }
                        onChange={(value) => {
                          set(
                            initialValues,
                            `children.${index}.identity.socialRegime`,
                            value
                          );
                        }}
                        required={isFieldRequired(
                          `children.${index}.identity.socialRegime`
                        )}
                      />
                    </Col>
                    <Col
                      lg={24}
                      xs={24}
                      className={classNames({
                        hidden:
                          moment()
                            .startOf("year")
                            .diff(
                              moment(
                                get(
                                  initialValues,
                                  `children.${index}.identity.user.birthDate`
                                )
                              ).startOf("year"),
                              "years"
                            ) < 16,
                      })}
                    >
                      <Input
                        label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
                        icon={<MdOutlineSubtitles size={16} />}
                        placeholder={
                          SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                        }
                        defaultValue={get(
                          initialValues,
                          `children.${index}.identity.socialSecurityNumber`
                        )}
                        errors={
                          errors[
                            `children[${index}].identity.socialSecurityNumber`
                          ]
                        }
                        touched={
                          !isEmpty(
                            errors[
                              `children[${index}].identity.socialSecurityNumber`
                            ]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `children.${index}.identity.socialSecurityNumber`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `children.${index}.identity.socialSecurityNumber`
                        )}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>,
            ])}
          </Row>
        </Form>
      </div>
    )
  );
};

export default Children;
