import { Col, Row } from "antd";
import dayjs from "dayjs";
import Card from "shared/components/Card";
import Date from "shared/components/Input/Date";
import Select from "shared/components/Select";
import BasicBar from "../../Components/BasicBar";

const View = ({
  dateRange,
  getProcessStats,
  selectedProcess,
  processes,
  data,
}) => {
  return (
    <Card type="simple">
      <div className="process_chart">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="chart_header">
              <div className="chart_header--datePicker">
                <Date
                  variant="range"
                  defaultValue={dateRange}
                  onChange={(_, dates) => {
                    getProcessStats({
                      id: selectedProcess,
                      dateRange: [dayjs(dates[0]), dayjs(dates[1])],
                    });
                  }}
                />
              </div>
              <div className="chart_header--options">
                <Select
                  defaultValue={selectedProcess}
                  options={processes.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  onChange={(id) => {
                    getProcessStats({ id, dateRange });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <BasicBar data={data} />
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default View;
