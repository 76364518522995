import { Card } from "antd";
import cuid from "cuid";
import { Formik } from "formik";
import { get, set } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import Radio from "shared/components/Form/Radio";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ATTACHMENT_TYPES,
  ORGANIZATION_TYPES,
  USER_TYPES,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Advisor from "./UserTypes/Advisor";
import Supervisor from "./UserTypes/Supervisor";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const initialValues = useMemo(
    () => ({
      code: null,
      socialReason: null,
      orias: null,
      user: {
        gender: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        meta: { type: null },
      },
      organization: {
        type: ORGANIZATION_TYPES.BROKER,
        name: null,
        form: null,
        icon: null,
      },
    }),
    []
  );

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("organization.icon", file.base64);
  };

  const generateUserForm = (formProps, setFieldValue) => ({
    // [USER_TYPES.BROKER_ADMIN.type]: (
    //   <Admin
    //     key="admin"
    //     formProps={formProps}
    //     setFieldValue={setFieldValue}
    //     handleFileUpload={handleFileUpload}
    //   />
    // ),
    [USER_TYPES.INTERNAL_ADVISOR.type]: (
      <Advisor
        key="advisor"
        formProps={formProps}
        setFieldValue={setFieldValue}
      />
    ),
    [USER_TYPES.INTERNAL_SUPERVISOR.type]: (
      <Supervisor
        key="supervisor"
        formProps={formProps}
        setFieldValue={setFieldValue}
      />
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="organization" type={"simple"}>
          <Radio
            isHorizontal={false}
            label={FORM.STATUS}
            options={[
              // {
              //   label: FORM.ADMIN,
              //   value: USER_TYPES.BROKER_ADMIN.type,
              // },
              {
                label: FORM.SUPERVISOR,
                value: USER_TYPES.INTERNAL_SUPERVISOR.type,
              },
              {
                label: FORM.ADVISOR,
                value: USER_TYPES.INTERNAL_ADVISOR.type,
              },
            ]}
            {...bindInputProps({ name: "user.meta.type", ...formProps })}
            onChange={({ target: { value } }) => {
              set(initialValues, "user.meta.type", value);
              formProps.setValues(initialValues);
            }}
          />
        </Card>,
        generateUserForm(formProps, setFieldValue)[
          get(formProps, "values.user.meta.type")
        ],
      ]}
    </Formik>
  );
};

export default Create;
