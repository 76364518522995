const Level = ({ size, filled, label }) => {
  const filledBars = Array.from({ length: filled }, (_, index) => (
    <li key={`filled-${index}`} className="active"></li>
  ));
  const emptyBars = Array.from({ length: size - filled }, (_, index) => (
    <li key={`empty-${index}`}></li>
  ));
  return (
    <>
      <div>{label}</div>
      <div>
        <ul>
          {filledBars}
          {emptyBars}
        </ul>
      </div>
    </>
  );
};
export default Level;
