import React, { useContext, useMemo } from "react";
import { faker } from "@faker-js/faker";
import { notification } from "antd";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";
import { useQuery } from "@apollo/client";

const Upload = ({ open, setOpen, onCancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const FORM = t("FORM", { returnObjects: true });
  const { t: errors } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = errors("API", { returnObjects: true });
  const navigate = useNavigate();
  const [userId, orgId] = useMemo(() => [cuid(), cuid()], []);
  const { user: userContext } = useContext(StoreContext);

  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);

  const { data: orgData } = useQuery(graphql.queries.ORGANIZATIONS, {
    variables: { where: {} },
  });

  const [signUpAuth] = useMutation(graphql.mutations.SIGN_UP_AUTH, {
    onError: (error) => {
      console.error("Error signUpAuth mutation:", error);
      notification.error({ message: FORM.IN_USE });
    },
  });
  const [signUp] = useMutation(graphql.mutations.SIGN_UP);

  const onSubmit = (data) => {
    data.forEach((item, index) => {
      const { organization, onError, ...user } = item;
      const { email, type } = user;
      const phone = String(user.phone);
      const org = orgData?.organizations.data.find(
        (org) => org.id === organization.id
      );

      signUpAuth({
        variables: {
          data: {
            id: userId,
            username: email,
            email,
            phone,
            password: faker.internet.password(),
            meta: type,
            organization: {
              id: orgId,
              name: org ? org.name : "",
            },
          },
        },
        onCompleted: (data) => {
          signUp({
            variables: {
              data: {
                id: userId,
                username: email,
                organization: {
                  id: userContext.organization.id,
                },
                pole: {
                  id: cuid(),
                  organization: { id: userContext.organization.id },
                },
              },
            },
            onCompleted: () => {
              notification.success({ message: "Done" });
              setOpen(false);
              navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
            },
            onError: (error) => {
              if (onError) onError();
              notification.error({ message: API_ERRORS.SIGN_UP_FAILED });
            },
          });
        },
        onError: (error) => {
          if (onError) onError();
          notification.error({ message: "Error signing up" });
        },
      });
    });
  };

  return (
    <View
      open={open}
      setOpen={setOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      cancel={cancel}
    />
  );
};

export default Upload;
