const Iframe = () => {
  return (
    <div style={{ height: "99vh" }}>
      <iframe
        title="Parcours Embarqué"
        width="100%"
        height="100%"
        src={`${process.env.REACT_APP_BASE_URL}/#/public/process/init/63d1817df03b8d4918de893`}
      />
    </div>
  );
};

export default Iframe;
