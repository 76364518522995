import { Affix, Col, Divider, Row } from "antd";
import { find, get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import {
  ActionStepper,
  ClientInformation,
  PricingCard,
  ProductCard,
} from "shared/components/SmartphoneWidgets";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { excludedItems, includedItems } from "../insuranceData";
import DetailsDrawer from "./Widgets/Details";
import PlansModal from "./Widgets/Plans";

const View = ({
  project,
  onSubscribe,
  onDownload,
  onSendQuote,
  data,
  onBack,
  loading,
  openModal,
  setOpenModal,
  duplicateProject,
  hideDetail,
  setHideDetail,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLearnMore = () => {
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Affix offsetTop={isTabletOrMobile ? "null" : 110}>
            <Card type={"custom"} size="full" styleType={"primary"}>
              <ProductCard
                image="https://via.placeholder.com/150"
                name={get(project, "fields.terminal.model", "")}
                price={`${get(
                  project,
                  "fields.terminal.purchasePrice",
                  ""
                )} XPF`}
                onModify={() => onBack({ steps: 2 })}
                variant="vertical"
                disabled={project.locked}
              />
              <Divider />
              <ClientInformation
                title="Informations client"
                info={[
                  {
                    label: "Nom",
                    value: get(project, "contact.user.lastname"),
                  },
                  {
                    label: "Prénom",
                    value: get(project, "contact.user.firstname"),
                  },
                  {
                    label: "Numéro de téléphone VINI",
                    value:
                      find(get(project, "contact.telecoms", []), {
                        system: CONTACT_POINT_SYSTEM.PHONE,
                      })?.value || "-",
                  },
                ]}
                variant="client"
                onModify={onBack}
                disabled={project.locked}
              />
            </Card>
          </Affix>
        </Col>
        <Col xs={24} sm={24} md={16} lg={18}>
          <Card
            type={"custom"}
            size="full"
            head
            title={"Produits"}
            subtitle={
              "Les produits affichés ci-dessous sont basés sur le niveau de garanties"
            }
          >
            <div className="d-flex gap-16 flex--column m-top-24">
              <PricingCard
                title="Premium"
                description="Couverture complète incluant réparation ou remplacement en cas de bris, oxydation, ou vol"
                price="2 510 XPF"
                monthlyPrice="30 120 XPF"
                includedItems={includedItems}
                excludedItems={excludedItems}
                onSubscribe={onSubscribe}
                onLearnMore={handleLearnMore}
                disabled={
                  !project.locked ||
                  !project.locked ||
                  !get(project, "fields.insurancePlans", []).includes("premium")
                }
              />

              <PricingCard
                title="Essentiel"
                description="Assurance couvrant la réparation ou le remplacement de l'écran en cas de dommages affectant le fonctionnement"
                price="1 672 XPF"
                monthlyPrice="20 064 XPF"
                includedItems={includedItems}
                excludedItems={excludedItems}
                onSubscribe={onSubscribe}
                onLearnMore={handleLearnMore}
                disabled={
                  !project.locked ||
                  !get(project, "fields.insurancePlans", []).includes(
                    "essentiel"
                  )
                }
              />
            </div>
          </Card>
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              onPrevious={onBack}
              onNext={() => setIsModalOpen(true)}
              isNextDisabled={project.locked}
              isPrevDisabled={project.locked}
              nextLabel="Envoyer un devis"
            />
          </Card>
        </Col>
      </Row>

      <DetailsDrawer
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      />
      <PlansModal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        onDownload={onDownload}
        onSendQuote={onSendQuote}
        fields={project.fields}
      />
    </>
  );
};

export default View;
