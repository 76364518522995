import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "utils/constants";
import View from "./View";

const EditOffer = ({ id, translate, onClose }) => {
  const [updateOffer] = useMutation(graphql.mutations.UPDATE_OFFER, {
    refetchQueries: [
      {
        query: graphql.queries.OFFERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => onClose(),
    onError: () => {},
  });
  const { data, loading } = useQuery(graphql.queries.OFFER, {
    variables: { where: { id } },
  });

  if (loading) {
    return <Loading />;
  }

  const initialValues = {
    insurancePlans: get(
      omitDeep(data.offer, "__typename"),
      "offerInsurancePlans",
      []
    ),
  };

  const onSubmit = (values) => {
    const { insurancePlans } = values;
    updateOffer({
      variables: {
        where: { id },
        data: {
          offerInsurancePlans: insurancePlans,
        },
      },
    });
  };
  return (
    <View
      translate={translate}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default EditOffer;
