import { gql } from "@apollo/client";

export const COVERAGE_BENEFITS = gql`
  query CoverageBenefits(
    $where: CoverageBenefitWhereInput
    $like: CoverageBenefitWhereInput
    $orderBy: [JSONObject]
    $skip: Int
    $take: Int
  ) {
    coverageBenefits(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
      like: $like
    ) {
      data {
        id
        order
        type
        subtype
        additionalInfo
      }
      count
    }
  }
`;

export const COVERAGE_BENEFIT = gql`
  query CoverageBenefit($where: CoverageBenefitWhereInput) {
    coverageBenefit(where: $where) {
      id
      order
      type
      subtype
      additionalInfo
    }
  }
`;

const coverageBenefits = { COVERAGE_BENEFITS, COVERAGE_BENEFIT };

export default coverageBenefits;
