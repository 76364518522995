import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { NAVIGATION_DIRECTIONS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => {
    navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
  };

  const [updateUserAuth] = useMutation(graphql.mutations.UPDATE_USER_AUTH);

  const [deleteUser] = useMutation(graphql.mutations.DELETE_USER, {
    refetchQueries: [
      {
        query: graphql.queries.USERS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      updateUserAuth({
        variables: {
          where: { id },
          data: {
            phone: null,
            username: null,
            email: null,
            password: null,
          },
        },
      });
      setVisible(false);
      navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.USER, {
    variables: { where: { id } },
  });

  const onSubmit = () => {
    return deleteUser({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      cancel={cancel}
      visible={visible}
      userType={get(data, "user.meta.type")}
      poleId={get(data, "user.pole.id")}
      userName={`${get(data, "user.firstname")} ${get(data, "user.lastname")}`}
    />
  );
};

export default Delete;
