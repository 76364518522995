import { useQuery } from "@apollo/client";
import cuid from "cuid";
import { find, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { useScreenActionPermissions } from "shared/hooks/usePermissions";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
} from "utils/api/graphql/mutations/projects";
import { EXPORT_SUBSCRIPTIONS } from "utils/api/graphql/mutations/subscription";
import { PROCESS_STATUSES } from "utils/api/graphql/queries/process-statuses";
import { TRACER, TRACERS } from "utils/api/graphql/queries/tracers";
import {
  ACTION_TYPES,
  CONTENT_TYPES,
  INITIAL_VALUES,
  MODULES,
  PROCESS_TAGS,
  PROCESS_TYPES,
  SCREENS,
  SUBMODULES,
  TRACER_TYPES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import {
  clearPaginationHistory,
  getPaginationHistory,
} from "utils/helpers/pagination";
import { filterByConditions } from "../Detail";
import View from "./View";

const List = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tracer, setTracer] = useState(null);
  const [tracers, setTracers] = useState([]);
  const { user } = useContext(StoreContext);
  const [pagination, setPagination] = useState(
    getPaginationHistory("projects")
  );
  const [filter, setFilter] = useState({ where: { visible: true } });
  const [isAssigning, setIsAssigning] = useState({});
  const actions = useScreenActionPermissions({
    module: MODULES.PROJECT,
    submodule: SUBMODULES.BROKER_LIST,
  });

  const [getProcessStatuses, { data: processStatuses }] =
    useLazyQuery(PROCESS_STATUSES);

  useQuery(TRACER, {
    fetchPolicy: "no-cache",
    variables: { where: { type: TRACER_TYPES.MANUAL } },
    onCompleted: ({ tracer }) => {
      setTracer(tracer);
      getProcessStatuses({
        fetchPolicy: "no-cache",
        variables: {
          where: { process: { id: get(tracer, "flow.process.id") } },
          isIn: { tag: [PROCESS_TAGS.SIGNED, PROCESS_TAGS.CLOSE] },
        },
      });
      setLoading(false);
    },
  });

  useQuery(TRACERS, {
    fetchPolicy: "no-cache",
    variables: { where: { type: TRACER_TYPES.MANUAL } },
    onCompleted: ({ tracers: { data } }) => {
      setTracers(omitDeep(data, "__typename"));
    },
  });

  const [updateProject] = useMutation(UPDATE_PROJECT, {});

  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: {
          where: {
            visible: true,
          },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: ({ duplicateProject }) => {
      clearPaginationHistory("projects");
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${duplicateProject.id}`,
        {
          state: {
            process: get(tracer, "flow.process"),
            tracer,
          },
        }
      );
    },
  });

  const [exportSubscriptions, { loading: exportLoading }] =
    useMutation(EXPORT_SUBSCRIPTIONS);

  const handleExportSubscriptions = async () => {
    const { data } = await exportSubscriptions();
    downloadDocument({
      base64: data.exportSubscriptions,
      contentType: CONTENT_TYPES.XLSX,
      name: "subscriptions.xlsx",
    });
  };

  const onDetail = (project) => {
    const status = project.statuses.find(
      ({ status }) => status.process.type === PROCESS_TYPES.B2B
    );
    const tracer = tracers.find(
      ({ flow }) => flow.process.id === get(status, "status.process.id")
    );
    navigate(`${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${project.id}`, {
      state: {
        process: get(tracer, "flow.process"),
        tracer,
      },
    });
  };

  const onDelete = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.PROJECTS.DELETE.path}/${id}`);

  const onSearch = ({
    clientFirstName = "",
    clientLastName = "",
    advisorFirstName = "",
    advisorLastName = "",
  }) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => {
      return {
        ...prev,
        like: {
          ...prev.like,
          AND: [
            {
              contact: {
                user: {
                  firstname: `%${clientFirstName}%`,
                },
              },
            },
            {
              contact: {
                user: {
                  lastname: `%${clientLastName}%`,
                },
              },
            },
            ...(advisorFirstName
              ? [{ author: { firstname: `%${advisorFirstName}%` } }]
              : []),
            ...(advisorLastName
              ? [{ author: { lastname: `%${advisorLastName}%` } }]
              : []),
          ],
        },
      };
    });
  };

  const applyFilter = ({ expired, tags = [], offers = [] }) => {
    const tagFilter = [
      ...(expired
        ? [
            {
              AND: [
                { expired },
                {
                  OR: [
                    {
                      statuses: {
                        status: { tag: PROCESS_TAGS.CURRENT_SUBSCRIPTION },
                      },
                    },
                    {
                      statuses: {
                        status: { tag: PROCESS_TAGS.IN_PROGRESS },
                      },
                    },
                    {
                      statuses: {
                        status: { tag: PROCESS_TAGS.QUOTE_SENT },
                      },
                    },
                    {
                      statuses: {
                        status: { tag: PROCESS_TAGS.SIGNED },
                      },
                    },
                    {
                      statuses: {
                        status: {
                          tag: PROCESS_TAGS.ELECTRONIC_SIGNATURE_PENDING,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ]
        : []),
      ...tags?.map((tag) => ({
        AND: [
          { statuses: { status: { tag } } },
          ...(tag === PROCESS_TAGS.CLOSE ? [] : [{ expired: false }]),
        ],
      })),
    ];
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      where: {
        ...prev.where,
        AND: [
          ...(!isEmpty(tagFilter)
            ? [
                {
                  OR: tagFilter,
                },
              ]
            : []),
          ...(!isEmpty(offers)
            ? [
                {
                  OR: offers?.map((offer) => ({
                    statuses: {
                      status: {
                        process: {
                          flows: {
                            tracers: {
                              id: get(
                                find(tracers, ({ name }) => name === offer),
                                "id"
                              ),
                            },
                          },
                        },
                      },
                    },
                  })),
                },
              ]
            : []),
        ],
      },
    }));
  };

  const [addProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: { where: { visible: true }, ...INITIAL_VALUES },
      },
    ],
    onCompleted: ({ addProject }) => {
      clearPaginationHistory("projects");
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${addProject.id}`,
        {
          state: {
            process: get(tracer, "flow.process"),
            tracer,
          },
        }
      );
    },
  });

  const createProject = (tracer) => {
    setTracer(tracer);
    const status = get(tracer, "flow.process.processStatuses", []).reduce(
      (previous, current) =>
        Number(previous?.order) < Number(current.order) ? previous : current
    );
    addProject({
      variables: {
        data: {
          visible: false,
          id: cuid(),
          statuses: [{ id: cuid(), status: { id: get(status, "id") } }],
          author: { id: user.id },
          fields: { processTemplate: get(tracer, "flow.process.theme") },
        },
      },
    });
  };

  const onAssign = ({ projectId, authorId }) => {
    setIsAssigning({ [projectId]: true });
    updateProject({
      variables: {
        where: { id: projectId },
        data: { author: { id: authorId } },
      },
      onCompleted: () => setTimeout(() => setIsAssigning({}), 1000),
    });
  };

  const onChangeStatus = ({ project: projectData, status }) => {
    const project = omitDeep(projectData, "__typename");
    const actionsToExecute = filterByConditions(status?.actions || [], project);
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({ id: cuid(), status: { id: status } }));
    const noSyncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.NO_SYNC_PROCESSES)
      .map(({ args: { process } }) =>
        get(project, "statuses", []).find(
          ({ status }) => get(status, "process.id") === process
        )
      );
    const statuses = [
      {
        id: cuid(),
        status: { id: status.id },
      },
      ...syncActions,
      ...noSyncActions,
    ];

    updateProject({
      variables: {
        where: { id: project?.id },
        data: { statuses },
      },
      onCompleted: () => setTimeout(() => setIsAssigning({}), 1000),
    });
  };

  if (loading) return <Loading />;

  return (
    <View
      filter={filter}
      onDetail={onDetail}
      pagination={pagination}
      setPagination={setPagination}
      onSearch={onSearch}
      applyFilter={applyFilter}
      duplicate={duplicateProject}
      createProject={createProject}
      onDelete={onDelete}
      actions={actions}
      exportSubscriptions={handleExportSubscriptions}
      exportLoading={exportLoading}
      onAssign={onAssign}
      tracer={tracer}
      isAssigning={isAssigning}
      processStatuses={get(processStatuses, "processStatuses.data", [])}
      onChangeStatus={onChangeStatus}
      tracers={tracers}
      setTracer={setTracer}
      user={user}
    />
  );
};

export default List;
