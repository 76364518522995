import { useMemo } from "react";

import PropTypes from "prop-types";

import Group from "./Group";
import Simple from "./Simple";

const MODES = {
  simple: Simple,
  group: Group,
};

const Radio = ({ variant, ...props }) => {
  const View = useMemo(() => MODES[variant], [variant]);
  return <View {...props} />;
};

Radio.propTypes = {
  variant: PropTypes.oneOf(Object.keys(MODES)),
};

Radio.defaultProps = {
  variant: "simple",
  direction: "row",
};

export default Radio;
