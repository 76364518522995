import { notification } from "antd";
import cuid from "cuid";
import { filter, get, isNil, last, sortBy } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import format from "string-template";
import {
  GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT,
  GENERATE_QUOTE_DOCUMENT,
} from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { SEND_B2C_QUOTE } from "utils/api/graphql/mutations/quotes";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import { PROJECT } from "utils/api/graphql/queries/projects";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_PARAMS_RULES,
} from "utils/constants";
import { filterByConditions } from "../../../..";
import View from "./View";

const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const Products = ({ processId, project, onNext, onBack }) => {
  const [loading, setLoading] = useState(true);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [generalCosts, { data }] = useLazyQuery(GENERAL_COSTS);
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [generateDutyOfAdviceQuoteDocument] = useMutation(
    GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT
  );
  const [generateQuoteDocument] = useMutation(GENERATE_QUOTE_DOCUMENT);
  const [sendQuoteMutation] = useMutation(SEND_B2C_QUOTE);

  const onSendQuote = async (insurancePlanId) => {
    const status = get(
      last(
        sortBy(
          filter(
            project.statuses,
            ({ status }) => get(status,"process.id") === processId
          ),
          "createdDate"
        )
      ),
      "status"
    );
    const { possibleStatuses } = status;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      project
    );
    await generateDutyOfAdviceQuoteDocument({
      variables: {
        data: {
          project: { id: project.id },
          insurancePlans: [{ id: [insurancePlanId] }],
        },
      },
    });
    await generateQuoteDocument({
      variables: {
        data: {
          project: { id: project.id },
          insurancePlans: [{ id: insurancePlanId }],
        },
      },
    });
    addSubscription({
      variables: {
        data: {
          id: cuid(),
          project: { id: project.id },
          insurancePlan: {
            id: insurancePlanId,
          },
        },
      },
      onCompleted: ({ addSubscription: { id } }) =>
        updateProject({
          refetchQueries: [
            {
              query: PROJECT,
              awaitRefetchQueries: true,
              variables: { where: { id: project.id } },
            },
          ],
          variables: {
            where: { id: project.id },
            data: {
              locked: true,
              fields: {
                ...project.fields,
                insurancePlans: [
                  ...get(project, "fields.insurancePlans", []),
                  insurancePlanId,
                ],
                selectedToCompare: [
                  ...get(project, "fields.selectedToCompare", []),
                  insurancePlanId,
                ],
                currentSubscription: id,
                ...(!isNil(possibleStatus?.nextStatus) && {
                  nextQuoteStatus: possibleStatus.nextStatus,
                }),
              },
            },
          },
          onCompleted: () =>
            sendQuoteMutation({
              variables: { data: { project: { id: project.id } } },
              onCompleted: (_) =>
                notification.open({
                  message: "Devis envoyé",
                  duration: 5,
                  type: "success",
                }),
            }),
        }),
    });
  };

  const onSubscribe = async (insurancePlanId) => {
    addSubscription({
      variables: {
        data: {
          id: cuid(),
          project: { id: project.id },
          insurancePlan: {
            id: insurancePlanId,
          },
        },
      },
      onCompleted: ({ addSubscription: { id } }) =>
        onNext({
          payload: {
            fields: {
              ...project.fields,
              insurancePlans: [
                ...get(project, "fields.insurancePlans", []),
                insurancePlanId,
              ],
              selectedToCompare: [
                ...get(project, "fields.selectedToCompare", []),
                insurancePlanId,
              ],
              currentSubscription: id,
            },
          },
          subscription: id,
        }),
    });
  };

  useEffect(() => {
    const issuanceDate = moment(get(project, "contract.issuanceDate")).startOf(
      "day"
    );
    const issuanceDateDifference = issuanceDate.diff(
      moment().startOf("day"),
      "days"
    );

    const adherentAge = moment(get(project, "contact.user.birthDate")).startOf(
      "year"
    );

    const age = moment().diff(adherentAge, "years");

    const [startKey, endKey] = project.ria
      ? [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
        ]
      : [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
        ];

    const socialRegime = get(project, "contact.socialRegime");

    const exerciseFrame = get(project, "contact.exerciseFrame");

    const department = get(
      get(project, "contact.telecoms", []).find(
        ({ system }) => system === CONTACT_POINT_SYSTEM.ZIP_CODE
      ),
      "value",
      ""
    )
      .toString()
      .substring(0, 2);

    const processId = get(project, "status.process.id");

    const sqlConditions = [
      generateSQLCondition(
        startKey,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        issuanceDateDifference
      ),
      generateSQLCondition(
        endKey,
        `r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value ${
          moment(get(project, "contract.issuanceDate")).year() ===
          moment().year()
            ? "OR CAST(r.value AS NUMERIC) = 0"
            : ""
        }`,
        issuanceDateDifference
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        age
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
        age
      ),
      // generateSQLCondition(
      //   INSURANCE_PLAN_PARAMS_RULES.EXERCISE_FRAME,
      //   "r.value IS NULL OR CAST(r.value AS TEXT) = '' OR CAST(r.value AS TEXT) = ':value' ",
      //   exerciseFrame
      // ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${socialRegime}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${department}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.PROCESSES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${processId}'`
      ),
    ];

    const insurancePlanFilter = {
      raw: sqlConditions,
      custom: {
        AND: [
          {
            OR: [
              { startDate: null },
              {
                startDate: issuanceDate,
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { endDate: null },
              {
                endDate: issuanceDate,
                operation: ">=",
              },
            ],
          },
        ],
      },
    };
    buildGeneralCosts({
      variables: {
        data: {
          project: { id: project.id },
          insurancePlanFilter,
        },
      },
      onCompleted: ({ buildGeneralCosts }) => {
        const ids = buildGeneralCosts
          .filter(({ cost }) => cost)
          .map(({ id }) => id);
        generalCosts({
          variables: {
            isIn: { id: ids },
          },
          onCompleted: () => setLoading(false),
        });
      },
    });
  }, []);

  if (loading) return <Loading />;
  return (
    <View
      products={omitDeep(get(data, "generalCosts.data"), "__typename")}
      count={get(data, "generalCosts.count")}
      onBack={onBack}
      onSendQuote={onSendQuote}
      onSubscribe={onSubscribe}
      disabledProducts={get(project, "fields.insurancePlans", [])}
    />
  );
};

export default Products;
