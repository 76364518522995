import check from "shared/assets/icons/check_circle.svg";

const Done = ({ translation }) => {
  return (
    <div className="modal-info">
      <img src={check} alt={"done"} />
      <div className="modal-info__text">
        <h4 className="modal-info__text--success">{translation.DONE}</h4>
      </div>
    </div>
  );
};

export default Done;
