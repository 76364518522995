import { get } from "lodash";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import Loading from "../Components/Spin";
import View from "./View";

const ElectronicSignature = ({ project, onNext, onBack, updateUrl }) => {
  const subscriptionId = get(project, "fields.currentSubscription");
  const { data, loading } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      variables: { where: { id: subscriptionId }, data: { locked: true } },
    }
  );

  if (loading) return <Loading />;
  const signatureLink = get(data, "subscription.signature.signatureLink");

  const next = (_ = {}) => {
    updateSubscription();
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({ payload: {} });
  };

  return (
    <View
      onNext={next}
      user={get(project, "contact.user")}
      onBack={onBack}
      signatureLink={signatureLink}
    />
  );
};

export default ElectronicSignature;
