import { Col, Row, Switch } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineAlternateEmail,
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineCall,
  MdOutlineHealthAndSafety,
  MdWorkHistory,
} from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  SOCIAL_REGIMES,
} from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Stepper from "../../widgets/Stepper";
import validation from "./validation";

const View = ({ onNext, initialValues, onBack, isFieldRequired, disabled }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { handleSubmit, setFieldValue, values, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  const birthDate = get(values, "spouse.identity.user.birthDate", undefined);

  return (
    <div>
      <div className="b2c__area bg-transparent">
        <div className="b2c-subscription_stepper">
          <Stepper translate={TRANSLATION} />
        </div>
        <div className="edit-subscription_wrapper">
          <div className="edit-subscription_wrapper--header">
            <span className="edit-subscription_wrapper--header__title">
              {TRANSLATION.EDIT_SUBSCRIPTION.TITLE}
            </span>
          </div>
          <div className="edit-subscription_wrapper--child">
            <div className="edit-subscription_wrapper--child__title">
              {TRANSLATION.EDIT_SUBSCRIPTION.SUBTITLE}
            </div>
            <div className="edit-subscription_wrapper--child__content">
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Input
                    direction="row"
                    label={TRANSLATION.FORM.FIRST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
                    icon={<MdOutlineBadge size={16} />}
                    {...bindInputProps({
                      name: "project.contact.user.firstname",
                      values,
                      ...formProps,
                    })}
                    disabled={disabled}
                    required={isFieldRequired("project.contact.user.firstname")}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    direction="row"
                    label={TRANSLATION.FORM.LAST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
                    icon={<MdOutlineBadge size={16} />}
                    {...bindInputProps({
                      name: "project.contact.user.lastname",
                      values,
                      ...formProps,
                    })}
                    disabled={disabled}
                    required={isFieldRequired("project.contact.user.lastname")}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Select
                    icon={<MdOutlineHealthAndSafety size={16} />}
                    direction="row"
                    options={Object.entries(SOCIAL_REGIMES).map(
                      ([key, value]) => ({
                        value,
                        label: TRANSLATION.MANDATORY_SCHEME.OPTIONS[key],
                      })
                    )}
                    label={TRANSLATION.MANDATORY_SCHEME.LABEL}
                    placeholder={TRANSLATION.MANDATORY_SCHEME.PLACEHOLDER}
                    {...bindInputProps({
                      name: "project.contact.socialRegime",
                      values,
                      ...formProps,
                    })}
                    disabled={disabled}
                    required={isFieldRequired("project.contact.socialRegime")}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    placeholder={TRANSLATION.FORM.BIRTH_DATE.PLACEHOLDER}
                    type="date"
                    variant="simple"
                    direction="row"
                    label={TRANSLATION.FORM.BIRTH_DATE.LABEL}
                    defaultValue={dayjs(
                      get(values, "project.contact.user.birthDate")
                    )}
                    icon={<MdOutlineCake size={16} />}
                    {...bindDateInputProps({
                      name: `project.contact.user.birthDate`,
                      values,
                      setFieldValue,
                      ...formProps,
                    })}
                    format={DATE_FORMAT}
                    disabled={disabled}
                    required={isFieldRequired("project.contact.user.birthDate")}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={TRANSLATION.MOBILE.LABEL}
                    icon={<MdOutlineCall size={16} />}
                    direction="row"
                    placeholder={TRANSLATION.MOBILE.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      values,
                      ...formProps,
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={TRANSLATION.EMAIL.LABEL}
                    direction="row"
                    icon={<MdOutlineAlternateEmail size={16} />}
                    placeholder={TRANSLATION.EMAIL.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                      values,
                      ...formProps,
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                    )}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="edit-subscription_wrapper--child">
            <div className="edit-subscription_wrapper--child__title">
              <span>{TRANSLATION.PARTNER.TITLE}</span>
              <Switch
                className="contact-stepper__content--radio--switch button__switch"
                checkedChildren={TRANSLATION.TELETRANSMISSION.OPTIONS.YES}
                unCheckedChildren={TRANSLATION.TELETRANSMISSION.OPTIONS.NO}
                defaultChecked={get(values, "hasSpouse", false)}
                onChange={(e) => setFieldValue("hasSpouse", e)}
              />
            </div>
            {get(values, "hasSpouse", false) && (
              <div className="edit-subscription_wrapper--child__content">
                <Row gutter={[16, 16]}>
                  <Col lg={24} xs={24}>
                    <Input
                      label={TRANSLATION.FORM.BIRTH_DATE.LABEL}
                      icon={<MdOutlineCake size={16} />}
                      placeholder={TRANSLATION.FORM.BIRTH_DATE.PLACEHOLDER}
                      type="date"
                      direction="row"
                      variant="simple"
                      defaultValue={birthDate && dayjs(birthDate)}
                      format={DATE_FORMAT}
                      disabled={disabled}
                      required={isFieldRequired(
                        "spouse.identity.user.birthDate"
                      )}
                      {...bindDateInputProps({
                        name: `spouse.identity.user.birthDate`,
                        values,
                        setFieldValue,
                        ...formProps,
                      })}
                    />
                  </Col>
                  <Col lg={24} xs={24}>
                    <Select
                      icon={<MdOutlineHealthAndSafety size={22} />}
                      direction="row"
                      options={Object.entries(SOCIAL_REGIMES).map(
                        ([key, value]) => ({
                          value,
                          label: TRANSLATION.MANDATORY_SCHEME.OPTIONS[key],
                        })
                      )}
                      label={TRANSLATION.MANDATORY_SCHEME.LABEL}
                      placeholder={TRANSLATION.MANDATORY_SCHEME.PLACEHOLDER}
                      {...bindInputProps({
                        name: "spouse.identity.socialRegime",
                        values,
                        ...formProps,
                      })}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="edit-subscription_wrapper--child">
            <div className="edit-subscription_wrapper--child__title">
              <span>{TRANSLATION.CHILDREN.TITLE}</span>
              <div>
                <Input
                  type="radio"
                  variant="group"
                  icon={<MdWorkHistory size={16} />}
                  values={[
                    {
                      value: 0,
                      label: 0,
                    },
                    {
                      value: 1,
                      label: 1,
                    },
                    {
                      value: 2,
                      label: 2,
                    },
                    {
                      value: 3,
                      label: 3,
                    },
                    {
                      value: 4,
                      label: 4,
                    },
                  ]}
                  {...bindInputProps({
                    name: "childrenCount",
                    values,
                    ...formProps,
                  })}
                  onChange={(_) => setFieldValue("childrenCount", _)}
                />
              </div>
            </div>
            <div className="edit-subscription_wrapper--child__content">
              {Array.from({ length: get(values, "childrenCount") }).map(
                (_, i) => (
                  <Row key={`child-${i}`} gutter={[16, 16]}>
                    <Col lg={24} xs={24}>
                      <span className="children-count">
                        {TRANSLATION.CHILDREN_COUNT[i]}
                      </span>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        icon={<MdOutlineCake size={22} />}
                        label={TRANSLATION.FORM.BIRTH_DATE.LABEL}
                        placeholder={TRANSLATION.FORM.BIRTH_DATE.PLACEHOLDER}
                        type="date"
                        direction="row"
                        variant="simple"
                        format={DATE_FORMAT}
                        disabledDate={(current) => current > dayjs()}
                        defaultPickerValue={
                          !get(
                            values,
                            `children.${i}.identity.user.birthDate`
                          ) && dayjs().subtract(1, "years")
                        }
                        {...bindDateInputProps({
                          name: `children.${i}.identity.user.birthDate`,
                          values,
                          setFieldValue,
                          ...formProps,
                        })}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Select
                        icon={<MdOutlineHealthAndSafety size={22} />}
                        direction="row"
                        options={Object.entries(SOCIAL_REGIMES).map(
                          ([key, value]) => ({
                            value,
                            label: TRANSLATION.MANDATORY_SCHEME.OPTIONS[key],
                          })
                        )}
                        label={TRANSLATION.MANDATORY_SCHEME.LABEL}
                        placeholder={TRANSLATION.MANDATORY_SCHEME.PLACEHOLDER}
                        {...bindInputProps({
                          name: `children.${i}.identity.socialRegime`,
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>
                  </Row>
                )
              )}
            </div>
          </div>
          <div className="edit-subscription_wrapper--actions">
            <Row gutter={[16, 16]}>
              <Col lg={6} xs={8}>
                <Button
                  onClick={onBack}
                  className="edit-subscription_wrapper--actions__previous"
                >
                  <MdArrowBackIos />
                  {TRANSLATION.PREVIOUS}
                </Button>
              </Col>
              <Col lg={18} xs={16}>
                <Button
                  className="edit-subscription_wrapper--actions__next"
                  onClick={handleSubmit}
                  disabled={!isEmpty(formProps.errors)}
                >
                  {TRANSLATION.NEXT}
                  <MdArrowForwardIos />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
