import { useFormik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { SOCIAL_REGIMES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, spouse }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: spouse,
    onSubmit: onNext,
  });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.SPOUSE_SOCIAL_REGIME.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.SPOUSE_SOCIAL_REGIME.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <div className="flex--grow-1">
          <Radio
            size={1}
            values={[
              {
                label: TRANSLATION.SOCIAL_REGIMES.GENERAL_REGIME,
                value: SOCIAL_REGIMES.SECURITE_SOCIALE,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.ALSACE_MOSELLE_REGIME,
                value: SOCIAL_REGIMES.ALSACE_MOSELLE,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.AGRICULTURAL_EMPLOYEES_REGIME,
                value: SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.SELF_EMPLOYED_REGIME,
                value: SOCIAL_REGIMES.SECURITE_SOCIALE_INDEPENDANTS,
              },
            ]}
            {...bindInputProps({
              name: "identity.socialRegime",
              values,
              ...formProps,
            })}
          />
        </div>
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!get(values, "identity.socialRegime")}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
