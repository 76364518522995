import cuid from "cuid";
import { defaults, get, map } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const onDelete = () =>
    navigate(`${SCREENS.PRIVATE.ADMIN.BROKERS.DELETE.path}/${id}`);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER_AUTH);
  const [updateUserParams] = useMutation(graphql.mutations.UPDATE_USER, {
    refetchQueries: [
      {
        query: graphql.queries.USERS,
        awaitRefetchQueries: true,
      },
    ],
  });
  const [acceptSignUp] = useMutation(graphql.mutations.ACCEPT_SIGN_UP);

  const { data: distributions, loading: distributionsLoading } = useQuery(
    graphql.queries.DISTRIBUTIONS,
    { variables: { where: { broker: { id } } } }
  );
  const [updateBroker] = useMutation(graphql.mutations.UPDATE_BROKER, {
    refetchQueries: [
      {
        query: graphql.queries.BROKERS,
        awaitRefetchQueries: true,
        variables: {
          ...INITIAL_VALUES,
        },
      },
      {
        query: graphql.queries.BROKER,
        awaitRefetchQueries: true,
        variables: { where: { id } },
      },
      {
        query: graphql.queries.DISTRIBUTIONS,
        awaitRefetchQueries: true,
        variables: { where: { broker: { id } } },
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
    },
  });

  const { data, loading, error } = useQuery(graphql.queries.BROKER, {
    variables: { where: { id } },
  });

  const updateParams = ({ userId, params }) => {
    updateUserParams({
      variables: {
        where: { id: userId },
        data: { params },
      },
    });
  };

  const onSubmit = ({
    telecoms,
    iconAttachment,
    organization,
    user,
    icon,
    distributions,
    ...values
  }) => {
    const organizationTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    return updateBroker({
      variables: {
        where: {
          id,
        },
        data: {
          user: {
            ...user,
            organization: {
              ...organization,
              telecoms: organizationTelecoms,
              icon: iconAttachment,
              users: undefined,
              contracts: undefined,
            },
          },
          // distributions: distributions?.map(({ commissions, ...rest }) => ({
          //   ...rest,
          //   commissions: commissions?.map((commission) => ({
          //     id: commission,
          //   })),
          // })),
          ...values,
        },
      },
    });
  };

  if (loading || distributionsLoading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.broker, "__typename")}
      distributions={omitDeep(
        get(distributions, "distributions.data", []),
        "__typename"
      )}
      cancel={cancel}
      onDelete={onDelete}
      acceptSignUp={acceptSignUp}
      updateParams={updateParams}
    />
  );
};

export default Detail;
