import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";

import { get, isEmpty } from "lodash";
import Card from "shared/components/Card";
import Icon from "shared/components/Icon";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { ActionStepper } from "shared/components/SmartphoneWidgets";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const BRANDS = [
  {
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://img.icons8.com/?size=16&id=9oDLg7zNzE4O&format=png&color=000000"
          alt="Apple"
          style={{ marginRight: 8 }}
        />
        Apple
      </div>
    ),
    value: "Apple",
  },
  {
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://img.icons8.com/?size=16&id=wGYgIlqPWdC2&format=png&color=000000"
          alt="Apple"
          style={{ marginRight: 8 }}
        />
        Samsung
      </div>
    ),
    value: "Samsung",
  },
];

const MODELS = {
  Apple: [
    { label: "Iphone 15", value: "Iphone 15" },
    { label: "Iphone 14", value: "Iphone 14" },
    { label: "Iphone 13", value: "Iphone 13" },
  ],
  Samsung: [
    { label: "Samsung S24", value: "Samsung S24" },
    { label: "Samsung S23", value: "Samsung S23" },
    { label: "Samsung S22", value: "Samsung S22" },
  ],
};

const TerminalInfo = ({ onNext, onBack, project }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", {
    returnObjects: true,
  });
  const TERMINAL_INFO = t("DETAIL.TEMPLATES.TERMINAL_INFO", {
    returnObjects: true,
  });

  const onSubmit = (terminal) => {
    onNext({
      payload: {
        fields: {
          ...project.fields,
          terminal,
        },
      },
    });
  };

  return (
    <>
      <Header key={"header"} title={DETAIL.TITLE} />
      <Formik
        onSubmit={onSubmit}
        initialValues={get(project, "fields.terminal", {
          brand: null,
          model: null,
          purchasePrice: null,
        })}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ handleSubmit, setFieldValue, ...formProps }) => [
          <Card
            key="card"
            type={"custom"}
            styleType={"bordered"}
            size={"medium"}
            head
            title={TERMINAL_INFO.TITLE}
            subtitle={TERMINAL_INFO.SUBTITLE}
          >
            <div className="m-top-24" />
            <h1 className="title">
              <span>{TERMINAL_INFO.TERMINAL_INFO}</span>
            </h1>
            <div className="m-top-8" />
            <Card type={"custom"} styleType={"shadow"}>
              <div className="grid--1">
                <Select
                  options={BRANDS}
                  label={TERMINAL_INFO.BRAND}
                  placeholder={TERMINAL_INFO.SELECT_BRAND}
                  icon={<Icon size={16} name="new_releases" />}
                  required
                  {...bindInputProps({
                    name: "brand",
                    ...formProps,
                  })}
                  onChange={(value) => {
                    setFieldValue("brand", value);
                    setFieldValue("model", null);
                  }}
                />
                <Select
                  options={MODELS[get(formProps, "values.brand")] || []}
                  label={TERMINAL_INFO.MODEL}
                  placeholder={TERMINAL_INFO.SELECT_MODEL}
                  icon={<Icon size={16} name="widgets" />}
                  required
                  {...bindInputProps({
                    name: "model",
                    ...formProps,
                  })}
                />
                <Input
                  type="number"
                  label={TERMINAL_INFO.PURCHASE_PRICE}
                  placeholder="0"
                  suffix="XPF"
                  icon={<Icon size={16} name="euro" />}
                  required
                  defaultBackground
                  {...bindInputProps({
                    name: "purchasePrice",
                    ...formProps,
                  })}
                />
              </div>
            </Card>
          </Card>,
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              onPrevious={onBack}
              onNext={handleSubmit}
              isNextDisabled={!isEmpty(formProps.errors)}
            />
          </Card>,
        ]}
      </Formik>
    </>
  );
};

export default TerminalInfo;
