import { Drawer } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { PROJECT } from "utils/api/graphql/queries/projects";
import { SUBSCRIPTIONS } from "utils/api/graphql/queries/subscription";
import { TRACER_TYPES } from "utils/constants";
import View from "./View";

const List = ({ onClose, project, onChangeStatus }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const LIST = t("LIST", { returnObjects: true });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const { data: subscriptions, loading: subscriptionLoading } = useQuery(
    SUBSCRIPTIONS,
    {
      variables: {
        where: { project: { id: project } },
      },
    }
  );
  const { data, loading } = useQuery(PROJECT, {
    variables: {
      where: {
        id: project,
        statuses: {
          status: {
            process: {
              flows: {
                tracers: {
                  type: TRACER_TYPES.MANUAL,
                },
              },
            },
          },
        },
      },
    },
  });

  return (
    <Drawer
      title={LIST.PRODUCTS_LIST}
      placement="right"
      onClose={onClose}
      open={true}
      className="drawer__area"
      contentWrapperStyle={{
        width: isTabletOrMobile ? "98%" : "600px",
      }}
    >
      {subscriptionLoading || loading ? (
        <Loading />
      ) : (
        <View
          translation={LIST}
          data={get(subscriptions, "subscriptions.data", [])}
          project={get(data, "project")}
          onChangeStatus={onChangeStatus}
        />
      )}
    </Drawer>
  );
};

export default List;
