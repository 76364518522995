import { gql } from "@apollo/client";

const SUBSCRIPTION_UPDATED = gql`
  subscription subscriptionUpdated($where: SubscriptionWhereInput) {
    subscriptionUpdated(where: $where) {
      id
      createdDate
      locked
      envelopeId
      attachments {
        id
        name
        type
        fileUrl
      }
      insurancePlan {
        id
        name
        fields
      }
      project {
        id
        ria
        expired
        projectType
        terminationRequest {
          id
          contractNumber
          reason
          address
          brokerageMandate {
            id
            firstName
            lastName
            currentCompany
          }
        }
        contact {
          id
          socialRegime
          socialSecurityNumber
          familySituation
          socioProfessionalCategory
          politicallyExposedPerson
          siretNumber
          profession
          exerciseFrame
          optMadelin
          acceptContact
          acceptDigital
          telecoms {
            id
            system
            value
          }
          user {
            id
            firstname
            lastname
            gender
            phone
            birthDate
          }
          relatedPersons {
            id
            relationship
            identity {
              id
              socialRegime
              socioProfessionalCategory
              user {
                id
                firstname
                lastname
                birthDate
              }
            }
          }
          telecoms {
            id
            system
            value
          }
          fields
        }
        contract {
          id
          issuanceDate
          caseType
          saleMode
        }
      }
      payment {
        id
        type
        frequency
        debitDay
        payer {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
            isAdherent
            telecoms {
              id
              system
              value
            }
          }
        }
        receiver {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const subscriptions = { SUBSCRIPTION_UPDATED };

export default subscriptions;
