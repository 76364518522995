import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import { NAME_SPACES } from "shared/locales/constants";
import processDone from "shared/assets/icons/process_nocomplete.svg";

const View = ({ onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  return (
    <Container size={"medium"}>
      <div className="b2c__area contact-us">
        <div className="b2c__area--header">
          <div className="b2c__area--header--icon">
            <img alt="done" src={processDone} />
          </div>
          <h2 className="b2c__area--header__title">
            {TRANSLATION.CONTACT_US.THANKS}
          </h2>
          <h3 className="b2c__area--header__desc">
            {TRANSLATION.CONTACT_US.NO_SUBSCRIPTION}
          </h3>
        </div>

        <div className="contact-us__link" onClick={onBack}>
          {TRANSLATION.CONTACT_US.LINK}
        </div>
      </div>
    </Container>
  );
};

export default View;
