import { isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { StoreContext } from "shared/store";
import { SCREENS } from "utils/constants";

const Redirect = () => {
  const navigate = useNavigate();
  const { accessToken, user } = useContext(StoreContext);

  useEffect(() => {
    if (isEmpty(user) || !accessToken)
      return navigate(SCREENS.AUTH.LOGOUT.path, {
        replace: true,
      });
    navigate(SCREENS.AUTH.SIGN_IN.path, {
      replace: true,
    });
  }, [accessToken]);

  return <Loading />;
};

export default Redirect;
