import classNames from "classnames";
import { useFormik } from "formik";
import { get, keyBy } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { USER_TYPES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Advisor from "./UserTypes/Advisor";
import Employee from "./UserTypes/Employee";
import Representative from "./UserTypes/Representative";
import validation from "./validation";

const Detail = ({ onSubmit, cancel, data = {} }) => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const {
    meta,
    gender,
    firstname,
    lastname,
    email,
    organization,
    phone,
    address,
    zipCode,
    city,
  } = data;
  const telecoms = keyBy(get(organization, "telecoms"), "system");

  const formProps = useFormik({
    initialValues: {
      meta,
      gender,
      firstname,
      lastname,
      email,
      phone,
      orias: get(organization, "broker.orias"),
      socialReason: get(organization, "broker.socialReason"),
      code: get(organization, "broker.code"),
      rcsNumber: get(organization, "broker.rcsNumber"),
      telecoms,
      address,
      zipCode,
      city,
    },
    onSubmit: onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
        returnObjects: true,
      })
    ),
  });

  const generateUserForm = (formProps) => ({
    [USER_TYPES.INSURANCE_REPRESENTATIVE.type]: (
      <Representative key="representative" formProps={formProps} />
    ),
    [USER_TYPES.BROKER_REPRESENTATIVE.type]: (
      <Representative key="broker.representative" formProps={formProps} />
    ),
    [USER_TYPES.INTERNAL_ADVISOR.type]: (
      <Advisor key="advisor" formProps={formProps} />
    ),
    [USER_TYPES.BROKER_EMPLOYEE.type]: (
      <Employee key="employee" formProps={formProps} />
    ),
  });

  return (
    <>
      <Header title={DETAIL.TITLE} />
      <div className="fast-container">
        <Card head title={FORM.DETAIL.TITLE} subtitle={FORM.DETAIL.SUBTITLE}>
          <div className="wrapper--forms">
            <div
              className={classNames({
                hidden: user.meta.type === USER_TYPES.INTERNAL_SUPERVISOR.type,
              })}
            >
              <Radio
                isHorizontal={false}
                label={FORM.STATUS}
                options={[
                  {
                    label: FORM.SALARY,
                    value: USER_TYPES.BROKER_EMPLOYEE.type,
                  },
                  {
                    label: FORM.AGENT,
                    value: get(user, "organization.broker.isInternal")
                      ? USER_TYPES.INSURANCE_REPRESENTATIVE.type
                      : USER_TYPES.BROKER_REPRESENTATIVE.type,
                  },
                ]}
                icon={<BiUserCircle size={14} />}
                {...bindInputProps({ name: "meta.type", ...formProps })}
                onClick={(value) => {
                  if (value === USER_TYPES.BROKER_EMPLOYEE.type) {
                    formProps.setFieldValue("orias", null);
                  }
                  formProps.setFieldValue("meta.type", value);
                }}
                disabled
              />
            </div>
            {generateUserForm(formProps)[get(formProps, "values.meta.type")]}
          </div>
        </Card>
        <div className="d-flex justify--between">
          <Button onClick={cancel} type="secondary--link">
            <MdArrowBackIos size={16} />
            {FORM.PREVIOUS}
          </Button>

          <Button onClick={formProps.handleSubmit} type="primary">
            {FORM.SAVE}
            <MdArrowForwardIos size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Detail;
