import * as yup from "yup";

const validation = (messages) => {
  const stringOrArrayOfStrings = yup
    .mixed()
    .test("string-or-array", messages.REQUIRED, (value) => {
      if (typeof value === "string") {
        return yup.string().required(messages.REQUIRED).isValidSync(value);
      } else if (Array.isArray(value)) {
        return yup
          .array()
          .of(yup.string().required(messages.REQUIRED))
          .min(1, messages.REQUIRED)
          .required(messages.REQUIRED)
          .isValidSync(value);
      }
      return false;
    });
  return yup.object().shape({
    name: yup.string(),
    dates: yup
      .array()
      .typeError(messages.REQUIRED)
      .of(yup.date().typeError(messages.required))
      .length(2, messages.REQUIRED)
      .required(messages.REQUIRED),
    insurancePlan: yup.object().shape({
      id: yup.string().required(messages.REQUIRED),
    }),
    prices: yup.array().of(
      yup.object({
        departments: yup
          .object()
          .shape({
            values: yup
              .array()
              .of(yup.string().required(messages.REQUIRED))
              .min(1, messages.REQUIRED),
            excluded: yup.boolean().nullable(),
          })
          .required(messages.REQUIRED),
        socialRegime: stringOrArrayOfStrings,
        beneficiaryType: stringOrArrayOfStrings,
        // commission: yup
        //   .string()
        //   .typeError(messages.REQUIRED)
        //   .required(messages.REQUIRED),
        costs: yup
          .string()
          .test("is-json", messages.INVALID_JSON, function (value) {
            try {
              JSON.parse(value);
              return true;
            } catch (error) {
              return false;
            }
          }),
      })
    ),
  });
};

export default validation;
