import cuid from "cuid";
import {
  defaultTo,
  defaultsDeep,
  filter,
  get,
  head,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  keyBy,
  map,
  mapValues,
} from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";

import dayjs from "dayjs";
import { USER_AUTH } from "utils/api/graphql/queries/users";
import {
  ANSET_SUBSCRIPTION_FORM_KEYS,
  BANK_ACCOUNT_OWNER_TYPE,
  CONTACT_POINT_SYSTEM,
  PAYMENT_TYPES,
  PROJECT_SOURCE,
  SALE_MODES,
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import View from "./View";
import { DOCUMENTS_TYPE } from "./Widgets/Proof";

export const STEPPER_KEYS = {
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  PROOF: "PROOF",
  DOCUMENT: "DOCUMENT",
};

const Detail = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkUser] = useLazyQuery(USER_AUTH);
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
    }
  );

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const subscriptionPayment = get(subscription, "payment");
    const paymentsTypes = get(
      subscription,
      "insurancePlan.fields.payment.type",
      []
    );
    const debitDays = get(
      subscription,
      "insurancePlan.fields.payment.debitDay",
      []
    );
    const frequencies = get(
      subscription,
      "insurancePlan.fields.payment.frequency",
      []
    );
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    if (isEmpty(subscription)) return;
    const contactTelecoms = get(subscription, "project.contact.telecoms", []);
    const payerTelecoms = get(subscription, "payment.payer.owner.telecoms", []);
    const initialDisabled = {
      project: {
        ria: true,
        projectType:
          get(subscription, "project.fields.source") ===
            PROJECT_SOURCE.CLIENT ||
          get(subscription, "project.fields.twoTimesSale") ||
          [SALE_MODES.FACE_TO_FACE].includes(
            get(subscription, "project.contract.saleMode")
          ),
      },
      createdDate: true,
      proof: {
        paymentAccount:
          get(subscription, "payment.type", "") !== PAYMENT_TYPES.DEBIT,
      },
      telecoms: {
        payer: {
          firstName: shouldBeAdherent,
          lastName: shouldBeAdherent,
          [CONTACT_POINT_SYSTEM.STREET]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.CITY]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
        },
        contact: {},
      },
      [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]:
        true ||
        !get(subscription, "project.contact.fields.teletransmission", true),
      [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PENSION_FUND]: !(
        (
          get(subscription, "insurancePlan.config.additionalDocuments", []) ||
          []
        ).includes("proof.pensionFund") &&
        get(subscription, "project.contact.socioProfessionalCategory") ===
          SOCIO_PROFESSIONAL_CATEGORIES.RETIREES
      ),
    };

    const telecoms = defaultsDeep(
      {
        contact: keyBy(contactTelecoms, "system"),
        payer: keyBy(
          shouldBeAdherent ? contactTelecoms : payerTelecoms,
          "system"
        ),
      },
      {
        contact: {
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
          [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
        },
        payer: {
          [CONTACT_POINT_SYSTEM.STREET]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
        },
      }
    );
    const fields = {
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: {
        [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT]: [
          "project.contact.user.lastname",
          "project.contact.user.firstname",
        ],
        [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT]: [
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
        ],
        [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINAL]: [
          `project.fields.phoneViniNumber`,
          `project.fields.imei`,
          `project.fields.purchaseDate`,
        ],
        [ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT]: [
          "payment.type",
          ...(get(subscription, "payment.type", "") === PAYMENT_TYPES.DEBIT
            ? [
                "payment.frequency",
                "payment.debitDay",
                "payment.payer.IBAN",
                "payment.payer.BIC",
                "payment.payer.owner.firstName",
                "payment.payer.owner.lastName",
              ]
            : []),
        ],
      },
      [STEPPER_KEYS.PROOF]: {
        [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PURCHASE_INVOICE]: [
          "proof.purchaseInvoice",
        ],
        [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.ID]: ["proof.cin"],
        [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: [
          "proof.paymentAccount",
        ],
      },
    };

    const additionalInfoInitialValues = {
      ...subscription,
      telecoms,
      project: {
        ...subscription.project,
        contact: defaultsDeep(subscription.project.contact, {
          fields: { teletransmission: true, familySituation: null },
        }),
        contract: subscription.project.contract,
        fields: defaultsDeep(subscription.project.contact.fields, {
          phoneViniNumber: null,
          imei: null,
          purchaseDate: dayjs(),
        }),
      },
      payment: {
        id: cuid(),
        ...subscriptionPayment,
        type: get(
          subscriptionPayment,
          "type",
          paymentsTypes.length === 1 ? head(paymentsTypes) : null
        ),
        debitDay: get(
          subscriptionPayment,
          "debitDay",
          debitDays.length === 1 ? head(debitDays) : null
        ),
        frequency: get(
          subscriptionPayment,
          "frequency",
          frequencies.length === 1 ? head(frequencies) : null
        ),
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
        receiver: defaultsDeep(subscription.payment?.receiver, {
          id: cuid(),
          IBAN: null,
          BIC: null,
          owner: { id: cuid() },
        }),
      },
    };

    const proofInitialValues = {
      proof: mapValues(DOCUMENTS_TYPE, (type) =>
        map(
          filter(get(subscription, "attachments", []), { type }),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        )
      ),
    };
    setDisabled(initialDisabled);
    setInitialValues({
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: additionalInfoInitialValues,
      [STEPPER_KEYS.PROOF]: proofInitialValues,
      fields,
    });

    if (subscription.status === SUBSCRIPTION_STATUSES.SIGNED)
      setActive(STEPPER_KEYS.PROOF);
    if (subscription.status === SUBSCRIPTION_STATUSES.PENDING)
      setActive(STEPPER_KEYS.DOCUMENT);
  }, []);

  const { data, loading, error } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id } },
    onCompleted: initSubscription,
  });

  const [active, setActive] = useState(STEPPER_KEYS.ADDITIONAL_INFORMATION);
  const [disabled, setDisabled] = useState({});
  const [realProgress, setRealProgress] = useState({
    [STEPPER_KEYS.ADDITIONAL_INFORMATION]: 0,
    [STEPPER_KEYS.PROOF]: 0,
  });
  const [progress, setProgress] = useState({
    [STEPPER_KEYS.ADDITIONAL_INFORMATION]: {},
    [STEPPER_KEYS.PROOF]: {},
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [active]);

  const calculateBlockProgress = (parent, block, values, disabled) => {
    if (
      disabled[block] &&
      block !== ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE
    )
      return;
    const maxLength = Object.values(
      get(initialValues, `fields.${parent}.${block}`, [])
    ).filter((g) => g && !get(disabled, g)).length;
    const filedLength = Object.values(
      get(initialValues, `fields.${parent}.${block}`, [])
    ).filter(
      (g) =>
        !isNil(get(values, g)) &&
        get(values, g) !== "" &&
        (!isObject(get(values, g)) || !isEmpty(get(values, g))) &&
        !get(disabled, g)
    ).length;
    return maxLength ? Math.ceil((filedLength / maxLength) * 100) : 100;
  };

  const calculateRealTimeProgress = (
    parent,
    block,
    values,
    initialDisabled = disabled
  ) => {
    setProgress((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [block]: calculateBlockProgress(parent, block, values, initialDisabled),
      },
    }));
  };

  const calculateProgressMean = (
    parent,
    values,
    initialDisabled = disabled
  ) => {
    const { count, sum } = Object.keys(
      get(initialValues, `fields.${parent}`, [])
    ).reduce(
      (acc, block) => {
        const progress = calculateBlockProgress(
          parent,
          block,
          values,
          initialDisabled
        );
        if (
          block ===
          ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE
        )
          return acc;
        return isNumber(progress)
          ? { sum: (acc.sum += progress), count: (acc.count += 1) }
          : acc;
      },
      { count: 0, sum: 0 }
    );
    const progress = +(sum / count).toFixed(2);

    setRealProgress((prev) => ({
      ...prev,
      [parent]: progress,
    }));
    if (
      parent === STEPPER_KEYS.PROOF &&
      ((progress === 100 && realProgress[parent] !== 100) ||
        (progress !== 100 && realProgress[parent] === 100))
    ) {
      return updateSubscription({
        variables: {
          where: { id },
          data: {
            additionalInfo: {
              ...get(
                omitDeep(data, "__typename"),
                "subscription.additionalInfo",
                {}
              ),
              isDocumentsCompleted: progress === 100,
            },
          },
        },
        onCompleted: () => setIsSubmitting(false),
      });
    }
    if (
      parent === STEPPER_KEYS.ADDITIONAL_INFORMATION &&
      ((progress === 100 && realProgress[parent] !== 100) ||
        (progress !== 100 && realProgress[parent] === 100))
    ) {
      return updateSubscription({
        variables: {
          where: { id },
          data: {
            additionalInfo: {
              ...get(
                omitDeep(data, "__typename"),
                "subscription.additionalInfo",
                {}
              ),
              isInfoCompleted: progress === 100,
            },
          },
        },
        onCompleted: () => setIsSubmitting(false),
      });
    }
    setIsSubmitting(false);
  };

  if (loading || error || isEmpty(initialValues) || isEmpty(disabled))
    return <Loading />;

  return (
    <View
      active={active}
      setActive={setActive}
      data={omitDeep(data, "__typename")}
      progress={progress}
      setProgress={setProgress}
      disabled={disabled}
      setDisabled={setDisabled}
      setPercent={calculateRealTimeProgress}
      realProgress={realProgress}
      calculateProgressMean={calculateProgressMean}
      initialValues={initialValues}
      checkUser={checkUser}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
    />
  );
};

export default Detail;
