import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineHealthAndSafety,
  MdOutlineSubtitles,
  MdOutlineWorkOutline,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  RELATIONSHIP,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

const Partner = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  errors,
  initialValues,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const birthDate = get(
    initialValues,
    "spouse.identity.user.birthDate",
    undefined
  );
  return (
    get(initialValues, "project.contact.relatedPersons", []).find(
      (person) => person.relationship === RELATIONSHIP.SPOUSE
    ) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER}-section`}
      >
        <Form
          type="vertical"
          title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.TITLE}
          subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.SUBTITLE}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.lastname"
                )}
                errors={errors["spouse.identity.user.lastname"]}
                touched={!isEmpty(errors["spouse.identity.user.lastname"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.lastname", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.lastname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.BIRTH_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.BIRTH_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.birthName"
                )}
                errors={errors["spouse.identity.user.birthName"]}
                touched={!isEmpty(errors["spouse.identity.user.birthName"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.birthName", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.birthName")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.firstname"
                )}
                errors={errors["spouse.identity.user.firstname"]}
                touched={!isEmpty(errors["spouse.identity.user.firstname"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.firstname", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.firstname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
                icon={<MdOutlineCake size={16} />}
                placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
                defaultValue={birthDate && dayjs(birthDate)}
                type="date"
                variant="simple"
                format={DATE_FORMAT}
                disabled
                required={isFieldRequired("spouse.identity.user.birthDate")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Select
                type="simple"
                optional={SUBSCRIPTION.FORM.OPTIONAL}
                label={SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.LABEL}
                icon={<MdOutlineWorkOutline size={16} />}
                placeholder={
                  SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.PLACEHOLDER
                }
                options={Object.entries(SOCIO_PROFESSIONAL_CATEGORIES).map(
                  ([key, value]) => ({
                    value,
                    label:
                      SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.OPTIONS[
                        key
                      ],
                  })
                )}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.socioProfessionalCategory"
                )}
                errors={errors["spouse.identity.socioProfessionalCategory"]}
                touched={
                  !isEmpty(errors["spouse.identity.socioProfessionalCategory"])
                }
                onChange={(value) => {
                  set(
                    initialValues,
                    "spouse.identity.socioProfessionalCategory",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  "spouse.identity.socioProfessionalCategory"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Select
                icon={<MdOutlineHealthAndSafety size={16} />}
                optional={SUBSCRIPTION.FORM.OPTIONAL}
                options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                  value,
                  label: SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
                }))}
                label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
                placeholder={SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.socialRegime"
                )}
                errors={errors["spouse.identity.socialRegime"]}
                touched={!isEmpty(errors["spouse.identity.socialRegime"])}
                onChange={(value) => {
                  set(initialValues, "spouse.identity.socialRegime", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.socialRegime")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
                icon={<MdOutlineSubtitles size={16} />}
                placeholder={
                  SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                }
                defaultValue={get(
                  initialValues,
                  "spouse.identity.socialSecurityNumber"
                )}
                errors={errors["spouse.identity.socialSecurityNumber"]}
                touched={
                  !isEmpty(errors["spouse.identity.socialSecurityNumber"])
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    "spouse.identity.socialSecurityNumber",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  "spouse.identity.socialSecurityNumber"
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default Partner;
