import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { MdChecklist, MdFilterList } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "shared/components/Button";
import Input from "shared/components/Input";

const Filter = ({ translate, items, setFilter }) => {
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");
  const [expand, setExpand] = useState(false);

  const onCheck = (record, state) =>
    state
      ? setSelectedData((prev) => [...prev, record])
      : setSelectedData((prev) => [...prev.filter((item) => item !== record)]);

  const clear = () => {
    setSelectedData([]);
    setExpand(false);
    setFilter(items);
  };

  const onSearch = (e) => setSearch(e.target.value);

  const apply = () => {
    setExpand(false);
    setFilter(selectedData);
  };

  return (
    <div className="filter__container">
      <Button type="primary--outlined" onClick={() => setExpand(!expand)}>
        <span className="row">
          <MdFilterList size={16} />
          {selectedData.length > 0
            ? `${selectedData.length} ${
                selectedData.length === 1
                  ? translate.TITLE_SELECTED
                  : translate.TITLE_SELECTEDS
              }`
            : translate.TITLE}
        </span>
        <RiArrowDownSLine size={20} />
      </Button>
      <div
        className={classNames("filter__container--dropdown", {
          expanded: expand,
        })}
      >
        <Input
          type="search"
          placeholder={translate.PLACEHOLDER}
          onChange={onSearch}
        />
        <div className="filter__container--dropdown__list">
          <ul>
            {items.map((record, index) => (
              <li
                key={`row_${index}`}
                className={classNames({
                  selected: record,
                  hide:
                    search &&
                    translate.ITEMS[record]
                      .toLowerCase()
                      .indexOf(search.toLowerCase()) === -1,
                })}
              >
                <Input
                  type="checkbox"
                  onChange={(e) => onCheck(record, e.target.checked)}
                  checked={selectedData.includes(record)}
                  label={translate.ITEMS[record] || record}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="filter__container--dropdown__actions">
          <Button type="gray--outlined" onClick={clear}>
            {translate.CLEAR}
          </Button>
          <Button
            type="primary"
            onClick={apply}
            disabled={isEmpty(selectedData)}
          >
            <MdChecklist size={16} />
            {translate.APPLY}
          </Button>
        </div>
      </div>
      {/* <div
        className={classNames("filter__container--result", {
          hidden: isEmpty(selectedData),
        })}
      >
        {selectedData.map((key, index) => (
          <span key={`selected_${index}`}>{translate.ITEMS[key]} </span>
        ))}
      </div> */}
    </div>
  );
};

Filter.prototype = {
  items: PropTypes.arrayOf(PropTypes.string),
};

Filter.defaultProps = {};

export default Filter;
