import * as yup from "yup";

const validation = (errors) => {
  return yup.array().of(
    yup.object().shape({
      identity: yup.object().shape({
        user: yup.object().shape({
          birthDate: yup
            .date()
            .typeError(errors.REQUIRED)
            .required(errors.REQUIRED),
        }),
      }),
    })
  );
};

export default validation;
