import moment from "moment";

const { filter, get, isEmpty } = require("lodash");
const { RELATIONSHIP, DATE_FORMAT } = require("utils/constants");

const SubscriptionRecap = ({ translate, generalCost, subscription }) => {
  const children = filter(
    get(subscription, "project.contact.relatedPersons", []),
    {
      relationship: RELATIONSHIP.CHILD,
    }
  );
  const spouse = get(subscription, "project.contact.relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );

  return (
    <div className="product__reacp">
      <p className="product__reacp--name">{translate.PRODUCT}</p>

      <div className="product__reacp--top --line">
        <div className="product__reacp--top--offer">
          <div className="content space">
            <span className="label">
              {get(subscription, "insurancePlan.name")}
            </span>
            <span className="price__tag">
              <span>{get(generalCost, "cost")} €</span>
              <sub>{translate.PRODUCTS.PER_MONTH}</sub>
            </span>
          </div>

          <div className="--date">
            <span>{translate.INSURANCE_DATE.ISSUANCE_DATE.LABEL} : </span>
            <p>
              {moment(
                get(subscription, "project.contract.issuanceDate")
              ).format(DATE_FORMAT)}
            </p>
          </div>
        </div>
      </div>

      <div className="members__recap">
        <div className="members__recap--child">
          <span className="--title">
            {translate.ADHERENT_INFO.BLOCK_TITLE} :
          </span>
          <div className="--item">
            <p className="--fullname">
              {`${get(subscription, "project.contact.user.firstname")} ${get(
                subscription,
                "project.contact.user.lastname"
              )}`}
            </p>
            <span className="--info">
              <span className="label">{translate.BIRTH_AT} :</span>
              <span className="value">
                {moment(
                  get(subscription, "project.contact.user.birthDate")
                ).format(DATE_FORMAT)}
              </span>
            </span>
            <span className="--info">
              <span className="label">{translate.SS_NUMBER} :</span>
              <span className="value">
                {get(subscription, "project.contact.socialSecurityNumber")}
              </span>
            </span>
          </div>
        </div>
        {!isEmpty(spouse) && (
          <div className="members__recap--child">
            <span className="--title">{translate.PARTNER.TITLE} :</span>
            <div className="--item">
              <p className="--fullname">
                {`${get(spouse, "identity.user.firstname")} ${get(
                  spouse,
                  "identity.user.lastname"
                )}`}
              </p>
              <span className="--info">
                <span className="label"> {translate.BIRTH_AT} :</span>
                <span className="value">
                  {moment(get(spouse, "identity.user.birthDate")).format(
                    DATE_FORMAT
                  )}
                </span>
              </span>
              <span className="--info">
                <span className="label"> {translate.SS_NUMBER} :</span>
                <span className="value">
                  {get(spouse, "identity.socialSecurityNumber")}
                </span>
              </span>
            </div>
          </div>
        )}
        {!isEmpty(children) && (
          <div className="members__recap--child">
            <span className="--title">{translate.PRODUCTS.CHILDREN}</span>
            {children.map(
              (
                {
                  identity: {
                    socialSecurityNumber,
                    user: { birthDate },
                  },
                },
                index
              ) => (
                <div className="--item " key={`child-${index}`}>
                  <p className="--fullname child">
                    {translate.CHILDREN.CHILD.TITLE} {index + 1}
                  </p>
                  <span className="--info">
                    <span className="label"> {translate.BIRTH_AT} :</span>
                    <span className="value">
                      {moment(birthDate).format(DATE_FORMAT)}
                    </span>
                  </span>
                  {socialSecurityNumber && (
                    <span className="--info">
                      <span className="label"> {translate.SS_NUMBER} :</span>
                      <span className="value">{socialSecurityNumber}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionRecap;
