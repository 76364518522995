import { gql } from "@apollo/client";

export const CONTACTS = gql`
  query Contacts(
    $where: ContactWhereInput
    $take: Int
    $skip: Int
    $like: JSONObject
    $orderBy: [JSONObject]
  ) {
    contacts(
      where: $where
      take: $take
      like: $like
      skip: $skip
      orderBy: $orderBy
    ) {
      data {
        id
        name
        qualifications {
          id
          qualifier {
            id
            name
          }
        }
        createdDate
        notes {
          id
          content
          createdDate
        }
        raw
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      count
    }
  }
`;

export const CONTACT = gql`
  query Contact($where: ContactWhereInput) {
    contact(where: $where) {
      id
      fields
      telecoms {
        id
        system
        value
      }
      user {
        id
        firstname
        lastname
        gender
        phone
        birthDate
      }
      relatedPersons {
        id
        relationship
        identity {
          id
          user {
            id
            firstname
            lastname
            birthDate
          }
        }
      }
      createdDate
    }
  }
`;

const COUNT_DISTINCT_CONTACT_WITH_QUOTE = gql`
  query countDistinctContactWithQuote(
    $where: ContactWhereInput
    $gt: ContactWhereInput
    $lt: ContactWhereInput
  ) {
    countDistinctContactWithQuote(where: $where, gt: $gt, lt: $lt)
  }
`;

const COUNT_DISTINCT_CONTACT_WITH_PROJECT = gql`
  query countDistinctContactWithProject(
    $where: ContactWhereInput
    $gt: ContactWhereInput
    $lt: ContactWhereInput
  ) {
    countDistinctContactWithProject(where: $where, gt: $gt, lt: $lt)
  }
`;

const contacts = {
  CONTACTS,
  CONTACT,
  COUNT_DISTINCT_CONTACT_WITH_PROJECT,
  COUNT_DISTINCT_CONTACT_WITH_QUOTE,
};

export default contacts;
