import Card from "antd/es/card/Card";
import Qualifiers from "entities/Qualifiers";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import Header from "shared/components/Header";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CREATE_QUALIFIER,
  UPDATE_QUALIFIER,
} from "utils/api/graphql/mutations/qualifiers";
import Create from "../Create";
import Detail from "../Detail";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFIER);
  const LIST = t("LIST", { returnObjects: true });
  const [pagination, setPagination] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState({});

  const [addQualifier] = useMutation(CREATE_QUALIFIER, {
    onCompleted: () => {
      setOpenCreate(false);
    },
  });

  const [updateQualifier] = useMutation(UPDATE_QUALIFIER, {
    onCompleted: () => {
      setOpenDetail("");
    },
  });

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () => setOpenCreate(true),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <Qualifiers
          View={View}
          filter={pagination}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
          setOpenDetail={setOpenDetail}
        />
        {openCreate && (
          <Create
            openCreate={openCreate}
            addQualifier={addQualifier}
            setOpenCreate={setOpenCreate}
          />
        )}
        {!isEmpty(openDetail) && (
          <Detail
            openDetail={openDetail}
            updateQualifier={updateQualifier}
            setOpenDetail={setOpenDetail}
          />
        )}
      </Card>
    </>
  );
};

export default List;
