import { Col, Row } from "antd";
import classNames from "classnames";
import cuid from "cuid";
import { useFormik } from "formik";
import { defaultTo, get } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  DEBIT_POSSIBLE_DATES,
  PAYMENT_FREQUENCIES,
} from "utils/constants";
import { bindInputProps, setDefaultIfEmpty } from "utils/helpers/input";
import Stepper from "../Components/Stepper";
import validation from "./validation";

const View = ({
  onBack,
  generalCost,
  onSubmit,
  subscription,
  initialValues,
  onPersonalInformationEdit,
  onSocialRegimeEdit,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  useEffect(() => {
    if (get(initialValues, "payment.payer.owner.isAdherent"))
      PAYMENT_PAYER[false]();
  }, []);

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  const PAYMENT_PAYER = {
    [true]: () => {
      if (subscription?.locked) return;
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        isAdherent: false,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue("payment.payer.owner.telecoms", []);
    },
    [false]: () => {
      if (subscription?.locked) return;
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        firstName: get(values, "contact.user.firstname"),
        lastName: get(values, "contact.user.lastname"),
        isAdherent: true,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue(
        "payment.payer.owner.telecoms",
        get(subscription, "project.contact.telecoms", [])
      );
    },
  };
  const PAYMENT_RECEIVER_VALUES = {
    [true]: (payer) => {
      setFieldValue("payment.receiver", payer);
    },
    [false]: () => {
      setFieldValue(
        "payment.receiver",
        defaultTo(get(values, "payment.receiver"), {
          id: cuid(),
          owner: {
            id: cuid(),
          },
        })
      );
    },
  };
  return (
    <Container size="medium">
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: true },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: false },
          ]}
        />

        <div className="b2c__area--header">
          <div className="b2c__area--header__title">
            {TRANSLATION.PAYMENT_INFORMATIONS}
          </div>
        </div>
        <div className="subscription__section">
          <div className="product__reacp">
            <div className="product__reacp--top">
              <div className="product__reacp--top--offer">
                <span className="label">
                  {get(subscription, "insurancePlan.name")}
                </span>
                <span className="price__tag">
                  <span>{get(generalCost, "cost")} €</span>
                  {TRANSLATION.PRODUCTS.PER_MONTH}
                </span>
              </div>
              <div className="product__reacp--top--infos">
                <div className="item">
                  <label>{TRANSLATION.ADHERENT_INFO.BLOCK_TITLE} :</label>
                  <p className="d-flex gap-4 align--center">
                    <span>
                      {get(subscription, "project.contact.user.firstname")}
                    </span>
                    <span>
                      {get(subscription, "project.contact.user.lastname")}
                    </span>
                  </p>
                  <Button
                    className="danger--link"
                    onClick={onPersonalInformationEdit}
                  >
                    {TRANSLATION.EDIT}
                  </Button>
                </div>
                <div className="item">
                  <label>{TRANSLATION.ADHERENT_INFO.REGIME} :</label>
                  <p>
                    {
                      TRANSLATION.MANDATORY_SCHEME.OPTIONS[
                        get(subscription, "project.contact.socialRegime")
                      ]
                    }
                  </p>
                  <Button className="danger--link" onClick={onSocialRegimeEdit}>
                    {TRANSLATION.EDIT}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="payment__section">
            <span className="payment__section--title">
              {TRANSLATION.BANK_ACCOUNT_INFORMATIONS}
            </span>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.IBAN.LABEL}
                  placeholder={TRANSLATION.IBAN.PLACEHOLDER}
                  {...bindInputProps({
                    name: `payment.payer.IBAN`,
                    values,
                    ...formProps,
                  })}
                  onChange={({ target: { value } }) => {
                    setFieldValue("payment.payer.IBAN", value);
                    PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                      ...get(values, "payment.payer"),
                      IBAN: value,
                    });
                  }}
                  disabled={subscription?.locked}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.BIC.LABEL}
                  placeholder={TRANSLATION.BIC.PLACEHOLDER}
                  {...bindInputProps({
                    name: `payment.payer.BIC`,
                    values,
                    ...formProps,
                  })}
                  onChange={({ target: { value } }) => {
                    setFieldValue("payment.payer.BIC", value);
                    PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                      ...get(values, "payment.payer"),
                      BIC: value,
                    });
                  }}
                  disabled={subscription?.locked}
                />
              </Col>
            </Row>
            <Input
              type="checkbox"
              label={TRANSLATION.PAYER_ACCOUNT.LABEL}
              {...bindInputProps({
                name: "payment.payer.owner.isAdherent",
                values,
                ...formProps,
              })}
              checked={get(values, "payment.payer.owner.isAdherent") === false}
              onChange={({ target: { checked } }) => PAYMENT_PAYER[checked]()}
              disabled={subscription?.locked}
            />
            {get(values, "payment.payer.owner.isAdherent") === false && (
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Input
                      label={TRANSLATION.OWNER.LASTNAME.LABEL}
                      placeholder={TRANSLATION.OWNER.LASTNAME.PLACEHOLDER}
                      {...bindInputProps({
                        name: `payment.payer.owner.lastName`,
                        values,
                        ...formProps,
                      })}
                      onChange={({ target: { value } }) => {
                        setFieldValue("payment.payer.owner.lastName", value);
                        PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")](
                          {
                            ...get(values, "payment.payer"),
                            owner: {
                              ...get(values, "payment.payer.owner"),
                              lastName: value,
                            },
                          }
                        );
                      }}
                      disabled={subscription?.locked}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Input
                      label={TRANSLATION.OWNER.FIRSTNAME.LABEL}
                      placeholder={TRANSLATION.OWNER.FIRSTNAME.PLACEHOLDER}
                      {...bindInputProps({
                        name: `payment.payer.owner.firstName`,
                        values,
                        ...formProps,
                      })}
                      onChange={({ target: { value } }) => {
                        setFieldValue("payment.payer.owner.firstName", value);
                        PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")](
                          {
                            ...get(values, "payment.payer"),
                            owner: {
                              ...get(values, "payment.payer.owner"),
                              firstName: value,
                            },
                          }
                        );
                      }}
                      disabled={subscription?.locked}
                    />
                  </Col>
                </Row>
                <div className="_refund_wrapper__caption">
                  {TRANSLATION.PAYER_CAPTION}
                </div>
              </>
            )}
            <Input
              type="checkbox"
              label={TRANSLATION.PARTICIPATION_COLLECTIVE_BENEFICIARY.TITLE}
              {...bindInputProps({
                name: "project.fields.info.participationCollectiveBeneficiary",
                values,
                ...formProps,
              })}
              disabled={subscription?.locked}
            />
            <div className="refund__section">
              <div className="refund__section--action">
                <div className="refund__section--action__left">
                  <span>{TRANSLATION.REFUND_ACCOUNT.LABEL}</span>
                </div>
                <div className="refund__section--right">
                  <div className="d-flex">
                    <Button
                      type={classNames({
                        primary: get(values, "isPayerReceiver") === true,
                        "primary--outlined":
                          get(values, "isPayerReceiver", false) === false,
                      })}
                      onClick={() => {
                        if (subscription?.locked) return;
                        setFieldValue("isPayerReceiver", true);
                        setFieldValue(
                          "payment.receiver",
                          get(values, "payment.payer")
                        );
                      }}
                    >
                      {TRANSLATION.YES}
                    </Button>
                    <Button
                      type={classNames({
                        primary: get(values, "isPayerReceiver") === false,
                        "primary--outlined":
                          get(values, "isPayerReceiver", true) === true,
                      })}
                      onClick={() => {
                        if (subscription?.locked) return;
                        setFieldValue("isPayerReceiver", false);
                        setFieldValue("payment.receiver", {
                          id: cuid(),
                          owner: {
                            id: cuid(),
                          },
                        });
                      }}
                    >
                      {TRANSLATION.NO}
                    </Button>
                  </div>
                </div>
              </div>
              {get(values, "isPayerReceiver") === false && (
                <div className="ria__section">
                  <span className="ria__section--title">
                    {TRANSLATION.OWNER.TITLE}
                  </span>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Input
                        label={TRANSLATION.OWNER.LASTNAME.LABEL}
                        placeholder={TRANSLATION.OWNER.LASTNAME.PLACEHOLDER}
                        {...bindInputProps({
                          name: `payment.receiver.owner.lastName`,
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Input
                        label={TRANSLATION.OWNER.FIRSTNAME.LABEL}
                        placeholder={TRANSLATION.OWNER.FIRSTNAME.PLACEHOLDER}
                        {...bindInputProps({
                          name: `payment.receiver.owner.firstName`,
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Input
                        label={TRANSLATION.IBAN.LABEL}
                        placeholder={TRANSLATION.IBAN.PLACEHOLDER}
                        {...bindInputProps({
                          name: `payment.receiver.IBAN`,
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Input
                        label={TRANSLATION.BIC.LABEL}
                        placeholder={TRANSLATION.BIC.PLACEHOLDER}
                        {...bindInputProps({
                          name: `payment.receiver.BIC`,
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>

            <span className="payment__section--title">
              {TRANSLATION.FREQUENCY_SAMPLING}
            </span>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Select
                  options={setDefaultIfEmpty(
                    get(subscription, "insurancePlan.fields.payment.frequency"),
                    Object.values(PAYMENT_FREQUENCIES)
                  ).map((value) => ({
                    value,
                    label: TRANSLATION.PAYMENT_FREQUENCY.OPTIONS[value],
                  }))}
                  label={TRANSLATION.PAYMENT_FREQUENCY.LABEL}
                  placeholder={TRANSLATION.PAYMENT_FREQUENCY.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.frequency",
                    values,
                    ...formProps,
                  })}
                  disabled={subscription?.locked}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Select
                  options={setDefaultIfEmpty(
                    get(subscription, "insurancePlan.fields.payment.debitDay"),
                    DEBIT_POSSIBLE_DATES
                  ).map((value) => ({
                    value,
                    label: value,
                  }))}
                  label={TRANSLATION.DEBIT_DAY.LABEL}
                  placeholder={TRANSLATION.DEBIT_DAY.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.debitDay",
                    values,
                    ...formProps,
                  })}
                  disabled={subscription?.locked}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="b2c__area--actions">
          <Button onClick={onBack} className="prev">
            <BsArrowLeftCircle size={32} />
            <span>{TRANSLATION.PREVIOUS}</span>
          </Button>
          <Button
            shape={true}
            className="b2c__area--actions__next"
            onClick={handleSubmit}
            disabled={!isValid}
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default View;
