import { get } from "lodash";
import View from "./View";

const ChildrenNumber = ({ project, onNext, onBack }) => {
  const next = (childrenNumber) =>
    onNext({
      payload: {
        fields: {
          ...project.fields,
          childrenNumber,
        },
      },
    });

  return (
    <View
      onNext={next}
      childrenNumber={get(project, "fields.childrenNumber")}
      onBack={onBack}
    />
  );
};

export default ChildrenNumber;
