import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import ProductCard from "../components/ProductCard";

const View = ({
  onSendQuote,
  onSubscribe,
  onBack,
  products,
  count,
  disabledProducts,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {format(TRANSLATION.PRODUCTS.TITLE, {
            count,
            s: count > 1 ? "s" : "",
          })}
        </div>
      </div>
      <div className="b2c__area--content">
        {products.map(({ cost, currency, insurancePlan }) => (
          <ProductCard
            key={insurancePlan.id}
            translate={TRANSLATION}
            cost={cost}
            currency={currency}
            name={get(insurancePlan, "name")}
            icon={get(insurancePlan, "icon")}
            description={get(insurancePlan, "description")}
            needs={get(insurancePlan, "needs")}
            onClick={() => onSendQuote(insurancePlan.id)}
            onSubscribe={() => onSubscribe(insurancePlan.id)}
            disabled={disabledProducts.includes(insurancePlan.id)}
          />
        ))}
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
      </div>
    </div>
  );
};

export default View;
