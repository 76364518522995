import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { NAVIGATION_DIRECTIONS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(NAVIGATION_DIRECTIONS.BACK);

  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER, {
    refetchQueries: [
      {
        query: graphql.queries.USER,
        awaitRefetchQueries: true,
        variables: {
          where: { id },
        },
      },
    ],
    onCompleted: () => {
      navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.USER, {
    variables: { where: { id } },
  });

  const onSubmit = (data) => {
    return updateUser({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.user, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;
