import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import CoverageBenefitsList from "./CoverageBenefits";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <>
      <Formik
        initialValues={data}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, ...formProps }) => [
          <Header
            key={"header"}
            title={DETAIL.HEADER.TITLE}
            subtitle={DETAIL.HEADER.ACTION}
            actions={[
              {
                text: DETAIL.HEADER.DISCARD,
                onClick: cancel,
                type: "gray--outlined",
                icon: <BiChevronLeft size={20} />,
              },
              {
                text: DETAIL.HEADER.DELETE,
                onClick: () =>
                  navigate(
                    `${SCREENS.PRIVATE.ADMIN.COVERAGES.DELETE.path}/${data.id}`,
                    { replace: true }
                  ),
                type: "danger--outlined",
                icon: <BiTrash size={20} />,
              },
              {
                text: DETAIL.HEADER.SAVE,
                onClick: handleSubmit,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />,
          <div key="coverage" className="coverage-container">
            <CoverageBenefitsList formProps={formProps} />
          </div>,
        ]}
      </Formik>
    </>
  );
};

export default Detail;
