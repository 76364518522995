const Header = ({ src, type, label }) => (
  <div className="coverage-header">
    <div className="coverage-header__icon">
      {src && <img className="coverage-header__icon" src={src} alt={type} />}
    </div>
    <span className="coverage-header__label">{label}</span>
  </div>
);

export default Header;
