import cuid from "cuid";
import { cloneDeep, get, isEmpty, isNumber, set } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "utils/api/graphql/mutations/attachment";
import { ATTACHMENTS } from "utils/api/graphql/queries/attachments";
import {
  DELTA_END_TYPES,
  INITIAL_VALUES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  INSURANCE_PLAN_PARAMS_RULES,
  PERIOD_UNITS,
  SCREENS,
} from "utils/constants";
import View from "./View";

const DELTA_END_VALUES = {
  [DELTA_END_TYPES.END_OF_YEAR]: () => 0,
  [DELTA_END_TYPES.ADD_PERIOD]: (start, end, unit = PERIOD_UNITS.DAYS) => {
    if (!isNumber(end) || !isNumber(start)) return null;
    return moment()
      .add(start || 0, "days")
      .add(end, unit)
      .diff(moment().startOf("day"), "days");
  },
  DEFAULT: () => null,
};

const calculateDeltaEndValue = (rules, startRuleKey, endRuleKey) => {
  const type = get(rules, `${endRuleKey}.type`) || "DEFAULT";
  return DELTA_END_VALUES[type](
    get(rules, `${startRuleKey}.value`),
    get(rules, `${endRuleKey}.value`),
    get(rules, `${endRuleKey}.unit`)
  );
};

const updateRulesWithDeltaValues = (rules) => {
  const newRules = cloneDeep(rules);

  const deltaEndValue = calculateDeltaEndValue(
    newRules,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_END
  );
  if (deltaEndValue !== null) {
    set(
      newRules,
      `${INSURANCE_PLAN_PARAMS_RULES.DELTA_END}.value`,
      deltaEndValue
    );
  }

  const deltaEndRiaValue = calculateDeltaEndValue(
    newRules,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
    INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA
  );
  if (deltaEndRiaValue !== null) {
    set(
      newRules,
      `${INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA}.value`,
      deltaEndRiaValue
    );
  }
  return newRules;
};

const convertRulesToEntries = (rules) => {
  return Object.entries(rules).map(([name, data]) => ({
    id: get(data, "id") || cuid(),
    name,
    value: get(data, "value"),
  }));
};

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const refetchAttachments = [
    {
      query: graphql.queries.ATTACHMENTS,
      variables: {
        where: {
          insurancePlans: { id },
        },
        isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      },
    },
  ];
  const [addAttachment] = useMutation(CREATE_ATTACHMENT, {
    refetchQueries: refetchAttachments,
  });
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    refetchQueries: refetchAttachments,
  });

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);

  const [updateInsurancePlan] = useMutation(
    graphql.mutations.UPDATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
        },
        {
          query: graphql.queries.INSURANCE_PLAN,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
      onCompleted: () =>
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path),
      onError: () => {},
    }
  );
  const { data: attachmentsData, loading: attachmentsLoading } = useQuery(
    ATTACHMENTS,
    {
      variables: {
        where: {
          insurancePlans: { id },
        },
        isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      },
    }
  );

  const { data, loading, error } = useQuery(graphql.queries.INSURANCE_PLAN, {
    variables: { where: { id } },
  });

  const onSubmit = (values) => {
    const {
      date,
      params,
      commissions,
      iconAttachment,
      attachments,
      newAttachments,
      ...rest
    } = values;
    const updatedParams = params
      .map((item) => ({
        id: item.id,
        rules: item.rules,
      }))
      .filter((item) => !isEmpty(item.rules))
      .map(({ id, rules }) => {
        return {
          id,
          rules: convertRulesToEntries(updateRulesWithDeltaValues(rules)),
        };
      });

    return updateInsurancePlan({
      variables: {
        where: { id },
        data: {
          ...rest,
          startDate: get(date, "0") || null,
          endDate: get(date, "1") || null,
          icon: iconAttachment,
          params: updatedParams,
          commissions: commissions.map((commission) => ({ id: commission })),
        },
      },
    });
  };

  if (loading || attachmentsLoading) return <Loading />;
  if (error) return "Error occurred";

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.insurancePlan, "__typename")}
      attachments={omitDeep(
        get(attachmentsData, "attachments.data", []),
        "__typename"
      )}
      cancel={cancel}
      addAttachment={addAttachment}
      deleteAttachment={deleteAttachment}
    />
  );
};

export default Detail;
