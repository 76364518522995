import View from "./View";

const OfferDrawer = ({
  open,
  createProject,
  setCreateOfferDrawer,
  tracers,
}) => {
  const onClose = () => {
    setCreateOfferDrawer(false);
  };

  return (
    <View
      onClose={onClose}
      open={open}
      createProject={createProject}
      tracers={tracers}
    />
  );
};

export default OfferDrawer;
