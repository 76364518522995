import { notification } from "antd";
import { findKey, isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { SEND_VERIFICATION_CODE } from "utils/api/graphql/mutations/users";
import { SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const SignInTwoFactorAuth = () => {
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const ERRORS = t("ERRORS", {
    returnObjects: true,
  });
  const navigate = useNavigate();
  const { setUser, accessToken } = useContext(StoreContext);

  const [checkUser, { loading }] = useLazyQuery(graphql.queries.CHECK_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ profile }) => onSignIn(profile),
  });

  const [sendVerificationCode] = useMutation(SEND_VERIFICATION_CODE);

  const onSubmit = ({ email, password }) => {
    sendVerificationCode({
      variables: {
        data: { email, password },
      },
      onCompleted: ({ sendTwoFactorAuthCode: { id, email } }) => {
        navigate(SCREENS.AUTH.VERIFICATION.path, {
          state: { id, email, password },
        });
      },
      onError: () => notification.error({ message: ERRORS.EMAIL_PASSWORD }),
    });
  };

  useEffect(() => {
    if (accessToken) checkUser();
  }, [checkUser, accessToken]);

  const onSignIn = (user) => {
    if (isEmpty(user)) return;
    setUser({ user });
    navigate(
      USER_TYPES[
        findKey(USER_TYPES, (element) => element.type === user?.meta?.type)
      ].index
    );
  };

  const onLinkClick = () => navigate(SCREENS.AUTH.FORGOT_PASSWORD.path);

  if (loading) return <Loading />;
  return <View onSubmit={onSubmit} onLinkClick={onLinkClick} />;
};

export default SignInTwoFactorAuth;
