import { SCREENS } from "utils/constants";
import Detail from "../../Broker/Projects/Detail";

const routes = [
  {
    path: `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/:id`,
    breadcrumb: SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.breadcrumb,
    exact: true,
    element: <Detail />,
    applyLayout: false,
  },
];

export default routes;
