import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { SOCIAL_REGIMES } from "utils/constants";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, socialRegime }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const [selectedOption, setSelectedOption] = useState(socialRegime);

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.ADHERENT_SOCIAL_REGIME.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.ADHERENT_SOCIAL_REGIME.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <div className="flex--grow-1">
          <Radio
            name={"gender"}
            value={selectedOption}
            size={1}
            values={[
              {
                label: TRANSLATION.SOCIAL_REGIMES.GENERAL_REGIME,
                value: SOCIAL_REGIMES.SECURITE_SOCIALE,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.ALSACE_MOSELLE_REGIME,
                value: SOCIAL_REGIMES.ALSACE_MOSELLE,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.AGRICULTURAL_EMPLOYEES_REGIME,
                value: SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES,
              },
              {
                label: TRANSLATION.SOCIAL_REGIMES.SELF_EMPLOYED_REGIME,
                value: SOCIAL_REGIMES.SECURITE_SOCIALE_INDEPENDANTS,
              },
            ]}
            onChange={setSelectedOption}
          />
        </div>
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={() => onNext(selectedOption)}
          disabled={!selectedOption}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
