import { useTranslation } from "react-i18next";
import { BsInfoCircle } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";

const Delete = ({ onSubmit, cancel, visible }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DELETE = t("DELETE", { returnObjects: true });
  return (
    <Modal
      okText={DELETE.OK}
      cancelText={DELETE.CANCEL}
      onCancel={cancel}
      onOk={onSubmit}
      message={DELETE.MESSAGE}
      visible={visible}
      icon={<BsInfoCircle color={COLORS.C_DANGER} size={32} />}
    />
  );
};

export default Delete;
