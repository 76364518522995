import { get } from "lodash";
import View from "./View";

const Needs = ({ project, onNext, onBack }) => {
  const needs = get(project, "fields.needs") || {
    hospitalization: 2,
    medicalExpenses: 2,
    optical: 2,
    dental: 2,
    hearingAid: 2,
    alternativeMedicine: 0,
  };
  const next = (needs) =>
    onNext({
      payload: {
        fields: {
          ...project.fields,
          needs,
        },
      },
    });

  return <View onNext={next} needs={needs} onBack={onBack} />;
};

export default Needs;
