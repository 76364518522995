import { useMemo } from "react";

import PropTypes from "prop-types";

import Async from "./Async";
import Simple from "./Simple";
import Subtext from "./Subtext";
import Tag from "./Tag";

const DIRECTIONS = ["column", "row"];
const MODES = {
  simple: Simple,
  tag: Tag,
  async: Async,
  subtext: Subtext,
};

const Select = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Select.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
  direction: PropTypes.oneOf(DIRECTIONS),
};

Select.defaultProps = {
  type: "simple",
  direction: "column",
};

export default Select;
