import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  const insurancePlanCoveragesSchema = yup.object().shape({
    id: requiredString,
    coverageCategory: yup.object().shape({
      id: requiredString,
    }),
    coverageMappings: yup
      .array()
      .of(
        yup.object().shape({
          id: requiredString,
          coverage: yup
            .object({ id: requiredString })
            .required(errors.REQUIRED),
          limits: yup
            .array()
            .of(
              yup.object().shape({
                id: requiredString,
                value: yup.object().shape({
                  value: requiredString,
                  category: requiredString,
                }),
              })
            )
            .min(1, errors.REQUIRED),
        })
      )
      .min(1, errors.REQUIRED),
  });
  return yup.object().shape({
    insurancePlanCoverages: yup.array().of(insurancePlanCoveragesSchema),
  });
};

export default validation;
