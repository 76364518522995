import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  return yup.object().shape({
    name: requiredString,
    type: requiredString,
    theme: requiredString,
  });
};

export default validation;
