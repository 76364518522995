import gql from "graphql-tag";

export const BUILD_GENERAL_COST = gql`
  mutation buildGeneralCost($data: GeneralCostDataInput) {
    buildGeneralCost(data: $data) {
      id
      cost
      currency
      commission {
        id
      }
    }
  }
`;

export const BUILD_GENERAL_COSTS = gql`
  mutation buildGeneralCosts($data: GeneralCostsDataInput) {
    buildGeneralCosts(data: $data) {
      id
      insurancePlan {
        id
      }
      cost
      currency
      commission {
        id
      }
    }
  }
`;

export const UPDATE_GENERAL_COST = gql`
  mutation UpdateGeneralCost(
    $where: GeneralCostWhereInput
    $data: GeneralCostDataInput
  ) {
    updateGeneralCost(where: $where, data: $data) {
      id
    }
  }
`;

const generalCosts = {
  BUILD_GENERAL_COST,
  UPDATE_GENERAL_COST,
  BUILD_GENERAL_COSTS,
};

export default generalCosts;
