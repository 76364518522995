import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Create from "./Create";
import Delete from "./Delete";
import Detail from "./Detail";
import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COVERAGE_CATEGORY]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={List}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.CREATE.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COVERAGE_CATEGORY]: SUBMODULES.ADMIN_CREATE }}
        WrappedComponent={Create}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.DETAIL.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COVERAGE_CATEGORY]: SUBMODULES.ADMIN_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.DELETE.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COVERAGE_CATEGORY]: SUBMODULES.ADMIN_DELETE }}
        WrappedComponent={Delete}
      />
    ),
  },
  // {
  //   path: `${SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.WEIGHT.path}`,
  //   exact: true,
  //   element: (
  //     <WithRoutePermission
  //       submodules={{ [MODULES.COVERAGE_CATEGORY]: SUBMODULES.ADMIN_DETAIL }}
  //       WrappedComponent={Weight}
  //     />
  //   ),
  // },
];

export default routes;
