import { isFunction } from "lodash";
import PropTypes from "prop-types";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";

const ActionStepper = ({
  onPrevious,
  onNext,
  prevLabel = "Précédent",
  nextLabel = "Suivant",
  isNextDisabled = false,
  isPrevDisabled = false,
  prevType = "gray--link",
  nextType = "primary",
  nextIcon = true,
}) => {
  return (
    <div className="action__stepper">
      {isFunction(onPrevious) && (
        <Button
          type={prevType}
          onClick={onPrevious}
          disabled={isPrevDisabled}
          className="action__stepper--previous"
        >
          <MdArrowBackIos />
          {prevLabel}
        </Button>
      )}

      {isFunction(onNext) && (
        <Button
          type={nextType}
          onClick={onNext}
          disabled={isNextDisabled}
          className="action__stepper--next"
        >
          {nextLabel}
          {nextIcon && <MdArrowForwardIos />}
        </Button>
      )}
    </div>
  );
};

ActionStepper.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  isNextDisabled: PropTypes.bool,
  nextIcon: PropTypes.bool,
};

ActionStepper.defaultProps = {
  prevLabel: "Précédent",
  nextLabel: "Suivant",
  isNextDisabled: false,
  prevType: "gray--link",
  nextType: "primary",
  nextIcon: true,
};

export default ActionStepper;
