import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline } from "react-icons/md";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const CopyOrSendLink = ({
  onCopyLink,
  onSendLink,
  link,
  selectedOption,
  linkCopied,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const GENERATE_LINK = t("LIST.SUBSCRIPTION.GENERATE_SUBSCRIPTION_LINK", {
    returnObjects: true,
  });
  if (!link)
    return (
      <div className="copy-send">
        <Spin fullscreen />
      </div>
    );
  return (
    <div className="copy-send">
      <div className="copy-send__success">
        <MdCheckCircleOutline size={24} />
        <p>{GENERATE_LINK.SUCCESS}</p>
      </div>
      <div className="copy-send__step">
        <strong>{GENERATE_LINK[selectedOption]?.TEXT}</strong>
      </div>
      <p className="copy-send__link">{link}</p>
      <div className="copy-send__actions">
        <Button type="primary--outlined" onClick={onCopyLink}>
          {linkCopied ? GENERATE_LINK.COPIED : GENERATE_LINK.COPY}
        </Button>
        <Button
          type="primary"
          size="full--width"
          className="flex--grow-1"
          onClick={onSendLink}
        >
          {GENERATE_LINK.SEND}
        </Button>
      </div>
    </div>
  );
};

export default CopyOrSendLink;
