import { Table } from "antd";
import cuid from "cuid";
import { get } from "lodash";
import React from "react";
import { BiSave, BiTrash } from "react-icons/bi";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { COLORS } from "shared/style/colors";
import { INSURANCE_PLAN_ATTACHMENTS_TYPES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Documents = ({
  translation,
  values,
  setFieldValue,
  formProps,
  addAttachment,
  deleteAttachment,
}) => {
  const saveAttachment = ({ attachment, insurancePlanId, index, values }) => {
    const {
      id,
      name,
      base64: content,
      contentType,
      type,
      filename,
    } = attachment;
    addAttachment({
      variables: {
        data: {
          id,
          insurancePlans: [{ id: insurancePlanId }],
          name,
          content,
          contentType,
          type,
          filename,
        },
      },
      onCompleted: ({ addAttachment }) => {
        const newAttachments = [...get(values, "newAttachments", [])];
        newAttachments.splice(index, 1);
        setFieldValue("newAttachments", newAttachments);
        setFieldValue("attachments", [
          addAttachment,
          ...get(values, "attachments", []),
        ]);
      },
    });
  };

  const deleteAttachments = ({ index, values }) => {
    const newAttachments = [...get(values, "newAttachments", [])];
    newAttachments.splice(index, 1);
    setFieldValue("newAttachments", newAttachments);
  };

  const removeAttachments = ({ id, index, values }) =>
    deleteAttachment({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        const attachments = [...get(values, "attachments", [])];
        attachments.splice(index, 1);
        setFieldValue("attachments", attachments);
      },
    });
  return (
    <>
      <Card title={translation.DOCUMENTS.TITLE} divider={false}>
        <Table
          dataSource={get(values, "attachments", [])}
          className="table__wrapper"
          columns={[
            {
              title: translation.DOCUMENTS.FILENAME,
              dataIndex: "filename",
              render: (item) => item || "-",
            },
            {
              title: translation.DOCUMENTS.NAME,
              dataIndex: "name",
              render: (item) => item || "-",
            },
            {
              title: translation.DOCUMENTS.FILETYPE,
              dataIndex: "type",
              render: (item) => translation.DOCUMENTS.TYPES[item] || "-",
            },
            {
              title: translation.DOCUMENTS.ACTIONS,
              dataIndex: "actions",
              render: (_, { id }, index) => {
                return (
                  <div className="d-flex">
                    <div
                      onClick={() =>
                        removeAttachments({
                          id,
                          values,
                          index,
                        })
                      }
                    >
                      <BiTrash color={COLORS.C_DANGER} size={24} />
                    </div>
                  </div>
                );
              },
            },
          ]}
          rowKey={"id"}
          pagination={false}
          scroll={{ x: true }}
        />
      </Card>
      <Card title={translation.DOCUMENTS.ADD} divider={false}>
        <Input
          type="file"
          variant="dragger"
          onUpload={(fileList) =>
            setFieldValue(
              "newAttachments",
              fileList.map(({ filename, name, ...rest }) => ({
                id: cuid(),
                filename: filename || name,
                name,
                ...rest,
              }))
            )
          }
          fileList={get(values, "newAttachments", [])}
          itemRender={() => null}
        />
        {!!get(values, "newAttachments.length", 0) && (
          <Table
            dataSource={get(values, "newAttachments", [])}
            className="table__wrapper"
            columns={[
              {
                title: translation.DOCUMENTS.FILENAME,
                dataIndex: "filename",
                render: (item) => item || "-",
              },
              {
                title: translation.DOCUMENTS.NAME,
                dataIndex: "name",
                render: (_, __, i) => (
                  <Input
                    placeholder={translation.NAME}
                    {...bindInputProps({
                      name: `newAttachments.${i}.name`,
                      values,
                      ...formProps,
                    })}
                  />
                ),
              },
              {
                title: translation.DOCUMENTS.FILETYPE,
                dataIndex: "type",
                render: (_, __, i) => (
                  <Select
                    placeholder={translation.FILETYPE}
                    options={Object.entries(
                      INSURANCE_PLAN_ATTACHMENTS_TYPES
                    ).map(([key, value]) => ({
                      label: translation.DOCUMENTS.TYPES[key],
                      value,
                    }))}
                    {...bindInputProps({
                      name: `newAttachments.${i}.type`,
                      values,
                      ...formProps,
                    })}
                  />
                ),
              },
              {
                dataIndex: "actions",
                render: (_, attachment, index) => (
                  <div className="d-flex">
                    <div
                      onClick={() =>
                        saveAttachment({
                          insurancePlanId: get(values, "id"),
                          attachment,
                          values,
                          index,
                        })
                      }
                    >
                      <BiSave color={COLORS.C_PRIMARY} size={24} />
                    </div>
                    <div
                      onClick={() =>
                        deleteAttachments({
                          values,
                          index,
                        })
                      }
                    >
                      <BiTrash color={COLORS.C_DANGER} size={24} />
                    </div>
                  </div>
                ),
              },
            ]}
            rowKey={"id"}
            pagination={false}
            scroll={{ x: true }}
          />
        )}
      </Card>
    </>
  );
};

export default React.memo(Documents);
