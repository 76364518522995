import dayjs from "dayjs";
import { get } from "lodash";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineCall,
  MdOutlineHealthAndSafety,
  MdOutlineLocationOn,
  MdWorkHistory,
  MdWorkOutline,
} from "react-icons/md";
import { STEPS_KEYS } from "screens/Private/Broker/Clients/Create";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import {
  CONTACT_EXERCISE_FRAME,
  DATE_FORMAT,
  JOB_LIST,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
export const step = { ADHERENT: "ADHERENT" };

const Adherent = ({
  index,
  translation,
  values,
  setFieldValue,
  ...formProps
}) => {
  const showExerciseFrame = (value) =>
    [
      SOCIO_PROFESSIONAL_CATEGORIES.FARMERS,
      SOCIO_PROFESSIONAL_CATEGORIES.ARTISANS,
      SOCIO_PROFESSIONAL_CATEGORIES.LIBERAL_PROFESSIONS_AND_SIMILAR,
      SOCIO_PROFESSIONAL_CATEGORIES.TRADERS_AND_SIMILAR,
      SOCIO_PROFESSIONAL_CATEGORIES.ENTREPRENEURS,
    ].includes(value);

  return (
    <div className="contact-stepper" key={step.ADHERENT}>
      <div className="contact-stepper__header">
        <div className="contact-stepper__header--counter">
          <span>{index}</span>
        </div>
        <div className="contact-stepper__header--content">
          <h1 className="contact-stepper__header--content--title">
            <span>
              {translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].TITLE}
            </span>
          </h1>
          <p className="contact-stepper__header--content--description">
            {translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].DESCRIPTION}
          </p>
        </div>
      </div>
      <div className="contact-stepper__content">
        {/* <Radio
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.CIVILITY
              .LABEL
          }
          options={[
            {
              label:
                translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.CIVILITY
                  .MALE,
              value: "M",
            },
            {
              label:
                translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.CIVILITY
                  .FEMALE,
              value: "F",
            },
          ]}
          icon={<MdOutlineBadge size={22} />}
          {...bindInputProps({
            name: "contact.user.gender",
            values,
            ...formProps,
          })}
        /> */}
        <Input
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.LASTNAME
              .LABEL
          }
          icon={<MdOutlineBadge size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.LASTNAME
              .PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.user.lastname",
            values,
            ...formProps,
          })}
        />
        <Input
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.FIRSTNAME
              .LABEL
          }
          icon={<MdOutlineBadge size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.FIRSTNAME
              .PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.user.firstname",
            values,
            ...formProps,
          })}
        />
        <Input
          icon={<MdOutlineCake size={22} />}
          label={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.BIRTHDAY
              .LABEL
          }
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[step.ADHERENT].FORM.BIRTHDAY
              .PLACEHOLDER
          }
          type="date"
          variant="simple"
          format={DATE_FORMAT}
          disabledDate={(current) => current > isMajor}
          defaultPickerValue={
            !get(values, "contact.user.birthDate") &&
            dayjs().subtract(30, "years")
          }
          {...bindDateInputProps({
            name: "contact.user.birthDate",
            values,
            setFieldValue,
            ...formProps,
          })}
        />
        <Input
          icon={<MdOutlineLocationOn size={22} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .ZIPCODE.PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .ZIPCODE.LABEL
          }
          {...bindInputProps({
            name: "zipCode.value",
            values,
            ...formProps,
          })}
        />
        <Select
          icon={<MdOutlineHealthAndSafety size={22} />}
          options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
            value,
            label: translation.SOCIAL_REGIMES[key],
          }))}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .SOCIAL_REGIME.PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .SOCIAL_REGIME.LABEL
          }
          {...bindInputProps({
            name: "contact.socialRegime",
            values,
            ...formProps,
          })}
          onChange={(value) => {
            setFieldValue("contact.socialRegime", value);
            if (value !== SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES) {
              setFieldValue("contact.socioProfessionalCategory", null);
              setFieldValue("contact.exerciseFrame", null);
            }
          }}
        />
        {get(values, "contact.socialRegime") ===
          SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES && (
          <Select
            options={Object.entries(SOCIO_PROFESSIONAL_CATEGORIES).map(
              ([key, value]) => ({
                value,
                label:
                  translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                    .SOCIO_PROFESSIONAL_CATEGORY.OPTIONS[key],
              })
            )}
            label={
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .SOCIO_PROFESSIONAL_CATEGORY.LABEL
            }
            icon={<MdWorkOutline size={16} />}
            placeholder={
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .SOCIO_PROFESSIONAL_CATEGORY.PLACEHOLDER
            }
            {...bindInputProps({
              name: "contact.socioProfessionalCategory",
              values,
              ...formProps,
            })}
            onChange={(value) => {
              setFieldValue("contact.socioProfessionalCategory", value);
              if (!showExerciseFrame(value))
                setFieldValue(
                  "contact.exerciseFrame",
                  CONTACT_EXERCISE_FRAME.EMPLOYEE
                );
            }}
          />
        )}
        {showExerciseFrame(
          get(values, "contact.socioProfessionalCategory")
        ) && (
          <Input
            type="radio"
            variant="group"
            icon={<MdWorkHistory size={16} />}
            label={
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .EXERCISE_FRAMEWORK.LABEL
            }
            values={[
              {
                value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                label:
                  translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                    .EXERCISE_FRAMEWORK.OPTIONS.EMPLOYEE,
              },
              {
                value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                label:
                  translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                    .EXERCISE_FRAMEWORK.OPTIONS.INDEPENDENT,
              },
            ]}
            {...bindInputProps({
              name: "contact.exerciseFrame",
              values,
              ...formProps,
            })}
          />
        )}
        <Select
          showSearch
          icon={<MdWorkOutline size={16} />}
          options={Object.entries(JOB_LIST).map(([key, value]) => ({
            label:
              translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
                .PROFESSION.JOB_LIST[key],
            value,
          }))}
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .PROFESSION.LABEL
          }
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM
              .PROFESSION.PLACEHOLDER
          }
          {...bindInputProps({
            name: "contact.profession",
            values,
            ...formProps,
          })}
        />
        <Input
          icon={<MdOutlineCall size={16} />}
          placeholder={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM.PHONE
              .PLACEHOLDER
          }
          label={
            translation.TEMPLATES.CONTACT.STEPS[STEPS_KEYS.ADHERENT].FORM.PHONE
              .LABEL
          }
          {...bindInputProps({
            name: "phoneNumber.value",
            values,
            ...formProps,
          })}
        />
      </div>
    </div>
  );
};
export default Adherent;
