import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle, BsArrowRightCircle, BsDownload } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import processDone from "shared/assets/icons/process_complete.svg";
import Button from "shared/components/Button";
import Container from "shared/components/Container";
import { NAME_SPACES } from "shared/locales/constants";
import Stepper from "../Components/Stepper";
import SubscriptionRecap from "../Components/SubscriptionRecap";

const View = ({
  onNext,
  generalCost,
  download,
  onBack,
  attachments,
  subscription,
}) => {
  const pageSize = 3;
  const pageLength = Math.ceil(attachments.length / pageSize);
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  return (
    <Container size="medium">
      <div className="b2c__area">
        <Stepper
          items={[
            { label: TRANSLATION.STTEPER.SITUATION, completed: true },
            { label: TRANSLATION.STTEPER.QUOTE, completed: true },
            { label: TRANSLATION.STTEPER.SUBSCRIPTION, completed: true },
            { label: TRANSLATION.STTEPER.VALIDATION, completed: true },
          ]}
        />

        <div className="b2c__area--header">
          <div className="b2c__area--header--icon">
            <img alt="done" src={processDone} />
          </div>
          <div className="b2c__area--header__title">
            {TRANSLATION.THANK_YOU.TITLE}
          </div>
          <div className="b2c__area--header__subtitle">
            {TRANSLATION.THANK_YOU.SUBTITLE}
          </div>
        </div>

        <div className="subscription__section">
          <SubscriptionRecap
            translate={TRANSLATION}
            generalCost={generalCost}
            subscription={subscription}
          />
        </div>
        <div className="documents__section">
          <span className="documents__section--title">
            {TRANSLATION.DOWNLOADS}
          </span>
          <div className="documents__section--content">
            {attachments
              .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
              .map(({ id, name }, index) => (
                <div
                  className="documents__section--content__card"
                  key={`document-${index}`}
                >
                  <span className="documents__section--content__card_title">
                    <FaRegFileAlt />
                    <span>{name}</span>
                  </span>
                  <Button type="danger--link" onClick={() => download(id)}>
                    <BsDownload />
                    {TRANSLATION.DOWNLOAD}
                  </Button>
                </div>
              ))}
          </div>
          <div className="documents__section--pagination">
            <Button
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={pageIndex < 1}
            >
              <BsArrowLeftCircle size={32} />
            </Button>
            <span>
              {pageIndex + 1} sur {pageLength} pages
            </span>
            <Button
              onClick={() => setPageIndex(pageIndex + 1)}
              disabled={pageIndex >= pageLength - 1}
            >
              <BsArrowRightCircle size={32} />
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default View;
