import {
  defaultTo,
  find,
  flatMap,
  get,
  isEmpty,
  isNil,
  mapValues,
  max,
  min,
  omitBy,
  pickBy,
} from "lodash";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_PARAMS_RULES,
} from "utils/constants";

export const joinList = (list, separator) => {
  const filteredList = list.filter((item) => !isNil(item));
  return filteredList.join(separator);
};

export const removeDuplicates = (array, proptety = "name") => {
  let seen = {};
  return array.filter((obj) => {
    return seen.hasOwnProperty(obj[proptety])
      ? false
      : (seen[obj[proptety]] = true);
  });
};

export const getFullAddress = ({ telecoms }) => {
  return `${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.ADDRESS_NUMBER,
      }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, { system: CONTACT_POINT_SYSTEM.REPETITION_INDEX }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.STREET_TYPE,
      }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.STREET,
      }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.COMPLEMENT,
      }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.ZIP_CODE,
      }),
      "value",
      ""
    ) || ``
  } ${
    get(
      find(telecoms, {
        system: CONTACT_POINT_SYSTEM.CITY,
      }),
      "value",
      ""
    ) || ``
  }`;
};

export const cleanObject = (obj) => {
  if (isNil(obj) || obj === "" || (Array.isArray(obj) && isEmpty(obj))) {
    return null;
  }

  if (typeof obj === "object" && !Array.isArray(obj)) {
    const cleanedObj = mapValues(obj, cleanObject);
    const filteredObj = pickBy(cleanedObj, (value) => !isNil(value));
    return isEmpty(filteredObj) ? null : filteredObj;
  }

  if (Array.isArray(obj)) {
    const cleanedArray = obj.map(cleanObject).filter((item) => !isNil(item));
    return isEmpty(cleanedArray) ? null : cleanedArray;
  }

  return obj;
};

export const removeEmptyValues = (obj) =>
  omitBy(
    obj,
    (value) =>
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
  );

export const getMinDeltaStart = ({
  insurancePlans,
  minValue = 30,
  key = "DELTA_START_RIA",
}) => {
  const values = flatMap(insurancePlans, (plan) => plan.params)
    .flatMap((param) => param.rules)
    .filter((rule) => rule.name === INSURANCE_PLAN_PARAMS_RULES[key])
    .map((rule) => rule.value);
  return defaultTo(min(values), minValue);
};

export const getMaxDeltaEnd = ({ insurancePlans, key = "DELTA_END_RIA" }) => {
  const values = flatMap(insurancePlans, (plan) => plan.params)
    .flatMap((param) => param.rules)
    .filter((rule) => rule.name === INSURANCE_PLAN_PARAMS_RULES[key])
    .map((rule) => rule.value);
  return (values.includes(0) ? 0 : max(values)) || 0;
};
