import { gql } from "@apollo/client";

const QUALIFIER_ADDED = gql`
  subscription qualifierAdded($where: QualifierWhereInput) {
    qualifierAdded(where: $where) {
      id
      name
      description
      priority
      isDefault
      isBlocking
      hasReminder
      fields
      qualifications {
        id
        createdDate
      }
    }
  }
`;

const QUALIFIER_UPDATED = gql`
  subscription qualifierUpdated($where: QualifierWhereInput) {
    qualifierUpdated(where: $where) {
      id
      name
      description
      priority
      isDefault
      isBlocking
      hasReminder
      fields
      qualifications {
        id
        createdDate
      }
    }
  }
`;

const QUALIFIER_DELETED = gql`
  subscription qualifierDeleted($where: QualifierWhereInput) {
    qualifierDeleted(where: $where) {
      id
      name
      description
      priority
      isDefault
      isBlocking
      hasReminder
      fields
      qualifications {
        id
        createdDate
      }
    }
  }
`;

const qualifiers = { QUALIFIER_ADDED, QUALIFIER_UPDATED, QUALIFIER_DELETED };

export default qualifiers;
