import gql from "graphql-tag";

export const CREATE_INSURANCE_PLAN = gql`
  mutation AddInsurancePlan($data: InsurancePlanDataInput) {
    addInsurancePlan(data: $data) {
      id
      internal
    }
  }
`;

export const UPDATE_INSURANCE_PLAN = gql`
  mutation UpdateInsurancePlan(
    $data: InsurancePlanDataInput
    $where: InsurancePlanWhereInput
  ) {
    updateInsurancePlan(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_INSURANCE_PLAN = gql`
  mutation DeleteInsurancePlan($where: InsurancePlanWhereInput) {
    deleteInsurancePlan(where: $where) {
      id
    }
  }
`;

const products = {
  CREATE_INSURANCE_PLAN,
  UPDATE_INSURANCE_PLAN,
  DELETE_INSURANCE_PLAN,
};

export default products;
