import gql from "graphql-tag";

export const CREATE_COVERAGE_CATEGORY = gql`
  mutation AddCoverageCategory($data: CoverageCategoryDataInput) {
    addCoverageCategory(data: $data) {
      id
    }
  }
`;
export const UPDATE_COVERAGE_CATEGORY = gql`
  mutation UpdateCoverageCategory(
    $where: CoverageCategoryWhereInput
    $data: CoverageCategoryDataInput
  ) {
    updateCoverageCategory(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_COVERAGE_CATEGORY = gql`
  mutation DeleteCoverageCategory($where: CoverageCategoryWhereInput) {
    deleteCoverageCategory(where: $where) {
      id
    }
  }
`;

const coverages = {
  CREATE_COVERAGE_CATEGORY,
  UPDATE_COVERAGE_CATEGORY,
  DELETE_COVERAGE_CATEGORY,
};

export default coverages;
