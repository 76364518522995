import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import {
  MdAlternateEmail,
  MdLocationCity,
  MdLocationPin,
  MdOutlineBadge,
  MdOutlineNumbers,
  MdPhone,
} from "react-icons/md";
import Radio from "shared/components/Form/Radio";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Representative = ({ formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const FORM = t("FORM", { returnObjects: true });

  return (
    <>
      <div className="grid--2">
        <Input
          placeholder={FORM.NUMBER}
          label={FORM.NUMBER}
          icon={<MdOutlineNumbers size={14} />}
          {...bindInputProps({
            name: "orias",
            ...formProps,
          })}
        />
        <Input
          placeholder={FORM.SOCIAL_REASON}
          label={FORM.SOCIAL_REASON}
          icon={<MdOutlineNumbers size={14} />}
          {...bindInputProps({
            name: "socialReason",
            ...formProps,
          })}
        />
      </div>
      {true && (
        <>
          <div className="grid--2">
            <Input
              icon={<MdOutlineNumbers size={14} />}
              placeholder={FORM.AGENT_CODE}
              label={FORM.AGENT_CODE}
              {...bindInputProps({ name: "code", ...formProps })}
            />
            <Input
              icon={<MdOutlineNumbers size={14} />}
              placeholder={FORM.RCS_NUMBER}
              label={FORM.RCS_NUMBER}
              {...bindInputProps({ name: "rcsNumber", ...formProps })}
            />
            <Input
              placeholder={FORM.ORGANIZATION_PHONE}
              label={FORM.ORGANIZATION_PHONE}
              icon={<MdPhone size={14} />}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ORGANIZATION_EMAIL}
              label={FORM.ORGANIZATION_EMAIL}
              icon={<MdAlternateEmail size={14} />}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.POSTAL_ADDRESS}
              label={FORM.POSTAL_ADDRESS}
              icon={<MdLocationPin size={14} />}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ZIP_CODE}
              label={FORM.ZIP_CODE}
              icon={<MdLocationPin size={14} />}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                ...formProps,
              })}
            />
            <Input
              icon={<MdLocationCity size={14} />}
              placeholder={FORM.CITY}
              label={FORM.CITY}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                ...formProps,
              })}
            />
          </div>
        </>
      )}
      <Radio
        isHorizontal={false}
        label={FORM.CIVILITY}
        options={[
          {
            label: FORM.MALE,
            value: GENDER.MALE,
          },
          {
            label: FORM.FEMALE,
            value: GENDER.FEMALE,
          },
        ]}
        icon={<BiUserCircle size={14} />}
        {...bindInputProps({ name: "gender", ...formProps })}
      />
      <Input
        placeholder={FORM.FIRSTNAME}
        label={FORM.FIRSTNAME}
        icon={<MdOutlineBadge size={14} />}
        {...bindInputProps({ name: "firstname", ...formProps })}
      />
      <Input
        placeholder={FORM.LASTNAME}
        label={FORM.LASTNAME}
        icon={<MdOutlineBadge size={14} />}
        {...bindInputProps({ name: "lastname", ...formProps })}
      />
      <Input
        placeholder={FORM.EMAIL}
        label={FORM.EMAIL}
        icon={<MdAlternateEmail size={14} />}
        {...bindInputProps({ name: "email", ...formProps })}
      />
      <Input
        placeholder={FORM.PHONE}
        label={FORM.PHONE}
        icon={<MdPhone size={14} />}
        {...bindInputProps({ name: "phone", ...formProps })}
      />
    </>
  );
};

export default Representative;
