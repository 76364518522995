import { isEmpty } from "lodash";
import { CONTACT_POINT_SYSTEM, REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (
  errors,
  { addressZipCodeCity: validateZipCode, setCity }
) => {
  return yup.object().shape({
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
      value: yup
        .string()
        .typeError(errors.REQUIRED)
        .test("isValid", errors.INVALID_VALUE, async function (value) {
          const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
          if (!isValidRegex) return false;
          const {
            data: { addressZipCodeCity },
          } = await validateZipCode({
            variables: {
              where: { zipCode: value },
            },
          });
          setCity((prev) => ({ ...prev, value: addressZipCodeCity[0]?.city }));
          return !isEmpty(addressZipCodeCity);
        }),
    }),
    [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
      value: yup
        .string()
        .nullable()
        .email(errors.EMAIL)
        .required(errors.REQUIRED),
    }),
    [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
      value: yup
        .string()
        .typeError(errors.REQUIRED)
        .required(errors.REQUIRED)
        .test("isValid", errors.INVALID_VALUE, async function (value) {
          return new RegExp(REGEX_EXPS.phone).test(value);
        }),
    }),
  });
};

export default validation;
