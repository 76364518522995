import View from "./View";

const Create = ({ updateQualifier, setOpenDetail, openDetail }) => {
  const onClose = () => setOpenDetail({});

  const onSubmit = (data) => {
    const { name, description } = data;
    return updateQualifier({
      variables: {
        where: { id: openDetail?.id },
        data: {
          name,
          description,
        },
      },
    });
  };

  return <View onSubmit={onSubmit} onClose={onClose} open={openDetail} />;
};

export default Create;
