import * as yup from "yup";

const validation = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);

  return yup.object().shape({
    insurancePlans: yup.array().of(
      yup.object().shape({
        insurancePlan: yup.object().shape({
          id: requiredString,
        }),
      })
    ),
  });
};

export default validation;
