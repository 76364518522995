import dayjs from "dayjs";
import { useFormik } from "formik";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { API_DATE_FORMAT, DATE_FORMAT } from "utils/constants";
import { bindDateInputProps } from "utils/helpers/input";
import Radio from "../components/Radio";

const View = ({ onNext, onBack, contract }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const [selectedOption, setSelectedOption] = useState(
    contract?.issuanceDate &&
      dayjs(contract?.issuanceDate).format(API_DATE_FORMAT)
  );

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: contract,
    onSubmit: onNext,
  });

  return (
    <div className="b2c__area">
      <div className="b2c__area--header">
        <div className="b2c__area--header__title">
          {TRANSLATION.INSURANCE_DATE.TITLE}
        </div>
        <div className="b2c__area--header__subtitle">
          {TRANSLATION.INSURANCE_DATE.SUBTITLE}
        </div>
      </div>
      <div className="b2c__area--content">
        <Input
          label={TRANSLATION.INSURANCE_DATE.ISSUANCE_DATE.LABEL}
          placeholder={TRANSLATION.INSURANCE_DATE.ISSUANCE_DATE.PLACEHOLDER}
          type="date"
          variant="simple"
          format={DATE_FORMAT}
          disabledDate={(current) => current < dayjs().startOf("day")}
          {...bindDateInputProps({
            name: "issuanceDate",
            setFieldValue,
            values,
            ...formProps,
          })}
        />
        <Radio
          value={selectedOption}
          size={1}
          values={[
            {
              label: TRANSLATION.INSURANCE_DATE.OPTIONS.TODAY,
              value: dayjs().format(API_DATE_FORMAT),
            },
            {
              label: TRANSLATION.INSURANCE_DATE.OPTIONS.TOMORROW,
              value: dayjs().add(1, "day").format(API_DATE_FORMAT),
            },
            {
              label: TRANSLATION.INSURANCE_DATE.OPTIONS.IN_A_WEEK,
              value: dayjs().add(1, "week").format(API_DATE_FORMAT),
            },
            {
              label: TRANSLATION.INSURANCE_DATE.OPTIONS.IN_A_MONTH,
              value: dayjs().add(1, "month").format(API_DATE_FORMAT),
            },
          ]}
          onChange={(date) => {
            setSelectedOption(date);
            setFieldValue("issuanceDate", dayjs(date).add(2, "h"));
          }}
        />
      </div>
      <div className="b2c__area--actions">
        <Button onClick={onBack}>
          <MdArrowBackIos />
          {TRANSLATION.PREVIOUS}
        </Button>
        <Button
          className="b2c__area--actions__next"
          onClick={handleSubmit}
          disabled={!get(values, "issuanceDate")}
        >
          {TRANSLATION.NEXT}
          <MdArrowForwardIos />
        </Button>
      </div>
    </div>
  );
};

export default View;
