import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "@bit/taillislabs.pega.button";
import { Col } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import Card from "shared/components/Card";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { PROCESS_TYPES, TEMPLATES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PROCESS);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const TEMPLATES_LABELS = t("TEMPLATES", { returnObjects: true });
  const TEMPLATES_THEMES = t("TEMPLATES_THEMES", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        name: "",
        type: PROCESS_TYPES.B2B,
        processStatuses: [],
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, values, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--3">
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", values, ...formProps })}
            />
            <Select
              options={Object.keys(TEMPLATES).map((value) => ({
                label: TEMPLATES_LABELS[value],
                value,
              }))}
              placeholder={FORM.TYPE}
              label={FORM.TYPE}
              {...bindInputProps({
                name: "type",
                values,
                ...formProps,
              })}
              onChange={(value) => {
                formProps.setFieldValue(`type`, value);
                formProps.setFieldValue(`theme`, null);
              }}
            />
            <Select
              options={Object.keys(
                get(TEMPLATES[get(values, "type")], "THEMES", {})
              ).map((key) => ({
                label: TEMPLATES_THEMES[key],
                value: key,
              }))}
              placeholder={FORM.THEME}
              label={FORM.THEME}
              {...bindInputProps({
                name: "theme",
                values,
                ...formProps,
              })}
            />
            {/* <InsurancePlans
              filter={{ take: null }}
              View={({ data }) => (
                <Select
                  mode="multiple"
                  options={data?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  placeholder={FORM.INSURANCE_PLANS}
                  label={FORM.INSURANCE_PLANS}
                  {...bindInputProps({
                    name: `insurancePlans`,
                    values,
                    ...formProps,
                  })}
                  onChange={(value) =>
                    formProps.setFieldValue(`insurancePlans`, value)
                  }
                />
              )}
              NoDate={() => <></>}
            /> */}
          </div>
          <Form title={FORM.STATUSES_INFO} key="form">
            <FieldArray
              name={"processStatuses"}
              render={(arrayHelpers) => [
                values.processStatuses.map((_, i) => [
                  <Col lg={5} key={`name-${_.id}`}>
                    <Input
                      placeholder={FORM.STATUSES.NAME}
                      label={FORM.STATUSES.NAME}
                      {...bindArrayInputProps({
                        parent: "processStatuses",
                        index: i,
                        name: "name",
                        values,
                        ...formProps,
                      })}
                    />
                  </Col>,
                  <Col lg={5} key={`progress-${_.id}`}>
                    <Input
                      placeholder={FORM.STATUSES.PROGRESS}
                      label={FORM.STATUSES.PROGRESS}
                      {...bindArrayInputProps({
                        parent: "processStatuses",
                        index: i,
                        name: "progress",
                        values,
                        ...formProps,
                      })}
                    />
                  </Col>,
                  <Col lg={5} key={`order-${_.id}`}>
                    <Input
                      label={FORM.STATUSES.ORDER}
                      placeholder={FORM.STATUSES.ORDER}
                      {...bindArrayInputProps({
                        parent: "processStatuses",
                        index: i,
                        name: "order",
                        values,
                        ...formProps,
                      })}
                    />
                  </Col>,
                  <Col lg={5} key={`template-${_.id}`}>
                    <Select
                      options={Object.entries(
                        get(
                          TEMPLATES,
                          `${get(values, "type")}.THEMES.${get(
                            values,
                            "theme"
                          )}`,
                          {}
                        )
                      ).map(([key, value]) => ({
                        label: TEMPLATES_LABELS[key],
                        value,
                      }))}
                      placeholder={FORM.STATUSES.TEMPLATE}
                      label={FORM.STATUSES.TEMPLATE}
                      {...bindArrayInputProps({
                        parent: "processStatuses",
                        index: i,
                        name: "template",
                        values,
                        ...formProps,
                      })}
                    />
                  </Col>,
                  <Col lg={2} key={`move-${_.id}`}>
                    <Input
                      type="checkbox"
                      placeholder={FORM.STATUSES.MOVE_TO_NEXT}
                      label={FORM.STATUSES.MOVE_TO_NEXT}
                      {...bindArrayInputProps({
                        parent: "processStatuses",
                        index: i,
                        name: "moveToNext",
                        values,
                        ...formProps,
                      })}
                      checked={values.processStatuses[i].moveToNext}
                    />
                  </Col>,
                  <Col lg={2} key={`delete-${_.id}`}>
                    <div
                      className={"delete-icon"}
                      onClick={() => {
                        arrayHelpers.remove(i);
                      }}
                    >
                      <DeleteOutlined style={{ color: "#f00", fontSize: 22 }} />
                    </div>
                  </Col>,
                ]),
                <Button
                  key="add-row"
                  onClick={() => {
                    arrayHelpers.push({ id: cuid(), moveToNext: false });
                  }}
                  bg={"#5462AA"}
                  title={FORM.BUTTON}
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                />,
              ]}
            />
          </Form>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
